<template>
  <div class="activity text-left mb-6">
    <div class="flex justify-between items-start mb-2 w-7/10">
      <div>
        <p class="text-base font-bold mb-3 text-neutral-500">Activity {{ index + 1 }}</p>
        <div class="flex gap-6">
          <div v-show="!isTemplateAct">
            <input type="checkbox" :id="'independent_parent' + index" v-model="data.isIndependent" class="styled-checkbox" />
            <label :for="'independent_parent' + index" class="text-sm">Independent Activity</label>
          </div>
          <div v-show="!isTemplateAct">
            <input type="checkbox" :id="'post_test_checkbox' + index" v-model="data.isPostTest" class="styled-checkbox" />
            <label :for="'post_test_checkbox' + index" class="text-sm">Mark as Post-Test</label>
          </div>
        </div>
        <p class="text-xs pl-4 ml-2 text-neutral-400 mb-2">Independent activity can be accessed immediately without waiting for previous activity</p>
      </div>
      <div v-if="index !== 0" class="flex justify-center items-center mt-1 mr-5">
        <button
          class="mr-2 p-1 border-1 rounded transition-all outline-none inline-flex justify-center items-center gap-2 bg-yellow-primary border-yellow-primary hover:bg-yellow-300 focus:outline-none"
          :class="[isFirstIndex && enableFirstAct ? 'bg-grey-field hover:bg-grey-field cursor-not-allowed' : '']"
          :disabled="isFirstIndex && enableFirstAct"
          @click="changePosition('up', index)"
        >
          <Chevron direction="up" height="16" width="16" color="#FFF" />
        </button>
        <button
          class="p-1 border-1 rounded transition-all outline-none inline-flex justify-center items-center gap-2 bg-yellow-primary border-yellow-primary hover:bg-yellow-300 focus:outline-none"
          :class="[isLastIndex ? 'bg-grey-field hover:bg-grey-field cursor-not-allowed' : '']"
          :disabled="isLastIndex"
          @click="changePosition('down', index)"
        >
          <Chevron direction="down" height="16" width="16" color="#FFF" />
        </button>
      </div>
      <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteItem(index)" v-show="index !== 0 || enableFirstAct" />
    </div>
    <div class="p-8 border rounded-lg w-7/10">
      <p class="text-sm font-medium mb-2 text-neutral-500">Activity Title</p>
      <div class="bg-neutral-100 p-4 rounded-lg mb-6" :class="{ 'is-invalid': isError?.error }">
        <div class="w-3/5 mb-4">
          <TextField type="text" isLabelRequire label="Title" v-model="data.title" :borderEnabled="true" placeholder="Activity Title" />
        </div>
      </div>
      <div class="child" v-for="(child, childIndex) in data.activityItems" :key="'child-' + childIndex">
        <ChildActivityForm
          :ref="'childActivity' + childIndex"
          :isTemplateAct="isTemplateAct"
          :isLastIndex="childIndex === data.activityItems.length - 1 ? true : false"
          :data="child"
          :index="childIndex"
          :parentIndex="index"
          :error="childError && childError[childIndex]"
          :classId="classId"
          :primaryInstructor="primaryInstructor"
          :initialInstructorList="initialInstructorList"
          :selectedWorkflow="selectedWorkflow"
          :isError="isError?.activityItems[childIndex]"
          @changePositionChild="changePositionChild($event, index)"
          @deleteChild="deleteChild($event, index)"
          @deleteInstructorActivityItem="deleteInstructorActivityItem"
          @onChange="$emit('onChange')"
        />
      </div>
      <div class="text-center">
        <Button buttonText="Add New Child Activity" type="tertiary" @action="addMoreChild()" additionalClass="bg-white" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    Trash: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Trash'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    ChildActivityForm: () => import(/* webpackChunkName: "ChildActivityForm" */ '@/views/jadwalkelas/ChildActivityForm')
  },
  props: {
    isError: {
      type: [Object, Boolean],
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    isTemplateAct: { type: Boolean, default: false },
    enableFirstAct: { type: Boolean, default: false },
    isFirstIndex: { type: Boolean, default: false },
    isLastIndex: { type: Boolean, default: false },
    index: null,
    error: {
      type: Object,
      default: () => {}
    },
    childError: null,
    classId: {
      type: String,
      default: ''
    },
    initialInstructorList: { type: [Object, Array], default: null },
    primaryInstructor: {
      type: [Object, Array],
      default: () => {}
    },
    selectedWorkflow: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activityTitle: null,
      visibleDeleteModal: false
    }
  },
  computed: {
    isEditMode() {
      return this.$route.meta.mode === 'Edit'
    },
    customerId() {
      return localStorage.getItem('client')
    }
  },
  methods: {
    addMoreChild() {
      const data = {
        title: null,
        description: null,
        activityItemType: null,
        itemReferenceId: null,
        itemLowResReferenceId: null,
        includeInCertificate: false
      }
      this.data.activityItems.push(data)
    },
    changePosition(position, index) {
      this.$emit('changePosition', position, index)
    },
    changePositionChild(childParams, index) {
      this.$emit('changePositionChild', childParams, index)
    },
    deleteItem(index) {
      this.$emit('delete', index)
    },
    deleteChild(index, activityIndex) {
      this.$emit('deleteChild', activityIndex, index)
    },
    deleteInstructorActivityItem(activityIndex, activityItemIndex) {
      this.$emit('deleteInstructorActivityItem', activityIndex, activityItemIndex)
    }
  }
}
</script>
<style lang="scss" scoped>
.rounded-lg {
  border-radius: 0.5rem !important;
}
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }

  // Box.
  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #f7931e;
    border-radius: 4px;
    margin-right: 10px;
  }

  // Box hover
  &:hover + label:before {
    background: #f7931e;
  }

  // Box checked
  &:checked + label:before {
    background: #f7931e;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.error-indicator {
  background-color: rgba($color: #eb5757, $alpha: 0.2);
}
.is-invalid {
  background-color: rgba(235, 87, 87, 0.1);
  border: 2px solid rgb(235, 87, 87);
}
</style>
