import store from './store'
let modeling = '{}'
let itemsMenu = []
if (window.localStorage.modeling) {
  modeling = window.localStorage.modeling
}

const ALLMENU = [
  {
    name: 'Overview',
    icon: () => import('@/components/Icons/DashboardLine'),
    isHaveGrandChild: false,
    active: false,
    url: '/class-schedule-overview'
  },
  {
    name: 'Registration',
    icon: () => import('@/components/Icons/Registration'),
    isHaveGrandChild: false,
    active: false,
    children: [
      {
        name: 'Profile',
        url: '/profile',
        icon: () => import('@/components/Icons/People')
      },
      {
        name: 'Document',
        url: '/document-client',
        icon: () => import('@/components/Icons/File')
      }
    ]
  },
  {
    name: 'LMS',
    icon: () => import('@/components/Icons/Master'),
    active: false,
    isHaveGrandChild: true,
    id: 'master',
    children: [
      {
        name: 'Master',
        icon: () => import('@/components/Icons/Master'),
        active: false,
        url: '/kelas',
        id: 'master',
        children: [
          {
            name: 'Class',
            url: '/kelas',
            icon: () => import('@/components/Icons/People')
          },
          {
            name: 'Class Schedule',
            url: '/jadwalkelas',
            icon: () => import('@/components/Icons/Reader')
          },
          {
            name: 'File Gallery',
            url: '/gallery',
            icon: () => import('@/components/Icons/File')
          },
          {
            name: 'Exercise',
            url: '/exercise',
            icon: () => import('@/components/Icons/Book')
          },
          {
            name: 'Certificate',
            url: '/certificate',
            icon: () => import('@/components/Icons/BookmarkOutline')
          },
          {
            name: 'Voucher',
            url: '/voucher',
            icon: () => import('@/components/Icons/PriceTag')
          },
          {
            name: 'Instructor',
            url: '/instructor',
            icon: () => import('@/components/Icons/Person')
          }
        ]
      },
      {
        name: 'Student',
        icon: () => import('@/components/Icons/Student'),
        active: false,
        id: 'student',
        url: '/absensi-report',
        children: [
          {
            name: 'Attendance Report',
            url: '/absensi-report',
            icon: () => import('@/components/Icons/Attendance')
          },
          {
            name: 'Answer of Student',
            url: '/answer',
            icon: () => import('@/components/Icons/DocumentText')
          }
        ]
      },
      {
        name: 'Ecommerce',
        icon: () => import('@/components/Icons/Ecommerce'),
        active: false,
        id: 'ecommerce',
        url: '/daily_ecommerce',
        children: [
          {
            name: 'Daily Ecommerce',
            url: '/daily_ecommerce',
            icon: () => import('@/components/Icons/Reader')
          },
          {
            name: 'Report Ecommerce',
            url: '/reportecommerce',
            icon: () => import('@/components/Icons/Reader')
          }
        ]
      }
    ]
  },
  {
    name: 'CRM',
    icon: () => import('@/components/Icons/User'),
    isHaveGrandChild: false,
    active: false,
    children: [
      {
        name: 'All Student',
        url: '/crm/student',
        icon: () => import('@/components/Icons/Person')
      },
      {
        name: 'Student Administration',
        url: '/crm/student-administration',
        icon: () => import('@/components/Icons/DocumentText')
      }
    ]
  },
  {
    name: 'OMS',
    icon: () => import('@/components/Icons/Cash'),
    active: false,
    isHaveGrandChild: false,
    id: 'order',
    children: [
      {
        name: 'All Order',
        url: '/order',
        icon: () => import('@/components/Icons/Cart')
      }
    ]
  },
  {
    name: 'Talent',
    active: false,
    isHaveGrandChild: false,
    icon: () => import('@/components/Icons/Talent'),
    children: [
      {
        name: 'Dashboard Talent',
        url: '/talent',
        icon: () => import('@/components/Icons/TalentDashboard')
      }
    ]
  },
  {
    name: 'Human Resources',
    active: false,
    isHaveGrandChild: false,
    icon: () => import('@/components/Icons/Hr'),
    children: [
      {
        name: 'HR Dashboard',
        url: '/dashboard',
        icon: () => import('@/components/Icons/HRDashboard')
      },
      {
        name: 'Attendance',
        url: '/attendance',
        icon: () => import('@/components/Icons/Attendance')
      },
      {
        name: 'Employee',
        url: '/employee',
        icon: () => import('@/components/Icons/People')
      },
      {
        name: 'Leave Type',
        url: '/leave-type',
        icon: () => import('@/components/Icons/CalendarV2')
      },
      {
        name: 'Leave Approval',
        url: '/leave-approval',
        icon: () => import('@/components/Icons/CheckmarkCircleV2')
      },
      {
        name: 'Leave Balance',
        url: '/leave-balance',
        icon: () => import('@/components/Icons/CalendarV2')
      }
    ]
  },
  {
    name: 'Settings',
    active: false,
    isHaveGrandChild: false,
    icon: () => import('@/components/Icons/Setting'),
    children: [
      {
        name: 'Integrations',
        url: '/integrations',
        icon: () => import('@/components/Icons/Option')
      },
      {
        name: 'Client Roles',
        url: '/roles',
        icon: () => import('@/components/Icons/Roles')
      },
      {
        name: 'Client Credentials',
        url: '/credentials',
        icon: () => import('@/components/Icons/Roles')
      }
    ]
  },
  {
    name: 'Curation',
    active: false,
    icon: () => import('@/components/Icons/Curation'),
    children: [
      {
        name: 'Curation Dashboard',
        url: '/curation',
        icon: () => import('@/components/Icons/CurationDashboard')
      }
    ]
  }
]

// itemsMenu = ALLMENU

// let customer = store.getters['customer/customer'].customerRoles[0].roles.forEach((e) => {
//   if (e === 'ADMIN') {
//     itemsMenu.push({})
//   } else if (e === 'LP_CURATION') {
//     itemsMenu.push()
//   }
// })
// export default {
//   items: itemsMenu
// }
export default ALLMENU
