<template>
  <div class="detail pb-6 w-full min-h-contain">
    <p class="text-3xl font-bold mb-4">Zoom</p>
    <div class="breadcrumb flex items-center gap-2 mb-4">
      <router-link to="/integrations" class="text-sm text-yellow font-medium">Integrations</router-link>
      <Chevron direction="right" width="10" height="10" color="#F7931E" />
      <p class="text-sm font-medium text-neutral-500 ellipsis">Zoom</p>
    </div>
    <div class="bg-white p-6 rounded-md shadow-soft">
      <div class="border-b border-neutral-50 mb-4 pb-2 flex justify-between items-center">
        <div class="font-semibold flex gap-1 items-start">
          <Label color="yellow" />
          <div class="flex flex-col gap-1">
            <p class="font-bold">Connected Accounts</p>
            <p class="text-smallest text-neutral-500 leading-3">Connected accounts can be used for auto-generate Webinar link on Class Schedule</p>
          </div>
        </div>
        <Button buttonText="Connect New Account" type="tertiary" @action="getAuthZoomURL()" />
      </div>
      <TableComponent :columns="columns" :list="zoomAccountList">
        <template slot="table-row" slot-scope="{ entry, column }">
          <div v-if="column.field === 'action'" class="text-right">
            <Button type="delete" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="toggleDeleteConfirmation(entry.id)" />
          </div>
          <div v-else-if="column.field === 'createdDate'">
            {{ formatDate(entry.createdDate) }}
          </div>
          <div v-else>{{ entry[column.field] }}</div>
        </template>
      </TableComponent>
      <div class="mt-6 flex justify-center items-center" v-if="totalRecords > 0">
        <Pagination :currentPage="currentPage" :totalCount="totalRecords" :pageSize="pageSize" @onPageChange="onPageChange" />
      </div>
    </div>
    <Modal :modalVisible="visibleDeleteConfirmation" @close="toggleDeleteConfirmation()" width="640px" id="delete">
      <template slot="modal-content">
        <div class="my-4">
          <p class="text-2xl font-bold mb-4">Are you sure to delete this account?</p>
          <p class="text-sm text-neutral-500">This action can not be undone!</p>
          <div class="flex items-center justify-center mt-8 gap-8 px-20">
            <Button buttonText="Delete" type="secondary" size="full" @action="deleteAccount()" />
            <Button buttonText="Cancel" type="primary" size="full" @action="toggleDeleteConfirmation()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
import moment from 'moment'
export default {
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Label: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Label'),
    TableComponent: () => import(/* webpackChunkName: "Simple-Table" */ '@/components/Table/SimpleTable'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination')
  },
  data() {
    return {
      columns: [
        { label: 'Account', field: 'name' },
        { label: 'Created Date', field: 'createdDate' },
        { label: 'Action', field: 'action', position: 'right' }
      ],
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      visibleDeleteConfirmation: false,
      selectedAccountId: null
    }
  },
  computed: {
    ...mapGetters('integration', ['zoomSetting', 'zoomAccountList']),
    customerId() {
      return localStorage.getItem('client')
    },
    filteredData() {
      return this.zoomAccountList.length > 0 ? this.zoomAccountList : this.dummyAccountList
    }
  },
  created() {
    this.getZoomSetting()
    this.getZoomAccountList()
    if (this.$route?.query?.code) {
      this.zoomAuth()
    }
  },
  methods: {
    ...mapActions('integration', ['GET_ZOOM_SETTING', 'ZOOM_AUTH_CODE', 'GET_ZOOM_ACCOUNT_LIST', 'DELETE_ZOOM_ACCOUNT']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    toggleDeleteConfirmation(id) {
      this.visibleDeleteConfirmation = !this.visibleDeleteConfirmation
      if (id) {
        this.selectedAccountId = id
      }
    },
    deleteAccount() {
      this.showLoading()
      this.toggleDeleteConfirmation()
      this.DELETE_ZOOM_ACCOUNT({
        customerId: this.customerId,
        zoomAccountId: this.selectedAccountId
      })
        .then(() => {
          this.hideLoading()
          showVueToast('Successfullty delete the account.', 'success', 2000)
          this.getZoomAccountList()
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Failed to delete the account. Please try again.', 'error', 2000)
        })
    },
    formatDate(date) {
      return moment(date).local().format('DD MMM YYYY HH:mm')
    },
    onPageChange(page) {
      this.currentPage = page
      this.getZoomAccountList(this.currentPage - 1)
    },
    getZoomAccountList(page = 0) {
      this.showLoading()
      let params = queryString.stringify({
        ...{
          page: page,
          size: 10,
          direction: 'DESC',
          sortBy: 'createdDate'
        }
      })
      this.GET_ZOOM_ACCOUNT_LIST({
        customerId: this.customerId,
        params: params
      }).then((res) => {
        this.totalRecords = res.data.pagination.total
        this.hideLoading()
      })
    },
    getZoomSetting() {
      this.GET_ZOOM_SETTING()
    },
    getAuthZoomURL() {
      window.location = `https://zoom.us/oauth/authorize?response_type=code&client_id=${this.zoomSetting.client_id}&redirect_uri=${this.zoomSetting.redirect_url}`
      //   window.location = `https://zoom.us/oauth/authorize?response_type=code&client_id=${this.zoomSetting.client_id}&redirect_uri=http://localhost:8083/zoomcallback`
    },
    zoomAuth() {
      this.showLoading()
      this.ZOOM_AUTH_CODE({
        customerId: this.customerId,
        payload: {
          authorizationCode: this.$route?.query?.code
        }
      })
        .then(() => {
          this.hideLoading()
          showVueToast('Successfullty connected to the new account.', 'success', 2000)
          this.getZoomAccountList()
          this.$router.push('/integrations/zoom')
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Failed to authorize the code. Please try again.', 'error', 2000)
          this.$router.push('/integrations/zoom')
        })
    }
  }
}
</script>
