var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ml-60 px-6 py-2 text-sm flex justify-center"
  }, [_vm._v("Copyright © " + _vm._s(_vm.getYear()) + ". PT Generasi Teknologi Buana")]);
}
var staticRenderFns = []

export { render, staticRenderFns }