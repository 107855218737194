var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading"
  }, [_c('div', [_c('p', [_vm._v("G")]), _c('p', [_vm._v("2")]), _c('p', [_vm._v("A")]), _c('p', [_vm._v("C")]), _c('p', [_vm._v("A")]), _c('p', [_vm._v("D")]), _c('p', [_vm._v("E")]), _c('p', [_vm._v("M")]), _c('p', [_vm._v("Y")])])]);
}]

export { render, staticRenderFns }