var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "text-2xl font-bold"
  }, [_vm._v("HR Dashboard")]), _c('div', {
    staticClass: "bg-white p-4 mt-4 rounded-lg shadow-md"
  }, [_c('p', {
    staticClass: "font-bold"
  }, [_vm._v("Attendance Record")]), _c('div', {
    staticClass: "flex items-center my-4"
  }, [_c('div', {
    staticClass: "mr-4"
  }, [_c('p', {
    staticClass: "text-sm my-2 text-gray-500"
  }, [_vm._v("Start date")]), _c('TextField', {
    attrs: {
      "type": "date",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.filter.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "startDate", $$v);
      },
      expression: "filter.startDate"
    }
  })], 1), _c('span', {
    staticClass: "mt-8"
  }, [_vm._v("-")]), _c('div', {
    staticClass: "ml-4"
  }, [_c('p', {
    staticClass: "text-sm my-2 text-gray-500"
  }, [_vm._v("End date")]), _c('TextField', {
    attrs: {
      "type": "date",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.filter.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "endDate", $$v);
      },
      expression: "filter.endDate"
    }
  })], 1), _c('div', {
    staticClass: "ml-4"
  }, [_c('p', {
    staticClass: "text-sm my-2 text-gray-500"
  }, [_vm._v("Group By")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.groupBy,
      expression: "filter.groupBy"
    }],
    staticClass: "form-control m-input p-3 bg-white border-2 rounded-lg",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filter, "groupBy", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v("-- Pilih --")]), _vm._l(_vm.selectGroupByList, function (data) {
    return _c('option', {
      key: data,
      staticClass: "text-sm",
      domProps: {
        "value": data
      }
    }, [_vm._v(" " + _vm._s(data) + " ")]);
  })], 2)]), _c('div', {
    staticClass: "flex items-center"
  }, [_c('button', {
    staticClass: "w-32 p-2 mx-3 mt-10 font-semibold inline bg-yellow text-white text-center rounded-lg",
    on: {
      "click": function click($event) {
        return _vm.fetchAttandancesSummary(_vm.filter);
      }
    }
  }, [_vm._v("Apply")]), _c('button', {
    staticClass: "p-2 bg-yellow mt-10 rounded-lg inline",
    on: {
      "click": function click($event) {
        _vm.filter.reset(), _vm.fetchAttandancesSummary(_vm.filter);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/change.png"),
      "alt": ""
    }
  })])])]), (_vm.chartBar ? _vm.chartBar.labels.length : false) ? _c('div', [_c('bar-chart', {
    attrs: {
      "data": _vm.chartBar,
      "options": _vm.chartOptions,
      "width": 800,
      "height": 320
    }
  })], 1) : _c('div', {
    staticClass: "flex h-96 justify-center items-center"
  }, [_c('p', [_vm._v("Data not found")])]), _c('div', {
    staticClass: "flex flex-wrap justify-center"
  }, _vm._l(_vm.pieData, function (data, idx) {
    return _c('div', {
      key: idx,
      staticClass: "flex items-center m-4"
    }, [_c('div', {
      staticClass: "mr-2 w-5 h-5 rounded-sm",
      style: "background: ".concat(data.color)
    }), _c('p', [_vm._v(_vm._s(data.title))])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }