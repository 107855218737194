<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 16.8454C11.8795 16.8454 11.7611 16.8139 11.6565 16.7541L5.59636 13.2905C5.54371 13.2601 5.48397 13.2441 5.42316 13.2441C5.36236 13.2442 5.30263 13.2602 5.25 13.2906C5.19736 13.3211 5.15368 13.3649 5.12333 13.4175C5.09299 13.4702 5.07706 13.53 5.07715 13.5908V16.8454C5.07705 16.969 5.11001 17.0903 5.17263 17.1968C5.23525 17.3033 5.32523 17.3912 5.43324 17.4512L11.6638 20.9126C11.7667 20.9697 11.8824 20.9997 12 20.9997C12.1177 20.9997 12.2334 20.9697 12.3362 20.9126L18.5668 17.4512C18.6748 17.3912 18.7648 17.3033 18.8274 17.1968C18.89 17.0903 18.923 16.969 18.9229 16.8454V13.5908C18.923 13.53 18.907 13.4702 18.8767 13.4175C18.8464 13.3649 18.8027 13.3211 18.75 13.2906C18.6974 13.2602 18.6377 13.2442 18.5769 13.2441C18.5161 13.2441 18.4563 13.2601 18.4037 13.2905L12.3436 16.7541C12.2389 16.8139 12.1205 16.8454 12 16.8454Z"
    />
    <path
      d="M22.3807 9.16564V9.16088C22.3695 9.05114 22.3322 8.94567 22.272 8.85325C22.2118 8.76083 22.1304 8.68413 22.0345 8.62955L12.3425 3.09126C12.2379 3.03146 12.1195 3 11.999 3C11.8785 3 11.7601 3.03146 11.6554 3.09126L1.96342 8.62955C1.85749 8.69011 1.76946 8.77759 1.70823 8.88314C1.64699 8.98868 1.61475 9.10852 1.61475 9.23054C1.61475 9.35256 1.64699 9.47241 1.70823 9.57795C1.76946 9.68349 1.85749 9.77097 1.96342 9.83153L11.6554 15.3698C11.7601 15.4296 11.8785 15.4611 11.999 15.4611C12.1195 15.4611 12.2379 15.4296 12.3425 15.3698L20.8698 10.4974C20.8829 10.4898 20.8979 10.4858 20.9131 10.4858C20.9283 10.4858 20.9433 10.4899 20.9564 10.4975C20.9696 10.5051 20.9805 10.5161 20.9881 10.5293C20.9957 10.5425 20.9996 10.5575 20.9996 10.5727V16.8262C20.9996 17.1988 21.286 17.5185 21.6585 17.5371C21.7521 17.5416 21.8457 17.5271 21.9335 17.4944C22.0213 17.4617 22.1016 17.4115 22.1694 17.3468C22.2373 17.2822 22.2913 17.2044 22.3282 17.1183C22.3651 17.0321 22.3841 16.9394 22.3841 16.8457V9.23054C22.3841 9.20886 22.3829 9.1872 22.3807 9.16564Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
