<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 1H4.5C3.96974 1.00058 3.46137 1.21148 3.08643 1.58643C2.71148 1.96137 2.50058 2.46974 2.5 3V13C2.50058 13.5303 2.71148 14.0386 3.08643 14.4136C3.46137 14.7885 3.96974 14.9994 4.5 15H11.5C12.0303 14.9994 12.5386 14.7885 12.9136 14.4136C13.2885 14.0386 13.4994 13.5303 13.5 13V3C13.4994 2.46974 13.2885 1.96137 12.9136 1.58643C12.5386 1.21148 12.0303 1.00058 11.5 1ZM8 9.5H5.5C5.36739 9.5 5.24021 9.44732 5.14645 9.35355C5.05268 9.25979 5 9.13261 5 9C5 8.86739 5.05268 8.74021 5.14645 8.64645C5.24021 8.55268 5.36739 8.5 5.5 8.5H8C8.13261 8.5 8.25979 8.55268 8.35355 8.64645C8.44732 8.74021 8.5 8.86739 8.5 9C8.5 9.13261 8.44732 9.25979 8.35355 9.35355C8.25979 9.44732 8.13261 9.5 8 9.5ZM10.5 7H5.5C5.36739 7 5.24021 6.94732 5.14645 6.85355C5.05268 6.75979 5 6.63261 5 6.5C5 6.36739 5.05268 6.24021 5.14645 6.14645C5.24021 6.05268 5.36739 6 5.5 6H10.5C10.6326 6 10.7598 6.05268 10.8536 6.14645C10.9473 6.24021 11 6.36739 11 6.5C11 6.63261 10.9473 6.75979 10.8536 6.85355C10.7598 6.94732 10.6326 7 10.5 7ZM10.5 4.5H5.5C5.36739 4.5 5.24021 4.44732 5.14645 4.35355C5.05268 4.25979 5 4.13261 5 4C5 3.86739 5.05268 3.74021 5.14645 3.64645C5.24021 3.55268 5.36739 3.5 5.5 3.5H10.5C10.6326 3.5 10.7598 3.55268 10.8536 3.64645C10.9473 3.74021 11 3.86739 11 4C11 4.13261 10.9473 4.25979 10.8536 4.35355C10.7598 4.44732 10.6326 4.5 10.5 4.5Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
