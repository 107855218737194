var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login bg-neutral-100"
  }, [_c('div', {
    staticClass: "flex justify-center items-center h-screen w-full relative"
  }, [_c('img', {
    staticClass: "absolute top-0 left-0 z-1",
    attrs: {
      "src": require("@/assets/images/octagon-shape-1.svg")
    }
  }), _c('img', {
    staticClass: "absolute bottom-0 right-0 z-1",
    attrs: {
      "src": require("@/assets/images/octagon-shape-2.svg")
    }
  }), _c('div', {
    staticClass: "login__form bg-white flex items-stretch rounded-lg shadow-small relative z-10"
  }, [_vm._m(0), _c('div', {
    staticClass: "w-7/12 p-16"
  }, [_vm._m(1), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "label": "Email",
      "type": "text",
      "placeholder": "Enter Your Email Address",
      "enterKeyAction": _vm.login,
      "border-enabled": ""
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Password")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": _vm.typeInput,
      "borderEnabled": true,
      "id": "password",
      "name": "password",
      "placeholder": "Enter Your Password",
      "enterKeyAction": _vm.login
    },
    on: {
      "input": function input($event) {
        return _vm.userIsTyping();
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm.typeInput === 'password' && _vm.isTyping ? _c('img', {
    staticClass: "w-5 absolute right-4 top-1/2 transform -translate-y-1/2",
    attrs: {
      "src": require("@/assets/images/open-eye.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.showPassword(true);
      }
    }
  }) : _vm._e(), _vm.typeInput === 'text' && _vm.isTyping ? _c('img', {
    staticClass: "w-5 absolute right-4 top-1/2 transform -translate-y-1/2",
    attrs: {
      "src": require("@/assets/images/close-eye.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.showPassword(false);
      }
    }
  }) : _vm._e()], 1)])]), _vm.loginError ? _c('div', {
    staticClass: "text-system-error text-center mb-6 mt-3 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.loginError) + " ")]) : _vm._e(), _c('div', {
    staticClass: "flex justify-end mt-4 mb-6"
  }, [_c('p', {
    staticClass: "text-sm font-semibold cursor-pointer text-yellow",
    on: {
      "click": function click($event) {
        return _vm.toggleForgotPassword();
      }
    }
  }, [_vm._v("Forgot password")])]), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Login",
      "size": "full"
    },
    on: {
      "action": _vm.login
    }
  })], 1)])])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleForgotPassword,
      "width": "640px",
      "id": "forgot-pass"
    },
    on: {
      "close": _vm.toggleForgotPassword
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [!_vm.isSuccess ? [_c('p', {
    staticClass: "text-2xl font-bold text-center mb-6 mt-6"
  }, [_vm._v("Reset Your Password")]), _c('p', {
    staticClass: "text-center mb-6"
  }, [_vm._v("Fill in your email address")]), _c('div', {
    staticClass: "p-1 mb-8 w-3/4 mx-auto"
  }, [_c('TextField', {
    attrs: {
      "borderEnabled": "",
      "placeholder": "Input Your Email Address"
    },
    model: {
      value: _vm.resetEmail,
      callback: function callback($$v) {
        _vm.resetEmail = $$v;
      },
      expression: "resetEmail"
    }
  })], 1), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.generalError ? _c('div', {
    staticClass: "text-system-error text-center mb-6 mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.generalError) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "text-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Reset Password"
    },
    on: {
      "action": function action($event) {
        return _vm.doReset();
      }
    }
  })], 1)] : [_c('div', {
    staticClass: "py-6"
  }, [_c('div', {
    staticClass: "flex justify-center items-center mb-6"
  }, [_c('G2Academy', {
    attrs: {
      "color": "yellow-primary"
    }
  })], 1), _c('p', [_vm._v(" Check your email at "), _c('strong', [_vm._v(_vm._s(_vm.resetEmail))]), _vm._v(" and follow the instruction to set a new password. ")])])]], 2)], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleCustomerRoles,
      "closeIconVisible": false,
      "width": "350px",
      "id": "customer-roles"
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "customer"
  }, [_c('div', {
    staticClass: "text-2xl font-bold mb-5"
  }, [_vm._v("Login as")]), _c('div', {
    staticClass: "relative cursor-pointer"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "placeholder": "Select Client",
      "border-enabled": "",
      "disabledWhite": ""
    },
    model: {
      value: _vm.customerSelected,
      callback: function callback($$v) {
        _vm.customerSelected = $$v;
      },
      expression: "customerSelected"
    }
  }), _c('div', {
    staticClass: "absolute right-3 top-1/2 pt-2 transform -translate-y-1/2"
  }, [_c('Chevron', {
    attrs: {
      "direction": "down",
      "width": "15",
      "color": "#58595B"
    }
  })], 1)], 1), _c('div', {
    staticClass: "dropdown invisible opacity-0 -mt-3 ease-linear transition-all duration-300 left-5 top-13 transform absolute cursor-default z-10"
  }, [_c('div', {
    staticClass: "bg-neutral-500 text-white text-left shadow-small py-4 px-3 rounded-lg",
    staticStyle: {
      "width": "310px"
    }
  }, _vm._l(_vm.customerOptions, function (person, index) {
    return _c('div', {
      key: index,
      staticClass: "cursor-pointer",
      class: index + 1 === _vm.customerOptions.length ? '' : 'mb-2',
      on: {
        "click": function click($event) {
          return _vm.clickCustomer(person);
        }
      }
    }, [_vm._v(" " + _vm._s(person.name) + " ")]);
  }), 0)])])])], 2), _vm.pageLoading ? _c('Loading') : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-5/12 screen"
  }, [_c('div', {
    staticClass: "screen__image"
  }), _c('div', {
    staticClass: "absolute top-0 left-0 w-full h-full z-10 screen__top flex justify-start flex-col items-center p-16"
  }, [_c('img', {
    staticClass: "w-48",
    attrs: {
      "src": require("@/assets/images/logo-admin2.svg")
    }
  }), _c('p', {
    staticClass: "text-lg font-semibold mt-2 text-center text-neutral-500"
  }, [_vm._v("Admin Center Dashboard")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center flex-col items-center"
  }, [_c('h1', {
    staticClass: "text-2xl font-semibold mt-2 mb-6"
  }, [_vm._v("Login")])]);
}]

export { render, staticRenderFns }