<template>
  <div class="new-schema pb-6 w-full min-h-contain">
    <div class="font-bold text-3xl mb-6 flex items-center">
      <button @click="toLMSClassListMenu()" class="mr-3 w-8 h-8 flex justify-center items-center focus:outline-none">
        <ArrowForward direction="left" />
      </button>
      <p>{{ $route.meta.label }} <span class="font-normal inline-block">(New Schema)</span></p>
    </div>
    <div class="text-xs bg-yellow-10 border border-yellow-100 rounded-xl shadow-soft px-4 py-4 mt-4 mb-4" v-if="hasIssue">
      <p class="flex text-xs gap-4 items-center">
        <Warning color="brown" />
        <span>
          <span class="font-semibold block text-brown text-lg">Issues found in this Class Schedule</span>
          <span class="text-xs block text-brown-500 mt-1">System has failed to auto-generate meeting link for your Webinar activity.</span>
        </span>
      </p>
    </div>
    <div class="tab flex justify-between items-end pl-4">
      <div class="flex gap-2">
        <div @click="selectTab('Detail')" class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer" :class="[selectedTab === 'Detail' ? 'font-bold bg-white' : 'bg-neutral-50']">
          Detail
        </div>
        <div
          @click="selectTab('Activities')"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg"
          :class="[selectedTab === 'Activities' ? 'font-bold bg-white' : 'bg-neutral-50', isActivitiesTabEnable ? 'cursor-pointer' : 'cursor-not-allowed']"
        >
          Activities
        </div>
        <div
          v-show="isEditMode"
          @click="selectTab('Students')"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedTab === 'Students' ? 'font-bold bg-white' : 'bg-neutral-50']"
        >
          Students
        </div>
        <div
          v-show="isEditMode"
          @click="selectTab('Grade')"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedTab === 'Grade' ? 'font-bold bg-white' : 'bg-neutral-50']"
        >
          Grade
        </div>
        <div
          v-show="isEditMode"
          @click="selectTab('Instructor Feedback')"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedTab === 'Instructor Feedback' ? 'font-bold bg-white' : 'bg-neutral-50']"
        >
          Instructor Feedback
        </div>
      </div>
      <div class="flex justify-end py-2">
        <Button
          :disabled="disableSubmit"
          v-show="selectedTab !== 'Instructor Feedback' && selectedTab !== 'Grade' && selectedTab !== 'Students'"
          :buttonText="isEditMode ? 'Update' : isCopyMode ? 'Copy' : 'Submit'"
          @action="save()"
          size="compact"
        />
      </div>
    </div>
    <template v-if="selectedTab === 'Detail'">
      <div class="shadow-small rounded-lg bg-white flex">
        <div class="form w-1/2 p-6 shadow-border-r">
          <div class="mb-6">
            <Dropdown :options="filterWorkflow" label="Workflow Type (Optional)" v-model="selectedWorkflow" :default="selectedWorkflow" placeholder="Select Workflow" optionLabel="name" />
          </div>
          <div class="mb-6">
            <TextField type="text" isLabelRequire label="Schedule Name" :borderEnabled="true" placeholder="Schedule Name" v-model="scheduleName" :error="error['name']" required />
          </div>
          <div class="mb-6">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Type<span class="text-red"> *</span></label>
            <Dropdown
              :options="typeList"
              v-model="selectedClassType"
              :disabled="isEditMode"
              optionLabel="name"
              :default="selectedClassType"
              :error="error['deliveryType']"
              placeholder="Select Class Type"
            />
          </div>
          <div class="mb-6">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">
              Class <span v-if="!visibleClass" class="inline-block text-neutral-300">(Please select the class type first)</span><span class="text-red"> *</span></label
            >
            <Dropdown
              :options="list_kelas"
              v-model="selectedClass"
              :default="selectedClass"
              placeholder="Select Class"
              optionLabel="nama"
              :error="error['coreClassId']"
              :disabled="!visibleClass || isEditMode"
            />
          </div>
          <div class="mb-6" v-if="coreClassVariantList.length > 0">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Class Variant<span class="text-red"> *</span></label>
            <Dropdown :options="coreClassVariantList" v-model="selectedVariant" :default="selectedVariant" placeholder="Select Class" optionLabel="variantName" :disabled="!selectedClass" />
          </div>
          <div class="mb-6" v-for="(instructor, idx) in selectedInstructorList" :key="'instructor-' + idx">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block"
              >Instructor <span v-if="!visibleInstructor" class="inline-block text-neutral-300">(Please select the class first)</span><span class="text-red"> *</span></label
            >
            <div class="flex items-center w-full">
              <div class="w-full">
                <Dropdown
                  :options="filteredInstructorList"
                  v-model="instructor.selectedInstructor"
                  :default="instructor.selectedInstructor"
                  placeholder="Select Instructor"
                  optionLabel="name"
                  :error="error['instructorId']"
                  :disabled="!visibleInstructor"
                  @search="searchInstructor"
                  maxHeight="200px"
                  :isLoadData="isLoadDataInstructor"
                  enableSearch
                />
              </div>
              <div class="w-10 flex justify-end ml-4" v-if="selectedInstructorList.length > 1">
                <Button
                  type="delete"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                  :tooltip="{ show: true, text: 'Delete' }"
                  @action="deleteInstructor(idx)"
                />
              </div>
            </div>
          </div>
          <div class="mb-6">
            <Button buttonText="Add More Instructor" :disabled="!visibleInstructor" size="compact" type="tertiary" @action="addInstructor()" />
          </div>
          <div class="">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Status</label>
            <Dropdown :options="statusList" v-model="selectedStatus" optionLabel="name" :default="selectedStatus" />
          </div>
        </div>
        <div class="form w-1/2 p-6">
          <div class="mb-6">
            <input type="checkbox" id="eligible_from_start_checkbox" v-model="isAvailableFromStart" class="styled-checkbox" />
            <label for="eligible_from_start_checkbox" class="text-sm">All Items Eligible from Start</label>
          </div>
          <div class="mb-6">
            <TextField type="text" label="Zoom Link" :borderEnabled="true" placeholder="Zoom Link" v-model="zoomLink" />
          </div>
          <div class="mb-6">
            <TextField type="text" label="Host Key" :borderEnabled="true" placeholder="Host Key" v-model="zoomHostKey" />
          </div>
          <div class="mb-6">
            <TextField type="text" label="Telegram Link" :borderEnabled="true" placeholder="Telegram Link" v-model="telegramLink" />
          </div>
          <div class="mb-6">
            <TextField
              :type="phoneInputType"
              placeholder="08xxxx"
              label="Customer Service Number"
              v-model="customerServiceNumber"
              maxlength="15"
              autocomplete="off"
              isStayShow
              borderEnabled
              :error="generalError['customerServiceNumber']"
            />
          </div>

          <div class="mb-6">
            <TextField type="text" label="Schedule Start End PMO (Optional)" :borderEnabled="true" placeholder="Schedule Start End PMO" v-model="scheduleStartEndPMO" />
          </div>
          <div class="mb-6">
            <TextField type="text" label="Schedule Code (Optional)" :borderEnabled="true" placeholder="Schedule Code" v-model="scheduleCodePMO" />
          </div>
          <div class="">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Time Limit</label>
            <Dropdown
                :options="timeLimitOptions"
                v-model="selectedTimeLimit"
                placeholder="Select Time Limit"
                optionLabel="label"
                :default="selectedTimeLimit"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-if="selectedTab === 'Activities'">
      <div v-if="isFetchActivityData" class="shadow-small rounded-lg bg-white p-56">
        <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
      </div>
      <div v-else class="shadow-small rounded-lg bg-white p-6">
        <div class="text-center py-16" v-if="activities.length === 0">
          <p class="text-neutral-500 mb-3">No activities added</p>
          <Button buttonText="Add New Activity" type="secondary" @action="addNewActivity()" />
        </div>
        <div class="" v-else>
          <div v-for="(activity, index) in activities" :key="'activity-' + index">
            <ActivityForm
              :key="refresh"
              :isLastIndex="index === activities.length - 1 ? true : false"
              :data="activity"
              :index="index"
              :error="errorActivity[index]"
              :childError="errorChildActivity[index]"
              :classId="selectedClass && selectedClass.id"
              :ref="'activityForm' + index"
              :primaryInstructor="selectedInstructorList"
              :initialInstructorList="instructorList"
              :selectedWorkflow="selectedWorkflow"
              :isError="activityErrorHighlight[index]"
              @changePosition="changePosition('activity', ...arguments)"
              @changePositionChild="changePosition('child', ...arguments)"
              @delete="openDeleteModal('activity', ...arguments)"
              @deleteChild="openDeleteModal('child', ...arguments)"
              @deleteInstructorActivityItem="deleteInstructorActivityItem"
              @onChange="updateActivities()"
            />
          </div>
          <Button buttonText="Add New Activity" type="secondary" @action="addNewActivity()" />
        </div>
      </div>
    </template>
    <template v-if="selectedTab === 'Students'">
      <div class="shadow-small rounded-lg bg-white flex">
        <div class="form p-6 transition-all duration-200" :class="[isAddStudentActive ? 'w-1/2' : 'w-full']">
          <div class="flex w-full justify-between mb-4 items-center border-b border-neutral-50 pb-4">
            <p class="font-semibold flex gap-1 items-center">
              <span class="-ml-1"><Label color="yellow" /></span>List of Students
            </p>
            <Button buttonText="Add Students" type="tertiary" v-if="!isAddStudentActive" @action="isAddStudentActive = !isAddStudentActive" />
          </div>
          <div>
            <div v-if="studentList.length > 0">
              <StudentTable :columns="columnsOfStudentTable" :list="studentList">
                <template slot="table-row" slot-scope="{ entry, column }">
                  <div v-if="column.label === 'Action'">
                    <Button
                      class="ml-3"
                      type="delete"
                      size="icon"
                      :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                      :tooltip="{ show: true, text: 'Delete' }"
                      @action="toogleDeleteStudentAlertModal(entry)"
                    />
                  </div>
                  <div v-else>{{ entry[column.field] }}</div>
                </template>
              </StudentTable>
              <div v-if="totalRowsStudent > sizeStudent" class="mt-3 flex justify-center items-center">
                <Pagination :currentPage="pageForPaginationStudent" :totalCount="totalRowsStudent" :pageSize="sizeStudent" @onPageChange="onPageChangeStudent" />
              </div>
            </div>
            <div v-else class="w-full h-60 border border-neutral-250 rounded-lg text-sm bg-white flex justify-center items-center">
              <p>No student available</p>
            </div>
          </div>
        </div>
        <div class="form p-6 shadow-border-l w-1/2 transition-all duration-200" v-if="isAddStudentActive">
          <div class="flex justify-between items-center mb-4 border-b border-neutral-50 pb-4">
            <p class="font-semibold flex gap-1 items-center">
              <span class="-ml-1"><Label color="yellow" /></span>Add Students
            </p>
            <span class="cursor-pointer" @click="isAddStudentActive = !isAddStudentActive">
              <Close />
            </span>
          </div>
          <div>
            <div class="border rounded-lg p-5">
              <div class="mb-5">
                <p class="mb-3 text-sm">1. Apply filter</p>
                <div class="bg-neutral-100 rounded-lg p-3">
                  <div class="mb-6">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Source</label>
                    <Dropdown :options="source" v-model="selectedSource" :default="selectedSource" placeholder="Select Source" optionLabel="name" />
                  </div>
                  <div class="mb-6" v-if="selectedSource.value === 'SCHOLARSHIP'">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Program</label>
                    <Dropdown :options="scholarshipPrograms" v-model="scholarshipProgramSelected" :default="scholarshipProgramSelected" placeholder="Select Class Type" optionLabel="name" />
                  </div>
                  <div class="mb-6" v-else>
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Program</label>
                    <Dropdown :disabled="isDisabled" :options="programs" v-model="selectedProgramStudent" :default="selectedProgramStudent" placeholder="Select Class Type" optionLabel="name" />
                  </div>
                  <div class="mb-6">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">
                      Status
                      <span v-show="selectedProgramStudent.value === ''" class="inline-block text-neutral-300">(Please select the Program first)</span>
                    </label>
                    <Dropdown
                      :disabled="isDisabled || selectedProgramStudent.value === ''"
                      :options="pipelineStage"
                      v-model="selectedPipelineStage"
                      :default="selectedPipelineStage"
                      placeholder="Select Status"
                      optionLabel="name"
                    />
                  </div>
                  <div class="flex items-center">
                    <Button class="mr-3" buttonText="Apply" type="secondary" @action="getProspectList()" />
                    <Button buttonText="Reset" type="tertiary" @action="resetFilterStudent()" />
                  </div>
                </div>
              </div>
              <div>
                <p class="mb-3 text-sm">2. Add student</p>
                <div v-if="isFormRegisterVisible" class="bg-neutral-100 rounded-lg p-3">
                  <div class="mb-6">
                    <TextField placeholder="Enter name" label="Name" isStayShow v-model="userFullName" autocomplete="off" borderEnabled :error="generalError['userFullName']" />
                  </div>
                  <div class="mb-6">
                    <TextField placeholder="Enter email" label="Email" isStayShow v-model="userEmail" autocomplete="off" borderEnabled :error="generalError['userEmail']" />
                  </div>
                  <div class="mb-5">
                    <TextField
                      :type="phoneInputType"
                      placeholder="Enter phone number"
                      label="Phone Number"
                      v-model="userPhoneNumber"
                      maxlength="15"
                      autocomplete="off"
                      borderEnabled
                      isStayShow
                      :error="generalError['userPhoneNumber']"
                    />
                  </div>
                  <div class="mb-5">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block"> Digital Platform <span class="inline-block text-neutral-300">(Prakerja Only)</span></label>
                    <Dropdown :options="dpList" optionLabel="name" v-model="ecommerce" @change="searchByEcommerce()" :default="ecommerce" placeholder="Select Ecommerce" />
                  </div>
                  <div class="mb-6">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block"> Voucher Code <span class="inline-block text-neutral-300">(Prakerja Only)</span></label>
                    <TextField placeholder="Enter voucher" v-model="voucerCode" autocomplete="off" borderEnabled />
                  </div>
                  <div class="mb-6">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block"> Redeem Code <span class="inline-block text-neutral-300">(Prakerja Only)</span></label>
                    <TextField placeholder="Enter voucher" v-model="redeemCode" autocomplete="off" borderEnabled />
                  </div>
                  <div class="mb-6">
                        <div class="leading-0 flex items-center gap-3 relative">
                          <input type="checkbox" id="female" v-model="isCurator" class="styled-checkbox" :disabled="modeForm === 'Detail'" />
                          <label for="female">Mark as Kurator</label>
                        </div>
                    </div>
                  <div class="mb-3 flex justify-center">
                    <Button size="compact" buttonText="Add New Student" @action="addNewStudent" />
                  </div>
                </div>
                <div v-else class="bg-neutral-100 rounded-lg p-3">
                  <div class="relative mb-6">
                    <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search</label>
                    <div class="relative">
                      <TextField @keyUpAction="searchProspect" :enterKeyAction="searchProspect" borderEnabled withIcon v-model="keyword" placeholder="Search name, email, phone number" />
                      <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                        <Search />
                      </div>
                    </div>
                  </div>
                  <div v-show="selectedProspect.length > 0" class="flex justify-between items-center">
                    <p class="text-sm">{{ selectedProspect.length }} selected students</p>
                    <Button buttonText="Add Selected Student" size="compact" @action="showAddStudentModal()" />
                  </div>
                  <div v-if="prospectList.length > 0">
                    <DataTable
                      :tableHeader="['Name', 'Phone Number']"
                      :tableHeaderAlign="['left', 'left']"
                      :checkbox="true"
                      :actionCheckbox="actionCheckbox"
                      :resetSelectedData="isReset"
                      :list="prospectList"
                      :listAlign="['left', 'left']"
                      :actionButtons="[
                        {
                          additionalStyle: 'p-1.5',
                          height: '20',
                          width: '20',
                          viewBox: '0 0 20 20',
                          paths: [
                            {
                              d: 'M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM13.75 10.75H10.75V13.75C10.75 13.9489 10.671 14.1397 10.5303 14.2803C10.3897 14.421 10.1989 14.5 10 14.5C9.80109 14.5 9.61032 14.421 9.46967 14.2803C9.32902 14.1397 9.25 13.9489 9.25 13.75V10.75H6.25C6.05109 10.75 5.86032 10.671 5.71967 10.5303C5.57902 10.3897 5.5 10.1989 5.5 10C5.5 9.80109 5.57902 9.61032 5.71967 9.46967C5.86032 9.32902 6.05109 9.25 6.25 9.25H9.25V6.25C9.25 6.05109 9.32902 5.86032 9.46967 5.71967C9.61032 5.57902 9.80109 5.5 10 5.5C10.1989 5.5 10.3897 5.57902 10.5303 5.71967C10.671 5.86032 10.75 6.05109 10.75 6.25V9.25H13.75C13.9489 9.25 14.1397 9.32902 14.2803 9.46967C14.421 9.61032 14.5 9.80109 14.5 10C14.5 10.1989 14.421 10.3897 14.2803 10.5303C14.1397 10.671 13.9489 10.75 13.75 10.75Z',
                              fill: '#F7931E'
                            }
                          ],
                          next: addStudent
                        }
                      ]"
                      :counter="counter"
                      :totalPages="totalPages"
                      :totalItems="totalRows"
                      :size="size"
                    />
                    <div v-if="totalRows > 10" class="mt-3 flex justify-center items-center">
                      <Pagination :currentPage="pageForPagination" :totalCount="totalRows" :pageSize="size" @onPageChange="onPageChange" />
                    </div>
                  </div>
                  <div v-else class="w-full h-60 border border-neutral-250 rounded-lg text-sm bg-white flex justify-center items-center">
                    <p>No student available</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="selectedTab === 'Instructor Feedback'">
      <div class="shadow-small rounded-lg bg-white p-6">
        <div class="flex items-center justify-between w-full mb-5">
          <div class="w-1/2">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Select Feedback</label>
            <Dropdown
              :options="feedbackList"
              :disabled="feedbackList.length === 0 || !feedbackList"
              v-model="feedbakSelected"
              :default="feedbakSelected"
              placeholder="No feedback selected"
              optionLabel="title"
            />
          </div>
          <div class="pt-3">
            <Button buttonText="Download" type="secondary" @action="downloadFeedback()" :disabled="studentFeedback.length === 0" />
          </div>
        </div>
        <div v-if="!isFetchFeedbackInstructor && studentFeedback.length > 0">
          <TableComponent :columns="feedbackColumn" :list="studentFeedback"
            ><template v-slot="{ entry, column }">
              <div v-if="column.field === 'student_name'" class="truncate">
                {{ entry[column.field] || 'undefined' }}
              </div>
              <div class="" v-else>{{ sliceString(entry[column.field]) || '-' }}</div>
            </template>
          </TableComponent>
        </div>
        <div class="flex justify-center items-center py-32" v-if="!isFetchFeedbackInstructor && studentFeedback.length === 0">
          <p>No data available</p>
        </div>
      </div>
    </template>
    <template v-if="selectedTab === 'Grade'">
      <div class="shadow-small rounded-lg bg-white p-6">
        <div v-if="!isLevel1Available" class="flex justify-center items-center">
          <template v-if="isFetching">
            <vue-simple-spinner size="large" class="py-32"></vue-simple-spinner>
          </template>
          <div class="py-20" v-else>
            <img src="@/assets/images/empty-content.svg" alt="empty" class="mx-auto" />
            <div class="font-bold text-2xl py-5">This schedule doesn’t have grading system</div>
            <div class="flex justify-center items-center gap-8">
              <Button buttonText="Create New" type="primary" @action="showCreateNewGradingModal()" />
              <Button buttonText="Copy from Other Schedule" type="secondary" @action="showCopyFromScheduleModal()" />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="tab flex mb-3">
            <div
              v-for="(tabGrading, tabIndex) in tabLevel"
              :key="tabIndex"
              @click="selectGradingTab(tabGrading)"
              class="tab__item text-sm px-5 pb-2 rounded-tl-lg cursor-pointer font-semibold"
              :class="[tabGrading === levelTabSelected ? 'text-yellow border-yellow border-b-2' : 'text-neutral-400 border-neutral-400 border-b']"
            >
              {{ `Level ${tabGrading}` }}
            </div>
            <div
              @click="selectGradingTab('Summary')"
              class="text-sm px-5 pb-2 rounded-tl-lg font-semibold"
              :class="[
                'Summary' === levelTabSelected ? 'text-yellow border-yellow border-b-2 ' : 'text-neutral-400 border-neutral-400 border-b ',
                isSummaryTabEnable ? 'cursor-pointer' : 'cursor-not-allowed'
              ]"
            >
              Summary
            </div>
          </div>
          <template>
            <div class="">
              <template v-if="isFetching">
                <vue-simple-spinner size="large" class="py-32"></vue-simple-spinner>
              </template>
              <template v-else>
                <div class="flex justify-between items-center mb-3">
                  <div class="font-semibold text-neutral-500">
                    {{
                      Number.isInteger(this.levelTabSelected)
                        ? `Level
                    ${this.levelTabSelected}`
                        : this.levelTabSelected
                    }}
                  </div>
                  <div class="flex items-center gap-2">
                    <Button buttonText="Add Item" type="primary" @action="showEditComponentModal(null, 'New')" v-if="levelTabSelected !== 'Summary'" />
                    <Button buttonText="Add Level" type="secondary" @action="addLevel()" v-if="isAddLevelButtonEnable" />
                    <Button buttonText="Download" type="tertiary" @action="donwloadGradingSummary()" v-if="levelTabSelected === 'Summary'" />
                  </div>
                </div>
                <TableComponent
                  :columns="levelTabSelected === 'Summary' ? columnsSummary : columnsGrading"
                  :list="gradingList"
                  :drag="levelTabSelected === 'Summary' ? false : true"
                  @handleChange="handleChangeTable"
                  @handleMutateTable="handleMutateList"
                >
                  <template v-slot="{ entry, column }" v-if="levelTabSelected === 'Summary'">
                    <div v-if="column.field === 'student_name'">
                      {{ entry[column.field] || 'undefined' }}
                    </div>
                    <div class="text-center" v-else>{{ entry[column.field] || 0 }}</div>
                  </template>
                  <template v-slot="{ entry, column }" v-else>
                    <div v-if="column.field === 'btn'" class="flex items-center gap-2 w-1/10">
                      <Button
                        class="mr-2"
                        type="primary"
                        size="icon"
                        :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                        @action="showEditComponentModal(entry, 'Edit')"
                      />
                      <Button type="delete" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="showDeleteConfirmationModal(entry)" />
                    </div>
                    <div v-else-if="column.field === 'scoringSchemaGroupItems'">
                      <div class="pl-12" v-if="entry.scoringSchemaGroupItems.length === 0">-</div>
                      <div v-else>
                        <div v-for="item in entry.scoringSchemaGroupItems" :key="item.id">{{ percentageAverage(item, item.percentage) }}% {{ item.title }}</div>
                      </div>
                    </div>
                    <div v-else class="">{{ entry[column.field] || '-' }}</div>
                  </template>
                </TableComponent>
              </template>
            </div>
          </template>
        </div>
      </div>
    </template>
    <Modal :modalVisible="isEditComponentModal" @close="hideEditComponentModal()" width="950px" id="editComponent">
      <template slot="modal-content">
        <div class="text-left my-6">
          <p class="text-2xl font-bold mb-3">{{ itemMode }} Component</p>
          <div class="mb-6">
            <TextField borderEnabled label="Title" type="text" v-model="editComponentSelected.title" />
          </div>
          <div class="mb-6">
            <TextField borderEnabled label="Topic" type="text" v-model="editComponentSelected.topic" />
          </div>
          <div class="mb-3" v-for="(item, itemIdx) in editComponentSelected.scoringSchemaGroupItems" :key="itemIdx + 'editComponent'" v-show="itemMode === 'Edit'">
            <div class="flex gap-2">
              <div class="w-1/2">
                <div v-if="item.referenceType === 'Free'">
                  <TextField borderEnabled label="Title" type="text" v-model="item.title" :default="item.title ? item.title : null" placeholder="Enter title..." />
                </div>
                <div v-if="item.referenceType === `Level ${levelTabSelected - 1}` || item.referenceType === 'Activity'">
                  <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Title</label>
                  <Dropdown
                    :options="item.referenceType === 'Activity' ? activityItemOptions : referenceGradingList"
                    maxHeight="500px"
                    v-model="item.title"
                    :default="item.title ? item.title : null"
                    placeholder="Select activity"
                    :optionLabel="item.referenceType === 'Activity' ? 'title' : ''"
                  />
                </div>
              </div>
              <div class="w-1/5" v-if="!editComponentSelected.isAverage">
                <TextField borderEnabled label="Percentage" type="number" :indexData="itemIdx" @input="handleLimitPercentage" v-model="item.percentage" />
              </div>
              <div class="w-1/5">
                <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Reference</label>
                <Dropdown
                  :options="referenceTypeOptions"
                  v-model="item.referenceType"
                  :indexData="itemIdx"
                  @onChangeSelected="handleSwitchingReferenceType"
                  :default="item.referenceType ? item.referenceType : referenceTypeSelected"
                />
              </div>
              <div class="my-auto pt-5">
                <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteComponentItems(item, itemIdx)" />
              </div>
            </div>
          </div>
          <div v-if="editComponentSelected.scoringSchemaGroupItems && itemMode === 'Edit'">
            <div class="text-red mb-3 text-xs">*total percentage must be 100</div>
            <input type="checkbox" id="average" v-model="editComponentSelected.isAverage" class="styled-checkbox" />
            <label class="text-xs pl-2" for="average">Average Percentage</label>
          </div>

          <div class="flex justify-start my-5" v-if="itemMode === 'Edit'">
            <Button buttonText="+ Add" type="tertiary" @action="addComponentItems()" />
          </div>
          <div class="flex justify-end my-5">
            <Button buttonText="Save Component" :disabled="!isSaveItemComponentEnable" @action="saveGradingComponent()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isDeleteConfirmationModal" @close="hideDeleteConfirmationModal()" id="createNewGrading">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-3">Are you sure to delete?</p>
          <div class="flex justify-center gap-4 my-5">
            <Button buttonText="Delete" type="delete" @action="deleteGradingData()" />
            <Button buttonText="Cancel" type="tertiary" @action="hideDeleteConfirmationModal()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isCreateNewGradingModal" @close="hideCreateNewGradingModal()" width="" id="createNewGrading">
      <template slot="modal-content">
        <div class="text-left my-6">
          <p class="text-2xl font-bold mb-3">Choose a reference</p>
          <div class="">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Reference</label>
            <Dropdown :options="createNewGradingOptions" v-model="newGradingSelected" :default="newGradingSelected" />
          </div>
          <div class="flex justify-end my-5">
            <Button buttonText="Save" @action="createNewGrading()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isCopyFromScheduleModal" @close="hideCopyFromScheduleModal()" width="640px" :overflowModal="false" id="copyFromSchedule">
      <template slot="modal-content">
        <div class="my-6 text-left relative">
          <p class="text-2xl font-bold mb-3">Select From Other Schedule</p>
          <div class="relative w-full mb-5">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Schedule Name</label>
            <div class="relative">
              <TextField type="text" :enterKeyAction="searchingSchedule" v-model="copyFromScheduleQuery" additionalClass="pr-10" borderEnabled placeholder="Search schedule by name..." />
              <div class="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" v-if="copyFromScheduleQuery !== '' && isSearchingSchedule" @click="clearKeyWord">
                <Close color="#58595B" />
              </div>
              <div class="absolute right-3 top-1/2 transform -translate-y-1/2" v-else>
                <Search color="#58595B" />
              </div>
            </div>
            <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10" v-if="isSearchingSchedule">
              <template v-if="scheduleClassList.length > 0 && !isFetching">
                <div ref="scheduleClassList" class="">
                  <div
                    @click="selectSchedule(sch)"
                    class="py-1 px-3 text-white cursor-pointer truncate hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                    v-for="sch in scheduleClassList"
                    :key="sch.id"
                  >
                    {{ sch.nama }}
                  </div>
                </div>
              </template>
              <template v-if="scheduleClassList.length === 0 && !isFetching">
                <p class="py-1 px-3 text-white font-normal text-center">Schedule is not found.</p>
              </template>
              <template v-if="isFetching">
                <vue-simple-spinner size="large" class="py-2"></vue-simple-spinner>
              </template>
            </div>
          </div>
          <div class="flex justify-end" :class="isSearchingSchedule ? marginBtm : 'mb-5'">
            <Button buttonText="Copy Schedule" :disabled="!selectedScheduleId" @action="generateGradingData('COPY')" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isAddStudentModal" @close="hideAddStudentModal()" width="640px" id="addStudent">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-3">Are you sure to add student to this class?</p>
          <p class="text-sm mb-4">
            You are about to permanently add new student into the Student List. You will not be able to remove student from the student list one you’ve proceed. This action cannot be undone.
          </p>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Cancel" type="tertiary" @action="hideAddStudentModal()" />
            <Button buttonText="Proceed" type="secondary" @action="enrollStudent()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="alreadyExistModal" @close="hideAlreadyExistModal()" width="640px" id="alreadyExistModal">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-3">Failed to Enroll Student</p>
          <div class="overflow-y-auto h-36 border rounded-lg p-2 text-left mb-3">
            <p class="text-sm">{{ failedStudent.join(', ') }}</p>
          </div>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Ok" type="secondary" @action="hideAlreadyExistModal()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isModalActivityTemplate" @close="toggleModalActivityTemplate" width="640px" id="activityTemplate">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-5">Do you want to use activity template?</p>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Yes" type="secondary" @action="duplicateActivityTemplate" />
            <Button buttonText="No" type="primary" @action="dontDuplicateActivityTemplate" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="visibleDeleteModal" @close="closeDeleteModal()" width="640px" id="delete">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-3">Are you sure to delete this {{ selectedTypeToBeDeleted }}?</p>
          <p class="text-sm mb-4">This action can not be undone</p>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Delete" type="secondary" @action="deleteItem()" />
            <Button buttonText="Cancel" type="primary" @action="closeDeleteModal()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isInstructorWarningModal" @close="closeInstructorWarningModal()">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-xl font-bold mb-3">Instructor {{ errorMessage?.status === 'NA' ? 'Not Available' : 'Is Conflict' }}</p>
          <p class="text-base mb-4">{{ errorMessage?.message }}</p>
          <div class="flex justify-center items-center gap-8">
            <Button :buttonText="errorMessage?.status === 'NA' ? 'Edit Availability' : 'See Conflicted Schedule'" type="secondary" @action="openNewTab()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="visibleDeleteStudentAlert" @close="toogleDeleteStudentAlertModal()">
      <template slot="modal-content">
        <div class="text-center my-6">
          <h1 class="text-xl font-bold mb-3">Remove Student Confirmation</h1>
          <p class="text-sm mb-4">You are about to permanently remove student and their class progress from this class schedule’s Student List. This action cannot be undone.</p>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Cancel" type="tertiaryNonBorder" @action="toogleDeleteStudentAlertModal()" />
            <Button buttonText="Proceed" type="secondary" @action="deleteStudentLmsClass()" />
          </div>
        </div>
      </template>
    </Modal>
    <div class="border-2 table-cell align-middle" v-if="!this.showModal">
      <div class="fixed z-60 pt-16 top-0 left-0 w-full h-full table transition-opacity" style="background-color: rgba(0, 0, 0, 0.5); transition: opacity 0.3s ease">
        <div
          class="relative rounded-xl py-3 px-6 bg-red-10 border-2 border-red-400"
          style="width: 482px; margin: 0px auto; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33); transition: all 0.3s ease; -webkit-transform: scale(1.1); transform: scale(1.1)"
        >
          <div class="text-lg font-semibold text-red-400">You have exercise with time constraint!</div>
          <span class="text-12 text-red-400">Please turn on 'Mark as Independent' to Submit</span>
          <img src="@/assets/images/activition.png" alt="Message" class="w-7 h-7 absolute top-6 right-7" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { isNotEmptyError } from '@/utils/form-validator'
import { showVueToast } from '@/utils'
import moment from 'moment'
export default {
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    NotFound: () => import(/* webpackChunkName: "Icons" */ '@/components/Illustration/404'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Close: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Close'),
    Warning: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Warning'),
    DataTable: () => import(/* webpackChunkName: "data-table" */ '@/components/Table/DataTable'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    TableComponent: () => import(/* webpackChunkName: "Simple-Table" */ '@/components/Table/GeneralTable'),
    ActivityForm: () => import(/* webpackChunkName: "ActivityForm" */ '@/views/jadwalkelas/ActivityForm'),
    StudentTable: () => import(/* webpackChunkName: "simple-table" */ '@/components/Table/SimpleTable')
  },
  data() {
    return {
      selectedTimeLimit: "0",
      timeLimitOptions: [
        { value: 0, label: "No Time Limit" },
        { value: 120, label: "120 Minutes (2 Hours)" }
      ],
      isLoadDataTimeLimit: false,
      visibleDeleteStudentAlert: false,
      studentDeleted: null,
      hasIssue: false,
      isDisabled: false,
      isFormRegisterVisible: false,
      isAddStudentActive: false,
      statusList: [
        {
          name: 'Open',
          value: 1
        },
        {
          name: 'Close',
          value: 0
        }
      ],
      typeList: [
        {
          name: 'Self-based learning',
          value: 'SELF_BASED'
        },
        {
          name: 'Offline',
          value: 'OFFLINE'
        },
        {
          name: 'Blended',
          value: 'BLENDED'
        }
      ],
      source: [
        {
          name: 'CRM',
          value: 'CRM'
        },
        {
          name: 'Manual',
          value: 'MANUAL'
        },
        {
          name: 'Scholarship',
          value: 'SCHOLARSHIP'
        }
      ],
      programs: [],
      pipelineStage: [],
      prospectList: [],
      studentList: [],
      isReset: false,
      isAddStudentModal: false,
      refresh: false,
      visibleInstructor: false,
      visibleClass: false,
      selectedClass: null,
      selectedClassType: null,
      selectedInstructor: null,
      selectedInstructorList: [{ selectedInstructor: null }],
      selectedStatus: null,
      selectedWorkflow: null,
      selectedTimeLimit: null,
      selectedSource: null,
      selectedProgramStudent: null,
      selectedPipelineStage: null,
      dpList: [],
      ecommerce: '',
      voucerCode: '',
      redeemCode: '',
      isCurator: '',
      allObject: {
        name: 'All',
        value: ''
      },
      selectedProspect: [],
      failedStudent: [],
      userFullName: '',
      userPhoneNumber: '',
      userEmail: '',
      keyword: '',
      counter: 0,
      counterStudent: 0,
      currentPage: 0,
      currentPageStudent: 0,
      size: 10,
      sizeStudent: 20,
      totalPages: 0,
      totalPagesStudent: 0,
      totalRows: 0,
      totalRowsStudent: 0,
      alreadyExistModal: false,
      visibleActivityModal: false,
      activities: [],
      instructorList: [],
      originalScheduleName: null,
      scheduleName: null,
      zoomHostKey: null,
      zoomLink: null,
      telegramLink: null,
      customerServiceNumber: '',
      phoneInputType: 'number',
      scheduleStartEndPMO: '',
      scheduleCodePMO: '',
      selectedTab: 'Detail',
      error: [],
      generalError: {},
      errorActivity: [],
      errorChildActivity: [],
      visibleDeleteModal: false,
      selectedIndexToBeDeleted: null,
      selectedTypeToBeDeleted: null,
      temp: [],
      showModal: true,
      feedbackColumn: [],
      studentFeedback: [],
      feedbackList: [],
      feedbakSelected: null,
      isFetchFeedbackInstructor: false,
      warnings: {},
      isScoringSchemaGroupItemsEmpty: true,
      isFetchActivityData: false,
      errorMessage: null,
      customPath: '',
      customQuery: null,
      isInstructorWarningModal: false,
      //////////GRADE////////////
      isLevel1Available: false,
      levelTabSelected: 1,
      totalLevel: 0,
      tabLevel: [],
      isFetching: false,
      referenceGradingList: [],
      referenceGradingListComplete: [],
      latestGrades: [],
      gradingList: [],
      activityTemplate: [],
      oneChance: false,
      isModalActivityTemplate: false,
      isCopyFromScheduleModal: false,
      isCreateNewGradingModal: false,
      isDeleteConfirmationModal: false,
      isSearchingSchedule: false,
      copyFromScheduleQuery: '',
      selectedScheduleId: null,
      isSearchingSchedule: '',
      newGradingSelected: 'Activity',
      createNewGradingOptions: ['Activity', 'Free'],
      activityItemOptions: [],
      copyFromScheduleQuery: '',
      scheduleClassList: [],
      isSearchingSchedule: false,
      tabLevel: [],
      scoringSchemaGroupId: null,
      editComponentSelected: {
        title: '',
        topic: '',
        scoringSchemaGroupItems: []
      },
      isEditComponentModal: false,
      columnsSummary: [],
      columnsGrading: [
        { field: 'title', label: 'Title' },
        { field: 'topic', label: 'Topic' },
        { field: 'scoringSchemaGroupItems', label: 'Component' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ],
      itemMode: '',
      referenceTypeOptions: ['Activity', 'Free'],
      referenceTypeSelected: 'Activity',
      temporaryDisabled: false,
      isAvailableFromStart: false,
      isActivitiesAccord: true,
      isDeleteComponentItems: false,
      isLoadDataInstructor: false,
      activityErrorHighlight: [],
      scholarshipCurrentWorkflow: 7,
      scholarshipPrograms: [
        {
          name: 'All',
          value: ''
        },
        {
          name: 'IndoDigi',
          value: 'IndoDigi'
        },
        {
          name: 'TechChamp',
          value: 'TechChamp'
        }
      ],
      scholarshipProgramSelected: {
        name: 'All',
        value: ''
      },
      delayTime: 500,
      debounceTimer: null,
      filteredInstructorList: [],
      selectedVariant: null,
      coreClassVariantList: [],
      coreClassIdSelected: ''
    }
  },
  created() {
    this.initProcess()
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.leaving)
  },
  watch: {
    checkboxPercentage(value) {
      if (this.editComponentSelected.scoringSchemaGroupItems.length !== 0 && value) {
        this.editComponentSelected.scoringSchemaGroupItems = this.editComponentSelected.scoringSchemaGroupItems.map((item) => {
          return {
            ...item,
            percentage: ''
          }
        })
      }
    },
    levelTabSelected(value) {
      if (value === 1) {
        this.referenceTypeSelected = 'Activity'
        this.referenceTypeOptions = ['Activity', 'Free']
      } else {
        this.referenceTypeOptions = []
        this.referenceTypeOptions = [`Level ${this.levelTabSelected - 1}`, 'Activity', 'Free']
        this.referenceTypeSelected = `Level ${this.levelTabSelected - 1}`
      }
    },
    feedbakSelected(val) {
      if (val) this.fetchInstructorFeedback()
    },
    selectedSource(val) {
      if (val.value === 'MANUAL') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
      if (val.value === 'SCHOLARSHIP') {
        this.getScholarshipWorkflow()
      }
      if (val.value === 'CRM') {
        this.getPipelineStage()
      }
    },
    selectedClass(val) {
      if (val) {
        this.getListInstructor()
        this.visibleInstructor = true
        this.oneChance = false
        this.getFeedbackList(val.id)
        this.checkActivityTemplate(val.id)
        this.coreClassVariantList = []
        // this.selectedVariant = null
        if (!this.isEditMode || !this.isCopyMode) this.getCoreClassVariantList(val.id)
      } else this.visibleInstructor = false
    },
    selectedClassType(val) {
      if (val) {
        this.getClassList()
      }
    },
    selectedProgramStudent(val) {
      if (val.value && this.selectedSource.value !== 'SCHOLARSHIP') {
        this.getPipelineStage()
      }
    },
    userFullName(value) {
      if (!value) {
        this.generalError['userFullName'] = 'Name is required'
      } else {
        this.generalError['userFullName'] = undefined
      }
    },
    userEmail(value) {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (!value) {
        this.generalError['userEmail'] = 'Email is required'
      } else if (!regex.test(value)) {
        this.generalError['userEmail'] = 'Format is invalid. ex: user@mail.com'
      } else {
        this.generalError['userEmail'] = undefined
      }
    },
    userPhoneNumber(value) {
      this.validatorPhoneNumber(value, 'userPhoneNumber')
    },
    customerServiceNumber(value) {
      this.validatorPhoneNumber(value, 'customerServiceNumber')
    }
  },
  computed: {
    ...mapGetters('kelas', ['list_kelas']),
    ...mapGetters('instructur', ['list_instructur']),
    ...mapGetters('jadwalkelas', ['jadwalkelasnew', 'workflow']),
    columnsOfStudentTable() {
      return [
        { label: 'Name', field: 'name' },
        { label: 'Phone Number', field: 'hp' },
        { label: 'Action', field: 'btn' }
      ]
    },
    filterWorkflow() {
      const hiddenWorkflow = ['PRAKERJA_2021', 'PRAKERJA_PILOT_2022', 'PRAKERJA_NEW_SCHEMA']
      const result = this.workflow.filter((wf) => !hiddenWorkflow.includes(wf.code))
      return result
    },
    disableSubmit() {
      let result = true
      if (
        this.scheduleName &&
        this.selectedClassType &&
        this.selectedClass &&
        this.selectedInstructorList.length > 0 &&
        this.selectedInstructorList[0].selectedInstructor &&
        this.isCustomerServiceNumberAccord &&
        this.activities.length > 0 &&
        this.validationVariant
      ) {
        result = false
      }
      return result
    },
    validationVariant() {
      if (this.coreClassVariantList.length > 0 && !this.selectedVariant) return false
      return true
    },
    isCustomerServiceNumberAccord() {
      return (this.customerServiceNumber || !this.customerServiceNumber) && !this.generalError['customerServiceNumber']
    },
    isChildActivityAccord() {
      let result = true
      for (let i = 0; i < this.activities.length; i++) {
        const child = this.activities[i].activityItems
        for (let j = 0; j < child.length; j++) {
          const type = child[j].activityItemType
          switch (type) {
            case 'VIDEO':
            case 'DOCUMENT':
            case 'ASSIGNMENT':
              if (!child[j].itemReferenceId || child[j].itemReferenceId === 0) result = false
              break
            case 'PRETEST':
            case 'QUIZ':
            case 'EXAM':
              if (!child[j].itemReferenceId || child[j].itemReferenceId === 0) {
                result = false
                return
              } else {
                switch (`${child[j].timeConstraint}`) {
                  case '1':
                    if (!child[j].startTime || !child[j].endTime) result = false
                    break
                  case '2':
                    if (!child[j].startTime || !child[j].durationInMinutes) result = false
                    break
                  case '3':
                    if (!child[j].startTime || !child[j].endTime || !child[j].durationInMinutes) result = false
                    break
                }
              }
              break
            case 'OFFLINE_MEETING':
            case 'WEBINAR':
              if (!child[j].startTime || !child[j].endTime) result = false
              break
            case 'OPEN_LINK':
              if (!child[j].label || !child[j].link) result = false
              break
            case 'RECURRING_ONLINE':
              if (!child[j].dayOfWeek || !child[j].startTimeOfDay || !child[j].endTimeOfDay) result = false
              break
          }
        }
      }
      return result
    },
    isSaveItemComponentEnable() {
      if (this.itemMode === 'New' && this.editComponentSelected.title !== '') return true
      if (!this.editComponentSelected.scoringSchemaGroupItems) return false
      const percentage = this.editComponentSelected.scoringSchemaGroupItems ? this.editComponentSelected.scoringSchemaGroupItems.map((item) => parseInt(item.percentage)) : []
      const titles = this.editComponentSelected.scoringSchemaGroupItems ? this.editComponentSelected.scoringSchemaGroupItems.map((item) => item.title) : []
      if (percentage.length === 0 || titles.length === 0) return false
      const reducer = (accumulator, curr) => accumulator + curr
      const totalPercentage = percentage.reduce(reducer)
      const isEmptyValueExist = percentage.includes(NaN)
      const isTitleEmptyExist = titles.includes('') || titles.includes(null)
      if (!isTitleEmptyExist && this.editComponentSelected.isAverage) return true
      if (totalPercentage === 100 && !isEmptyValueExist && !isTitleEmptyExist) return true
      return false
    },
    isSummaryTabEnable() {
      if (this.latestGrades.length === 0 || this.latestGrades.length !== 1 || this.studentList.length === 0) return false
      if (this.latestGrades[0].scoringSchemaGroupItems.length === 0) return false
      return true
    },
    marginBtm() {
      if (this.scheduleClassList.length >= 5) return 'mb-28'
      if (this.scheduleClassList.length < 5 && this.scheduleClassList.length >= 3) return 'mb-24'
      return 'mb-5'
    },
    isAddLevelButtonEnable() {
      if (this.levelTabSelected === 'Summary' || this.gradingList.length === 0) return false
      if (this.levelTabSelected !== this.totalLevel) return false
      return true
    },
    checkboxPercentage() {
      return this.editComponentSelected.isAverage
    },
    isActivitiesTabEnable() {
      if (this.scheduleName && this.selectedClass && this.selectedClassType && this.selectedInstructorList.length > 0 && this.selectedInstructorList[0].selectedInstructor) return true
      return false
    },
    pageForPagination() {
      return this.currentPage + 1
    },
    pageForPaginationStudent() {
      return this.currentPageStudent + 1
    },
    customerId() {
      return localStorage.getItem('client')
    },
    jadwalKelasId() {
      return this.$route.params.id
    },
    isCreateMode() {
      return this.$route.meta.mode === 'create'
    },
    isEditMode() {
      return this.$route.meta.mode === 'Edit'
    },
    isCopyMode() {
      return this.$route.meta.mode === 'Copy'
    },
    filterFeedback() {
      const filteredData = (this.list_exercise && this.list_exercise.filter((item) => item.exercise_type_name === 'SURVEY')) || []
      return filteredData
    }
  },
  methods: {
    ...mapActions('kelas', ['GET_LIST_KELAS', 'GET_LIST_PROGRAM', 'GET_ACTIVITIES', 'GET_CORE_CLASS_VARIANTS', 'GET_KELAS']),
    ...mapActions('exercise', ['GET_LIST_EXERCISE']),
    ...mapActions('instructur', ['GET_LIST_INSTRUCTUR_V2', 'GET_INSTRUCTUR']),
    ...mapActions('student', ['GET_PROSPECT', 'GET_PIPELINE_STAGE', 'ENROLL_PROSPECT']),
    ...mapActions('absensireport', ['GET_LIST_ABSENSIREPORT']),
    ...mapActions('common', ['showLoading', 'hideLoading', 'DIGITAL_PLATFORMS']),
    ...mapActions('jadwalkelas', [
      'CREATE_JADWALKELAS_NEW',
      'GET_JADWALKELAS_NEW',
      'UPDATE_JADWALKELAS_NEW',
      'GET_WORKFLOW',
      'GET_INSTRUCTOR_FEEDBACK',
      'DOWNLOAD_INSTRUCTOR_FEEDBACK',
      'GET_GRADING_DATA_GROUP',
      'GET_GRADING_DATA_ALL',
      'GET_GRADING_DATA',
      'GET_ACTIVITY_BY_LMS_ID',
      'DOWNLOAD_GRADING_SUMMARY',
      'UPDATE_MULTIPLE_GRADING',
      'CREATE_SINGLE_GRADING',
      'CREATE_MULTIPLE_GRADING',
      'DELETE_GRADING_DATA',
      'UPDATE_GRADING_DATA',
      'DELETE_GRADING_COMPONENT',
      'GET_GRADING_DATA_SUMMARY',
      'DELETE_STUDENT_FROM_LMS'
    ]),
    ...mapActions('attendance', ['GET_SCHOLARSHIP_PROSPECT_WORKFLOW', 'GET_SCHOLARSHIP_PROSPECT']),
    addInstructor() {
      this.selectedInstructorList.push({ selectedInstructor: null })
    },
    deleteInstructor(index) {
      this.selectedInstructorList.splice(index, 1)
    },
    /*
{
    "variantId": 359,
    "variantName": "Full Days Regular Big",
    "price": 0,
    "promoLabel": "",
    "promo": 0,
    "active": true,
    "duration": "",
    "weeklySchedule": "",
    "location": "",
    "seqNo": 0,
    "sku": "SKU-1-237"
}
    */
    getCoreClassVariantList(coreClassId) {
      return new Promise((resolve) => {
        const params = {
          page: 0,
          size: 100,
          sortBy: 'seqNo',
          direction: 'ASC',
          coreClassId,
          active: true
        }
        this.GET_CORE_CLASS_VARIANTS({ customerId: this.customerId, params }).then((res) => {
          if (res?.data?.code === 200) {
            this.coreClassVariantList = res?.data?.data
            resolve(res?.data?.data)
          }
        })
      })
    },
    initProcess() {
      window.addEventListener('beforeunload', this.leaving)
      this.selectedSource = this.source[0]
      this.selectedPipelineStage = this.allObject
      if (this.isEditMode || this.isCopyMode) {
        this.getJadwalKelasDetail()
        this.getProgramList()
        this.getTotalLevel(true)
      } else {
        this.getWorkflowList()
        this.selectedStatus = this.statusList[0]
      }
    },
    leaving(e) {
      e.returnValue = 'You have some unsaved changes'
      return 'You have some unsaved changes'
    },
    toLMSClassListMenu() {
      this.$router.push('/jadwalkelas')
    },
    deleteInstructorActivityItem(activityIndex, activityItemIndex) {
      this.activities[activityIndex].activityItems[activityItemIndex].expert_id = null
    },
    toogleDeleteStudentAlertModal(student) {
      if (student) {
        this.studentDeleted = student
      }
      this.visibleDeleteStudentAlert = !this.visibleDeleteStudentAlert
    },
    deleteStudentLmsClass() {
      this.showLoading()
      this.DELETE_STUDENT_FROM_LMS({ studentLmsClassId: this.studentDeleted.student_lms_class_id })
        .then((res) => {
          if (res?.status === 200) {
            const updateStudentlist = this.studentList.filter((std) => std.student_lms_class_id !== this.studentDeleted.student_lms_class_id)
            this.studentList = updateStudentlist
            this.toogleDeleteStudentAlertModal()
            showVueToast("You've successfully delete the student!", 'success', 2000)
            this.hideLoading()
            return
          }
          showVueToast('Failed!', 'error', 2000)
          this.hideLoading()
        })
        .catch(() => {
          showVueToast('Failed!', 'error', 2000)
        })
    },
    /////GRADE//////
    deleteComponentItems(item, index) {
      if (item.id) {
        this.DELETE_GRADING_COMPONENT({
          scoringSchemaGroupItemId: item.id
        })
          .then((res) => {
            this.isDeleteComponentItems = true
          })
          .catch((err) => {
            showVueToast('Failed to delete', 'error', 2000)
          })
      }
      this.editComponentSelected.scoringSchemaGroupItems.splice(index, 1)
    },
    getGradingSummary() {
      this.isFetching = true
      this.columnsSummary = []
      this.gradingList = []
      this.columnsSummary.push({ field: 'student_name', label: 'Student Name' })
      this.GET_GRADING_DATA_SUMMARY({
        lmsClassId: this.jadwalKelasId,
        params: {
          page: 0,
          size: 100
        }
      }).then((res) => {
        this.isFetching = false
        this.hideLoading()
        const summary = res.data.data
        if (summary.length !== 0) {
          summary[0].component.map((score) => {
            const item = { field: score.title, label: score.title, position: 'center' }
            this.columnsSummary.push(item)
          })
          this.columnsSummary.push({ field: 'total_student_score', label: 'Final Score', position: 'center' })

          summary.map((item) => {
            const result = {
              student_name: item.student_name,
              total_student_score: item.total_student_score ? (Number.isInteger(item.total_student_score) ? item.total_student_score : item.total_student_score.toFixed(2)) : 0
            }
            item.component.map((comp) => {
              result[`${comp.title}`] = comp.student_score ? (Number.isInteger(comp.student_score) ? comp.student_score : comp.student_score.toFixed(2) || 0) : 0
            })
            this.gradingList.push(result)
          })
        }
      })
    },
    handleLimitPercentage(value, index) {
      if (value >= 100) {
        this.editComponentSelected.scoringSchemaGroupItems[index].percentage = 100
      }
      if (value.length > 1 && value.charAt(0) === '0') {
        this.editComponentSelected.scoringSchemaGroupItems[index].percentage = ''
      }
    },
    handleSwitchingReferenceType(value, index) {
      this.editComponentSelected.scoringSchemaGroupItems[index].title = ''
    },
    deleteGradingData() {
      this.showLoading()
      this.DELETE_GRADING_DATA({
        scoringSchemaGroupId: this.scoringSchemaGroupId
      })
        .then((res) => {
          this.hideLoading()
          this.hideDeleteConfirmationModal()
          this.getGradingData()
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    addComponentItems() {
      const item = {
        title: '',
        percentage: '',
        referenceType: this.levelTabSelected === 1 ? 'Free' : `Level ${this.levelTabSelected - 1}`,
        seqNo: this.editComponentSelected.scoringSchemaGroupItems.length + 1
      }
      this.editComponentSelected.scoringSchemaGroupItems.push(item)
    },
    percentageAverage(item, percentage) {
      const calculatedType = item.isAverage ? 'AVERAGE' : 'PERCENTAGE'
      if (calculatedType === 'AVERAGE') {
        const calculate = (1 / item.scoringSchemaGroupItems.length) * 100
        const finalResult = Number.isInteger(calculate) ? calculate : parseFloat(calculate.toFixed(2))
        return finalResult
      }
      return percentage
    },

    saveGradingComponent() {
      if (this.itemMode === 'New') {
        this.showLoading()
        const payload = {
          topic: this.editComponentSelected.topic,
          title: this.editComponentSelected.title,
          calculatedType: 'PERCENTAGE',
          level: this.levelTabSelected,
          seqNo: this.gradingList.length + 1
        }

        this.CREATE_SINGLE_GRADING({
          lmsClassId: this.jadwalKelasId,
          payload
        })
          .then((res) => {
            this.isDeleteComponentItems = false
            this.hideEditComponentModal()
            this.getGradingData()
          })
          .catch((err) => {
            this.hideLoading()
          })
        return
      }
      const items = []
      this.editComponentSelected.scoringSchemaGroupItems.map((item, index) => {
        const referenceType = this.checkReferenceType(item.referenceType)
        const percentage = this.percentageAverage(this.editComponentSelected, item.percentage)
        if (item.referenceType === 'Free') {
          items.push({
            ...item,
            referenceType,
            percentage,
            seqNo: index + 1
          })
        } else if (item.referenceType === 'Activity') {
          items.push({
            id: item.id,
            title: item.title.activityItemTitle,
            lmsClassActivityItemId: item.title.id,
            referenceType,
            percentage,
            seqNo: index + 1
          })
        } else {
          items.push({
            ...item,
            id: this.checkChildId(item.id, item.title),
            referenceType,
            percentage,
            seqNo: index + 1
          })
        }
      })

      const payload = {
        level: this.levelTabSelected,
        title: this.editComponentSelected.title,
        topic: this.editComponentSelected.topic,
        calculatedType: this.editComponentSelected.isAverage ? 'AVERAGE' : 'PERCENTAGE',
        seqNo: this.editComponentSelected.seqNo,
        items
      }

      this.UPDATE_GRADING_DATA({
        scoringSchemaGroupId: this.editComponentSelected.id,
        payload
      })
        .then((res) => {
          this.isEditComponentModal = false
          this.getGradingData()
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    handleChangeTable() {
      this.showLoading()
      const items = this.gradingList
        .map((grade, index) => {
          return {
            ...grade,
            seqNo: index + 1,
            items: grade.scoringSchemaGroupItems
          }
        })
        .map((grade2) => {
          delete grade2.scoringSchemaGroupItems
          return {
            ...grade2
          }
        })

      const payload = {
        scoringSchemaGroup: {
          items
        }
      }

      this.UPDATE_MULTIPLE_GRADING({
        lmsClassId: this.jadwalKelasId,
        level: this.levelTabSelected,
        payload
      })
        .then((res) => {
          this.hideLoading()
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    handleMutateList(value) {
      this.gradingList = value
    },
    showEditComponentModal(grades, mode) {
      this.itemMode = mode
      if (grades) {
        const grade = JSON.parse(JSON.stringify(grades))
        const scoringSchemaGroupItems = grade.scoringSchemaGroupItems
          .map((score) => {
            const titles = score.referenceType === 'Activity' ? this.activityItemOptions.filter((act) => act.activityItemTitle == score.title)[0] : score.title
            return {
              titles,
              ...score
            }
          })
          .map((score) => {
            if (score.referenceType === 'Activity') delete score.title
            return {
              title: score.titles,
              ...score
            }
          })
        this.scoringSchemaGroupId = grade.id
        grade.scoringSchemaGroupItems = scoringSchemaGroupItems
        grade.scoringSchemaGroupItems.length === 0 ? (this.isScoringSchemaGroupItemsEmpty = true) : (this.isScoringSchemaGroupItemsEmpty = false)
        this.editComponentSelected = { ...grade, isAverage: grade.calculatedType === 'AVERAGE' ? true : false, index: this.gradingList.indexOf(grade) }
      } else {
        this.editComponentSelected = {
          title: '',
          topic: '',
          scoringSchemaGroupItems: []
        }
      }
      this.isEditComponentModal = true
    },
    hideEditComponentModal() {
      if (this.isDeleteComponentItems) {
        this.itemMode = 'Edit'
        this.saveGradingComponent()
      }
      this.editComponentSelected = {
        title: '',
        topic: '',
        scoringSchemaGroupItems: []
      }
      this.isScoringSchemaGroupItemsEmpty = true
      this.isEditComponentModal = false
    },
    showDeleteConfirmationModal(data) {
      this.scoringSchemaGroupId = data.id
      this.isDeleteConfirmationModal = true
    },
    hideDeleteConfirmationModal() {
      this.isDeleteConfirmationModal = false
    },
    toggleModalActivityTemplate() {
      this.isModalActivityTemplate = !this.isModalActivityTemplate
    },
    donwloadGradingSummary() {
      this.showLoading()
      this.DOWNLOAD_GRADING_SUMMARY({
        lmsClassId: this.jadwalKelasId
      }).then((res) => {
        const url = URL.createObjectURL(res.data)
        const a = document.createElement('a')
        a.download = 'grading_summary.xlsx'
        a.href = url
        a.target = '_self'

        a.click()

        setTimeout(function () {
          a.remove()
          URL.revokeObjectURL(url)
        }, 100)
        this.hideLoading()
      })
    },
    addLevel(isLoadData) {
      this.tabLevel.push(this.tabLevel.length + 1)
      if (!isLoadData) {
        this.totalLevel = this.totalLevel + 1
      }
    },
    selectGradingTab(tab) {
      if (tab === 'Summary' && !this.isSummaryTabEnable) return
      if (tab === this.levelTabSelected) return
      this.levelTabSelected = tab
      if (!Number.isInteger(this.levelTabSelected)) {
        this.getGradingSummary()
        return
      }
      this.getGradingData()
    },
    selectSchedule(data) {
      this.copyFromScheduleQuery = data.nama
      this.selectedScheduleId = data.id
      this.isSearchingSchedule = false
    },
    clearKeyWord() {
      this.copyFromScheduleQuery = ''
      this.scheduleClassList = []
      this.isSearchingSchedule = false
    },
    searchingSchedule() {
      this.scheduleClassList = []
      this.isSearchingSchedule = true
      this.isFetching = true
      const paramsTemp = queryString.stringify({
        ...{
          limit: 10,
          sort: 'name',
          nama: this.copyFromScheduleQuery
        }
      })
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          masterType: 'jadwalkelas',
          customerId: this.customerId,
          params: paramsTemp
        })
        .then((res) => {
          this.scheduleClassList = res.data.data
          this.isFetching = false
        })
        .catch(() => {
          showVueToast('Fetching data is failed!', 'error', 2000)
        })
    },
    getGradingData() {
      this.gradingList = []
      this.isFetching = true
      this.getAllGrading()
      this.GET_GRADING_DATA({
        lmsClassId: this.jadwalKelasId,
        level: this.levelTabSelected
      })
        .then((res) => {
          const data = res.data.data
          this.gradingList = data.map((item) => {
            const scoringSchemaGroupItems = item.scoringSchemaGroupItems.map((score) => {
              return {
                ...score,
                referenceType: this.checkReferenceType(score.referenceType)
              }
            })
            return {
              ...item,
              scoringSchemaGroupItems
            }
          })
          const checkUniqueItem = this.referenceGradingList.filter((item) => item.level === this.levelTabSelected)
          if (checkUniqueItem.length === 0) {
            this.referenceGradingList.push({ data, level: this.levelTabSelected })
          }
          if (this.levelTabSelected === 1 && data.length !== 0) this.isLevel1Available = true
          this.isFetching = false

          this.hideLoading()
        })
        .catch((err) => {
          this.isFetching = false
          this.hideLoading()
        })
    },
    checkChildId(id, title) {
      if (id) return id
      return this.referenceGradingListComplete.filter((item) => item.title === title)[0].id
    },
    getTotalLevel(loadLevel) {
      this.GET_GRADING_DATA_GROUP({
        lmsClassId: this.jadwalKelasId
      })
        .then((res) => {
          const data = res.data.data
          this.totalLevel = data.count.totalLevel
          if (loadLevel) {
            for (let i = 0; i < data.count.totalLevel; i++) {
              this.addLevel(true)
            }
          }
        })
        .catch((err) => {})
    },
    getAllGrading() {
      this.GET_GRADING_DATA_ALL({
        lmsClassId: this.jadwalKelasId
      }).then((res) => {
        setTimeout(() => {
          this.referenceGradingListComplete = res.data.data.filter((item) => item.level === this.levelTabSelected - 1)
          this.latestGrades = res.data.data.filter((item) => item.level === this.totalLevel)
          this.referenceGradingList = res.data.data.filter((item) => item.level === this.levelTabSelected - 1 && item.scoringSchemaGroupItems.length !== 0).map((item) => item.title)
        }, 200)
      })
    },
    createNewGrading() {
      if (this.newGradingSelected === 'Free') {
        this.hideCreateNewGradingModal()
        this.isLevel1Available = true
        this.addLevel()
        return
      }
      this.generateGradingData('NEW')
      this.addLevel()
    },
    getActivityItems(activity) {
      return activity
        .map((activity) => {
          return [
            ...activity.activityItems.map((item) => {
              return {
                activityItemTitle: `${activity.title} - ${item.title}`,
                ...item
              }
            })
          ]
        })
        .reduce((arr, item) => {
          arr.push(...item)
          return arr
        })
    },
    generateGradingData(mode) {
      const lmsClassId = mode === 'COPY' ? this.selectedScheduleId : this.jadwalKelasId
      this.showLoading()
      this.GET_ACTIVITY_BY_LMS_ID({
        lmsClassId
      })
        .then((res) => {
          if (res.data.data.length === 0) {
            this.hideLoading()
            return showVueToast('This class schedule is not activity based!', 'error', 2000)
          }
          const scoringSchemaGroup = res.data.data.map((sch, idx) => {
            return {
              calculatedType: 'PERCENTAGE',
              level: this.levelTabSelected,
              seqNo: idx + 1,
              title: sch.title,
              topic: ''
            }
          })
          this.CREATE_MULTIPLE_GRADING({
            lmsClassId: this.jadwalKelasId,
            payload: {
              scoringSchemaGroup
            }
          })
            .then((res) => {
              this.hideCopyFromScheduleModal()
              this.hideCreateNewGradingModal()
              this.getGradingData()
            })
            .catch((err) => {
              this.hideLoading()
            })
          this.hideLoading()
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    showCopyFromScheduleModal() {
      this.isCopyFromScheduleModal = true
    },
    hideCopyFromScheduleModal() {
      this.isCopyFromScheduleModal = false
      this.copyFromScheduleQuery = ''
      this.selectedScheduleId = ''
      this.isSearchingSchedule = false
    },
    showCreateNewGradingModal() {
      this.isCreateNewGradingModal = true
    },
    hideCreateNewGradingModal() {
      this.isCreateNewGradingModal = false
      this.newGradingSelected = 'Activity'
    },
    checkReferenceType(value) {
      let result = ''
      switch (value) {
        case 'Free':
          result = 'ScoringSchemaGroupItem'
          break
        case 'ScoringSchemaGroupItem':
          result = 'Free'
          break
        case 'LMS_CLASS_ACTIVITY_ITEM_ID':
          result = 'Activity'
          break
        case 'Activity':
          result = 'LMS_CLASS_ACTIVITY_ITEM_ID'
          break
        case 'SCORING_GROUP_ID':
          result = `Level ${this.levelTabSelected - 1}`
          break
        default:
          result = 'SCORING_GROUP_ID'
          break
      }
      return result
    },
    /////GRADE/////
    downloadFeedback() {
      this.showLoading()
      this.DOWNLOAD_INSTRUCTOR_FEEDBACK({
        activityItemsId: this.feedbakSelected.id,
        params: {
          instructorId: this.feedbakSelected.expert_id
        }
      }).then((res) => {
        const url = URL.createObjectURL(res.data)
        const a = document.createElement('a')
        a.download = `${this.feedbakSelected.title}.xlsx`
        a.href = url
        a.target = '_self'
        a.click()
        setTimeout(function () {
          a.remove()
          URL.revokeObjectURL(url)
        }, 100)
        this.hideLoading()
      })
    },
    selectTab(tab) {
      if (tab === 'Activities' && !this.isActivitiesTabEnable) return
      this.selectedTab = tab
      if (tab === 'Activities') {
        console.log('masuk sini 1')
        this.showLoading()
        if (this.isCreateMode && !this.oneChance && this.activityTemplate.length > 0) {
          this.activities = []
          this.oneChance = true
          this.toggleModalActivityTemplate()
        } else if (this.isCreateMode && !this.oneChance) {
          this.activities = []
          this.oneChance = true
          this.addNewActivity()
        }
        if (this.activities.length >= 10) {
          setTimeout(() => this.hideLoading(), 5000)
        } else if (this.activities.length < 5) {
          this.hideLoading()
        } else {
          setTimeout(() => this.hideLoading(), 3000)
        }
      } else if (tab === 'Grade') {
        this.levelTabSelected = 1
        this.getGradingData()
      }
    },
    sliceString(str) {
      if (!str) return
      if (str.length < 20) return str
      return str.slice(0, 19) + '...'
    },
    getFeedbackList(classId) {
      let param = queryString.stringify({
        limit: 10000,
        nama: '',
        page: 0
        // kelas_id: classId || this.selectedClass.id
      })
      this.GET_LIST_EXERCISE({
        params: param,
        customerId: this.customerId
      }).then((res) => {
        let result = []
        result = this.activities
          .map((activity) => activity.activityItems.map((item) => item))
          .flat()
          .filter((item) => item.activityItemType === 'INSTRUCTOR_FEEDBACK')
        const filterFeedback = (res.data.data && res.data.data.filter((item) => item.exercise_type_name === 'SURVEY')) || []
        filterFeedback.map((feedback) => {
          result.map((item, index) => {
            if (feedback.id === parseInt(item.itemReferenceId)) {
              result[index].feedbackName = feedback.name
            }
          })
        })
        this.list_instructur.map((instructor) => {
          result.map((item, index) => {
            if (item.expert_id === instructor.id) result[index].title = `${result[index].feedbackName} - ${instructor.name}`
          })
        })
        this.feedbackList = result
      })
    },
    fetchInstructorFeedback() {
      this.isFetchFeedbackInstructor = true
      this.feedbackColumn = []
      this.feedbackColumn.push({ label: 'Name', field: 'student_name' })
      this.showLoading()
      this.GET_INSTRUCTOR_FEEDBACK({
        activityItemsId: this.feedbakSelected.id,
        params: {
          instructorId: this.feedbakSelected.expert_id
        }
      }).then((res) => {
        const feedbackResponse = res.data.data
        const parser = new DOMParser()
        const column = feedbackResponse.questionList.map((item) => {
          return {
            label: parser.parseFromString(item.question, 'text/html').body.firstChild.textContent,
            field: item.id
          }
        })
        this.feedbackColumn = this.feedbackColumn.concat(column)
        this.studentFeedback = feedbackResponse.student.map((std) => {
          std.answer.studentQuestionAnswers.map((answer) => {
            std[`${answer.id}`] = answer.answer
          })
          return {
            ...std
          }
        })
        this.isFetchFeedbackInstructor = false
        this.hideLoading()
      })
    },
    resetFilterStudent() {
      this.isFormRegisterVisible = false
      this.userFullName = this.userEmail = this.userPhoneNumber = this.ecommerce = this.voucerCode = this.redeemCode = this.isCurator = ''
      this.generalError['userFullName'] = this.generalError['userEmail'] = this.generalError['userPhoneNumber'] = undefined
      this.selectedSource = this.source[0]
      this.selectedProgramStudent = this.programs[0]
      this.selectedPipelineStage = this.allObject
      this.currentPage = 0
      this.getProspectList()
    },
    searchProspect() {
      this.currentPage = 0
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.getProspectList()
      }, this.delayTime)
    },
    addNewStudent() {
      if (!this.generalError['userFullName'] && !this.generalError['userEmail'] && !this.generalError['userPhoneNumber']) {
        if (this.userFullName && this.userEmail && this.userPhoneNumber) {
          this.selectedProspect = []
          this.selectedProspect.push({
            id: null,
            source: 'CRM',
            name: this.userFullName,
            email: this.userEmail,
            phoneNumber: this.userPhoneNumber,
            voucherCode: this.voucerCode,
            ecommerce: this.ecommerce?.code,
            isCurator: this.isCurator,
            redeemCode: this.redeemCode,
          })
          this.enrollStudent()
        } else {
          this.generalError['userFullName'] = 'Name is required'
          this.generalError['userEmail'] = 'Email is required'
          this.generalError['userPhoneNumber'] = 'Phone number is required'
          this.$forceUpdate()
        }
      }
    },
    getProspectList() {
      if (this.selectedSource.value === 'MANUAL') {
        this.getDP()
        this.userFullName = this.userEmail = this.userPhoneNumber = this.ecommerce = this.voucerCode = ''
        this.generalError['userFullName'] = this.generalError['userEmail'] = this.generalError['userPhoneNumber'] = undefined
        this.isFormRegisterVisible = true
      } else if (this.selectedSource.value === 'SCHOLARSHIP') {
        const params = queryString.stringify({
          page: this.currentPage,
          size: this.size,
          statusId: this.selectedPipelineStage.value,
          programCode: this.scholarshipProgramSelected.value,
          customerId: this.customerId,
          direction: 'DESC',
          sortBy: 'updatedDate',
          q: this.keyword
        })

        this.GET_SCHOLARSHIP_PROSPECT({ params }).then((res) => {
          this.counter === 3 ? (this.counter = 0) : this.counter++
          const totalRows = res.pagination.total
          const prospects = res.data
          this.prospectList = []
          this.totalRows = totalRows
          this.totalPages = totalRows > 10 ? Math.ceil(totalRows / this.size) : prospects.length === 0 ? 0 : 1
          if (prospects.length > 0) {
            prospects.forEach((prospect) => {
              this.prospectList.push({
                valueParams: { id: prospect.id, source: 'CRM', name: prospect.name, email: prospect.email, phoneNumber: prospect.phoneNumber },
                firstQuery: { id: prospect.id, source: 'CRM', name: prospect.name, email: prospect.email, phoneNumber: prospect.phoneNumber },
                firstColumn: prospect.name,
                firstColumnDesc: prospect.email,
                secondColumn: prospect.phoneNumber
              })
            })
          }
        })
      } else {
        this.isFormRegisterVisible = false
        this.$forceUpdate()
        this.GET_PROSPECT({
          params: {
            page: this.currentPage,
            size: this.size,
            source: this.selectedSource.value,
            programId: this.selectedProgramStudent.value,
            coreClassId: '',
            pipelineStageId: this.selectedPipelineStage.value,
            query: this.keyword
          }
        }).then((res) => {
          this.counter === 3 ? (this.counter = 0) : this.counter++
          this.totalPages = res.data.data.totalPages
          this.totalRows = res.data.data.totalRows
          this.size = res.data.data.size
          this.currentPage = res.data.data.currentPage

          this.prospectList = []
          res.data.data.data.forEach((prospect) => {
            this.prospectList.push({
              valueParams: { id: prospect.id, source: prospect.source, name: prospect.fullName, email: prospect.email, phoneNumber: prospect.phoneNumber },
              firstQuery: { id: prospect.id, source: prospect.source, name: prospect.fullName, email: prospect.email, phoneNumber: prospect.phoneNumber },
              firstColumn: prospect.fullName,
              firstColumnDesc: prospect.email,
              secondColumn: prospect.phoneNumber
            })
          })
        })
      }
    },
    getDP() {
      this.DIGITAL_PLATFORMS().then((res) => {
        const result = res.data.data
        const all = { code: '', id: 0, name: 'All Ecommerce' }
        this.dpList = [all].concat(result)
      })
    },
    searchByEcommerce() {},
    getStudentList() {
      this.showLoading()
      this.GET_LIST_ABSENSIREPORT({
        customerId: this.customerId,
        params: queryString.stringify({
          page: this.currentPageStudent,
          limit: this.sizeStudent,
          lms_class_id: this.$route.params.id
        })
      })
        .then((res) => {
          this.counterStudent === 3 ? (this.counterStudent = 0) : this.counterStudent++
          res.data.data.length === 0 ? (this.totalPagesStudent = 1) : Math.ceil(res.data.data.length / this.sizeStudent)
          this.totalRowsStudent = res.data.pagination.total
          this.studentList = res.data.data
        })
        .catch(() => showVueToast('Request failed, please try again later!', 'error', 2000))
      setTimeout(() => this.hideLoading(), 2000)
    },
    getProgramList() {
      this.programs = []
      this.GET_LIST_PROGRAM({}).then((res) => {
        this.selectedProgramStudent = {
          name: res.data.data[0].name,
          value: res.data.data[0].id
        }
        for (let i = 0; i < res.data.data.length; i++) {
          this.programs.push({
            name: res.data.data[i].name,
            value: res.data.data[i].id
          })
        }
        this.getProspectList()
        this.getStudentList()
      })
    },
    getPipelineStage() {
      this.selectedPipelineStage = this.allObject
      if (!this.selectedProgramStudent) return
      this.pipelineStage = []
      this.pipelineStage.push(this.allObject)
      this.GET_PIPELINE_STAGE({ params: { programId: this.selectedProgramStudent?.value, customerId: this.customerId } }).then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          this.pipelineStage.push({
            name: res.data.data[i].name,
            value: res.data.data[i].id
          })
        }
      })
    },
    getScholarshipProgram() {
      const programs = [
        {
          name: 'IndoDigi',
          value: 'IndoDigi'
        },
        {
          name: 'TechChamp',
          value: 'TechChamp'
        }
      ]
      this.selectedProgramStudent = programs[0]
      this.programs = programs
    },
    getScholarshipWorkflow() {
      const params = queryString.stringify({
        customerId: this.customerId,
        currentWorkflowStatusId: this.scholarshipCurrentWorkflow
      })
      this.pipelineStage = []
      const registered = {
        name: 'Registered',
        value: ''
      }

      this.selectedPipelineStage = registered
      this.pipelineStage.push(registered)
      this.GET_SCHOLARSHIP_PROSPECT_WORKFLOW({ params }).then((res) => {
        res.map((item) => {
          this.pipelineStage.push({
            name: this.uppercaseFirstWord(item.statusName),
            value: item.workflowStatusToId
          })
        })
      })
    },
    uppercaseFirstWord(str) {
      // Split the string into an array of words
      const words = str.split(' ')
      // Iterate through each word in the array
      const capitalizedWords = words.map((word) => {
        // Capitalize the first letter of each word
        const firstLetter = word.charAt(0).toUpperCase()
        const remainingLetters = word.slice(1)
        return firstLetter + remainingLetters
      })
      // Join the capitalized words back into a single string
      const result = capitalizedWords.join(' ')
      return result
    },
    onPageChange(page) {
      this.currentPage = page - 1
      this.getProspectList()
    },
    onPageChangeStudent(page) {
      this.currentPageStudent = page - 1
      this.getStudentList()
    },
    resetSelectedStudent() {
      this.isReset = true
      setTimeout(async () => {
        this.isReset = await false
        this.selectedProspect = []
      }, 500)
    },
    addStudent(prospect) {
      this.isReset = true
      setTimeout(async () => {
        this.isReset = await false
        this.selectedProspect = []
        this.selectedProspect.push(prospect)
      }, 500)
      this.isAddStudentModal = true
    },
    enrollStudent() {
      this.showLoading()
      this.failedStudent = []
      this.ENROLL_PROSPECT({
        lmsClassId: this.$route.params.id,
        params: { customerId: this.customerId },
        payload: { students: this.selectedProspect }
      })
        .then(async (res) => {
          await res.data.data.forEach((element) => {
            if (!element.success) {
              this.failedStudent.push(element.message)
            }
          })
          this.resetFilterStudent()
          this.resetSelectedStudent()
          this.hideAddStudentModal()
          if (this.failedStudent.length > 0) {
            this.showAlreadyExistModal()
          } else {
            showVueToast('Enroll student successfully!', 'success', 2000)
            this.getStudentList()
          }
        })
        .catch(() => showVueToast('Request failed, please try again later!', 'error', 2000))
      setTimeout(() => this.hideLoading(), 2000)
    },
    actionCheckbox(val) {
      this.selectedProspect = val
    },
    showAddStudentModal() {
      this.isAddStudentModal = true
    },
    hideAddStudentModal() {
      this.isAddStudentModal = false
    },
    showAlreadyExistModal() {
      this.alreadyExistModal = true
    },
    hideAlreadyExistModal() {
      this.alreadyExistModal = false
      this.getStudentList()
    },
    getWorkflowList() {
      this.GET_WORKFLOW()
    },
    getActivity() {
      if (this.isCopyMode || this.isEditMode) {
        this.showLoading()
        this.isFetchActivityData = true
        this.GET_JADWALKELAS_NEW({
          lmsClassId: this.jadwalKelasId
        }).then((res) => {
          const data = res.data.data
          if (this.isCopyMode) {
            const activities = JSON.parse(JSON.stringify(data.activities))
            activities.forEach((item) => {
              item.activityItems.map((act) => {
                if (act.startTime) act.startTime = null
                if (act.endTime) act.endTime = null
                if (act.startTimeOfDay) act.startTimeOfDay = null
                if (act.endTimeOfDay) act.endTimeOfDay = null
                if (act.dayOfWeek) act.dayOfWeek = null
              })
            })
            this.activities = activities
            this.isFetchActivityData = false
            this.hideLoading()
          } else {
            this.activities = JSON.parse(JSON.stringify(data.activities))
            this.isFetchActivityData = false
            this.hideLoading()
          }
        })
      }
    },
    getJadwalKelasDetail() {
      this.showLoading()
      this.GET_JADWALKELAS_NEW({
        lmsClassId: this.jadwalKelasId,
        customerId: this.customerId
      })
        .then((res) => {
          this.GET_WORKFLOW().then(() => {
            this.distributePayload(res?.data?.data)
          })
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('LMS class schedule is not found!', 'error', 2000)
        })
    },
    async distributePayload(data) {
      this.hasIssue = data.hasIssue
      this.isAvailableFromStart = data.isAvailableFromStart
      this.selectedStatus = this.statusList.filter((item) => {
        return item.value === data.active
      })[0]
      this.selectedWorkflow = this.workflow.filter((item) => {
        return item.code === data.workflowCode
      })[0]
      this.originalScheduleName = data.name
      this.scheduleName = data.name
      this.zoomLink = data.zoomLink
      this.selectedTimeLimit = await this.timeLimitOptions.filter((item) => {
        return item.value === data.timeLimit
      })[0]
      this.zoomHostKey = data.zoomHostKey
      this.telegramLink = data.telegramLink
      this.customerServiceNumber = data.customerServiceNumber
      this.scheduleStartEndPMO = data?.scheduleStartEndPMO || ''
      this.scheduleCodePMO = data?.scheduleCodePMO || ''
      this.selectedClassType = await this.typeList.filter((item) => {
        return item.value === data.deliveryType
      })[0]
      this.selectedClass = await this.getClassListAsync(data.coreClassId)
      this.visibleClass = true
      if (data?.variantId) {
        const getVariantList = await this.getCoreClassVariantList(data.coreClassId)
        this.selectedVariant = getVariantList.filter((variant) => variant.variantId === data.variantId)[0]
      }
      // this.selectedClass = await this.list_kelas.filter((item) => {
      //   return item.id === data.coreClassId
      // })[0]
      this.selectedInstructorList = []
      for (let i = 0; i < data.instructorIds.length; i++) {
        let instructor = await this.list_instructur.filter((item) => {
          return item.id === data.instructorIds[i]
        })[0]
        this.selectedInstructorList.push({ selectedInstructor: instructor })
      }

      // this.selectedInstructor = await this.list_instructur.filter((item) => {
      //   return item.id === data.instructorId
      // })[0]

      this.activityItemOptions = this.getActivityItems(data.activities).map((item) => {
        delete item.title
        if (item.itemReferenceId && item.itemReferenceId !== "") {
          item.itemReferenceId = parseInt(item.itemReferenceId)
        }
        if (item.itemLowResReferenceId && item.itemLowResReferenceId !== "") {
          item.itemLowResReferenceId = parseInt(item.itemLowResReferenceId)
        }
        return {
          title: item.activityItemTitle,
          ...item
        }
      })

      if (this.isCopyMode) {
        const activities = JSON.parse(JSON.stringify(data.activities))
        activities.forEach((item) => {
          item.activityItems.map((act) => {
            if (act.startTime) act.startTime = null
            if (act.endTime) act.endTime = null
            if (act.startTimeOfDay) act.startTimeOfDay = null
            if (act.endTimeOfDay) act.endTimeOfDay = null
            if (act.dayOfWeek) act.dayOfWeek = null
          })
        })
        this.activities = activities
      } else {
        this.activities = JSON.parse(JSON.stringify(data.activities))
        this.activities.forEach(activity => {
          activity.activityItems.forEach(item => {
            if (item.itemReferenceId && item.itemReferenceId !== "") {
              item.itemReferenceId = parseInt(item.itemReferenceId)
            }
            if (item.itemLowResReferenceId && item.itemLowResReferenceId !== "") {
              item.itemLowResReferenceId = parseInt(item.itemLowResReferenceId)
            }
          })
        })
      }
      this.checkActivityTemplate(data.coreClassId)
      this.hideLoading()
    },
    updateActivities() {
      const forActivities = JSON.parse(JSON.stringify(this.activities))
      this.activities = []
      this.activities = forActivities
    },
    checkActivityTemplate(coreClassId) {
      this.GET_ACTIVITIES({ coreClassId }).then((res) => {
        const code = res.data.code
        const data = res.data.data
        if (code === 200) {
          this.activityTemplate = []
          for (let l = 0; l < data.length; l++) {
            this.activityTemplate.push({
              title: data[l].title,
              isIndependent: data[l].isIndependent ? data[l].isIndependent : false,
              isPostTest: data[l].isPostTest ? data[l].isPostTest : false,
              activityItems: []
            })
            for (let m = 0; m < data[l].coreClassActivityItem.length; m++) {
              this.activityTemplate[l].activityItems.push({
                title: data[l].coreClassActivityItem[m].title ? data[l].coreClassActivityItem[m].title : null,
                description: data[l].coreClassActivityItem[m].description ? data[l].coreClassActivityItem[m].description : null,
                activityItemType: data[l].coreClassActivityItem[m].activityItemType ? data[l].coreClassActivityItem[m].activityItemType : null,
                itemReferenceId: data[l].coreClassActivityItem[m].itemReferenceId ? data[l].coreClassActivityItem[m].itemReferenceId : null,
                includeInCertificate: data[l].coreClassActivityItem[m].includeInCertificate ? data[l].coreClassActivityItem[m].includeInCertificate : false,
                download_status: data[l].coreClassActivityItem[m].download_status ? data[l].coreClassActivityItem[m].download_status : false,
                warned: data[l].coreClassActivityItem[m].warned ? data[l].coreClassActivityItem[m].warned : false
              })
            }
          }
        }
      })
    },
    dontDuplicateActivityTemplate() {
      this.addNewActivity()
      this.toggleModalActivityTemplate()
    },
    duplicateActivityTemplate() {
      this.activities = []
      this.activities = this.activityTemplate
      this.toggleModalActivityTemplate()
    },
    changePosition(type, otherParams, index) {
      this.refresh = !this.refresh
      switch (type) {
        case 'activity':
          const activity = this.activities.splice(index, 1)[0]
          this.activities.splice(otherParams === 'up' ? index - 1 : index + 1, 0, activity)
          break
        case 'child':
          const childActivity = this.activities[index].activityItems.splice(otherParams.index, 1)[0]
          this.activities[index].activityItems.splice(otherParams.position === 'up' ? otherParams.index - 1 : otherParams.index + 1, 0, childActivity)
          break
      }
    },
    openDeleteModal(type, activityIndex, index) {
      this.visibleDeleteModal = true
      this.selectedIndexToBeDeleted = {
        child: index,
        parent: activityIndex
      }
      this.selectedTypeToBeDeleted = type === 'activity' ? 'activity' : 'sub-activity'
    },
    closeDeleteModal() {
      this.visibleDeleteModal = false
      this.selectedIndexToBeDeleted = null
    },
    openInstructorWarningModal() {
      this.isInstructorWarningModal = true
    },
    closeInstructorWarningModal() {
      this.isInstructorWarningModal = false
      this.customPath = ''
      this.customQuery = null
    },
    deleteItem() {
      if (this.selectedIndexToBeDeleted && typeof this.selectedIndexToBeDeleted.child === 'undefined') {
        this.activities.splice(this.selectedIndexToBeDeleted.parent, 1)
      } else {
        this.activities[this.selectedIndexToBeDeleted.parent].activityItems.splice(this.selectedIndexToBeDeleted.child, 1)
      }
      this.closeDeleteModal()
    },
    getClassList() {
      if (!this.isEditMode && !this.isCopyMode) {
        this.selectedClass = null
      }
      this.showLoading()
      let paramsTemp = queryString.stringify({
        ...{
          limit: 500,
          sort: '',
          hasDeliveryTypes: this.selectedClassType.value
        }
      })
      this.GET_LIST_KELAS({
        params: paramsTemp,
        customerId: this.customerId
      }).then(() => {
        this.visibleClass = true
        // if (this.isEditMode || this.isCopyMode) {
        //   this.selectedClass = this.list_kelas.filter((item) => {
        //     return item.id === this.jadwalkelasnew.coreClassId
        //   })[0]
        // }
        this.hideLoading()
      })
    },
    getClassListAsync(masterId) {
      return new Promise((resolve) => {
        this.GET_KELAS({
          masterId,
          customerId: this.customerId
        })
          .then((res) => {
            if (res?.data?.code === 200) {
              resolve(res?.data?.data)
            } else {
              resolve(null)
            }
          })
          .catch(() => {
            resolve(null)
          })
      })
    },
    searchInstructor(keyword) {
      this.getListInstructor(keyword)
    },
    getListInstructor(keyword) {
      this.isLoadDataInstructor = true
      if (!this.isEditMode && !this.isCopyMode) {
        this.selectedInstructor = null
      }
      let paramsTemp = queryString.stringify({
        ...{
          limit: 500,
          page: 1,
          direction: 'DESC',
          program: '',
          sortBy: 'createdDate',
          nama: keyword
        }
      })

      this.GET_LIST_INSTRUCTUR_V2({
        params: paramsTemp,
        customerId: this.customerId
      })
        .then((res) => {
          this.isLoadDataInstructor = false
          this.visibleInstructor = true
          this.instructorList = res.data.data
          const listInstructor = []
          if (this.selectedInstructorList.length) {
            for (let k = 0; k < this.selectedInstructorList.length; k++) {
              if (this.selectedInstructorList[k].selectedInstructor) listInstructor.push(this.selectedInstructorList[k].selectedInstructor.id)
            }
          }
          this.filteredInstructorList = this.instructorList.filter(function (el) {
            return !listInstructor.includes(el.id)
          })
        })
        .catch(function () {
          this.visibleInstructor = true
        })
    },

    getInstructorById(expertId) {
      return new Promise((resolve, reject) => {
        this.GET_INSTRUCTUR({
          masterId: expertId,
          customerId: this.customerId
        })
          .then((res) => {
            if (res.data.code === 200) {
              resolve(res.data.data)
            } else {
              resolve(null)
            }
          })
          .catch(() => {
            resolve(null)
          })
      })
    },

    addNewActivity() {
      const item = {
        title: null,
        isIndependent: false,
        isPostTest: false,
        activityItems: [
          {
            title: null,
            description: null,
            activityItemType: null,
            itemReferenceId: null,
            includeInCertificate: false,
            download_status: false,
            warned: false
          }
        ]
      }
      this.activities.push(item)
    },
    validatorPhoneNumber(value, forError) {
      if (value.length >= 15) {
        switch (forError) {
          case 'userPhoneNumber':
            this.userPhoneNumber = value.slice(0, 15)
            break
          case 'customerServiceNumber':
            this.customerServiceNumber = value.slice(0, 15)
            break
        }
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (forError === 'customerServiceNumber' && value.length < 1) {
        this.generalError[forError] = undefined
      } else if (value[0] !== '0' || value[1] !== '8') {
        this.generalError[forError] = 'Must start with 08xxxx'
      } else if (value.length < 10) {
        this.generalError[forError] = 'Minimum 10 digits, Maximum 15 digits'
      } else {
        this.generalError[forError] = undefined
      }
    },
    save() {
      let pass = true
      for (let i = 0; i < this.activities.length; i++) {
        const activity = this.activities[i]
        if (!activity.isIndependent) {
          for (let j = 0; j < activity.activityItems.length; j++) {
            const activityItem = activity.activityItems[j]
            const activityItemType = activityItem.activityItemType
            if ((activityItemType === 'EXAM' || activityItemType === 'QUIZ' || activityItemType === 'PRETEST') && !activityItem.timeConstraint === 0 && activityItem.timeConstraint == null) {
              this.$refs['activityForm' + i][0].$refs['childActivity' + j][0].$refs.warn.innerHTML = '<div class="text-xs mt-2 ml-4 text-red-500">Please turn on ‘Mark as Independent’ checkbox!</div>'
              pass = false
            }
          }
        }
      }
      if (!pass) {
        this.showModal = false
        return setTimeout(() => (this.showModal = true), 3500)
      }

      this.activityErrorHighlight = JSON.parse(JSON.stringify(this.activities))

      for (let i = 0; i < this.activities.length; i++) {
        this.activityErrorHighlight[i].error = false
        if (!this.activities[i].title) {
          this.activityErrorHighlight[i].error = true
        } else {
          for (let j = 0; j < this.activities[i].activityItems.length; j++) {
            if (!this.activities[i].activityItems[j].title || !this.activities[i].activityItems[j].description || !this.activities[i].activityItems[j].activityItemType) {
              this.activityErrorHighlight[i].activityItems[j].error = true
            } else {
              this.activityErrorHighlight[i].activityItems[j].error = false
              switch (this.activities[i].activityItems[j].activityItemType) {
                case 'VIDEO':
                case 'DOCUMENT':
                case 'ASSIGNMENT':
                  if (!this.activities[i].activityItems[j].itemReferenceId || this.activities[i].activityItems[j].itemReferenceId === 0) this.activityErrorHighlight[i].activityItems[j].error = true
                  break
                case 'PRETEST':
                case 'QUIZ':
                case 'EXAM':
                  if (!this.activities[i].activityItems[j].itemReferenceId || this.activities[i].activityItems[j].itemReferenceId === 0) {
                    this.activityErrorHighlight[i].activityItems[j].error = true
                  } else {
                    switch (`${this.activities[i].activityItems[j].timeConstraint}`) {
                      case '1':
                        if (!this.activities[i].activityItems[j].startTime || !this.activities[i].activityItems[j].endTime) this.activityErrorHighlight[i].activityItems[j].error = true
                        break
                      case '2':
                        if (!this.activities[i].activityItems[j].startTime || !this.activities[i].activityItems[j].durationInMinutes) this.activityErrorHighlight[i].activityItems[j].error = true
                        break
                      case '3':
                        if (!this.activities[i].activityItems[j].startTime || !this.activities[i].activityItems[j].endTime || !this.activities[i].activityItems[j].durationInMinutes)
                          this.activityErrorHighlight[i].activityItems[j].error = true
                        break
                    }
                  }
                  break
                case 'MANUAL_TASK':
                  switch (`${this.activities[i].activityItems[j].timeConstraint}`) {
                    case '1':
                      if (!this.activities[i].activityItems[j].startTime || !this.activities[i].activityItems[j].endTime) this.activityErrorHighlight[i].activityItems[j].error = true
                      break
                    case '2':
                      if (!this.activities[i].activityItems[j].endTime) this.activityErrorHighlight[i].activityItems[j].error = true
                      break
                  }
                  break
                case 'INSTRUCTOR_FEEDBACK':
                  if (!this.activities[i].activityItems[j].itemReferenceId || this.activities[i].activityItems[j].itemReferenceId === 0 || !this.activities[i].activityItems[j].expert_id) {
                    this.activityErrorHighlight[i].activityItems[j].error = true
                  }
                  break
                case 'OFFLINE_MEETING':
                case 'WEBINAR':
                  if (!this.activities[i].activityItems[j].startTime || !this.activities[i].activityItems[j].endTime) this.activityErrorHighlight[i].activityItems[j].error = true
                  break
                case 'OPEN_LINK':
                  if (!this.activities[i].activityItems[j].label || !this.activities[i].activityItems[j].link) this.activityErrorHighlight[i].activityItems[j].error = true
                  break
                case 'RECURRING_ONLINE':
                  if (!this.activities[i].activityItems[j].dayOfWeek || !this.activities[i].activityItems[j].startTimeOfDay || !this.activities[i].activityItems[j].endTimeOfDay)
                    this.activityErrorHighlight[i].activityItems[j].error = true
                  break
                case 'REFLECTIVE_JOURNAL_ESSAY':
                  if (!this.activities[i].activityItems[j].reflectiveJournalQuestion1)
                    this.activityErrorHighlight[i].activityItems[j].error = true
                  
                  if (!this.activities[i].activityItems[j].reflectiveJournalQuestion2)
                    this.activityErrorHighlight[i].activityItems[j].error = true
                  break
              }
            }
          }
        }
      }
      this.$forceUpdate()
      let isValidToSubmit = true
      this.activityErrorHighlight.every((activity) => {
        if (activity.error) {
          isValidToSubmit = false
          return false
        } else {
          activity.activityItems.every((item) => {
            if (item.error) {
              isValidToSubmit = false
              return false
            }
            isValidToSubmit = true
            return true
          })
        }
      })

      if (!isValidToSubmit) {
        showVueToast('Failed to submit, please re-check all the required fields.', 'error', 2000)
      } else {
        const constructInstructor = this.selectedInstructorList.map((item) => item.selectedInstructor.id)
        const uniqueInstructor = constructInstructor.filter((value, index, self) => self.indexOf(value) === index)
        let timeLimit = 0
        if (this.selectedTimeLimit) {
          timeLimit = this.selectedTimeLimit['value']
        }
        const payloads = {
          isAvailableFromStart: this.isAvailableFromStart,
          coreClassId: this.selectedClass && this.selectedClass.id,
          name: this.scheduleName,
          workflowCode: this.selectedWorkflow && this.selectedWorkflow.code,
          zoomHostKey: this.zoomHostKey,
          zoomLink: this.zoomLink,
          timeLimit: timeLimit,
          telegramLink: this.telegramLink,
          customerServiceNumber: this.customerServiceNumber,
          instructorIds: uniqueInstructor,
          active: this.selectedStatus && this.selectedStatus.value,
          deliveryType: this.selectedClassType && this.selectedClassType.value,
          scheduleCodePMO: this.scheduleCodePMO,
          scheduleStartEndPMO: this.scheduleStartEndPMO,
          activities: this.activities
        }
        if (this.selectedVariant) {
          payloads.variantId = this.selectedVariant.variantId
        }

        this.createJadwal(payloads)
      }
    },
    syncSurveyParams(activityItems) {
      return activityItems.map((items) => {
        if (items.activityItemType === 'SURVEY') {
          delete items.includeInCertificate
          return {
            ...items,
            activityItemType: 'INSTRUCTOR_FEEDBACK'
          }
        }
        return {
          ...items
        }
      })
    },
    checkActivities(activities) {
      return activities.map((activity) => {
        const activityItems = this.syncSurveyParams(activity.activityItems)
        return {
          ...activity,
          activityItems
        }
      })
    },
    openNewTab() {
      const routeData = this.$router.resolve({
        path: this.customPath,
        query: this.customQuery
      })
      window.open(routeData.href, '_blank')
    },
    async errorMessageOfInstructor(res) {
      const instructor = await this.getInstructorById(res.data.expertId)
      const date = moment(res.data.startTime).format('DD MMMM')
      const startTime = moment(res.data.startTime).format('HH:mm')
      const endTime = moment(res.data.endTime).format('HH:mm')
      if (res.errors.error.includes('The instructor schedule does not match the day') || res.errors.error.includes('Instructor not available schedule setting')) {
        const message = `${instructor.name} is not available in ${date} from ${startTime} until ${endTime}, please check in instructor availability.`
        this.errorMessage = {
          message,
          status: 'NA'
        }
        this.customPath = `/instructur/edit/${res.data.expertId}`
        this.customQuery = { active_tab: 'Availability' }
        this.openInstructorWarningModal()
      } else {
        const message = `Schedule availibility of ${instructor.name} \n is conflict for ${date} from ${startTime} - ${endTime}, please check in overview page.`
        this.errorMessage = {
          message,
          status: 'CN'
        }
        this.customPath = `/class-schedule-overview`
        this.customQuery = { expert_id: res.data.expertId, month: moment(res.data.startTime).format('MM'), date: res.data.startTime }
        this.openInstructorWarningModal()
      }
    },
    createJadwal(payloads) {
      this.temporaryDisabled = true
      for (let i = 0; i < payloads.activities.length; i++) {
        payloads.activities[i].seqNo = i + 1
        for (let j = 0; j < payloads.activities[i].activityItems.length; j++) {
          payloads.activities[i].activityItems[j].seqNo = j + 1
        }
      }
      this.showLoading()
      const customerId = this.customerId
      payloads.activities = this.checkActivities(payloads.activities)
      if (!this.isEditMode) {
        if (this.isCopyMode) {
          const newName = payloads.name === this.originalScheduleName ? `COPY - ${payloads.name}` : payloads.name
          payloads.name = newName
        }
        this.CREATE_JADWALKELAS_NEW({
          customerId: customerId,
          payload: payloads
        }).then((res) => {
          if (res.code === 422) {
            this.errorMessageOfInstructor(res)
            this.hideLoading()
            this.temporaryDisabled = false
            return
          }
          if (res.status === 200) {
            if (this.isCopyMode) {
              showVueToast("You've successfully copy the schedule!", 'success', 3000)
            } else {
              showVueToast('Successfully saved schedule!', 'success', 3000)
            }
            setTimeout(() => {
              this.hideLoading()
              this.temporaryDisabled = false
            }, 3500)
            this.toLMSClassListMenu()
          } else {
            showVueToast(`${res?.errors?.error ? res?.errors?.error.charAt(0).toUpperCase() + res?.errors?.error.slice(1) : 'System error!'}!`, 'error', 3000)
            setTimeout(() => {
              this.hideLoading()
              this.temporaryDisabled = false
            }, 3500)
          }
        })
      } else {
        this.UPDATE_JADWALKELAS_NEW({
          customerId: this.customerId,
          lmsClassId: this.jadwalKelasId,
          payload: payloads
        }).then(async (res) => {
          if (res.code === 422) {
            this.errorMessageOfInstructor(res)
            this.hideLoading()
            this.temporaryDisabled = false
            return
          }
          if (res.status === 200) {
            showVueToast('Successfully saved schedule!', 'success', 3000)
            this.hideLoading()
            this.temporaryDisabled = false
            this.initProcess()
          } else {
            showVueToast(`${res?.errors?.error ? res?.errors?.error.charAt(0).toUpperCase() + res?.errors?.error.slice(1) : 'System error!'}!`, 'error', 3000)
            setTimeout(() => {
              this.hideLoading()
              this.temporaryDisabled = false
            }, 3500)
          }
        })
      }
    },
    formValidation(payloads, mandatoryFields, errorLabel, errorObject, index) {
      const validation = isNotEmptyError(payloads, mandatoryFields)
      if (typeof index !== 'undefined') {
        this[errorObject][index] = validation.reduce(function (map, obj) {
          map[obj.field] = errorLabel[obj.field] + ' ' + obj.error
          return map
        }, {})
      } else {
        this[errorObject] = validation.reduce(function (map, obj) {
          map[obj.field] = errorLabel[obj.field] + ' ' + obj.error
          return map
        }, {})
      }
      this.$forceUpdate()
      return this[errorObject]
    }
  }
}
</script>

<style lang="scss" scoped>
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }

  // Box.
  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #f7931e;
    border-radius: 4px;
    margin-right: 10px;
  }

  // Box hover
  &:hover + label:before {
    background: #f7931e;
  }

  // Box checked
  &:checked + label:before {
    background: #f7931e;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
