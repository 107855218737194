import { stat } from 'fs'

import store from '../../store'
import { async } from 'q'
import VueCookies from 'vue-cookies'

const state = {
  loading: false,
  customer: {},
  customers: [],
  columns: [],
  paginate: { total: 0 },
  resp: {},
  user_session: {
    refreshEnabled: false,
    email: '',
    //password : '',
    token: {
      name: '',
      iat: 0,
      exp: 0,
      token: ''
    }
  }
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  CUSTOMER: (state, payload) => {
    state.customer = payload
    state.loading = false
  },
  LOGIN: (state, payload) => {
    state.user_session = payload
    state.loading = false
  },
  SET_CUSTOMERS: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.customers = payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  }
}

const actions = {
  LOGIN: async ({ commit, dispatch }, payload) => {
    commit('LOADING')
    return await axios({
      url: 'api/auth/login',
      data: payload,
      method: 'POST'
    }).then((resp) => {
      if (resp.data.code == 200) {
        commit('CUSTOMER', resp.data.data)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.data.token
      }
      return resp
    })
  },
  RESET_PASSWORD: async ({ commit, dispatch }, payload) => {
    commit('LOADING')
    return await axios({
      url: 'api/auth/reset-password',
      data: payload,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  },
  PROFILE: async ({ commit }) => {
    commit('LOADING')
    return await axios({
      url: 'v2/api/student/my-profile',
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  CHANGE_PASSWORD: async ({ commit }, { payloads }) => {
    commit('LOADING')
    return await axios({
      url: 'api/user/change-password',
      data: payloads,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  },

  LOGOUT: async ({ commit, dispatch }) => {
    // axios({ url: "api/logout", data: {}, method: "POST" });
    commit('CUSTOMER', { ...state.customer, ...{ token: null } })
    localStorage.removeItem('modeling')
    localStorage.removeItem('client-name')
    localStorage.removeItem('customer')
    localStorage.removeItem('talent')
    localStorage.removeItem('client')
    VueCookies.remove('token')
    delete axios.defaults.headers.common['Authorization']

    return await true
  },
  RESET_TOKEN_TRX: async ({ commit, dispatch }, payload) => {
    return await axios({
      url: 'adm/reset-token-trx',
      data: payload,
      method: 'POST'
    }).then((resp) => {
      if (resp.data.code == 200) {
        commit('CUSTOMER', resp.data.data)
        //axios.defaults.headers.common['token'] = resp.data.data.token
      }
      return resp
    })
  },
  GET_CUSTOMERS: async ({ commit, dispatch }, args) => {
    commit('LOADING')
    var params = queryString.stringify({
      ...{
        limit: 10,
        page: 1,
        name: null,
        address: null,
        contact_phone: null,
        company: null,
        tarif: null,
        email: null,
        upline_id: null,
        sort: ''
      },
      ...args
    })
    return await axios({
      url: 'adm/customer/list?' + params,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status == 'success') {
        commit('SET_CUSTOMERS', resp.data)
      }
      return resp
    })
  },
  GET_COLUMNS: async ({ commit, dispatch }) => {
    commit('LOADING')
    return await axios({ url: 'adm/customer/columns', method: 'GET' }).then((resp) => {
      if (resp.data.status == 'success') {
        commit('SET_COLUMNS', resp.data.data)
      }
      return resp
    })
  },
  DELETE_CUSTOMER: async ({ commit, dispatch }, cutomerId) => {
    commit('LOADING')
    return await axios({
      url: 'adm/customer/' + cutomerId + '/delete' + campaignId,
      method: 'DELETE'
    }).then((resp) => {
      if (resp.data.code == 200) {
        swal({
          type: 'success',
          title: 'Status...',
          text: resp.data.message
        })
        commit('SET_RESP', resp.data)
      }
      return resp
    })
  }
}

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  customers: (state) => state.customers,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  user_session: (state) => state.user_session,
  resp: (state) => state.resp,
  columns: (state) => state.columns
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
