<template>
  <div>
    <div v-if="stillLoading">
      <vue-simple-spinner size="large" message="Loading..."></vue-simple-spinner>
    </div>
    <div v-show="!stillLoading">
      <b-progress v-show="showProgress" :value="counterProgress" :max="100" show-progress animated></b-progress>
      <b-col sm="12" tag="div">
        <b>
          <div slot="header" class="mt-4">
            <strong class="text-2xl"> Form {{ $route.meta.label }} </strong>
          </div>

          <b-row tag="div">
            <b-col sm="12" tag="div">
              <vue-dropzone
                v-show="showProfileLogo"
                id="dropzone-logo"
                ref="dropzoneLogo"
                class="m-dropzone dropzone"
                :options="dropzoneLogoOptions"
                @vdropzone-success="dropzoneLogoSuccess"
                @vdropzone-removed-file="dropzoneRemovedSuccess"
              />
              <div class="shadow-card rounded-lg bg-white p-6 mb-6 mt-6 w-7/10">
                <b-form-group breakpoint="sm" v-for="item in columns" v-bind:key="item.field">
                  <label for="name" style="color: black">
                    {{ item.label }}
                  </label>
                  <img :src="options[item.field]" v-if="item.field == 'url'" />
                  <div v-else-if="item.field == 'tugas_description'">
                    <quill-editor v-model="options[item.field]" />
                  </div>

                  <b-form-input
                    v-else-if="item.field == 'name'"
                    :placeholder="'Enter ' + item.label + ' ...'"
                    type="text"
                    readonly="true"
                    v-model="options[item.field]"
                    :disabled="item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'"
                  />
                  <div class="" v-else-if="item.field === 'deliveryTypes'">
                    <ul class="class-type">
                      <li>
                        <input type="checkbox" id="webinar" value="WEBINAR" v-model="options[item.field]" class="styled-checkbox" />
                        <label for="webinar">Webinar</label>
                      </li>
                      <li>
                        <input type="checkbox" id="self-based" value="SELF_BASED" v-model="options[item.field]" class="styled-checkbox" />
                        <label for="self-based">Self-based</label>
                      </li>
                      <li>
                        <input type="checkbox" id="offline" value="OFFLINE" v-model="options[item.field]" class="styled-checkbox" />
                        <label for="offline">Offline</label>
                      </li>
                      <li>
                        <input type="checkbox" id="blended" value="BLENDED" v-model="options[item.field]" class="styled-checkbox" />
                        <label for="blended">Blended</label>
                      </li>
                    </ul>
                  </div>

                  <b-form-group v-else-if="item.field == 'exam_url_array'">
                    <b-button variant="primary" @click="addExamUrlArray" style="margin-left: 10px"> <i class="fa fa-plus-square" />Tambah Exam Url </b-button>
                    <div v-for="(itemExamUrl, indexExamUrl) in options[item.field]" :key="'idx' + indexExamUrl">
                      <b-button variant="warning" @click="deleteExamUrlArray(indexExamUrl)">
                        <i class="fa fa-minus-square" />
                      </b-button>
                      <label style="color: black"> ExamUrl ke-{{ indexExamUrl + 1 }} </label>
                      <b-form-input :placeholder="'Enter ' + item.label + ' ...'" type="text" v-model="options[item.field][indexExamUrl]" />
                    </div>
                  </b-form-group>
                  <b-form-group v-else-if="item.field == 'quiz_url_array'">
                    <b-button variant="primary" @click="addQuizUrlArray" style="margin-left: 10px"> <i class="fa fa-plus-square" />Tambah Quiz Url </b-button>
                    <div v-for="(itemQuizUrl, indexQuizUrl) in options[item.field]" :key="'quiz-' + indexQuizUrl">
                      <b-button variant="warning" @click="deleteQuizUrlArray(indexQuizUrl)">
                        <i class="fa fa-minus-square" />
                      </b-button>
                      <label style="color: black"> QuizUrl ke-{{ indexQuizUrl + 1 }} </label>
                      <b-form-input :placeholder="'Enter ' + item.label + ' ...'" type="text" v-model="options[item.field][indexQuizUrl]" />
                    </div>
                  </b-form-group>
                  <b-form-group v-else-if="item.field == 'quiz_exercise_id_array'">
                    <div class="w-1/2">
                      <b-button variant="primary" @click="addQuizExerciseIdArray" style="margin-bottom: 16px"> <i class="fa fa-plus-square" />Tambah Quiz Exercise </b-button>
                      <div v-for="(itemQuizExerciseId, indexQuizExerciseId) in options[item.field]" class="border p-2 mb-2 rounded" :key="'quiz-' + indexQuizExerciseId">
                        <b-button variant="warning" @click="deleteQuizExerciseIdArray(indexQuizExerciseId)">
                          <i class="fa fa-minus-square" />
                        </b-button>
                        <label style="color: black; margin-left: 8px"> Quiz Exercise {{ indexQuizExerciseId + 1 }} </label>
                        <div class="mt-2">
                          <select v-model="options[item.field][indexQuizExerciseId]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                            <option value="0" selected="selected">ID Exercise Quiz</option>
                            <option v-for="item_exercise in list_exercise_quiz" v-bind:key="item_exercise.id" :value="item_exercise.id">{{ item_exercise.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group v-else-if="item.field == 'java_instructur_id_array'">
                    <div class="w-1/2">
                      <b-button variant="primary" @click="addJavaInstructurIdArray" style="margin-bottom: 16px"> <i class="fa fa-plus-square" />Tambah Instruktur </b-button>
                      <div v-for="(itemJavaInstructurId, indexJavaInstructurId) in options[item.field]" class="border p-2 mb-2 rounded" :key="'instructor-' + indexJavaInstructurId">
                        <b-button variant="warning" @click="deleteJavaInstructurIdArray(indexJavaInstructurId)">
                          <i class="fa fa-minus-square" />
                        </b-button>
                        <label style="color: black; margin-left: 8px"> Instruktur {{ indexJavaInstructurId + 1 }} </label>
                        <div class="mt-2">
                          <select v-model="options[item.field][indexJavaInstructurId]" class="form-control m-input">
                            <option v-for="(item, index) in list_instructur" :key="'instructor-' + index" :value="item.id">{{ item.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group v-else-if="item.field == 'pretest_url_array'">
                    <b-button variant="primary" @click="addPretestUrlArray" style="margin-left: 10px"> <i class="fa fa-plus-square" />Tambah Pretest Url </b-button>
                    <label style="color: black"> ExamUrl ke-{{ indexExamUrl + 1 }} </label>
                    <b-form-input :placeholder="'Enter ' + item.label + ' ...'" type="text" v-model="options[item.field][indexExamUrl]" />
                  </b-form-group>
                  <b-form-group v-else-if="item.field == 'quiz_url_array'">
                    <b-button variant="primary" @click="addQuizUrlArray" style="margin-left: 10px"> <i class="fa fa-plus-square" />Tambah Quiz Url </b-button>
                    <div v-for="(itemQuizUrl, indexQuizUrl) in options[item.field]" :key="'quizitem-' + indexQuizUrl">
                      <b-button variant="warning" @click="deleteQuizUrlArray(indexQuizUrl)">
                        <i class="fa fa-minus-square" />
                      </b-button>
                      <label style="color: black"> QuizUrl ke-{{ indexQuizUrl + 1 }} </label>
                      <b-form-input :placeholder="'Enter ' + item.label + ' ...'" type="text" v-model="options[item.field][indexQuizUrl]" />
                    </div>
                  </b-form-group>
                  <b-form-group v-else-if="item.field == 'quiz_exercise_id_array'">
                    <b-button variant="primary" @click="addQuizExerciseIdArray" style="margin-left: 10px"> <i class="fa fa-plus-square" />Tambah Quiz Exercise Id </b-button>
                    <div v-for="(itemQuizExerciseId, indexQuizExerciseId) in options[item.field]">
                      <b-button variant="warning" @click="deleteQuizUrlArray(indexQuizExerciseId)">
                        <i class="fa fa-minus-square" />
                      </b-button>
                      <label style="color: black"> Quiz Exercise Id ke-{{ indexQuizExerciseId + 1 }} </label>
                      <select v-model="options[item.field][indexQuizExerciseId]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                        <option value="0" selected="selected">ID Exercise Quiz</option>
                        <option v-for="(item_exercise, index_exercise) in list_exercise_quiz" v-bind:key="item_exercise.id" :value="item_exercise.id">{{ item_exercise.name }}</option>
                      </select>
                    </div>
                  </b-form-group>
                  <b-form-group v-else-if="item.field == 'pretest_url_array'">
                    <b-button variant="primary" @click="addPretestUrlArray" style="margin-left: 10px"> <i class="fa fa-plus-square" />Tambah Pretest Url </b-button>
                    <div v-for="(itemPretestUrl, indexPretestUrl) in options[item.field]">
                      <b-button variant="warning" @click="deletePretestUrlArray(indexPretestUrl)">
                        <i class="fa fa-minus-square" />
                      </b-button>
                      <label style="color: black"> Pretest Url ke-{{ indexPretestUrl + 1 }} </label>
                      <b-form-input :placeholder="'Enter ' + item.label + ' ...'" type="text" v-model="options[item.field][indexPretestUrl]" />
                    </div>
                  </b-form-group>
                  <b-form-group v-else-if="item.field == 'video_url_array'">
                    <b-button variant="primary" @click="addVideoUrlArray" style="margin-bottom: 16px"> <i class="fa fa-plus-square" />Tambah Video Url </b-button>
                    <div class="w-1/2">
                      <div v-for="(itemVideoUrl, indexVideoUrl) in options[item.field]" class="border p-2 mb-2 rounded">
                        <b-button variant="warning" @click="deleteVideoUrlArray(indexVideoUrl)">
                          <i class="fa fa-minus-square" />
                        </b-button>
                        <label style="color: black; margin-left: 8px"> Video Url {{ indexVideoUrl + 1 }} </label>
                        <div class="mt-2">
                          <b-form-input :placeholder="'Enter ' + item.label + ' ...'" type="text" v-model="options[item.field][indexVideoUrl]" />
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                  <select v-else-if="item.field == 'pretest_exercise_id'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                    <option value="0" selected="selected">ID Exercise Pretest</option>
                    <option v-for="(item_exercise, index_exercise) in list_exercise_pretest" v-bind:key="item_exercise.id" :value="item_exercise.id">{{ item_exercise.name }}</option>
                  </select>
                  <select v-else-if="item.field == 'quiz1_exercise_id'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                    <option value="0" selected="selected">ID Quiz 1 Pretest</option>
                    <option v-for="(item_exercise, index_exercise) in list_exercise_quiz" v-bind:key="item_exercise.id" :value="item_exercise.id">{{ item_exercise.name }}</option>
                  </select>
                  <select v-else-if="item.field == 'quiz2_exercise_id'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                    <option value="0" selected="selected">ID Quiz 2 Pretest</option>
                    <option v-for="(item_exercise, index_exercise) in list_exercise_quiz" v-bind:key="item_exercise.id" :value="item_exercise.id">{{ item_exercise.name }}</option>
                  </select>
                  <select v-else-if="item.field == 'quiz3_exercise_id'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                    <option value="0" selected="selected">ID Quizn3 Pretest</option>
                    <option v-for="(item_exercise, index_exercise) in list_exercise_quiz" v-bind:key="item_exercise.id" :value="item_exercise.id">{{ item_exercise.name }}</option>
                  </select>
                  <select v-else-if="item.field == 'exam_exercise_id'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                    <option value="0" selected="selected">ID Exam Pretest</option>
                    <option v-for="(item_exercise, index_exercise) in list_exercise_exam" v-bind:key="item_exercise.id" :value="item_exercise.id">{{ item_exercise.name }}</option>
                  </select>

                  <TextField
                    v-else
                    type="text"
                    borderEnabled
                    v-model="options[item.field]"
                    :placeholder="'Enter ' + item.label + ' ...'"
                    :disabled="item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <div class="mb-6 text-left w-7/10">
            <b-button variant="primary" @click="submitData" v-if="($store.getters['customer/customer'].role_id = 1)">
              <i class="fa fa-dot-circle-o" />
              Submit
            </b-button>
          </div>
        </b>
      </b-col>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapGetters } from 'vuex'
export default {
  name: 'KelasForm',
  mounted: function () {
    this.modeForm = this.$route.meta.mode
    if (this.modeForm === 'Edit') {
      this.idForm = this.$route.params.id
    }
    this.initData()
  },
  data: function () {
    return {
      options: {
        code: ' ',
        nama: ' ',
        max_peserta: '0',
        min_peserta: '0',
        quiz_exercise_id_array: [],
        customer_id: 0,
        video_url_array: [],
        id: 0,
        kode_sekolahmu: '',
        kode_voucher_sekolahmu: '',
        java_instructur_id: '',
        tugas_description: '',
        java_instructur_id_array: [],
        deliveryTypes: []
      },
      optionsFetch: {
        limit: 100,
        page: 1,
        sort: '',
        nama: ''
      },
      showProgress: true,
      counterProgress: 0,
      idForm: 0,
      modeForm: null,
      columns: [
        { field: 'id', label: 'Id' },
        { field: 'code', label: 'Kode' },
        { field: 'nama', label: 'Nama' },
        { field: 'deliveryTypes', label: 'Tipe Kelas' },
        { field: 'max_peserta', label: 'Max Peserta' },
        { field: 'min_peserta', label: 'Min Peserta' },
        // { "field": "exam_url","label": "Exam Url" },
        // { "field": "exam_url_array","label": "Exam Url Array" },
        // { "field": "exam_sheet","label": "Exam Sheet" },
        // { "field": "pretest_url", "label": "Pretest Url" },
        // { "field": "pretest_sheet", "label": "Pretest Sheet" },
        // { "field": "quiz_url_array", "label": "Quiz Url Array" },
        // { "field": "tugas_url", "label": "Tugas Url" },
        // { "field": "tugas_sheet", "label": "Tugas Sheet" },
        // { "field": "postest_url", "label": "Postest Url" },
        // { "field": "postest_sheet", "label": "Postest Sheet" },
        // { "field": "consultation_url", "label": "Consultation Url" },
        // { "field": "pretest_url_array", "label": "Pretest Url Array" },
        { field: 'video_url_array', label: 'Video Url(s)' },
        // { "field": "quiz_sheet", "label": "Quiz sheet" },
        // { "field": "pretest_exercise_id","label": "Pretest Exercise Id" },
        { field: 'quiz_exercise_id_array', label: 'Quiz Exercise(s)' },
        // { "field": "quiz1_exercise_id","label": "Quiz 1 Exercise Id" },
        // { "field": "quiz2_exercise_id","label": "Quiz 2 Exercise Id" },
        // { "field": "quiz3_exercise_id","label": "Quiz 3 Exercise Id" },
        // { "field": "exam_exercise_id","label": "Exam Exercise Id" },
        { field: 'tugas_description', label: 'Deskripsi Tugas' },
        { field: 'java_instructur_id_array', label: 'Instruktur' }
      ],
      masters: {},
      customer: {},
      list_exercise_pretest: [],
      list_exercise_quiz: [],
      list_exercise_exam: [],
      levelId: 0,
      locked: false,
      stillLoading: true,
      dropzoneLogoOptions: {
        url: window.axios.defaults.baseURL + 'api/attachment',
        maxFilesize: 1,
        maxFiles: 1,
        addRemoveLinks: true,
        headers: {
          Authorization: 'jwt ' + this.$store.getters['customer/customer'].token
        }
      },
      showProfileLogo: false,
      profileLogo: ''
    }
  },
  methods: {
    dropzoneLogoSuccess: function (file, response) {
      //this.options.logo = response.data.path
      this.profileLogo = response.data.path
      //this.$store.getters['customer/customer'].logo
    },
    initData() {
      if (this.$route.meta.name === 'customer' && this.$route.meta.mode === 'Edit') {
        if (this.$route.params.id !== this.$store.getters['customer/customer'].id) {
          this.locked = true
        }
      }
      if (this.locked) {
        return null
      }

      this.counterProgress = 0
      this.customer = this.$store.getters['customer/customer']
      const customerId = this.clientId
      this.getListExercise()
      this.getInstructor()
      if (this.modeForm === 'Edit') {
        this.$store
          .dispatch('kelas/GET_KELAS', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            customerId: customerId
          })
          .then((resp) => {
            if (resp.data.code === 200) {
              let tempData = this.$store.getters['kelas/kelas']
              for (var key in tempData) {
                this.options[key] = tempData[key]
              }
            } else {
              // this.$swal(resp.data.message)
            }
            this.counterProgress = 100
            this.showProgress = false
            this.stillLoading = false
          })
      } else {
        this.showProgress = false
        this.stillLoading = false
      }
    },
    getInstructor() {
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          name: null,
          sort: ''
        },
        ...this.optionsFetch
      })
      const customerId = this.clientId
      this.$store
        .dispatch('instructur/GET_LIST_INSTRUCTUR', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: customerId
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
    },
    submitData() {
      if (this.modeForm === 'Edit') {
        if (this.$route.meta.name === 'customer') {
          this.options.logo = this.profileLogo
        }
        this.$store
          .dispatch('kelas/UPDATE_KELAS', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            customerId: this.clientId,
            payload: this.options
          })
          .then((resp) => {
            if (resp.data.status === '200 OK') {
              this.$router.push('/' + this.$route.meta.name)
            }
            // this.$swal(resp.data.status)
          })
      } else {
        this.$store
          .dispatch('kelas/CREATE_KELAS', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            payload: this.options,
            customerId: this.clientId
          })
          .then((resp) => {
            if (resp.data.status === '200 OK') {
              if (this.customer.role_id > 2) {
                this.initData()
              } else {
                this.$router.push('/' + this.$route.meta.name)
              }
            }
            // this.$swal(resp.data.status)
          })
      }
    },
    dropzoneRemovedSuccess: function (file, response) {
      this.profileLogo = ''
    },
    addExamUrlArray: function () {
      this.options.exam_url_array.push('')
    },
    deleteExamUrlArray: function (indexExamUrlArray) {
      this.options.exam_url_array.splice(indexExamUrlArray, 1)
    },
    addQuizUrlArray: function () {
      this.options.quiz_url_array.push('')
    },
    deleteQuizUrlArray: function (indexQuizUrlArray) {
      this.options.quiz_url_array.splice(indexQuizUrlArray, 1)
    },
    addQuizExerciseIdArray: function () {
      this.options.quiz_exercise_id_array.push(0)
    },
    deleteQuizExerciseIdArray: function (indexQuizExerciseIdArray) {
      this.options.quiz_exercise_id_array.splice(indexQuizExerciseIdArray, 1)
    },
    addJavaInstructurIdArray: function () {
      this.options.java_instructur_id_array.push(0)
    },
    deleteJavaInstructurIdArray: function (indexJavaInstructurIdArray) {
      this.options.java_instructur_id_array.splice(indexJavaInstructurIdArray, 1)
    },
    addPretestUrlArray: function () {
      this.options.pretest_url_array.push('')
    },
    deletePretestUrlArray: function (indexPretestUrlArray) {
      this.options.pretest_url_array.splice(indexPretestUrlArray, 1)
    },
    addVideoUrlArray: function () {
      this.options.video_url_array.push('')
    },
    deleteVideoUrlArray: function (indexVideoUrlArray) {
      this.options.video_url_array.splice(indexVideoUrlArray, 1)
    },
    getListExercise() {
      const customerId = this.clientId
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          sort: ''
        }
      })
      if (this.modeForm === 'Edit') {
        paramsTemp = queryString.stringify({
          ...{
            limit: 10000,
            kelas_id: this.idForm,
            sort: ''
          }
        })
      }
      this.$store
        .dispatch('exercise/GET_LIST_EXERCISE', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: customerId
        })
        .then(() => {
          let list_exercise = this.$store.getters['exercise/list_exercise'] ? this.$store.getters['exercise/list_exercise'] : []
          this.append_to_list_exercise(list_exercise)
        })
        .catch(function () {})
    },
    append_to_list_exercise(list_exercise) {
      this.list_exercise_pretest = []
      this.list_exercise_quiz = []
      this.list_exercise_exam = []
      for (var j = 0; j < list_exercise.length; j++) {
        if (list_exercise[j].exercise_type_id === 1) {
          this.list_exercise_pretest.push(list_exercise[j])
        } else if (list_exercise[j].exercise_type_id === 2) {
          this.list_exercise_quiz.push(list_exercise[j])
        } else if (list_exercise[j].exercise_type_id === 3) {
          this.list_exercise_exam.push(list_exercise[j])
        }
      }
    }
  },
  computed: {
    ...mapGetters('instructur', ['list_instructur']),
    data: function () {
      return this.$store.getters['kelas/list_kelas'] ? this.$store.getters['kelas/list_kelas'] : []
    },
    clientId() {
      return localStorage.getItem('client')
    }
  },
  created: function () {
    //this.initData();
  },
  components: {
    vueDropzone: vue2Dropzone,
    quillEditor,
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.ql-editor ol li {
  display: list-item;
}
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }

  // Box.
  & + label:before {
    content: '';
    // margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #f7931e;
    border-radius: 4px;
    margin-right: 10px;
  }

  // Box hover
  &:hover + label:before {
    background: #f7931e;
  }

  // Box focus
  // &:focus + label:before {
  //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  // }

  // Box checked
  &:checked + label:before {
    background: #f7931e;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 11px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.class-type {
  li {
    margin-bottom: 3px;
  }
}
</style>
