<template>
  <div class="ml-60 px-6 py-2 text-sm flex justify-center">Copyright © {{ getYear() }}. PT Generasi Teknologi Buana</div>
</template>
<script>
export default {
  methods: {
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
