<template>
  <div class="content-body pb-8">
    <div class="space-y-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-4">
          <ArrowForward direction="left" @handleClick="goBack()" class="cursor-pointer" />
          <h1 class="text-3xl font-bold">{{ pageName }}</h1>
        </div>
      </div>
      <div v-if="pageLoading" class="loading-page">
        <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
      </div>
      <div v-else class="space-y-4">
        <div class="shadow-small card py-4 bg-white rounded-lg px-6">
          <div class="space-y-4">
            <div class="space-y-1">
              <h1 class="text-base text-neutral font-bold">
                {{ employeeName }}
              </h1>
              <p class="text-xs text-neutral-400 font-semibold">{{ departmentName }}</p>
            </div>
          </div>
        </div>
        <div v-if="leaveBalanceList.length > 0">
          <div class="flex gap-2 ml-5">
            <div
              v-for="(tab, indexTab) in tabOptions"
              :key="tab.value + indexTab"
              @click="selectTab(tab)"
              class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
              :class="[tabSelected.value === tab.value ? 'font-bold bg-white' : 'bg-neutral-50']"
            >
              {{ tab.label }}
            </div>
          </div>
          <div class="shadow-small card py-8 bg-white rounded-lg px-6">
            <div v-if="isFetchingData" class="py-20">
              <vue-simple-spinner size="medium" message="Fetching Data ..."></vue-simple-spinner>
            </div>
            <LeaveTable :columns="columnsTable" :list="leaveBalanceList" compact v-else>
              <template slot="table-row" slot-scope="{ entry, column }">
                <div v-if="column.label === 'Action'" class="flex justify-end gap-3 mr-4">
                  <Button size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')" :tooltip="{ show: true, text: 'Edit' }" @action="showModal(entry)" />
                </div>
                <div v-else-if="column.label === 'Description'" class="truncate max-w-xs">
                  {{ entry[column.field] }}
                </div>
                <div v-else>{{ entry[column.field] }}</div>
              </template>
            </LeaveTable>
          </div>
        </div>
        <div class="shadow-small card py-20 bg-white rounded-lg px-6" v-else>
          <div class="text-center">
            <img src="@/assets/images/empty-content.svg" alt="empty" class="mx-auto mt-0" />
            <div class="font-medium text-xl py-5">Data is Not Available</div>
          </div>
        </div>
      </div>
    </div>
    <Modal :modalVisible="isEditModalVisible" @close="toggleModal()">
      <template slot="modal-content">
        <div class="text-left my-2">
          <h1 class="text-xl font-bold mb-3">Modify Balance</h1>
          <div class="space-y-3 mb-5">
            <TextField label="Leave Type" type="text" borderEnabled disabled v-model="leaveBalanceSelected.leave_type_name" />
            <TextField
              label="Total Leave"
              type="number"
              borderEnabled
              placeholder="Enter total leave"
              v-model="leaveBalanceSelected.leave_type_balance"
              :error="errorForm['totalLeave']"
              isLabelRequire
            />
            <TextField label="Start Date" type="date" borderEnabled disabled v-model="leaveBalanceSelected.start_date" />
            <TextField label="Expiration" type="date" borderEnabled v-model="leaveBalanceSelected.end_date" isStayShow :error="errorForm['expiration']" />
            <TextArea label="Description" class="mx-1" isLabelRequire height="h-28" borderEnabled v-model="leaveBalanceSelected.description" />
          </div>
          <div class="flex justify-end items-center gap-4">
            <Button buttonText="Cancel" type="tertiary" @action="toggleModal()" />
            <Button buttonText="Confirm" type="primary" @action="confirmModify()" :disabled="!isEditFormValid" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast, upperCaseFirstWord } from '@/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    LeaveTable: () => import(/* webpackChunkName: "simple-table" */ '@/components/Table/SimpleTable'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    TextArea: () => import(/* webpackChunkName: "TextArea" */ '@/components/Form/TextArea')
  },
  data() {
    return {
      employeeName: '',
      departmentName: '',
      leaveBalanceList: [],
      isFetchingData: false,
      dynamicColumn: [],
      tabOptions: [
        {
          label: 'Limited Leave',
          value: 'LIMITED'
        },
        {
          label: 'Unlimited Leave',
          value: 'UNLIMITED'
        }
      ],
      tabSelected: {
        label: 'Limited Leave',
        value: 'LIMITED'
      },
      headerTableForUnlimitedType: [
        { label: 'Leave Type', field: 'leave_type_name' },
        { label: 'Total Leave', field: 'totalLeave' },
        { label: 'Used', field: 'used' },
        { label: 'Remaining', field: 'remaining' }
      ],
      headerTableForLimitedType: [
        { label: 'Leave Type', field: 'leave_type_name' },
        { label: 'Total Leave', field: 'totalLeave' },
        { label: 'Used', field: 'used' },
        { label: 'Remaining', field: 'remaining' },
        { label: 'Start Date', field: 'startDate' },
        { label: 'Expiration', field: 'endDate' },
        { label: 'Description', field: 'description' },
        { label: 'Action', field: 'btn' }
      ],
      isEditModalVisible: false,
      leaveBalanceSelected: {
        id: 5331,
        leave_type_id: 29,
        leave_type_name: 'WFA',
        duration_type: 'LIMITED',
        unit: 'DAYS',
        leave_type_balance: 14,
        employee_balance: 14,
        remaining_leave: 14,
        start_date: '2023-07-21T00:00:00.000+00:00',
        end_date: '2024-07-20T00:00:00.000+00:00',
        description: '-',
        totalLeave: '14 days',
        used: '14 days',
        remaining: '14 days',
        startDate: '21 Jul 2023',
        endDate: '20 Jul 2024'
      },
      errorForm: {
        totalLeave: '',
        expiration: ''
      }
    }
  },
  computed: {
    ...mapGetters('common', ['isUnauthorized', 'pageLoading']),
    customerId() {
      return localStorage.getItem('client')
    },
    employeeId() {
      return this.$route.params.employeeId
    },
    pageName() {
      return this.$route.meta.label
    },
    columnsTable() {
      if (this.tabSelected.value === 'LIMITED') return this.headerTableForLimitedType
      return this.headerTableForUnlimitedType
    },
    isEditFormValid() {
      if (this.leaveBalanceSelected.leave_type_balance && this.isEndDateAfterStartDate && this.leaveBalanceSelected.description) return true
      return false
    },
    isEndDateAfterStartDate() {
      return dayjs(this.leaveBalanceSelected.end_date).isAfter(this.leaveBalanceSelected.start_date)
    }
  },
  watch: {
    isEndDateAfterStartDate(value) {
      if (value) {
        this.errorForm.expiration = ''
      } else {
        this.errorForm.expiration = 'Expiration date must be after the start date.'
      }
    }
  },
  created() {
    this.showLoading()
    this.fetchingData()
  },
  methods: {
    ...mapActions('humanresource', ['GET_LEAVE_BALANCE_DETAIL', 'UPDATE_LEAVE_BALANCE_EMPLOYEE']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    goBack() {
      this.$router.go(-1)
    },
    selectTab(value) {
      this.tabSelected = value
      this.fetchingData()
    },
    showModal(item) {
      const copyItem = { ...item }
      copyItem.start_date = this.utcToLocal(copyItem.start_date, 'YYYY-MM-DD')
      copyItem.end_date = this.utcToLocal(copyItem.end_date, 'YYYY-MM-DD')
      copyItem.description = copyItem.description === '-' ? '' : copyItem.description
      this.leaveBalanceSelected = copyItem
      this.toggleModal()
    },
    toggleModal() {
      this.isEditModalVisible = !this.isEditModalVisible
    },
    confirmModify() {
      const payload = {
        customerId: this.customerId,
        leaveBalance: this.leaveBalanceSelected.leave_type_balance,
        leaveTypeId: this.leaveBalanceSelected.leave_type_id,
        expiration: this.leaveBalanceSelected.end_date, // ex format '2023-08-09'
        description: this.leaveBalanceSelected.description
      }

      this.UPDATE_LEAVE_BALANCE_EMPLOYEE({ leaveBalanceSelectedId: this.leaveBalanceSelected.id, payload })
        .then((res) => {
          if (res?.data?.code === 200) {
            showVueToast(`Leave balance successfully updated!`, 'success', 2000)
            this.toggleModal()
            this.fetchingData()
          } else {
            this.isUnauthorized ? null : showVueToast('Your request is failed!', 'error', 3000)
            this.hideLoading()
          }
        })
        .catch(() => {
          this.isUnauthorized ? null : showVueToast('Your request is failed!', 'error', 3000)
          this.hideLoading()
        })
    },
    utcToLocal(day, format = 'DD MMM YYYY') {
      const localTime = dayjs.utc(day).local().format(format)
      return localTime
    },
    fetchingData() {
      this.isFetchingData = true
      this.GET_LEAVE_BALANCE_DETAIL({ employeeId: this.employeeId, customerId: this.customerId, durationType: this.tabSelected.value })
        .then(async (res) => {
          if (res?.data?.code === 200) {
            const response = res?.data?.data
            this.employeeName = response?.employeeName
            this.departmentName = upperCaseFirstWord(response?.departmentName)
            this.leaveBalanceList = response?.leaveBalanceList.length ? await this.mappingData(response?.leaveBalanceList) : []
            setTimeout(() => {
              this.isFetchingData = false
            }, 600)
          } else {
            this.isUnauthorized ? null : showVueToast('Your request is failed!', 'error', 3000)
          }
          this.hideLoading()
        })
        .catch(() => {
          this.isUnauthorized ? null : showVueToast('Your request is failed!', 'error', 3000)
          this.hideLoading()
        })
    },
    mappingData(data) {
      return new Promise((resolve) => {
        const result = data.map((item) => {
          item.totalLeave = item.duration_type === 'UNLIMITED' ? 'Unlimited' : `${item.leave_type_balance} ${item.unit.toLowerCase()}`
          item.used = `${item.employee_balance} ${item.unit.toLowerCase()}`
          item.remaining = item.duration_type === 'UNLIMITED' ? 'Unlimited' : `${item.remaining_leave} ${item.unit.toLowerCase()}`
          item.startDate = this.utcToLocal(item.start_date)
          item.endDate = this.utcToLocal(item.end_date)
          return {
            ...item
          }
        })
        resolve(result)
      })
    }
  }
}
</script>

<style scoped>
.content-body {
  min-height: calc(83vh - 0px);
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #58595b;
}

[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #c2c2c2;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #f7931e;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #f7931e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>
