<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.75 6.93751L17.76 5.78579C16.9206 4.90415 15.9106 4.20252 14.7914 3.7236C13.6722 3.24468 12.4673 2.99849 11.25 3.00001C6.28125 3.00001 2.25 7.03126 2.25 12C2.25 16.9688 6.28125 21 11.25 21C13.1114 20.9999 14.927 20.4229 16.447 19.3484C17.967 18.2739 19.1166 16.7548 19.7377 15"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M21.75 4.56656V9.74999C21.75 9.94891 21.671 10.1397 21.5304 10.2803C21.3897 10.421 21.199 10.5 21 10.5H15.8166C15.1482 10.5 14.8135 9.69234 15.286 9.21984L20.4699 4.03593C20.9424 3.56249 21.75 3.89812 21.75 4.56656Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#F7931E'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
