<template>
  <div class="h-auto">
    <Modal :modalVisible="isPreviewModalVisible" :closeIconVisible="false" width="750px" @close="tooglePreviewModal" id="delete">
      <template slot="modal-content">
        <div v-if="!isImageReady" class="loading-page">
          <vue-simple-spinner size="large" message="Loading Image ..."></vue-simple-spinner>
        </div>
        <div>
          <img id="previewImage" />
        </div>
      </template>
    </Modal>
    <div class="flex items-center mb-10">
      <router-link class="mt-1 mr-3 cursor-pointer" to="/certificate">
        <ArrowForward direction="left" />
      </router-link>
      <h1 class="text-3xl font-bold">Edit Certificate Template</h1>
    </div>
    <div class="flex justify-between">
      <div class="w-4/5">
        <div id="canvas" class="shadow-lg">
          <div v-if="stillLoading" class="loading-page">
            <vue-simple-spinner size="large" message="Load Image ..."></vue-simple-spinner>
          </div>
          <v-stage v-else ref="stage" :config="stageSize">
            <v-layer ref="layer" @dblclick="handelDoubleClick" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
              <v-image
                @mousemove="handleMouseMove"
                :config="{
                  image: image,
                  width: stageSize.width,
                  height: stageSize.height
                }"
              />
              <div v-for="(value, index) in valueList" :key="index">
                <!-- <v-rect
                  v-if="value.code === 'qrcode'"
                  :config="{
                    x: switchCoordinateItems(value).x,
                    y: switchCoordinateItems(value).y,
                    width: value.image_width,
                    height: value.image_width,
                    fill: 'red'
                  }"
                /> -->

                <div v-if="value.code === 'qrcode'">
                  <v-text :config="{ text: convertName(value.code), fontSize: 12, x: switchCoordinateItems(value).x, y: switchCoordinateItems(value).y -15}," />
                  <v-image
                    :config="{
                      x: switchCoordinateItems(value).x,
                      y: switchCoordinateItems(value).y,
                      width: value.image_height / 2.2,
                      height: value.image_height / 2.2,
                      image: qrcode
                    }"
                  />
                </div>
                <div v-else>
                  <v-text :config="{ text: convertName(value.code), fontSize: 15, x: switchCoordinateItems(value).x, y: switchCoordinateItems(value).y - 15 }" />
                  <v-line
                    :config="{
                      x: switchCoordinateItems(value).x,
                      y: switchCoordinateItems(value).y,
                      points: [value.field_length, 0, 0, 0, 0, 0],
                      tension: 0.5,
                      closed: false,
                      stroke: 'black'
                    }"
                  />
                </div>

                <template v-if="value.isEdit">
                  <v-text :config="{ text: `<- Select this position (double click)`, fill: '#F7931E', fontSize: 12, x: value.location_x_moving, y: value.location_y_moving }" />
                </template>
              </div>
            </v-layer>
          </v-stage>
        </div>
      </div>
      <div class="w-1/3 bg-white shadow-lg ml-5 pb-7">
        <div class="mb-5">
          <div class="pl-5 pt-7 pb-5">
            <div class="text-2xl font-semibold">Values</div>
          </div>
          <div class="px-5 pb-6" v-for="(value, index) in valueList" :key="index">
            <div class="flex items-center gap-2 border-dashed border-b border-neutral-300 mb-2 pb-1">
              <!-- <p class="text-sm">Value {{ index + 1 }}:</p> -->
              <p class="text-sm font-bold inline-block" :class="{ 'text-neutral-300': !value.isEdit }">{{ convertName(value.code) }}</p>
            </div>
            <div class="flex items-center gap-3">
              <div class="flex items-center gap-3" v-if="value.code === 'qrcode'">
                <div class="w-1/2">
                  <label class="text-11 font-medium text-left block mb-1 leading-4" :class="{ 'text-neutral-300': !value.isEdit }">Image Height</label>
                  <TextField type="text" borderEnabled :disabled="!value.isEdit" v-model="value.image_height" @keypress="numbersOnly" />
                </div>
                <div class="w-1/2">
                  <label class="text-11 font-medium text-left block mb-1 leading-4" :class="{ 'text-neutral-300': !value.isEdit }">Image Width</label>
                  <TextField type="text" borderEnabled :disabled="!value.isEdit" v-model="value.image_height" @keypress="numbersOnly" />
                </div>
              </div>
              <div class="flex items-center gap-3" v-else>
                <div class="w-1/3">
                  <label class="text-11 font-medium text-left block mb-1 leading-4" :class="{ 'text-neutral-300': !value.isEdit }">Font Size</label>
                  <TextField type="text" borderEnabled :disabled="!value.isEdit" v-model="value.text_size" @keypress="numbersOnly" />
                </div>
                <div class="w-1/3">
                  <label class="text-11 font-medium text-left block mb-1 leading-4" :class="{ 'text-neutral-300': !value.isEdit }">Alignment</label>
                  <DropdownAllignment @selectItem="setAlignment($event, index)" :disabled="!value.isEdit" v-model="selectedAlignment[index]" :defaultValue="selectedAlignment[index]" />
                </div>
                <div class="w-1/3">
                  <label class="text-11 font-medium text-left block mb-1 leading-4" :class="{ 'text-neutral-300': !value.isEdit }">Length</label>
                  <TextField type="text" borderEnabled :disabled="!value.isEdit" v-model="value.field_length" @keypress="numbersOnly" />
                </div>
              </div>
              <div>
                <div class="text-right">
                  <Button v-if="value.isEdit" :disabled="value.isButtonDisable" buttonText="Set" size="compact" type="secondary" additionalClass="h-8 mt-4" @action="editValue(false, index)" />
                  <Button v-else buttonText="Edit" :disabled="value.isButtonDisable" size="compact" type="secondary" additionalClass="h-8 mt-4" @action="editValue(true, index)" />
                </div>
              </div>
            </div>
            <div class="text-smallest font-medium mt-3 leading-3 bg-yellow-secondary rounded-lg p-2 flex items-start gap-2" v-if="value.isEdit">
              <span class="w-6 flex-shrink-0"><Alert width="24" height="24" /></span>
              <div>
                <p class="mb-2">
                  You can set the position of <strong>{{ convertName(value.code) }}</strong> by <strong>double clicking</strong> the point on the image on the left.
                </p>
                <p><strong>Current Position</strong>: {{ value.location_x }}, {{ value.location_y }} (in term of x, y coordinates)</p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-center items-center">
          <Button v-if="activeIndex === null" buttonText="Preview" type="primary" size="full" additionalClass="py-3 text-base mx-5 " @action="tooglePreviewModal()" />
          <Button v-else buttonText="Cancel" type="secondary" size="full" additionalClass="py-3 text-base mx-5 " @action="cancelEditValue()" />

          <!-- <Button buttonText="Save" additionalClass="mx-5" @action="save()" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'Exercise',
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    DropdownAllignment: () => import(/* webpackChunkName: "Button" */ './DropdownAllignment.vue'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Trash: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Trash'),
    Alert: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Alert'),
    SelectValue: () => import(/* webpackChunkName: "Button" */ './SelectValue.vue'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Close')
  },
  mounted() {
    this.getCertificateDetail()
  },
  computed: {
    clientId() {
      return localStorage.getItem('client')
    }
  },
  watch: {},
  data() {
    return {
      isImageReady: false,
      selectedAlignment: [],
      isModalVisible: false,
      isPreviewModalVisible: false,
      isCanvasActive: false,
      isEditValue: false,
      coordinateLength: 0,
      stageSize: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      isImageVisible: false,
      image: null,
      qrcode: null,
      valueList: [],
      activeIndex: null,
      stillLoading: true,
      fontSizeOptions: ['12', '14', '16', '18', '20', '24'],
      realTemplateSize: {
        width: 0,
        height: 0
      },
      viewPortTemplateSize: {
        width: 0,
        height: 0
      },
      position: {
        x_viewport: 0,
        y_viewport: 0,
        x_real: 0,
        y_real: 0
      }
    }
  },
  computed: {
    ...mapGetters('certificate', ['certificateDetails']),
    certificate_template_id() {
      return this.$route.params.id
    }
  },
  methods: {
    ...mapActions('certificate', ['UPDATE_CERTIFICATE', 'UPDATE_CERTIFICATE_ITEM', 'PREVIEW_CERTIFICATE']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    numbersOnly(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    tooglePreviewModal() {
      this.isPreviewModalVisible = !this.isPreviewModalVisible
      if (this.isPreviewModalVisible) {
        this.isImageReady = false
        this.PREVIEW_CERTIFICATE({
          params: {
            id: this.certificate_template_id
          }
        }).then((res) => {
          let img = URL.createObjectURL(res)
          document.getElementById('previewImage').setAttribute('src', img)
          this.isImageReady = true
        })
      }
    },
    setAlignment(value, index) {
      this.valueList[index].centered_horizontally = value.value === 'center'
    },
    getCertificateDetail() {
      this.stillLoading = true
      let self = this
      this.$store
        .dispatch('certificate/GET_CERTIFICATE_DETAILS', {
          id: this.$route.params.id
        })
        .then((res) => {
          this.valueList = []
          res.data.items.map((item, index) => {
            this.valueList.push({
              ...item,
              isEdit: false,
              isCanvasActive: false,
              isButtonDisable: false,
              location_x: item.location_x,
              location_y: item.location_y,
              location_x_moving: item.location_x_moving,
              location_y_moving: item.location_y_moving,
              field_length: item.field_length || 382,
              text_size: item.text_size,
              location_x_real: 0,
              location_y_real: 0,
              image_width: item.image_height,
              image_height: item.image_height
            })

            const left = {
              value: 'left',
              image: require('@/assets/images/align-left.png')
            }
            const center = {
              value: 'center',
              image: require('@/assets/images/align-center.png')
            }
            self.selectedAlignment[index] = item.centered_horizontally ? center : left
          })
          this.realTemplateSize = {
            width: res.data.template_width,
            height: res.data.template_height
          }
          this.loadImage(res.data)
        })
    },
    loadImage(data) {
      const element = document.getElementById('canvas')
      this.stageSize.width = element.offsetWidth
      this.stageSize.height = (data.template_height / data.template_width) * element.offsetWidth
      this.viewPortTemplateSize = {
        width: element.offsetWidth,
        height: (data.template_height / data.template_width) * element.offsetWidth
      }
      const image = new Image()
      image.src = data.template_url
      image.onload = () => {
        this.stillLoading = false
        this.image = image
      }
      const qrcode = new Image()
      qrcode.src = require('/src/assets/images/qrcode.png')
      qrcode.onload = () => {
        this.qrcode = qrcode
      }
      this.valueList.forEach((item) => {
        const xViewPort = Math.ceil(item.location_x * (this.viewPortTemplateSize.width / this.realTemplateSize.width))
        const yViewPort = Math.ceil(item.location_y * (this.viewPortTemplateSize.height / this.realTemplateSize.height))
        if (item.field_length) {
          const xLengthEndPointReal = item.location_x + item.field_length
          const xLengthEndPointViewport = Math.ceil(xLengthEndPointReal * (this.viewPortTemplateSize.width / this.realTemplateSize.width))
          const distanceViewPort = xLengthEndPointViewport - xViewPort
          item.field_length = distanceViewPort
        }
        item.location_x = xViewPort
        item.location_y = yViewPort
      })
    },
    switchCoordinateItems(items) {
      return {
        x: items.location_x,
        y: items.location_y
      }
    },
    convertName(itemName) {
      switch (itemName) {
        case 'name':
          return 'Student Name'
        case 'score':
          return 'Post Test Score'
        case 'certificateDate':
          return 'Post Test Date'
        case 'courseTitle':
          return 'Course Title'
        case 'competenceList':
          return 'Competence List'
        case 'certificateNumber':
          return 'Certificate Number'
        case 'qrcode':
          return 'QR Code'
        case 'publishDate':
          return 'Publish Date'
        default:
          return 'Student Name'
      }
    },

    handleMouseMove() {
      if (this.activeIndex === null) return
      if (!this.valueList[this.activeIndex].isCanvasActive && !this.valueList(this.activeIndex).isEdit) return
      const mousePos = this.$refs.stage.getNode().getPointerPosition()
      this.valueList[this.activeIndex].location_x_moving = mousePos.x
      this.valueList[this.activeIndex].location_y_moving = mousePos.y
    },
    handelDoubleClick() {
      if (this.activeIndex === null) return
      this.valueList[this.activeIndex].location_x = this.valueList[this.activeIndex].location_x_moving
      this.valueList[this.activeIndex].location_y = this.valueList[this.activeIndex].location_y_moving
      const result = this.valueList[this.activeIndex]
      this.position = {
        x_viewport: result.location_x_moving,
        y_viewport: result.location_y_moving,
        x_real: result.location_x_moving * (this.realTemplateSize.width / this.viewPortTemplateSize.width),
        y_real: result.location_y_moving * (this.realTemplateSize.height / this.viewPortTemplateSize.height)
      }
      this.valueList[this.activeIndex].location_x_real = this.position.x_real
      this.valueList[this.activeIndex].location_y_real = this.position.y_real
      this.isEditValue = false
    },
    handleMouseOver() {
      if (this.activeIndex === null) return
      this.valueList[this.activeIndex].isCanvasActive = true
    },
    handleMouseOut() {
      if (this.activeIndex === null) return
      this.valueList[this.activeIndex].isCanvasActive = false
    },
    editValue(status, index) {
      this.activeIndex = status ? index : null
      this.valueList[index].isEdit = status
      this.isEditValue = status
      if (status) {
        this.valueList.map((value, valueIdx) => {
          if (valueIdx !== index) {
            this.valueList[valueIdx].isButtonDisable = true
          }
        })
      } else {
        this.valueList = this.valueList.map((value) => {
          return {
            ...value,
            isButtonDisable: false
          }
        })
        this.setCertificateItem(index)
      }
    },
    cancelEditValue() {
      this.activeIndex = null
      this.valueList = this.valueList.map((value) => {
        return {
          ...value,
          isEdit: false,
          isButtonDisable: false
        }
      })
    },
    setCertificateItem(index) {
      this.showLoading()
      const item = {
        centered_horizontally: this.valueList[index].centered_horizontally,
        certificate_template_id: parseInt(this.certificate_template_id),
        code: this.valueList[index].code,
        field_length: this.valueList[index].field_length,
        id: this.valueList[index].id,
        location_x: this.valueList[index].location_x * (this.realTemplateSize.width / this.viewPortTemplateSize.width),
        location_y: this.valueList[index].location_y * (this.realTemplateSize.height / this.viewPortTemplateSize.height),
        text_color: this.valueList[index].text_color,
        text_size: this.valueList[index].text_size,
        image_width: parseInt(this.valueList[index].image_height),
        image_height: parseInt(this.valueList[index].image_height)
      }

      const xLengthEndPoint = this.position.x_viewport + parseInt(item.field_length)
      const xLengthEndPointReal = xLengthEndPoint * (this.realTemplateSize.width / this.viewPortTemplateSize.width)
      const lengthReal = xLengthEndPointReal - this.position.x_real
      item.field_length = lengthReal
      this.UPDATE_CERTIFICATE_ITEM({
        itemId: this.valueList[index]?.id,
        payload: item
      }).then(() => {
        this.hideLoading()
        showVueToast('Certificate updated successfully!', 'success', 2000)
        this.getCertificateDetail()
      })
    }
    // save() {
    //   let self = this
    //   const constructedValueList = this.valueList.map((item) => {
    //     return {
    //       centered_horizontally: item.centered_horizontally,
    //       certificate_template_id: parseInt(self.certificate_template_id),
    //       code: item.code,
    //       field_length: item.field_length || 200,
    //       id: item.id,
    //       location_x: item.location_x_real,
    //       location_y: item.location_y_real,
    //       text_color: item.text_color,
    //       text_size: item.text_size
    //     }
    //   })
    //   const payload = {
    //     customer_id: this.clientId,
    //     items: constructedValueList,
    //     template_name: this.certificateDetails?.template_name,
    //     template_url: this.certificateDetails?.template_url
    //   }
    //   this.showLoading()
    //   setTimeout(() => {
    //     this.UPDATE_CERTIFICATE({
    //       certificateId: self.certificateDetails?.id,
    //       payload: payload
    //     }).then(this.hideLoading())
    //   }, 500)
    // }
  }
}
</script>
<style scoped lang="scss"></style>
