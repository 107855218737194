<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-show="!stillLoading" class="min-h-screen">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">{{ $route.meta.mode }} Exercise</h1>
        <Button :buttonText="isEditMode ? 'Update' : 'Submit'" @action="submitData" :disabled="disableSubmit" />
      </div>
      <div class="shadow-small rounded-lg bg-white mb-6">
        <div class="flex items-stretch p-6">
          <div class="flex-1 shadow-border-r flex-shrink-0 pr-6">
            <div v-if="isEditMode" class="mb-6">
              <TextField type="text" label="Id" :borderEnabled="true" v-model="options.id" disabled />
            </div>
            <div class="mb-6">
              <TextField type="text" label="Exercice Title" isLabelRequire :borderEnabled="true" v-model="options['name']" placeholder="Insert exercise title" />
            </div>
            <div class="mb-6" v-if="isDataReady">
              <div class="desc editor">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Description<span class="text-red"> *</span></label>
                <quill-editor
                  ref="desc"
                  v-model="options['description']"
                  @ready="onEditorReady"
                  @blur="onEditorBlur"
                  @focus="onEditorFocus"
                  :options="{ modules: quillOptionQuestion.modules, placeholder: 'Insert description here' }"
                  class="question-quill"
                />
              </div>
            </div>
          </div>
          <div class="flex-1 flex-shrink-0 pl-6">
            <div class="mb-6">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Exercise Type <span class="text-red"> *</span></label>
              <Dropdown :options="list_exercise_type" optionLabel="name" v-model="selectedExerciseType" :default="selectedExerciseType" placeholder="Select Exercise Type" />
            </div>
            <div>
              <input type="checkbox" id="sortBy" v-model="options.sort_by_number" class="styled-checkbox" />
              <label for="sortBy" class="text-sm">Sort by Number</label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex" v-if="selectedExerciseType?.id !== 5">
        <div
          v-for="(group, index) in options.groups"
          :key="'groups-' + index"
          :class="`ml-2 ${index === activeGroup ? 'bg-white' : 'bg-grey-header'} px-6 py-2 rounded-t-lg flex items-center cursor-pointer`"
          @click="changeActiveGroup(index)"
        >
          <p :class="`${index === activeGroup ? 'font-semibold' : ''} pr-2`">Group {{ index + 1 }}</p>
          <div @click="deleteGroup(index)">
            <Trash class="text-yellow hover:opacity-80" height="20" width="20" />
          </div>
        </div>
        <div v-show="options.groups.length < 5" class="ml-2 bg-yellow-secondary hover:opacity-80 px-6 py-2 rounded-t-lg flex justify-center items-center cursor-pointer" @click="addGroup()">
          <p class="text-yellow font-semibold">+ New Group</p>
        </div>
      </div>
      <div class="shadow-small rounded-lg bg-white">
        <div class="p-6">
          <div v-if="options.groups[activeGroup]">
            <div v-for="(itemExerciseDetail, indexExerciseDetail) in options.groups[activeGroup].exercise_detail" :key="'exerciseDetail-' + indexExerciseDetail" class="mb-6 last:mb-0">
              <div class="flex justify-between items-center mb-4">
                <label class="font-bold"> Question {{ indexExerciseDetail + 1 }} </label>
                <div class="flex justify-center text-center gap-2">
                  <Button type="delete" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteQuestion(indexExerciseDetail)" />
                </div>
              </div>
              <div class="p-3 rounded-lg bg-neutral-100" v-if="itemExerciseDetail?.answer_type === 'MULTIPLE_CHOICE' || itemExerciseDetail?.answer_type?.id === 'MULTIPLE_CHOICE'">
                <div class="">
                  <div class="mb-4">
                    <div class="flex gap-2">
                      <div class="w-full">
                        <div class="editor">
                          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Question Title <span class="text-red"> *</span></label>
                          <quill-editor
                            ref="desc"
                            v-model="itemExerciseDetail.question"
                            @ready="onEditorReady"
                            @blur="onEditorBlur"
                            @focus="onEditorFocus"
                            :options="{ modules: quillOptionQuestion.modules, placeholder: 'Insert question here' }"
                            class="question-quill"
                          />
                        </div>
                      </div>
                      <div class="w-40">
                        <TextField
                          type="number"
                          @input="handleInput"
                          label="Question value"
                          :indexData="indexExerciseDetail"
                          min="1"
                          isLabelRequire
                          :borderEnabled="true"
                          v-model="itemExerciseDetail.score_weight"
                          placeholder="Question Value"
                        />
                      </div>
                      <div class="w-44">
                        <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Type Answer</label>
                        <Dropdown
                          :options="typeAnswerList"
                          optionLabel="name"
                          v-model="itemExerciseDetail.answer_type"
                          :default="itemExerciseDetail.answer_type ? itemExerciseDetail.answer_type : typeAnswerList[0]"
                          placeholder="Select The Correct Answer"
                          :disabled="selectedExerciseType?.id !== 5"
                        />
                      </div>
                    </div>
                    <p class="text-red text-xs mt-1" v-if="!itemExerciseDetail.answer_true">Please select one of the answer on the radio button</p>
                  </div>
                  <div
                    :class="`mb-2 flex gap-4 relative items-center ${answer === itemExerciseDetail.answer_true ? 'bg-yellow-secondary -mx-3 px-3 py-2' : ''}`"
                    v-for="(answer, answerIdx) in optionList"
                    :key="'answer-' + answerIdx"
                  >
                    <div class="relative leading-0">
                      <input
                        type="radio"
                        :disabled="!itemExerciseDetail[answer]"
                        :value="answer"
                        v-model="itemExerciseDetail.answer_true"
                        :id="`answer-${itemExerciseDetail.no}-${answer}`"
                        :name="`answer-${itemExerciseDetail.no}`"
                      />
                      <label :for="`answer-${itemExerciseDetail.no}-${answer}`"></label>
                    </div>
                    <div class="w-full flex items-center gap-2">
                      <div class="uppercase">{{ answer }}.</div>
                      <div class="w-full">
                        <div class="editor">
                          <quill-editor
                            ref="desc"
                            v-model="itemExerciseDetail[answer]"
                            @ready="onEditorReady"
                            @blur="onEditorBlur"
                            @focus="onEditorFocus"
                            @change="
                              () =>
                                !itemExerciseDetail[answer] && answer === itemExerciseDetail.answer_true
                                  ? (itemExerciseDetail.answer_true = '')
                                  : (itemExerciseDetail.answer_true = itemExerciseDetail.answer_true)
                            "
                            :options="{ modules: quillOptionQuestion.modules, placeholder: 'If left empty, will not be shown in LMS Student' }"
                            class="question-quill"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full mt-4">
                    <div class="editor feedback">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Feedback (optional)</label>
                      <quill-editor
                        ref="desc"
                        v-model="itemExerciseDetail.reference"
                        @ready="onEditorReady"
                        @blur="onEditorBlur"
                        @focus="onEditorFocus"
                        :options="{ modules: quillOptionQuestion.modules, placeholder: 'Insert feedback here' }"
                        class="question-quill"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex rounded-lg p-3 gap-2 w-full bg-neutral-100" v-if="itemExerciseDetail?.answer_type === 'FREE_TEXT' || itemExerciseDetail?.answer_type?.id === 'FREE_TEXT'">
                <div class="w-full">
                  <div class="editor">
                    <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Question Title <span class="text-red"> *</span></label>
                    <quill-editor
                      ref="desc"
                      v-model="itemExerciseDetail.question"
                      @ready="onEditorReady"
                      @blur="onEditorBlur"
                      @focus="onEditorFocus"
                      :options="quillOptionQuestion"
                      class="question-quill"
                    />
                  </div>
                </div>
                <div class="w-40">
                  <TextField
                    type="number"
                    @input="handleInput"
                    label="Question value"
                    min="1"
                    isLabelRequire
                    :borderEnabled="true"
                    v-model="itemExerciseDetail.score_weight"
                    placeholder="Question Value"
                  />
                </div>
                <div class="w-48">
                  <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Type Answer</label>
                  <Dropdown
                    :options="typeAnswerList"
                    optionLabel="name"
                    v-model="itemExerciseDetail.answer_type"
                    :default="itemExerciseDetail.answer_type ? itemExerciseDetail.answer_type : typeAnswerList[0]"
                    placeholder="Select The Correct Answer"
                    :disabled="selectedExerciseType?.id !== 5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-start mt-6">
            <Button buttonText="Add Question" @action="addQuestion" type="secondary" />
          </div>
        </div>
      </div>
    </div>
    <Modal :modalVisible="visibleWarning" id="warning-modal" :closeIconVisible="true" @close="toggleWarning()">
      <template slot="modal-content">
        <p class="text-2xl font-bold mb-2">{{ warningTitle }}</p>
        <p class="mb-8 text-sm">{{ warningMessage }}</p>
        <Button buttonText="Got it" @action="toggleWarning" type="primary" />
      </template>
    </Modal>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { showVueToast } from '@/utils'
import { mapActions } from 'vuex'
export default {
  name: 'ExerciseForm',
  mounted: function () {
    this.modeForm = this.$route.meta.mode
    if (this.modeForm === 'Edit') {
      this.idForm = this.$route.params.id
    } else {
      // this.addQuestion()
    }
    this.initData()
  },
  data: function () {
    return {
      quillOptionQuestion: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image', 'video']
          ]
        }
      },
      isAddQuestionBtnDisable: false,
      optionList: ['a', 'b', 'c', 'd', 'e'],
      typeAnswerList: [
        {
          id: 'MULTIPLE_CHOICE',
          name: 'Multiple Choice'
        },
        {
          id: 'FREE_TEXT',
          name: 'Short Answer'
        }
      ],
      questionValueList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      selectedClass: null,
      selectedExerciseType: null,
      selectedInstrutor: null,
      isFetching: false,
      isSearching: false,
      classKeyword: '',
      className: '',
      readyToChange: false,
      debounce: null,
      options: {
        id: 0,
        name: '',
        instructur_id: 0,
        exercise_type_id: 0,
        sort_by_number: false,
        groups: [
          {
            group_no: 1,
            exercise_detail: []
          }
        ],
        description: ''
      },
      activeGroup: 0,
      list_kelas: [],
      showProgress: true,
      counterProgress: 0,
      idForm: 0,
      modeForm: null,
      columns: [
        { field: 'id', label: 'Id' },
        { field: 'name', label: 'Name' },
        { field: 'description', label: 'Description' },
        { field: 'instructur_id', label: 'Instructor' },
        { field: 'exercise_type_id', label: 'Exercise Type' },
        { field: 'kelas_id', label: 'Class' },
        { field: 'exercise_detail', label: 'List Question' }
      ],
      masters: {},
      customer: {},
      list_instructur: [],
      list_exercise_type: [
        { id: 1, name: 'Pretest' },
        { id: 2, name: 'Quiz' },
        { id: 3, name: 'PostTest' },
        { id: 4, name: 'Assignment' },
        { id: 5, name: 'Instructor Feedback' }
      ],
      levelId: 0,
      locked: false,
      stillLoading: true,
      profileLogo: '',
      visibleWarning: false,
      warningTitle: null,
      warningMessage: null,
      isDataReady: false
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    handleInput(event, index) {
      if (event < 1) {
        this.options.groups[this.activeGroup].exercise_detail[index].score_weight = 1
      }
    },
    onEditorReady(event) {
      const previousSibling = event.container.previousElementSibling
      this.$nextTick(() => {
        const toolbarHeight = previousSibling.offsetHeight + 1
        previousSibling.style.setProperty('--offset', `${toolbarHeight}px`)
      })
    },
    onEditorFocus(event) {
      const firstChild = event.container.firstChild
      const previousSibling = event.container.previousElementSibling
      previousSibling.classList.add('show-toolbar')
      firstChild.style.setProperty('--padding', `${previousSibling.offsetHeight}px`)
    },
    onEditorBlur(event) {
      const firstChild = event.container.firstChild
      const previousSibling = event.container.previousElementSibling
      previousSibling.classList.remove('show-toolbar')
      firstChild.style.setProperty('--padding', `0px`)
    },
    initData() {
      this.isDataReady = false
      if (this.$route.meta.name === 'customer' && this.$route.meta.mode === 'Edit') {
        if (this.$route.params.id !== this.$store.getters['customer/customer'].id) {
          this.locked = true
        }
      }
      if (this.locked) {
        return null
      }

      this.counterProgress = 0
      this.customer = this.$store.getters['customer/customer']
      // this.getListInstructur()
      if (this.modeForm === 'Edit') {
        this.$store
          .dispatch('exercise/GET_EXERCISE', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            customerId: this.customerId
          })
          .then((resp) => {
            if (resp.data.code === 200) {
              this.isDataReady = true
              let tempData = this.$store.getters['exercise/exercise']
              for (var key in tempData) {
                this.options[key] = JSON.parse(JSON.stringify(tempData[key]))
              }
              // this.forClassName(tempData['kelas_id'])
              if (this.options['groups'].length > 0) {
                this.options['groups'] = this.options['groups'].map((group) => {
                  const exercise_detail = this.filterExerciseDetail(group.exercise_detail, true)
                  return {
                    ...group,
                    exercise_detail
                  }
                })
              } else {
              }

              this.selectedExerciseType = this.list_exercise_type.filter((item) => item.id === this.options['exercise_type_id'])[0]
            } else {
              // this.$swal(resp.data.message)
            }
            this.counterProgress = 100
            this.showProgress = false
            this.stillLoading = false
          })
      } else {
        this.showProgress = false
        this.isDataReady = true
        this.stillLoading = false
      }
    },
    scrollToElement() {
      this.$refs['questions'].scrollIntoView({ behavior: 'smooth' })
    },
    filterExerciseDetail(exerciseDetail, isLoadData) {
      const filterResult = exerciseDetail.map((item) => {
        const answer_type = isLoadData ? this.typeAnswerList.filter((answer) => item.answer_type === answer.id)[0] : this.typeAnswerList.filter((answer) => item.answer_type.name === answer.name)[0].id

        if (answer_type === 'FREE_TEXT' && !isLoadData) {
          delete item.a
          delete item.b
          delete item.c
          delete item.d
          delete item.e
          delete item.answer_true
        }
        return {
          ...item,
          answer_type
        }
      })
      return filterResult
    },
    toggleWarning() {
      this.visibleWarning = !this.visibleWarning
    },
    submitData() {
      let isValid = true
      this.showLoading()
      for (let i = 0; i < this.options.groups.length; i++) {
        if (!this.options.groups[i].exercise_detail.length) {
          this.hideLoading()
          this.warningTitle = 'Missing Question'
          this.warningMessage = 'You have not added any questions. Please ensure that you have added at least 1 question before proceeding.'
          this.toggleWarning()
          isValid = false
          break
        } else {
          for (let j = 0; j < this.options.groups[i].exercise_detail.length; j++) {
            if (this.options.groups[i].exercise_detail[j].answer_type.id !== 'FREE_TEXT') {
              if (!this.options.groups[i].exercise_detail[j].answer_true) {
                this.hideLoading()
                this.warningTitle = 'Missing Answer'
                this.warningMessage = 'You have not selected an answer for one of the questions. Please ensure that all multiple-choice questions have an answer selected before proceeding.'
                this.toggleWarning()
                isValid = false
                break
              }
            } else {
              if (!this.options.groups[i].exercise_detail[j].question) {
                this.hideLoading()
                this.warningTitle = 'Missing Question'
                this.warningMessage = 'Please ensure that all question is filled in before proceeding.'
                this.toggleWarning()
                isValid = false
                break
              }
            }
          }
        }
      }
      if (isValid) {
        if (this.modeForm === 'Edit') {
          if (this.$route.meta.name === 'customer') {
            this.options.logo = this.profileLogo
          }
          this.options['exercise_type_id'] = this.selectedExerciseType.id
          this.options['exercise_type_name'] = this.selectedExerciseType.id === 5 ? 'SURVEY' : this.selectedExerciseType.name.toUpperCase()
          this.options['groups'] = this.options['groups'].map((group) => {
            const exercise_detail = this.filterExerciseDetail(group.exercise_detail, false)
            return {
              ...group,
              exercise_detail
            }
          })

          this.$store
            .dispatch('exercise/UPDATE_EXERCISE', {
              masterType: this.$route.meta.name,
              masterId: this.idForm,
              payload: this.options,
              customerId: this.customerId
            })
            .then((resp) => {
              this.hideLoading()

              if (resp.data.status === '200 OK') {
                showVueToast('The process is successful!', 'success', 2000)
                this.$router.push('/' + this.$route.meta.name)
              }
            })
            .catch(() => {
              this.hideLoading()
              showVueToast('The process is failed!', 'error', 2000)
            })
        } else {
          // this.options['instructur_id'] = this.selectedInstrutor.id
          this.options['exercise_type_id'] = this.selectedExerciseType.id
          this.options['exercise_type_name'] = this.selectedExerciseType.name.toUpperCase()
          if (this.options['groups'].length > 0) {
            this.options['groups'] = this.options['groups'].map((group) => {
              const exercise_detail = this.filterExerciseDetail(group.exercise_detail, false)
              return {
                ...group,
                exercise_detail
              }
            })
          }

          this.$store
            .dispatch('exercise/CREATE_EXERCISE', {
              masterType: this.$route.meta.name,
              masterId: this.idForm,
              payload: this.options,
              customerId: this.customerId
            })
            .then((resp) => {
              this.hideLoading()
              if (resp.data.status === '200 OK') {
                showVueToast('The process is successful!', 'success', 2000)
                if (this.customer.role_id > 2) {
                  this.initData()
                } else {
                  this.$router.push('/' + this.$route.meta.name)
                }
              }
            })
        }
      }
    },
    changeClass() {
      this.readyToChange = true
    },
    changeActiveGroup(index) {
      this.activeGroup = index
    },
    addGroup: function () {
      this.options.groups.push({
        group_no: this.options.groups.length + 1,
        exercise_detail: []
      })
      this.activeGroup = this.options.groups.length - 1
    },
    deleteGroup: function (index) {
      if (this.options.groups.length === 1) return
      this.changeActiveGroup(this.options.groups.length - 1)
      this.options.groups.splice(index, 1)
      let newArr = []
      newArr = this.options.groups.map((group, index) => {
        return {
          ...group,
          group_no: index + 1
        }
      })
      this.options.groups = newArr
    },
    addQuestion: function () {
      if (!this.options?.groups[this.activeGroup]?.exercise_detail) return
      this.options.groups[this.activeGroup].exercise_detail.push({
        id: 0,
        exercise_id: 0,
        no: this.options.groups[this.activeGroup].exercise_detail.length + 1,
        answer_type: this.typeAnswerList[0],
        a: '',
        b: '',
        c: '',
        d: '',
        e: '',
        question: '',
        answer_true: '',
        reference: '',
        score_weight: 1
      })
    },
    deleteQuestion: function (indexQuestion) {
      this.options.groups[this.activeGroup].exercise_detail.splice(indexQuestion, 1)
      let newArr = []
      if (this.options.groups[this.activeGroup].exercise_detail.length > 0) {
        newArr = this.options.groups[this.activeGroup].exercise_detail.map((item, index) => {
          return {
            ...item,
            no: index + 1
          }
        })
      }
      this.options.groups[this.activeGroup].exercise_detail = newArr
    },

    getListInstructur() {
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          sort: ''
        }
      })
      this.$store
        .dispatch('instructur/GET_LIST_INSTRUCTUR', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: this.customerId
        })
        .then(() => {
          this.list_instructur = this.$store.getters['instructur/list_instructur'] ? this.$store.getters['instructur/list_instructur'] : []
          if (this.isEditMode) {
            this.selectedInstrutor = this.list_instructur.filter((item) => item.id === this.options['instructur_id'])[0]
          }
        })
        .catch(function () {})
    }
  },
  computed: {
    data: function () {
      return this.$store.getters['exercise/list_exercise'] ? this.$store.getters['exercise/list_exercise'] : []
    },
    customerId() {
      return localStorage.getItem('client')
    },
    isEditMode() {
      return this.$route.meta.mode === 'Edit'
    },
    disableSubmit() {
      return !this.selectedExerciseType || !this.options['name'] || !this.options['description']
    }
  },
  components: {
    quillEditor,
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Trash: () => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash'),
    Pencil: () => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
  }
}
</script>
<style lang="scss" scoped>
.ql-editor ol li {
  display: list-item;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }

  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #f7931e;
    border-radius: 4px;
    margin-right: 10px;
  }

  &:hover + label:before {
    background: #f7931e;
    opacity: 0.3;
  }

  &:checked + label:before {
    background: #f7931e;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.question-quill {
  // height: 200px;
}

.editor {
  &::v-deep .ql-toolbar {
    --offset: 0;
    background-color: #fff;
    // border-color: #d6d6d6;
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: none;
    position: absolute;
    z-index: 1;
    width: calc(100% - 2px);
    top: calc(var(--offset) * -1);
    transition: top 300ms linear;
    margin: 0px 1px;
    &.show-toolbar {
      top: 1px;
      border-bottom: 1px solid #d6d6d6 !important;
    }
  }
  &::v-deep .ql-editor {
    --padding: 0px;
    transition: all 300ms linear;
    padding-top: calc(var(--padding) + 12px);
    background-color: #fff;
    border-radius: 8px;
    line-height: 1.25rem;
    &::before {
      font-style: normal;
      color: #adadad;
      font-size: 14px;
    }
  }
  &::v-deep .ql-container {
    border-color: #d6d6d6;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    border-top: 1px solid #d6d6d6 !important;
    font-size: 14px;
  }
  &::v-deep .ql-tooltip {
    left: 10px !important;
    top: -7px !important;
  }
  .question-quill {
    position: relative;
    overflow: hidden;
  }
}

.desc {
  &::v-deep .ql-editor {
    height: 160px;
  }
}
.feedback {
  &::v-deep .ql-editor {
    height: 120px;
  }
}
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  color: #666;
  line-height: 0;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1.5px solid #c2c2c2;
  border-radius: 100%;
  background: transparent;
}
[type='radio']:disabled + label {
  cursor: not-allowed;
  opacity: 0.5;
}
[type='radio']:checked + label:before {
  border: 1px solid #f7931e;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 16px;
  height: 16px;
  background: #f7931e;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) translate(-50%, -50%);
  transform: scale(1) translate(-50%, -50%);
}
</style>
