<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5939 1.4125C14.4644 1.28145 14.31 1.17749 14.1399 1.10667C13.9698 1.03585 13.7872 0.999592 13.603 1H9.7614C9.50941 1.00047 9.26776 1.1003 9.08891 1.27782L1.40922 8.95594C1.14717 9.21853 1 9.57435 1 9.94532C1 10.3163 1.14717 10.6721 1.40922 10.9347L5.06547 14.5909C5.32812 14.8531 5.68406 15.0003 6.05516 15.0003C6.42625 15.0003 6.78219 14.8531 7.04484 14.5909L14.7214 6.91594C14.8993 6.73737 14.9995 6.4958 15.0002 6.24375V2.4C15.0013 2.21669 14.966 2.03498 14.8962 1.86545C14.8265 1.69592 14.7237 1.54195 14.5939 1.4125V1.4125ZM12.0002 5C11.8024 5 11.609 4.94135 11.4446 4.83147C11.2801 4.72159 11.152 4.56541 11.0763 4.38269C11.0006 4.19996 10.9808 3.99889 11.0194 3.80491C11.058 3.61093 11.1532 3.43275 11.293 3.2929C11.4329 3.15304 11.6111 3.0578 11.8051 3.01922C11.999 2.98063 12.2001 3.00044 12.3828 3.07612C12.5656 3.15181 12.7217 3.27998 12.8316 3.44443C12.9415 3.60888 13.0002 3.80222 13.0002 4C13.0002 4.26522 12.8948 4.51957 12.7073 4.70711C12.5197 4.89465 12.2654 5 12.0002 5Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
