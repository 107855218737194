<template>
  <div class="bg-white shadow-small rounded-lg card">
    <div class="flex justify-between p-4">
      <div class="flex items-center gap-3">
        <span class="rounded-md flex justify-center items-start p-1 bg-yellow-secondary cursor-pointer" @click="selectPrevious"><ArrowForward direction="left" color="#F7931E" /></span>
        <span class="text-neutral text-xl font-bold">{{ selectedMonth }}</span>
        <span class="rounded-md flex justify-center items-start p-1 bg-yellow-secondary cursor-pointer" @click="selectNext"><ArrowForward color="#F7931E" /></span>
      </div>
      <div class="relative">
        <Button
          type="secondary"
          size="icon"
          :forIcon="{ color: '#F7931E' }"
          :buttonText="`Filter (${countFilter})`"
          additionalClass="px-2"
          :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Option')"
          @action="showFilter"
        />
        <div class="absolute z-20 top-0 w-72 rounded-lg bg-white shadow-card p-3 right-0" v-if="isFilterBoxVisible">
          <div class="flex justify-between items-center mb-3">
            <h1 class="text-sm font-bold">Filter ({{ countFilter }})</h1>
            <div class="cursor-pointer" @click="closeFilter()"><Close /></div>
          </div>
          <Dropdown
            :options="list_instructur"
            v-model="instructorFilter"
            :default="instructorFilter"
            placeholder="Select Instructor"
            label="Instructor"
            optionLabel="name"
            @search="getInstructorList"
            :isLoadData="isLoadDataInstructorList"
            enableSearch
            class="mb-3"
          />
          <Dropdown label="Program" :options="programTypeList" optionLabel="name" v-model="programTypeFilter" :default="programTypeFilter" placeholder="Select Program" class="mb-3" />
          <Dropdown
            :options="list_kelas"
            v-model="coreClassFilter"
            :default="coreClassFilter"
            placeholder="All Class"
            label="Class"
            optionLabel="nama"
            @search="getClassList"
            :isLoadData="isLoadDataClassList"
            enableSearch
            class="mb-5"
          />
          <div class="flex justify-between">
            <Button type="primary" size="regular" buttonText="Apply" additionalClass="px-10" @action="selectCurrentMonth(false)" />
            <Button type="tertiary" size="regular" buttonText="Reset" additionalClass="px-10" @action="resetFilter()" />
          </div>
        </div>
      </div>
    </div>
    <ol class="grid grid-cols-7 border-t border-b border-grey-header">
      <li class="text-center py-0 text-xs" v-for="weekday in weekdays" :key="weekday">{{ weekday }}</li>
    </ol>
    <div v-if="isFetchingDataCalendar" class="w-full py-40">
      <vue-simple-spinner size="large" message="Fetching Data..."></vue-simple-spinner>
    </div>
    <ol class="grid grid-cols-7" v-else>
      <DayItem
        v-for="(day, indexDay) in days"
        @clickMore="clickMore"
        @clickSchedule="clickSchedule"
        :key="indexDay"
        :dateFromQuery="dateFromQuery"
        :indexDay="indexDay"
        :day="day"
        :isToday="day.date === today"
        class=""
      />
    </ol>
    <Modal :modalVisible="isDetailModalVisible" @close="closeDetailModal" id="showDetailSchedule">
      <template slot="modal-content">
        <div v-if="isFetchingDataScheduleDetails" class="w-full py-16">
          <vue-simple-spinner size="medium" message="Fetching Data..."></vue-simple-spinner>
        </div>
        <div class="text-left" v-else>
          <h1 class="font-bold text-xl">Class Detail</h1>
          <p class="text-xs pt-3">Program</p>
          <h3 class="font-bold">{{ scheduleDetails?.programCode }}</h3>
          <p class="text-xs pt-3">Class Name</p>
          <h3 class="font-bold">{{ scheduleDetails?.className }}</h3>
          <p class="text-xs pt-3">Class Schedule</p>
          <div class="flex item-center">
            <h3 class="font-bold truncate mr-2">{{ scheduleDetails?.lmsClassScheduleName }}</h3>
            <Warning color="yellow" width="16" v-if="isScheduleConflict" />
          </div>
          <p class="text-xs pt-3">Time</p>
          <h3 class="font-bold">{{ utcToLocal(scheduleDetails?.startTime, 'HH:mm') }} - {{ utcToLocal(scheduleDetails?.endTime, 'HH:mm') }} WIB</h3>
          <p class="text-xs py-3">Instructors</p>
          <div class="flex gap-2 items-center mb-2">
            <div>
              <img v-if="instructorAssigned?.photoUrl" :src="instructorAssigned?.photoUrl" :alt="instructorAssigned?.name" class="w-6 h-6 rounded-full" />
              <div v-else class="w-6 h-6 rounded-full bg-gray-400"></div>
            </div>
            <div class="text-sm font-bold truncate">{{ instructorAssigned?.name }}</div>
          </div>
          <div class="mt-4" v-if="isScheduleConflict">
            <Button type="primary" size="full" buttonText="Edit Class Schedule" @action="openNewTab()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import utc from 'dayjs/plugin/utc'
const WEEKDAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
import DayItem from './DayItem'
import { mapGetters, mapActions } from 'vuex'

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(utc)
export default {
  name: 'Calendar',
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    Option: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Option'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/Button/Button'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Close'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Warning: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Warning'),
    DayItem
  },
  data() {
    return {
      selectedDate: dayjs(),
      isDetailModalVisible: false,
      isFilterBoxVisible: false,
      isFetchingDataCalendar: false,
      isFetchingDataScheduleDetails: false,
      isLoadDataInstructorList: false,
      isLoadDataClassList: false,
      scheduleDetails: null,
      instructorList: [],
      programTypeList: [],
      instructorFilter: null,
      programTypeFilter: null,
      coreClassFilter: null,
      days: [],
      customPath: '',
      customQuery: '',
      isScheduleConflict: false,
      instructorAssigned: null
    }
  },
  computed: {
    ...mapGetters('instructur', ['list_instructur']),
    ...mapGetters('kelas', ['list_kelas']),
    dateFromQuery() {
      const dateFromQueryPath = this.$route.query.date
      if (dateFromQueryPath) return this.utcToLocal(dateFromQueryPath, '')
      return null
    },
    countFilter() {
      let count = 0
      if (this.instructorFilter) count += 1
      if (this.programTypeFilter) count += 1
      if (this.coreClassFilter) count += 1
      return count
    },
    clientId() {
      return localStorage.getItem('client')
    },
    selectedMonth() {
      if (!this.selectedDate) return 'undefined'
      return this.selectedDate.format('MMMM YYYY')
    },
    weekdays() {
      return WEEKDAYS
    },
    today() {
      return dayjs().format('YYYY-MM-DD')
    },

    month() {
      return Number(this.selectedDate.format('M'))
    },

    monthInNumber() {
      return Number(this.selectedDate.format('MM'))
    },

    year() {
      return Number(this.selectedDate.format('YYYY'))
    },

    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth()
    },

    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).format('YYYY-MM-DD'),
          isConflict: false,
          isShowDetails: false,
          schedule: [],
          isCurrentMonth: true
        }
      })
    },

    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(this.currentMonthDays[0].date)
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(1, 'month')

      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday ? firstDayOfTheMonthWeekday - 1 : 6

      const previousMonthLastMondayDayOfMonth = dayjs(this.currentMonthDays[0].date).subtract(visibleNumberOfDaysFromPreviousMonth, 'day').date()

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
        return {
          date: dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).format('YYYY-MM-DD'),
          content: [],
          isCurrentMonth: false
        }
      })
    },

    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(`${this.year}-${this.month}-${this.currentMonthDays.length}`)

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month')

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday ? 7 - lastDayOfTheMonthWeekday : lastDayOfTheMonthWeekday

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format('YYYY-MM-DD'),
          content: [],
          isCurrentMonth: false
        }
      })
    }
  },
  created() {
    const expertId = this.$route.query.expert_id
    if (expertId) this.instructorFilter = { id: expertId }
    if (this.dateFromQuery) this.selectedDate = dayjs(this.dateFromQuery)
    this.getCurrentMonth()
  },
  methods: {
    ...mapActions('instructur', ['GET_LIST_INSTRUCTUR', 'GET_INSTRUCTUR']),
    ...mapActions('overview', ['GET_SCHEDULE_CALENDAR', 'GET_SCHEDULE_CALENDAR_DETAILS']),
    ...mapActions('kelas', ['GET_LIST_PROGRAM', 'GET_LIST_KELAS']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    utcToLocal(day, format) {
      const localTime = dayjs.utc(day).local().format(format)
      return localTime
    },
    getInstructorDetails(masterId) {
      this.GET_INSTRUCTUR({
        customerId: this.clientId,
        masterId
      }).then((res) => {
        if (res.data.code === 200) {
          this.instructorAssigned = res.data.data
          this.instructorList.push(res.data.data)
        }
      })
    },
    getInstructorList(nama) {
      this.isLoadDataInstructorList = true
      let params = queryString.stringify({
        ...{
          limit: 500,
          nama
        }
      })

      this.GET_LIST_INSTRUCTUR({
        params,
        customerId: this.clientId
      })
        .then(() => {
          this.isLoadDataInstructorList = false
        })
        .catch(function () {
          this.isLoadDataInstructorList = false
        })
    },
    getClassList(nama) {
      this.isLoadDataClassList = true
      let params = queryString.stringify({
        ...{
          limit: 200,
          name: nama
        }
      })

      this.GET_LIST_KELAS({
        params,
        customerId: this.clientId
      })
        .then((res) => {
          this.isLoadDataClassList = false
        })
        .catch(function () {
          this.isLoadDataClassList = false
        })
    },
    getProgramTypeList() {
      this.GET_LIST_PROGRAM().then((res) => {
        this.programTypeList = res.data.data
      })
    },
    clickMore(indexDay, value) {
      let copyArray = JSON.parse(JSON.stringify(this.days))
      this.days = copyArray.map((copy, index) => {
        if (index === indexDay) {
          return {
            ...copy,
            isShowDetails: value
          }
        } else {
          return {
            ...copy,
            isShowDetails: false
          }
        }
      })
    },
    removeFilterParameter() {
      this.instructorFilter = null
      this.coreClassFilter = null
      this.programTypeFilter = null
    },
    resetFilter() {
      this.removeFilterParameter()
      this.selectCurrentMonth(true)
    },
    openNewTab() {
      const routeData = this.$router.resolve({
        path: this.customPath,
        query: this.customQuery
      })
      window.open(routeData.href, '_blank')
    },
    clickSchedule(schedule, isConflict) {
      if (isConflict) {
        this.customPath = `/jadwalkelas/new-schema/${schedule.classScheduleId}`
        this.customQuery = { activity_item_id: schedule.classActivityItemId }
      }
      this.isScheduleConflict = isConflict
      this.isDetailModalVisible = true
      this.isFetchingDataScheduleDetails = true
      this.instructorList = []
      this.GET_SCHEDULE_CALENDAR_DETAILS({
        classActivityItemId: schedule.classActivityItemId
      })
        .then((res) => {
          this.scheduleDetails = res
          if (res.expertId) {
            this.getInstructorDetails(res.expertId)
          }
          setTimeout(() => {
            this.isFetchingDataScheduleDetails = false
          }, 500)
        })
        .catch(() => {})
    },
    closeDetailModal() {
      this.isDetailModalVisible = false
    },
    getWeekday(date) {
      return dayjs(date).weekday()
    },

    getCurrentMonth() {
      this.isFetchingDataCalendar = true
      let params = queryString.stringify({
        expertId: this.instructorFilter ? this.instructorFilter.id : null,
        programType: this.programTypeFilter ? this.programTypeFilter.code : null,
        coreClassId: this.coreClassFilter ? this.coreClassFilter.id : null,
        month: this.monthInNumber,
        year: this.year
      })
      this.GET_SCHEDULE_CALENDAR({
        customerId: this.clientId,
        params
      }).then((res) => {
        const scheduleCalendar = res.map((activityItem) => {
          if (!activityItem.dayOfWeek) {
            return {
              ...activityItem,
              date: this.utcToLocal(activityItem.startTime, 'YYYY-MM-DD')
            }
          } else {
            return {
              ...activityItem
            }
          }
        })

        const currentMonth = [...this.previousMonthDays, ...this.currentMonthDays, ...this.nextMonthDays]
        this.days = currentMonth
        this.days.map((day, indexDay) => {
          scheduleCalendar.map((schedule) => {
            if (schedule) {
              if (day.date === schedule.date) {
                this.days[indexDay].schedule.push(schedule)
              }
            }
          })
          this.isFetchingDataCalendar = false
        })
      })
    },
    showFilter() {
      this.clickMore(0, false)
      this.isFilterBoxVisible = true
      this.getProgramTypeList()
    },
    closeFilter() {
      this.isFilterBoxVisible = false
    },
    selectCurrentMonth(filterBoxVisible) {
      let newSelectedDate = dayjs(this.selectedDate)
      this.selectedDate = newSelectedDate
      this.isFilterBoxVisible = filterBoxVisible
      this.getCurrentMonth()
    },
    selectPrevious() {
      let newSelectedDate = dayjs(this.selectedDate).subtract(1, 'month')
      this.selectedDate = newSelectedDate
      this.getCurrentMonth()
    },
    selectNext() {
      let newSelectedDate = dayjs(this.selectedDate).add(1, 'month')
      this.selectedDate = newSelectedDate
      this.getCurrentMonth()
    }
  }
}
</script>

<style scoped>
.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
}
</style>
