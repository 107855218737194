<template>
  <div class="kelas-new-form pb-6 w-full min-h-contain">
    <div class="mb-6 flex items-center">
      <button @click="toClassListMenu()" class="mr-3 w-8 h-8 flex justify-center items-center focus:outline-none">
        <ArrowForward direction="left" />
      </button>
      <h1 class="text-3xl font-bold">{{ $route.meta.mode }} Class</h1>
    </div>
    <div class="absolute h-screen justify-center items-center justify-items-center ml-64 -mt-16" v-if="errorPromoPrice === true">
      <div class="border-2 border-red-indicator rounded-xl w-464 h-20 text-red-indicator bg-red-10 flex">
        <div class="m-auto w-24">
          <AlertCircle width="26" height="26" class="m-auto" />
        </div>
        <div class="m-auto w-full">
          <div class="font-semibold">Error: Promo Price</div>
          <div class="font-medium text-xs">Please remove Promo Price if Listing Price is not > Rp0</div>
        </div>
      </div>
    </div>
    <div class="tab flex justify-between items-end pl-4">
      <div class="flex gap-2">
        <div @click="selectTab('General')" class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer" :class="[selectedTab === 'General' ? 'font-bold bg-white' : 'bg-neutral-50']">
          General
        </div>
        <div @click="selectTab('Details')" class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer" :class="[selectedTab === 'Details' ? 'font-bold bg-white' : 'bg-neutral-50']">
          Web Content
        </div>
        <div
          @click="selectTab('Certificate')"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedTab === 'Certificate' ? 'font-bold bg-white' : 'bg-neutral-50']"
        >
          Certificate
        </div>
        <div
          @click="selectTab('Activities')"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedTab === 'Activities' ? 'font-bold bg-white' : 'bg-neutral-50']"
        >
          Activities Template
        </div>
        <div
          @click="selectTab('Schedule')"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedTab === 'Schedule' ? 'font-bold bg-white' : 'bg-neutral-50']"
          v-if="isEditMode"
        >
          Class Schedule
        </div>
        <div
          @click="selectTab('Settings')"
          class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedTab === 'Settings' ? 'font-bold bg-white' : 'bg-neutral-50']"
        >
          Settings
        </div>
      </div>
      <div class="flex justify-end py-2">
        <Button :disabled="disableSubmit || temporaryDisabled" :buttonText="isEditMode ? 'Update' : 'Submit'" @action="save()" size="compact" />
      </div>
    </div>
    <div class="shadow-small rounded-lg bg-white">
      <template v-if="selectedTab === 'General'">
        <div class="grid grid-cols-2">
          <div class="p-6 flex-1 shadow-border-r flex-shrink-0">
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
              <span class="-ml-1"><Label color="yellow" /></span>General
            </p>
            <div class="mb-6">
              <div class="form">
                <div v-if="isEditMode" class="mb-6">
                  <TextField type="text" label="Id" :borderEnabled="true" v-model="id" :error="error['id']" disabled />
                </div>
                <div class="mb-6">
                  <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Program</label>
                  <Dropdown :options="programOptions" optionLabel="name" v-model="program" :default="program" placeholder="Select Program" :disabled="isEditMode" />
                </div>
                <div class="mb-6">
                  <TextField type="text" isLabelRequire label="Name" placeholder="Enter class name" :borderEnabled="true" v-model="name" :error="error['name']" required />
                </div>
                <div class="mb-6 flex gap-6">
                  <div class="flex-1">
                    <label class="text-neutral-500 text-xs font-medium pl-3 block mb-1">Type</label>
                    <div class="border border-grey-field p-4 rounded-lg">
                      <div class="grid grid-layout2">
                        <div class="leading-0">
                          <input type="checkbox" id="webinar" value="WEBINAR" v-model="types" class="styled-checkbox" />
                          <label for="webinar">Webinar</label>
                        </div>
                        <div class="leading-0">
                          <input type="checkbox" id="self-based" value="SELF_BASED" v-model="types" class="styled-checkbox" />
                          <label for="self-based">Self-based</label>
                        </div>
                        <div class="leading-0">
                          <input type="checkbox" id="offline" value="OFFLINE" v-model="types" class="styled-checkbox" />
                          <label for="offline">Offline</label>
                        </div>
                        <div class="leading-0">
                          <input type="checkbox" id="blended" value="BLENDED" v-model="types" class="styled-checkbox" />
                          <label for="blended">Blended</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1">
                    <TextField type="text" label="Code" placeholder="Enter class code" :borderEnabled="true" v-model="code" :error="error['code']" required />
                  </div>
                </div>
                <div class="mb-10 flex gap-6">
                  <div class="flex-1">
                    <TextField type="number" min="0" label="Min Participants" :borderEnabled="true" v-model="minParticipants" :error="error['minParticipants']" required />
                  </div>
                  <div class="flex-1">
                    <TextField type="number" min="0" isLabelRequire label="Max Participants" :borderEnabled="true" v-model="maxParticipants" :error="error['maxParticipants']" required />
                  </div>
                </div>
                <div class="">
                  <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
                    <span class="-ml-1"><Label color="yellow" /></span>Instructor<span class="text-red"> *</span>
                  </p>
                  <div class="mb-6">
                    <div v-if="coreClassInstructors.length > 0" class="p-4 bg-neutral-100 rounded-lg grid grid-cols-2 gap-5 mb-4">
                      <div v-for="(instructor, instructorIdx) in coreClassInstructors" :key="`coreClassInstructors` + instructorIdx">
                        <p class="text-xs pl-3 font-medium mb-1 text-neutral-500">Instructor {{ instructorIdx + 1 }}</p>
                        <div class="flex gap-3">
                          <Dropdown
                            :options="instructorOptions"
                            v-model="coreClassInstructors[instructorIdx]"
                            :default="coreClassInstructors[instructorIdx]"
                            placeholder="Select Instructor"
                            optionLabel="name"
                            :indexData="instructorIdx"
                            @search="getInstructorList"
                            maxHeight="200px"
                            enableSearch
                            class="w-full"
                          />
                          <div class="flex items-center">
                            <Button type="secondary" size="icon" :icon="() => import('@/components/Icons/Trash')" @action="deleteInstructor(instructorIdx)" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex">
                      <Button :buttonText="coreClassInstructors.length > 0 ? 'Add More Instructor' : 'Add Instructor'" size="compact" type="tertiary" @action="addInstructor" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-6 flex-1 flex-shrink-0">
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
              <span class="-ml-1"><Label color="yellow" /></span>Excercise
            </p>
            <div class="mb-10">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Description</label>
              <quill-editor v-model="description" :options="quillOptions" />
            </div>
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
              <span class="-ml-1"><Label color="yellow" /></span>Quiz
            </p>
            <div class="mb-10">
              <div v-if="quizes.length > 0" class="mt-3 mb-3 p-4 bg-neutral-100 rounded-lg grid grid-layout">
                <div v-for="(item, index) in quizes" :key="'quiz -' + index" class="">
                  <div class="flex justify-between items-center">
                    <p class="text-xs pl-3 font-medium mb-1 text-neutral-500">Quiz {{ index + 1 }}</p>
                  </div>
                  <div class="flex gap-2">
                    <select v-model="quizes[index]" class="w-full pl-2 h-11 rounded-lg border-grey-field border outline-none text-sm text-neutral-500">
                      <option value="" disabled selected>Select quiz</option>
                      <option v-for="item in listQuiz" :key="item.id" :value="item.id">{{ item.name }}</option>
                    </select>
                    <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteQuiz(index)" />
                  </div>
                </div>
              </div>
              <div class="flex">
                <Button :buttonText="quizes.length > 0 ? 'Add More Quiz' : 'Add Quiz'" type="tertiary" size="compact" @action="addQuiz" />
              </div>
            </div>

            <!-- <p class="text-lg font-semibold mb-4">Videos</p>
            <div class="mb-6">
              <Button buttonText="Add URL" type="secondary" @action="addVideo" />
              <div v-if="videos.length > 0" class="mt-3 p-4 border rounded-lg">
                <div v-for="(item, index) in videos" :key="'video -' + index" class="mb-6 last:mb-0">
                  <div class="flex justify-between items-center mb-2">
                    <p class="text-sm font-medium mb-2 text-neutral-500">Video {{ index + 1 }}</p>
                    <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteVideo(index)" />
                  </div>
                  <div class="bg-neutral-100 p-4 rounded-lg">
                    <div class="w-full">
                      <TextField type="text" label="URL Video" placeholder="Input URL" :borderEnabled="true" v-model="videos[index]" />
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
              <span class="-ml-1"><Label color="yellow" /></span>Enrollment Condition
            </p>
            <div class="mb-6">
              <div class="mb-3">
                <div v-for="(condition, index) in conditions" :key="'condition-' + index">
                  <div class="flex justify-between items-end mt-4 mb-2">
                    <p class="text-sm font-medium mb-1 text-neutral-500">Class Enrollment Condition {{ index + 1 }}</p>
                    <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteCondition(index)" />
                  </div>
                  <div class="p-4 bg-neutral-100 rounded-lg">
                    <div v-for="(child, childIndex) in condition.or" :key="'child-' + childIndex">
                      <div class="flex justify-between items-center mb-2">
                        <p class="text-sm font-medium mb-2 text-neutral-500">
                          Enrollment Condition {{ childIndex + 1 }} -
                          {{ withCondition(index, childIndex) === 'userHasPassed' ? 'User has passed' : 'User has completed' }}
                        </p>
                        <Button type="secondary" size="icon" :icon="() => import('@/components/Icons/Trash')" @action="deleteChildCondition(index, childIndex)" />
                      </div>
                      <div class="bg-neutral-100 p-4 rounded-lg mb-6">
                        <div class="mb-4">
                          <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Class</label>
                          <div>
                            <select v-model="forVModel(index, childIndex).coreClassId" class="w-full pl-2 h-11 rounded-lg border-grey-field border outline-none text-sm text-neutral-500">
                              <option value="" disabled selected>Select class</option>
                              <option v-for="(item, index) in listClass" :key="'cciWc' + index" :value="item.id">{{ item.nama }}</option>
                            </select>
                            <div class="my-3">
                              <input type="checkbox" :checked="isChecked(index, childIndex)" :id="`wc-${index}-${childIndex}`" @click="addWithinTime(index, childIndex)" class="styled-checkbox" />
                              <label :for="`wc-${index}-${childIndex}`" style="font-size: 0.8rem">Add Time Constraint condition</label>
                            </div>
                            <div v-show="forVModel(index, childIndex).withinTime" class="flex justify-between gap-6">
                              <div class="w-1/2">
                                <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Time Value</label>
                                <input
                                  placeholder="Input Number"
                                  type="number"
                                  class="w-full form-control m-input h-11 pl-3 rounded-lg border border-grey-field outline-none"
                                  :value="forVModel(index, childIndex).withinTime ? forVModel(index, childIndex).withinTime.value : ''"
                                  @input="forVModel(index, childIndex).withinTime.value = $event.target.value"
                                />
                              </div>
                              <div class="w-1/2">
                                <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Time Parameter</label>
                                <select
                                  :value="forVModel(index, childIndex).withinTime ? forVModel(index, childIndex).withinTime.type : ''"
                                  @input="forVModel(index, childIndex).withinTime.type = $event.target.value"
                                  class="w-full pl-2 h-11 rounded-lg border-grey-field border outline-none text-sm text-neutral-500"
                                >
                                  <option v-for="(item, index) in listTimeParameter" :key="'tpWc-' + index" :value="item.id">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-around h-8">
                      <Button buttonText="New User Has Passed" size="small" type="secondary" @action="addChildConditionUHP(index)" />
                      <Button buttonText="New User Has Completed" size="small" type="secondary" @action="addChildConditionUHC(index)" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <Button buttonText="Add Condition" size="compact" type="tertiary" @action="addCondition()" />
              </div>
            </div>
            <!-- CLASS VARIANT -->
            <div v-if="isEditMode && programSelected === 'Bootcamp'">
              <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
                <span class="-ml-1"><Label color="yellow" /></span>Class Variant
              </p>
              <div class="p-4 bg-neutral-100 rounded-lg grid grid-cols-1 gap-5 mb-4" v-if="classVariants.length > 0">
                <div class="flex gap-3 items-center" v-for="(variant, variantIdx) in classVariants" :key="'classVariants' + variantIdx">
                  <Dropdown
                    :label="`Attribute ${variantIdx + 1}`"
                    :options="classAttributeOptions"
                    v-model="classVariants[variantIdx]"
                    :default="classVariants[variantIdx]"
                    placeholder="Choose available attribute"
                    optionLabel="attributeName"
                    maxHeight="200px"
                    class="w-full"
                    @onChangeSelected="handleClassVariantChange"
                  />
                  <div class="pt-5">
                    <Button type="secondary" size="icon" :icon="() => import('@/components/Icons/Trash')" @action="deleteVariant(classVariants, variantIdx)" />
                  </div>
                </div>
              </div>
              <Button buttonText="Add Attribute" size="compact" type="tertiary" :disabled="classVariants.length >= 3" @action="addNewVariant()" />
              <div v-if="classVariantDataTable.length > 0" class="mt-4">
                <TableComponent :columns="columnsTableClassVariant" :list="classVariantDataTable" compact>
                  <template slot="table-row" slot-scope="{ entry, column }">
                    <div v-if="column.label === 'Actions'" class="flex justify-end gap-3 mr-4">
                      <Button
                        type="tertiary"
                        size="icon"
                        :forIcon="{ color: 'yellow' }"
                        :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                        :tooltip="{ show: true, text: 'Edit' }"
                        @action="toggleClassVariantModal(entry)"
                      />
                    </div>
                    <div v-else-if="column.label === 'Price' || column.label === 'Promo'" class="flex items-center gap-1">
                      <p>Rp.</p>
                      <TextField type="number" v-model="entry[column.field]" borderEnabled placeholder="0" class="w-28" />
                    </div>
                    <div v-else-if="column.label === 'Status'">{{ entry[column.field] ? 'Active' : 'Inactive' }}</div>
                    <div v-else>{{ entry[column.field] }}</div>
                  </template>
                </TableComponent>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="selectedTab === 'Details'">
        <WebContent
          :payload="payload"
          :programSelected="programSelected"
          :scheduledPrices="scheduledPrices"
          :historyAlumniClasses="historyAlumniClasses"
          :testimonyList="testimonyList"
          :coreClassPaymentMethod="coreClassPaymentMethod"
          :paymentMethodSelected="paymentMethodSelected"
          :syllabusDocument="syllabusDocument"
          :coreClassSoftwareTool="coreClassSoftwareTool"
          @handlePaymentMethodSelected="handlePaymentMethodSelected"
          @handleSyllabusDocumentMutate="handleSyllabusDocumentMutate"
          @addMoreItem="addMoreItem"
          @deleteItem="deleteItem"
        />
      </template>
      <template v-if="selectedTab === 'Certificate'">
        <div class="w-full p-6">
          <div class="mb-6 mt-2">
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
              <span class="-ml-1"><Label color="yellow" /></span>Certificate Competence
            </p>
            <div class="p-4 rounded-lg mb-4 border-2 w-max" v-if="certificateCompetence.length > 0">
              <div v-for="(item, idx) in certificateCompetence" :key="idx" class="mb-5">
                <p class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Competence {{ idx + 1 }}</p>
                <div class="flex gap-2">
                  <TextField type="text" v-model="certificateCompetence[idx]" borderEnabled placeholder="placeholder" class="w-325" />
                  <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteItem(certificateCompetence, idx)" />
                </div>
              </div>
              <div v-if="certificateCompetence.length > 0">
                <Button
                  :buttonText="certificateCompetence.length > 0 ? 'Add Competence' : 'Add Competence'"
                  size="compact"
                  type="tertiary"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Plus')"
                  @action="addMoreItem(certificateCompetence, 'certifCompetenceId')"
                />
              </div>
            </div>
            <div class="flex ml-4" v-else>
              <Button
                :buttonText="certificateCompetence.length > 0 ? 'Add Competence' : 'Add Competence'"
                size="compact"
                type="tertiary"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Plus')"
                @action="addMoreItem(certificateCompetence, 'certifCompetenceId')"
              />
            </div>
          </div>

          <div class="mb-6 w-2/3">
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
              <span class="-ml-1"><Label color="yellow" /></span>Certificate Type
            </p>
            <div class="border-black-200 p-5 border-2 mb-6" v-if="certificates.length > 0">
              <div class="mb-6" v-for="(item, idx) in certificates" :key="idx">
                <div class="flex justify-between pb-2">
                  <span class="text-sm py-1 block">Certificate {{ idx + 1 }}</span>
                  <div class="bg-red-100 px-1 ml-2 rounded-lg cursor-pointer">
                    <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteItem(certificates, idx)" />
                  </div>
                </div>
                <div class="border-2 bg-neutral-100 p-4">
                  <div class="mb-6 w-2/3">
                    <TextField type="text" v-model="item.title" label="Title" borderEnabled placeholder="Enter title" />
                  </div>
                  <div class="mb-6 w-2/3">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3">Type</label>
                    <Dropdown
                      :options="certificateTypeOptions"
                      optionLabel="name"
                      v-model="certificates[idx].certificateType"
                      :default="certificates[idx].certificateType"
                      @change="(opt) => (certificates[idx].certificate_type = opt.code)"
                      placeholder="Select"
                    />
                  </div>
                  <div class="mb-6 w-2/3">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3">Description</label>
                    <textarea
                      label="Description"
                      v-model="item.description"
                      class="h-52 text-sm border-grey-field border resize-none px-3 py-3 focus:outline-none focus:ring focus:ring-white w-full rounded-lg"
                    />
                  </div>
                  <div class="w-2/3">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3">Template</label>
                    <Dropdown
                      :options="certificateTemplateOptions"
                      optionLabel="name"
                      v-model="certificates[idx].certificateTemplate"
                      :default="certificates[idx].certificateTemplate"
                      @change="
                        (opt) => {
                          certificates[idx].certificate_template_id = opt.id
                          certificates[idx].certificateId = opt.id
                        }
                      "
                      placeholder="Select"
                    />
                  </div>
                </div>
              </div>
              <div class="flex justify-center" v-if="certificates.length > 0">
                <Button :buttonText="certificates.length > 0 ? 'Add New Certificate' : 'Add New Certificate'" size="compact" type="tertiary" @action="addMoreItem(certificates, 'certifTypeId')" />
              </div>
            </div>
            <div class="flex justify-center" v-else>
              <Button :buttonText="certificates.length > 0 ? 'Add New Certificate' : 'Add New Certificate'" size="compact" type="tertiary" @action="addMoreItem(certificates, 'certifTypeId')" />
            </div>
          </div>
        </div>
      </template>
      <template v-if="selectedTab === 'Activities'">
        <div class="p-6">
          <div class="text-center py-16" v-if="activities.length === 0">
            <div class="flex justify-center items-center">
              <img src="@/assets/images/empty.svg" />
            </div>
            <p class="font-bold text-neutral-500 mb-3">This class doesn't have activity template</p>
            <Button buttonText="Create New" @action="addNewActivity()" />
          </div>
          <div class="" v-else>
            <div v-for="(activity, index) in activities" :key="'activity-' + index">
              <ActivityForm
                :key="refresh"
                :isFirstIndex="index === 0"
                :isLastIndex="index === activities.length - 1"
                :data="activity"
                :index="index"
                :classId="selectedClass && selectedClass.id"
                :ref="'activityForm' + index"
                :primaryInstructor="selectedInstructor"
                isTemplateAct
                enableFirstAct
                @changePosition="changePosition('activity', ...arguments)"
                @changePositionChild="changePosition('child', ...arguments)"
                @delete="openDeleteModal('activity', ...arguments)"
                @deleteChild="openDeleteModal('child', ...arguments)"
                @deleteInstructorActivityItem="deleteInstructorActivityItem"
              />
            </div>
            <Button buttonText="Add New Activity" type="secondary" @action="addNewActivity()" />
          </div>
        </div>
      </template>
      <template v-if="selectedTab === 'Schedule'">
        <div class="w-full p-6">
          <div v-if="list_jadwalkelas.length > 0">
            <TableComponent :columns="columns" :list="list_jadwalkelas">
              <template slot="table-row" slot-scope="{ entry, column }">
                <div v-if="column.field === 'start_date'">
                  <span v-if="entry.start_date">
                    <span class="block whitespace-nowrap">
                      {{ formatDate(entry.start_date).date }}
                    </span>
                    <span class="block text-xs whitespace-nowrap font-normal">
                      {{ formatDate(entry.start_date).time }}
                    </span>
                  </span>
                  <span v-else>-</span>
                </div>
                <div v-else-if="column.field === 'batch'">
                  {{ typeof entry.batch !== 'undefined' ? entry.batch : '-' }}
                </div>
                <div v-else-if="column.field === 'nama'">
                  <router-link class="text-yellow" :to="{ path: '/jadwalkelas', query: { id: entry.id } }">{{ entry.nama }}</router-link>
                </div>
                <div v-else>{{ entry[column.field] }}</div>
              </template>
            </TableComponent>
            <div class="mt-6 flex justify-center items-center">
              <Pagination :currentPage="currentSchedulePage" :totalCount="totalRecords" :pageSize="schedulePageSize" @onPageChange="onPageChange" />
            </div>
          </div>
          <div v-else class="w-full h-60 border border-neutral-250 rounded-lg text-sm bg-white flex justify-center items-center">
            <p>No schedule available</p>
          </div>
        </div>
      </template>
      <template v-if="selectedTab === 'Settings'">
        <div class="w-full p-6">
          <div class="mb-6 mt-2">
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
              <span class="-ml-1"><Label color="yellow" /></span>Settings
            </p>
            <div class="pl-5">
              <div class="leading-0 mb-2">
                <input type="checkbox" id="archived" v-model="archived" class="styled-checkbox" />
                <label for="archived">Archived</label>
              </div>
              <div class="leading-0 mb-2">
                <input type="checkbox" id="autoCreateLmsClass" v-model="autoCreateLmsClass" class="styled-checkbox" />
                <label for="autoCreateLmsClass">Auto create LMS class</label>
              </div>
              <div class="leading-0">
                <input type="checkbox" id="requireAdministration" v-model="requireAdministration" class="styled-checkbox" />
                <label for="requireAdministration">Require administration</label>
              </div>
              <div class="leading-0 mt-2">
                <input type="checkbox" id="informationOnly" v-model="informationOnly" class="styled-checkbox" />
                <label for="informationOnly">Information Only</label>
              </div>
              <div class="leading-0 mt-2">
                <input type="checkbox" id="autoExpandActivity" v-model="autoExpandActivity" class="styled-checkbox" />
                <label for="autoExpandActivity">Auto-expand Activity in LMS Student</label>
              </div>
              <div class="leading-0 mt-2" v-if="programSelected === 'Prakerja'">
                <input type="checkbox" id="requirePmoRedeem" v-model="requirePmoRedeem" class="styled-checkbox" />
                <label for="requirePmoRedeem">Require PMO Redeem</label>
              </div>
              <div class="leading-0 mt-2" v-if="programSelected === 'Bootcamp' || programSelected === 'Short Course'">
                <input type="checkbox" id="requireCheckLmsClass" v-model="requireCheckLmsClass" class="styled-checkbox" />
                <label for="requireCheckLmsClass">Enable Waiting List feature</label>
              </div>
              <div class="leading-0 mt-2">
                <input type="checkbox" id="requireMidtrans" v-model="requireMidtrans" class="styled-checkbox" />
                <label for="requireMidtrans">Auto-send Midtrans Invoice</label>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <Modal :modalVisible="visibleDeleteModal" @close="closeDeleteModal()" width="640px" id="delete">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-3">Are you sure to delete this {{ selectedTypeToBeDeleted }}?</p>
          <p class="text-sm mb-4">This action can not be undone</p>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Delete" type="secondary" @action="deleteActivityItem()" />
            <Button buttonText="Cancel" type="primary" @action="closeDeleteModal()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="classVariantDetailVisible" @close="toggleClassVariantModal()" id="class-variant-in-core-class">
      <template slot="modal-content">
        <div class="text-left mt-6">
          <p class="text-2xl font-bold mb-3">Edit Variant</p>
          <div class="border-b border-neutral-50 pb-4 mb-3 space-y-3">
            <h1 class="font-semibold text-grey-2">Variant Attribute</h1>
            <div v-if="isCreateNewClassVariants" class="grid gap-2" :class="[`${classVariantSelected.attributeCombination.length > 1 ? 'grid-cols-2' : 'grid-cols-1'}`]">
              <TextField
                disabledWhite
                :label="`${classVariantSelected.attributeCombination.length > 1 ? `Attribute ${variantModalIdx + 1}` : 'Attribute'}`"
                v-for="(variantModal, variantModalIdx) in classVariantSelected.attributeCombination"
                :key="'variantModalIdx' + variantModalIdx"
                type="text"
                v-model="classVariantSelected.attributeCombination[variantModalIdx]"
                borderEnabled
              />
            </div>
            <div v-else class="grid gap-2" :class="[`${classVariantSelected.attributeCombination.length > 1 ? 'grid-cols-2' : 'grid-cols-1'}`]">
              <TextField
                disabledWhite
                :label="`${classVariantSelected.attributeCombination.length > 1 ? `Attribute ${variantModalIdx + 1}` : 'Attribute'}`"
                v-for="(variantModal, variantModalIdx) in classVariantSelected.attributeCombination"
                :key="'variantModalIdx' + variantModalIdx"
                type="text"
                v-model="classVariantSelected.attributeCombination[variantModalIdx].valueName"
                borderEnabled
              />
            </div>
            <TextField label="Variant Name" type="text" v-model="classVariantSelected.variantName" borderEnabled />
            <Dropdown
              label="Status"
              notes="Only Active Variant will be shown on class detail page"
              :options="classVariantStatusOptions"
              optionLabel="name"
              v-model="classVariantSelected.active"
              :default="classVariantSelected.active"
            />
          </div>
          <div class="border-b border-neutral-50 pb-4 mb-3 space-y-3">
            <p class="font-semibold text-grey-2">Pricing</p>
            <TextField label="Price" type="number" v-model="classVariantSelected.price" borderEnabled />
            <div class="grid grid-cols-2 gap-2">
              <TextField label="Promo Label" type="text" v-model="classVariantSelected.promoLabel" borderEnabled placeholder="Enter promo label" />
              <TextField label="Promo Price" type="number" v-model="classVariantSelected.promo" borderEnabled />
            </div>
          </div>
          <h1 class="font-semibold text-grey-2">Web Content</h1>
          <p class="text-xs text-gray-500">If left empty, content from Bilingual menu will be used</p>
          <div class="py-3 mb-3 space-y-3">
            <TextField label="Duration (optional)" type="text" placeholder="Insert class duration" v-model="classVariantSelected.duration" borderEnabled />
            <TextField label="Weekly Schedule (optional)" type="text" placeholder="Insert weekly schedule" v-model="classVariantSelected.weeklySchedule" borderEnabled />
            <TextField label="Location (optional)" type="text" placeholder="Insert location" v-model="classVariantSelected.location" borderEnabled />
          </div>
          <div class="flex justify-end items-center gap-4 sticky bottom-0 bg-white pt-4 border-t border-neutral-250">
            <Button buttonText="Cancel" type="tertiary" @action="toggleClassVariantModal()" />
            <Button buttonText="Save" type="primary" @action="toggleClassVariantModal()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { showVueToast } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import ImageUploadMixin from '@/mixins/image-upload'
export default {
  mixins: [ImageUploadMixin],
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    ActivityForm: () => import(/* webpackChunkName: "ActivityForm" */ '@/views/jadwalkelas/ActivityForm'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Upload: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Upload'),
    TableComponent: () => import(/* webpackChunkName: "Simple-Table" */ '@/components/Table/SimpleTable'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    CurrencyInput: () => import(/* webpackChunkName: "CurrencyInput" */ '@/components/Form/CurrencyInput'),
    Calendar: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Calendar'),
    AlertCircle: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/AlertCircle'),
    WebContent: () => import(/* webpackChunkName: "WebContent" */ '@/views/kelas/WebContent')
  },
  data() {
    return {
      columns: [
        { label: 'Class Schedule', field: 'nama' },
        { label: 'Batch', field: 'batch' },
        { label: 'Participants', field: 'jumlah_peserta' },
        { label: 'Start Time', field: 'start_date' }
      ],
      program: { code: 'Prakerja', name: 'Prakerja' },
      excludedProgramTypeForExternal: ['Bootcamp', 'International', 'Short Course', 'clouducation-pre-event', 'clouducation-main-event', 'Event'],
      archived: false,
      informationOnly: false,
      autoExpandActivity: true,
      requireMidtrans: false,
      autoCreateLmsClass: false,
      requireAdministration: false,
      requirePmoRedeem: false,
      requireCheckLmsClass: false,
      code: '',
      name: '',
      types: [],
      maxParticipants: '0',
      minParticipants: '0',
      description: '',
      videos: [],
      quizes: [],
      instructors: [],
      conditions: [],
      error: [],
      listQuiz: [],
      listInstructor: [],
      listClass: [],
      programOptions: [],
      layoutOptions: [
        {
          name: 'Event',
          value: 'EVENT_LAYOUT'
        },
        {
          name: 'Class',
          value: 'CLASS_LAYOUT'
        },
        {
          name: 'Class + Schedule',
          value: 'CLASS_SCHEDULE_LAYOUT'
        },
        {
          name: 'Collaboration',
          value: 'COLLABORATION'
        }
      ],
      listTimeParameter: [
        { id: 'YEAR', name: 'Year(s)' },
        { id: 'MONTH', name: 'Month(s)' },
        { id: 'DAY', name: 'Day(s)' }
      ],
      generalParams: { limit: 600, sort: '' },
      selectedTab: 'General',
      quillOptions: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['link'], ['clean']]
        }
      },
      scheduledPrices: [],
      listPromoType: [
        {
          name: 'Lifetime',
          code: 'LIFETIME'
        },
        {
          name: 'Scheduled Promo',
          code: 'SCHEDULEDPROMO'
        }
      ],
      listPromoDays: [1, 3, 7, 14, 21, 30],
      promoPriceValue: false,
      promoPriceTime: false,
      promoPriceDate: false,
      errorPromoPrice: false,
      descriptionMaxLength: 5000,
      payload: {
        name: '',
        location: '',
        type: '',
        price: 0,
        slug: '',
        schedule: '',
        scheduleFrom: '',
        scheduleTo: '',
        durationText: '',
        shortDescriptionText: '',
        techStacksText: '',
        benefitText: '',
        categoryText: '',
        classValue: '',
        description: '',
        prasyarat: '',
        note: '',
        customContent: '',
        url1: '',
        url2: '',
        knowledgeCompetence: '',
        skillCompetence: '',
        attitudeCompetence: '',
        targetParticipant: '',
        purpose: '',
        capacity: '',
        learningMethod: '',
        certificateClarification: '',
        activity: '',
        layoutType: {
          name: 'Class',
          value: 'CLASS_LAYOUT'
        }
      },
      syllabusDocument: '',
      // syllabusItems: [],
      historyAlumniClasses: [],
      certificateCompetence: [],
      certificates: [],
      certificateTypeOptions: [],
      certificateTemplateOptions: [],
      certificateTemplateOptionsId: '',
      currentSchedulePage: 1,
      schedulePageSize: 10,
      selectedClass: null,
      selectedInstructor: null,
      selectedIndexToBeDeleted: null,
      selectedTypeToBeDeleted: null,
      refresh: false,
      visibleDeleteModal: false,
      temporaryDisabled: false,
      isActivitiesAccord: true,
      activities: [],
      coreClassPaymentMethod: [],
      paymentMethodSelected: [],
      coreClassSoftwareTool: [],
      coreClassInstructors: [],
      instructorOptions: [],
      classAttributeOptions: [],
      classVariants: [],
      staticColumnVariantTable: [
        { label: 'Variant Name', field: 'variantName' },
        { label: 'Price', field: 'price' },
        { label: 'Promo', field: 'promo' },
        { label: 'Status', field: 'active' }
      ],
      dynamicColumnVariantTable: [],
      classVariantDataTable: [],
      classVariantDetailVisible: false,
      classVariantStatusOptions: [
        {
          name: 'Active',
          value: true
        },
        {
          name: 'Inactive',
          value: false
        }
      ],
      classVariantSelected: {
        attributeCombination: [
          {
            valueId: 1,
            valueName: 'Value 1'
          }
        ],
        variantName: '',
        price: 0,
        promo: 0,
        promoLabel: '',
        active: true,
        duration: '',
        weeklySchedule: 'Monday,Friday',
        location: '',
        seqNo: 0
      },
      variantNameClone: '',
      attributeItemsList: [],
      isCreateNewClassVariants: true,
      coreClassVariantDataFromAPI: []
    }
  },
  created() {
    window.addEventListener('beforeunload', this.leaving)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.leaving)
  },
  watch: {
    program: function () {
      if (!this.isEditMode) {
        this.program.code === 'Prakerja' ? (this.autoExpandActivity = true) : (this.autoExpandActivity = false)
      }
    },
    activities: {
      handler: function (value) {
        if (value.length < 1) {
          this.isActivitiesAccord = true
        } else {
          for (let i = 0; i < value.length; i++) {
            if (!value[i].title) {
              this.isActivitiesAccord = false
              break
            } else {
              for (let j = 0; j < value[i].activityItems.length; j++) {
                if (!value[i].activityItems[j].title || !value[i].activityItems[j].description || !value[i].activityItems[j].activityItemType) {
                  this.isActivitiesAccord = false
                  break
                } else {
                  this.isActivitiesAccord = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    scheduledPrices: {
      handler: function () {
        if (this.scheduledPrices.length > 0) {
          this.scheduledPrices.forEach((item) => {
            if (!item.withTime && item.scheduleType?.code === 'SCHEDULEDPROMO' && (!item.startDate || !item.endDate)) {
              this.promoPriceTime = true
            } else {
              this.promoPriceTime = false
            }
            if ((item.price && !this.payload.price) || item.price >= this.payload.price) {
              this.promoPriceValue = true
            } else {
              this.promoPriceValue = false
            }
            if (moment(item.startDate).isAfter(item.endDate)) {
              this.promoPriceDate = true
            } else {
              this.promoPriceDate = false
            }
          })
        } else {
          this.promoPriceTime = false
          this.promoPriceValue = false
          this.promoPriceDate = false
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('common', ['testimonyList']),
    ...mapGetters('jadwalkelas', ['list_jadwalkelas', 'paginate']),
    columnsTableClassVariant() {
      const joinColumn = this.dynamicColumnVariantTable.concat(this.staticColumnVariantTable)
      const actionColumn = {
        label: 'Actions',
        field: 'btn',
        position: 'right',
        sticky: true
      }
      joinColumn.push(actionColumn)
      return joinColumn
    },
    isInstructorNotEmpty() {
      let result = false
      for (let i = 0; i < this.coreClassInstructors.length; i++) {
        if (!this.coreClassInstructors[i]) {
          result = false
          break
        } else {
          result = true
        }
      }
      return result
    },
    disableSubmit() {
      let result = true
      if (this.name && this.maxParticipants && `${this.maxParticipants}` !== '0' && this.isInstructorNotEmpty && this.payload.slug && this.isActivitiesAccord) {
        result = false
      }
      return result
    },
    programSelected() {
      return this.program?.code
    },
    mappingDayName() {
      return this.listPromoDays.map((item) => {
        if (item === 1) {
          return {
            name: item + ' day before',
            value: item
          }
        } else {
          return {
            name: item + ' days before',
            value: item
          }
        }
      })
    },
    id() {
      return this.$route.params.id
    },
    isEditMode() {
      return this.$route.meta.mode === 'Edit'
    },
    metaName() {
      return this.$route.meta.name
    },
    customerId() {
      return localStorage.getItem('client')
    },
    totalRecords: function () {
      return this.paginate.total ? this.paginate.total : 0
    }
  },
  mounted() {
    this.initProcess(true)
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading', 'getTestimonyList']),
    ...mapActions('jadwalkelas', ['GET_LIST_JADWALKELAS']),
    ...mapActions('instructur', ['GET_LIST_INSTRUCTUR_V3', 'GET_INSTRUCTUR']),
    ...mapActions('kelas', ['GET_CLASS_ATTRIBUTE_LIST']),
    initProcess(isFirstLoadData) {
      this.getListExercise()
      this.getListInstructor()
      this.getListClass()
      this.getAllTestimony()
      this.getListCertificateTemplate()
      this.getListCertificateType()
      this.getClassAttributes()
      if (this.isEditMode) {
        this.getDataClass(isFirstLoadData)
        this.getAllClassSchedule()
      } else {
        this.getListProgram()
      }
    },
    toggleClassVariantModal(variant) {
      if (variant) {
        const copyVariant = { ...variant }
        this.variantNameClone = copyVariant.variantName
        this.classVariantSelected = variant
        this.classVariantSelected.active = this.classVariantStatusOptions.filter((item) => item.value === variant.active)[0]
      } else {
        const getIndex = this.classVariantDataTable.findIndex((item) => item.seqNo === this.classVariantSelected.seqNo)
        this.classVariantDataTable[getIndex].active = this.classVariantSelected.active.value
        this.classVariantSelected.variantName = this.classVariantSelected.variantName ? this.classVariantSelected.variantName : this.variantNameClone
      }
      this.classVariantDetailVisible = !this.classVariantDetailVisible
    },
    addNewVariant() {
      this.classVariants.push(null)
    },
    deleteVariant(classVariant, index) {
      classVariant.splice(index, 1)
      this.handleClassVariantChange()
    },
    generateCombinations(arrays) {
      if (!Array.isArray(arrays) || arrays.length === 0) {
        return []
      }

      function combine(current, index) {
        if (index === arrays.length) {
          result.push(current.slice())
          return
        }

        for (let i = 0; i < arrays[index].length; i++) {
          current.push(arrays[index][i])
          combine(current, index + 1)
          current.pop()
        }
      }

      const result = []
      combine([], 0)
      return result
    },
    handleClassVariantChange() {
      // handle duplicate value
      if (!this.isListNoDuplicate(this.classVariants, 'attributeName')) {
        this.classVariants = this.removeDuplicateItemFromArray(this.classVariants)
        showVueToast('Class variant cant duplicate!', 'error', 2000)
        return
      }
      this.isCreateNewClassVariants = true
      // handle dynamic column table
      this.dynamicColumnVariantTable = this.classVariants.map((variant, variantIdx) => {
        return { label: `Att ${variantIdx + 1}`, field: `att${variantIdx + 1}` }
      })

      // get all attribute item from class attribute that selected
      this.attributeItemsList = this.mergeAttributeItems(this.classVariants)
      // get attribute value only
      const attributeValues = this.classVariants.map((variant) => {
        return this.handleReturnOneValue(variant.attributeItems, 'attributeValue')
      })

      // generate combination from attribute value selected
      const attributeCombination = this.generateCombinations(attributeValues)

      // NEW
      this.classVariantDataTable = attributeCombination.map((att, attIdx) => {
        const constructPayload = {
          price: 0,
          promo: 0,
          variantName: att.join(' '),
          promoLabel: '',
          active: true,
          duration: '',
          weeklySchedule: '',
          location: '',
          seqNo: attIdx,
          attributeCombination: att
        }
        att.map((val, idx) => {
          constructPayload[`att${idx + 1}`] = val
        })
        return constructPayload
      })
    },
    mergeAttributeItems(objects) {
      const result = []
      objects.forEach((item) => {
        result.push(...item.attributeItems)
      })
      return result
    },
    handleReturnOneValue(arr, property) {
      return arr.map((val) => val[property])
    },
    isListNoDuplicate(objectItem, property) {
      if (objectItem.length === 1) return true
      const uniqueValues = new Set()
      return objectItem.every((item) => {
        if (!uniqueValues.has(item[property])) {
          uniqueValues.add(item[property])
          return true
        }
        return false
      })
    },
    removeDuplicateItemFromArray(arr) {
      const uniqueObjects = Array.from(new Set(arr.map((obj) => JSON.stringify(obj))))
      return uniqueObjects.map((str) => JSON.parse(str))
    },
    getClassAttributes() {
      const params = {
        page: 0,
        size: 200,
        sortBy: 'createdDate',
        direction: 'ASC',
        attributeName: '',
        attributeValue: ''
      }
      this.GET_CLASS_ATTRIBUTE_LIST({ params, customerId: this.customerId }).then((res) => {
        if (res?.data?.code === 200) {
          this.classAttributeOptions = res.data.data
        }
      })
    },
    leaving(e) {
      e.returnValue = 'You have some unsaved changes'
      return 'You have some unsaved changes'
    },
    toClassListMenu() {
      this.$router.push('/kelas')
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    onPageChange(page) {
      this.currentSchedulePage = page
      this.getAllClassSchedule()
    },
    getAllClassSchedule() {
      this.showLoading()
      this.GET_LIST_JADWALKELAS({
        customerId: this.customerId,
        params: queryString.stringify({
          core_class_id: this.id,
          limit: this.schedulePageSize,
          name: '',
          page: this.currentSchedulePage - 1
        })
      }).then(() => {
        this.hideLoading()
      })
    },
    getAllTestimony() {
      this.getTestimonyList()
    },
    deleteItem(objContent, idx) {
      objContent.splice(idx, 1)
    },
    handleFileSelect(evt, item, field) {
      this.uploadImage(evt, item, field)
    },
    changeImage(item) {
      this.$refs[item].click()
    },
    minimalstartDate(index) {
      if (index === 0) {
        return moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
      } else {
        return moment(this.scheduledPrices[index - 1].endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
    },
    handlePaymentMethodSelected(value) {
      this.coreClassPaymentMethod = value
    },
    handleSyllabusDocumentMutate(value) {
      this.syllabusDocument = value
    },
    changeCheck(idx) {
      this.scheduledPrices[idx].withTime = !this.scheduledPrices[idx].withTime
      if (this.scheduledPrices[idx].withTime === false) {
        this.scheduledPrices[idx].startDate = ''
        this.scheduledPrices[idx].endDate = ''
      }
    },
    forDisableStartDate(index) {
      return index === 0 ? false : this.scheduledPrices[index - 1].endDate === ''
    },
    addMoreItem(objContent, type) {
      const obj = objContent
      let lastObj = {
        seqNo: 0
      }
      if (obj.length > 0) {
        lastObj = obj[obj.length - 1]
      }
      let item = {}
      switch (type) {
        case 'prices':
          item = {
            coreClassId: this.classId,
            price: 0,
            startDate: '',
            endDate: '',
            active: true,
            scheduleType: this.listPromoType[0],
            dayBeforeSchedule: 0,
            name: 'scheduled',
            category: 'scheduled',
            withTime: false
          }
          break
        case 'certifTypeId':
          const lastI = this.certificateTemplateOptions.length - 1
          item = {
            certificate_template_id: 0,
            certificate_type: 0,
            title: '',
            description: ''
          }
          break
        case 'certifCompetenceId':
          item = ''
          break
        default:
          item = {
            seqNo: lastObj.seqNo + 1
          }
          item[type] = ''
          break
      }
      obj.push(item)
    },
    selectTab(tab) {
      this.selectedTab = tab
    },
    addQuiz() {
      this.quizes.push('')
    },
    deleteQuiz(index) {
      this.quizes.splice(index, 1)
    },
    addVideo() {
      this.videos.push('')
    },
    deleteVideo(index) {
      this.videos.splice(index, 1)
    },
    addInstructor() {
      this.coreClassInstructors.push('')
      this.instructors.push('')
    },
    deleteInstructor(index) {
      this.coreClassInstructors.splice(index, 1)
      this.instructors.splice(index, 1)
    },
    addCondition() {
      const item = { or: [] }
      this.conditions.push(item)
    },
    deleteCondition(index) {
      this.conditions.splice(index, 1)
    },
    addChildConditionUHP(index) {
      const item = {
        userHasPassed: {
          coreClassId: ''
        }
      }
      this.conditions[index].or.push(item)
    },
    addChildConditionUHC(index) {
      const item = {
        userHasComplete: {
          coreClassId: ''
        }
      }
      this.conditions[index].or.push(item)
    },
    deleteChildCondition(index, childIndex) {
      this.conditions[index].or.splice(childIndex, 1)
    },
    addWithinTime(index, childIndex) {
      if (this.conditions[index].or[childIndex][this.withCondition(index, childIndex)].withinTime === undefined) {
        this.conditions[index].or[childIndex][this.withCondition(index, childIndex)].withinTime = {
          value: '1',
          type: 'YEAR'
        }
      } else {
        delete this.conditions[index].or[childIndex][this.withCondition(index, childIndex)].withinTime
      }
      this.$forceUpdate()
    },
    isDuplicateArray(payload) {
      let valueArr = payload.map(function (item) {
        return item.id
      })
      let isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx
      })
      return isDuplicate
    },
    mappingAttributeCombinationRequest(attributeCombination) {
      if (this.isCreateNewClassVariants) {
        return attributeCombination.map((combine) => {
          const valueId = this.attributeItemsList.filter((item) => item.attributeValue === combine)[0].attributeValueId
          return {
            valueId
          }
        })
      }
      return attributeCombination.map((att) => {
        const valueId = att.valueId
        return {
          valueId
        }
      })
    },
    mappingCoreClassVariantRequest(classVariantDataTable) {
      if (classVariantDataTable.length === 0) return []
      return classVariantDataTable.map((variant) => {
        const attributeCombination = this.mappingAttributeCombinationRequest(variant.attributeCombination)
        const item = {
          attributeCombination,
          price: variant.price ? variant.price : 0,
          promo: variant.promo ? variant.promo : 0,
          active: variant.active,
          duration: variant.duration,
          weeklySchedule: variant.weeklySchedule,
          location: variant.location,
          seqNo: variant.seqNo,
          promoLabel: variant.promoLabel,
          variantName: variant?.variantName || ''
        }
        if (variant.variantId) item.variantId = variant.variantId
        if (!this.isCreateNewClassVariants) item.sku = variant.sku
        return item
      })
    },
    save() {
      this.showLoading()
      this.temporaryDisabled = true
      const preentryCondition = JSON.stringify({ and: this.conditions })
      const prices = JSON.parse(JSON.stringify(this.scheduledPrices))
      const pricesList = prices.map((item) => {
        return {
          ...item,
          startDate: item.startDate ? moment(item.startDate).format('YYYY-MM-DDTHH:mm:ss+0700') : '',
          endDate: item.endDate ? moment(item.endDate).format('YYYY-MM-DDTHH:mm:ss+0700') : '',
          price: isNaN(item.price) ? '' : parseInt(item.price),
          scheduleType: item.scheduleType?.code,
          dayBeforeSchedule: item.dayBeforeSchedule?.value ? item.dayBeforeSchedule?.value : 0
        }
      })
      const formattedSlug = this.payload.slug ? this.payload.slug.toLowerCase().trim() : ''
      let fixArrayActivities = []
      for (let i = 0; i < this.activities.length; i++) {
        fixArrayActivities.push({
          seqNo: i + 1,
          title: this.activities[i].title,
          isIndependent: this.activities[i].isIndependent,
          coreClassActivityItem: []
        })
        for (let j = 0; j < this.activities[i].activityItems.length; j++) {
          fixArrayActivities[i].coreClassActivityItem.push({
            ...this.activities[i].activityItems[j],
            seqNo: j + 1
          })
        }
      }

      const checkHistoryAlumniClasses = this.historyAlumniClasses.filter((alumni) => alumni)
      if (checkHistoryAlumniClasses.length !== this.historyAlumniClasses.length) {
        showVueToast('Fill in class testimony section correctly !', 'error', 2000)
        this.temporaryDisabled = false
        this.hideLoading()
        return
      }
      if (this.isDuplicateArray(this.historyAlumniClasses)) {
        showVueToast('Class testimony cannot be duplicated !', 'error', 2000)
        this.temporaryDisabled = false
        this.hideLoading()
        return
      }
      const constructedPayload = {
        coreClassActivity: fixArrayActivities,
        autoCreateLmsClass: this.autoCreateLmsClass,
        requireAdministration: this.requireAdministration,
        archived: this.archived,
        requireCheckLmsClass: this.requireCheckLmsClass,
        code: this.code,
        nama: this.name,
        max_peserta: this.maxParticipants,
        min_peserta: this.minParticipants,
        java_instructur_id_array: this.coreClassInstructors.map((instructor) => instructor.id),
        quiz_exercise_id_array: this.quizes.filter((quiz) => quiz),
        video_url_array: [],
        tugas_description: this.description,
        competences: this.certificateCompetence,
        deliveryTypes: this.types,
        program: this.program.code,
        preentryCondition,
        classValue: this.payload.classValue,
        description: this.payload.description,
        historyAlumniClasses: this.historyAlumniClasses.map((testimony, testimonyIdx) => {
          return {
            ...testimony,
            testimonyId: testimony.id,
            seqNo: testimonyIdx + 1
          }
        }),
        scheduledPrices: pricesList,
        note: this.payload.note,
        prasyarat: this.payload.prasyarat,
        price: isNaN(this.payload.price) ? '' : parseInt(this.payload.price),
        schedule: this.payload.schedule,
        scheduleFrom: this.payload.scheduleFrom,
        scheduleTo: this.payload.scheduleTo,
        location: this.payload.location,
        seqNo: 0,
        slug: formattedSlug,
        durationText: this.payload.durationText,
        shortDescriptionText: this.payload.shortDescriptionText,
        techStacksText: this.payload.techStacksText,
        benefitText: this.payload.benefitText,
        categoryText: this.payload.categoryText,
        syllabus: this.syllabusDocument,
        // sylabusClasses: this.syllabusItems,
        url1: this.payload.url1,
        url2: this.payload.url2,
        customContent: this.payload.customContent,
        knowledgeCompetence: this.payload.knowledgeCompetence,
        skillCompetence: this.payload.skillCompetence,
        attitudeCompetence: this.payload.attitudeCompetence,
        targetParticipant: this.payload.targetParticipant,
        purpose: this.payload.purpose,
        capacity: this.payload.capacity,
        learningMethod: this.payload.learningMethod,
        certificateClarification: this.payload.certificateClarification,
        activity: this.payload.activity,
        layoutType: this.payload.layoutType.value,
        certificateCompetence: this.certificateCompetence,
        certificates: this.certificates,
        autoExpandActivity: this.autoExpandActivity,
        informationOnly: this.informationOnly,
        requirePmoRedeem: this.requirePmoRedeem,
        requireMidtrans: this.requireMidtrans,
        coreClassPaymentMethod: this.coreClassPaymentMethod,
        coreClassSoftwareTool: this.coreClassSoftwareTool
          .filter((tool) => tool.softwareToolUrl)
          .map((tool) => {
            delete tool.softwareToolFileName
            return { ...tool }
          }),
        content: []
      }
      // mapping data from class variant
      constructedPayload.coreClassVariants = this.mappingCoreClassVariantRequest(this.classVariantDataTable)
      constructedPayload.scheduledPrices.forEach((item) => {
        if (item.price === 0 || !item.price) {
          this.errorPromoPrice = true
          setTimeout(() => (this.errorPromoPrice = false), 3000)
          return
        } else if ((item.price && !constructedPayload.price) || item.price >= constructedPayload.price) {
          this.promoPriceValue = true
        } else {
          this.promoPriceValue = false
          this.errorPromoPrice = false
        }
      })
      if (this.promoPriceTime) {
        showVueToast('Promo date is invalid!', 'error', 3000)
        return
      } else if (this.errorPromoPrice) {
        this.errorPromoPrice = true
        setTimeout(() => (this.errorPromoPrice = false), 3000)
        return
      } else if (this.promoPriceDate) {
        showVueToast('Please set the date of promo price correctly!', 'error', 3000)
        return
      } else if (this.promoPriceValue) {
        showVueToast('Please set the date of promo price correctly!', 'error', 3000)
        return
      } else {
        if (this.isEditMode) {
          this.showLoading()
          constructedPayload.id = this.id
          this.$store
            .dispatch('kelas/UPDATE_KELAS', {
              masterType: this.metaName,
              masterId: this.id,
              customerId: this.customerId,
              payload: constructedPayload
            })
            .then((resp) => {
              this.hideLoading()
              this.temporaryDisabled = false
              if (resp.data.status === '200 OK') {
                showVueToast('The process is successful!', 'success', 3000)
                this.initProcess()
                this.selectTab('General')
              } else {
                showVueToast('Request is Failed', 'error', 3000)
              }
            })
            .catch((error) => {
              this.hideLoading()
              this.temporaryDisabled = false
              showVueToast(error?.errors?.error || 'Request is Failed', 'error', 3000)
            })
        } else {
          this.showLoading()
          this.$store
            .dispatch('kelas/CREATE_KELAS', {
              masterType: this.metaName,
              masterId: this.id,
              payload: constructedPayload,
              customerId: this.customerId
            })
            .then((resp) => {
              this.hideLoading()
              this.temporaryDisabled = false
              showVueToast('The process is successful!', 'success', 3000)
              if (resp.data.status === '200 OK') this.toClassListMenu()
            })
            .catch((error) => {
              this.hideLoading()
              this.temporaryDisabled = false
              showVueToast(error.errors.error, 'error', 3000)
            })
        }
      }
    },
    getListExercise() {
      let paramsTemp = { limit: 600, sort: '', kelas_id: this.id }
      this.$store
        .dispatch('exercise/GET_LIST_EXERCISE', {
          masterType: this.metaName,
          params: queryString.stringify(paramsTemp),
          customerId: this.customerId
        })
        .then(() => {
          let list_exercise = this.$store.getters['exercise/list_exercise'] ? this.$store.getters['exercise/list_exercise'] : []
          this.listQuiz = []
          for (var j = 0; j < list_exercise.length; j++) {
            if (list_exercise[j].exercise_type_id === 2) {
              this.listQuiz.push(list_exercise[j])
            }
          }
        })
        .catch(function () {})
    },
    getInstructorList(keyword) {
      const params = queryString.stringify({
        direction: 'DESC',
        page: 0,
        size: 10,
        keyword,
        program: null,
        sortBy: 'createdDate'
      })

      this.GET_LIST_INSTRUCTUR_V3({ params, customerId: this.customerId }).then((res) => {
        if (res?.data?.code === 200) {
          this.instructorOptions = res?.data?.data
        }
      })
    },
    getInstructorDetail(instructorId) {
      return new Promise((resolve, reject) => {
        this.GET_INSTRUCTUR({ masterId: instructorId, customerId: this.customerId }).then((res) => {
          if (res?.data?.code === 200) {
            resolve(res?.data?.data)
          }
        })
      })
    },
    getListInstructor(keyword) {
      this.$store
        .dispatch('instructur/GET_LIST_INSTRUCTUR', {
          masterType: this.metaName,
          params: queryString.stringify({
            size: 500,
            page: 0,
            direction: 'DESC',
            program: '',
            sortBy: 'createdDate',
            keyword
          }),
          customerId: this.customerId
        })
        .then((response) => {
          this.listInstructor = response.data.data
        })
    },
    getListCertificateType() {
      this.$store.dispatch('kelas/GET_LIST_CERTIFICATE_TYPE').then((resp) => {
        this.certificateTypeOptions = resp.data.data
      })
    },
    getListCertificateTemplate() {
      this.$store
        .dispatch('kelas/GET_LIST_CERTIFICATE_TEMPLATE', {
          masterType: this.metaName,
          params: queryString.stringify({
            ...{ page: 1, customerId: this.customerId, size: 100 }
          })
        })
        .then((response) => {
          const name = response.data.data.map((x) => ({
            ...x,
            name: x.template_name
          }))
          this.certificateTemplateOptions = name
        })
    },
    getListClass() {
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          masterType: this.metaName,
          params: queryString.stringify({
            ...{ page: 1, nama: '', name: null, program: '' },
            ...this.generalParams
          }),
          customerId: this.customerId
        })
        .then((response) => {
          this.listClass = response.data.data
        })
    },
    getListProgram() {
      this.$store.dispatch('kelas/GET_LIST_PROGRAM').then((response) => {
        const currentData = JSON.parse(JSON.stringify(response.data.data))
        if (this.customerId == 1) {
          this.programOptions = response.data.data
        } else {
          for (let i = currentData.length - 1; i >= 0; i--) {
            const obj = currentData[i]
            if (this.excludedProgramTypeForExternal.includes(obj.code)) {
              currentData.splice(i, 1)
            }
          }
          this.programOptions = currentData
        }
      })
    },
    readFileNameFromAWSLink(link) {
      if (link) return link.substring(66, link.length)
      return ''
    },
    getCoreClassVariants(coreClassVariant) {
      const attributeCombination = []
      // get attribute combintaion to find class attribute
      coreClassVariant.forEach((variant) => {
        attributeCombination.push(...variant.attributeCombination)
      })
      // remove duplicate value
      const uniqueValues = Array.from(new Set(attributeCombination.map((a) => a.attributeId))).map((id) => {
        return attributeCombination.find((a) => a.attributeId === id)
      })
      // sync data from class attribute options
      const classAttribute = uniqueValues.map((uniqueItem) => {
        const mappingData = this.classAttributeOptions.filter((item) => item.attributeId === uniqueItem.attributeId)[0]
        return mappingData
      })
      return classAttribute
    },
    mappingCoreClassVariantTableData(coreClassVariants) {
      return coreClassVariants.map((variant) => {
        const getCombination = this.handleReturnOneValue(variant.attributeCombination, 'valueName')
        getCombination.map((val, idx) => {
          variant[`att${idx + 1}`] = val
        })
        variant.variantName = variant?.variantName ? variant?.variantName : getCombination.join(' ')
        return {
          ...variant
        }
      })
    },
    getDataClass(isFirstLoadData) {
      this.showLoading()
      this.$store
        .dispatch('kelas/GET_KELAS', {
          masterType: this.metaName,
          masterId: this.id,
          customerId: this.customerId
        })
        .then((resp) => {
          this.hideLoading()
          if (resp.data.code === 200) {
            let tempData = resp.data.data
            this.program = tempData?.program ? this.toUserFriendly(tempData.program) : { code: 'Prakerja', name: 'Prakerja' }
            if (tempData.coreClassVariants.length && isFirstLoadData) {
              this.classVariants = this.getCoreClassVariants(tempData.coreClassVariants)
              this.classVariantDataTable = this.mappingCoreClassVariantTableData(tempData.coreClassVariants)
              this.dynamicColumnVariantTable = this.classVariants.map((variant, variantIdx) => {
                return { label: `Att ${variantIdx + 1}`, field: `att${variantIdx + 1}` }
              })
              // get all attribute item from class attribute that selected
              this.attributeItemsList = this.mergeAttributeItems(this.classVariants)
              this.isCreateNewClassVariants = false
              this.coreClassVariantDataFromAPI = JSON.parse(JSON.stringify(tempData.coreClassVariants))
            }
            this.autoCreateLmsClass = tempData?.autoCreateLmsClass
            this.requireAdministration = tempData.requireAdministration
            this.requirePmoRedeem = tempData.requirePmoRedeem
            this.requireMidtrans = tempData.requireMidtrans
            this.autoExpandActivity = tempData.autoExpandActivity
            this.informationOnly = tempData.informationOnly
            this.archived = tempData.archived
            this.requireCheckLmsClass = tempData.requireCheckLmsClass
            this.code = tempData.code
            this.name = tempData.nama
            this.maxParticipants = tempData.max_peserta
            this.minParticipants = tempData.min_peserta
            this.quizes = []
            for (let i = 0; i < tempData.quiz_exercise_id_array.length; i++) {
              this.quizes.push(tempData.quiz_exercise_id_array[i])
            }
            this.videos = []
            for (let j = 0; j < tempData.video_url_array.length; j++) {
              this.videos.push(tempData.video_url_array[j])
            }
            this.description = tempData.tugas_description
            this.instructors = []
            for (let k = 0; k < tempData.java_instructur_id_array.length; k++) {
              this.instructors.push(tempData.java_instructur_id_array[k])
            }
            this.coreClassInstructors = []
            tempData.java_instructur_id_array.map(async (instructorId) => {
              const instructorDetails = await this.getInstructorDetail(instructorId)
              this.coreClassInstructors.push(instructorDetails)
            })
            this.conditions = tempData.preentryCondition ? JSON.parse(tempData.preentryCondition).and : []
            this.types = tempData.deliveryTypes
            this.syllabusDocument = JSON.parse(JSON.stringify(tempData?.syllabus || ''))
            const testimoni = tempData.historyAlumniClasses && JSON.parse(JSON.stringify(tempData.historyAlumniClasses))
            this.historyAlumniClasses = testimoni.map((item, idx) => {
              return { ...item.testimony, seqNo: idx + 1, testimonyId: item.testimony.id }
            })
            const schedulePriceTemp = tempData.scheduledPrices && JSON.parse(JSON.stringify(tempData.scheduledPrices))
            const listPromo = this.listPromoType
            const prices = schedulePriceTemp.map((item) => {
              return {
                ...item,
                startDate: item.scheduleType === 'LIFETIME' || item.scheduleType === 'CLASSSCHEDULESDDAY' ? '' : moment(item.startDate).format('YYYY-MM-DDTHH:mm'),
                endDate: item.scheduleType === 'LIFETIME' || item.scheduleType === 'CLASSSCHEDULESDDAY' ? '' : moment(item.endDate).format('YYYY-MM-DDTHH:mm'),
                price: typeof item.price === 'undefined' ? '' : item.price,
                scheduleType: listPromo.find((items) => items.code == item.scheduleType),
                dayBeforeSchedule:
                  item.scheduleType === 'LIFETIME' || item.scheduleType === 'SCHEDULEDPROMO'
                    ? {
                        name: '' + 'days before',
                        value: item.dayBeforeSchedule
                      }
                    : {
                        name: item.dayBeforeSchedule === 1 ? item.dayBeforeSchedule + ' day before' : item.dayBeforeSchedule + ' days before',
                        value: item.dayBeforeSchedule
                      }
              }
            })
            this.scheduledPrices = prices
            this.coreClassPaymentMethod = JSON.parse(JSON.stringify(tempData.coreClassPaymentMethod))
            this.coreClassSoftwareTool = JSON.parse(JSON.stringify(tempData.coreClassSoftwareTool)).map((tool) => {
              return {
                ...tool,
                softwareToolFileName: this.readFileNameFromAWSLink(tool.softwareToolUrl)
              }
            })
            const layoutType = this.layoutOptions.filter((item) => item.value === tempData.layoutType)[0]
            const certifCompetence = tempData.competences && JSON.parse(JSON.stringify(tempData.competences))
            const certificate = tempData.certificates && JSON.parse(JSON.stringify(tempData.certificates))
            certificate.map((item) => {
              const templateName = this.certificateTemplateOptions.filter((x) => x.id == item.certificate_template_id)[0]?.name
              item.certificateId = item.id
              item.certificateTemplate = { name: templateName, code: templateName }
              item.certificateType = { name: item.certificate_type, code: item.certificate_type }
              return item
            })
            this.certificateCompetence = certifCompetence
            this.certificates = certificate
            this.payload = {
              location: tempData.location,
              price: typeof tempData.price === 'undefined' ? '' : tempData.price,
              slug: tempData.slug,
              schedule: tempData.schedule,
              scheduleFrom: tempData.scheduleFrom,
              scheduleTo: tempData.scheduleTo,
              durationText: tempData.durationText,
              shortDescriptionText: tempData.shortDescriptionText,
              techStacksText: tempData.techStacksText,
              benefitText: tempData.benefitText,
              categoryText: tempData.categoryText,
              classValue: tempData.classValue,
              description: tempData.description,
              prasyarat: tempData.prasyarat,
              note: tempData.note,
              customContent: tempData.customContent,
              url1: tempData.url1,
              url2: tempData.url2,
              knowledgeCompetence: tempData.knowledgeCompetence,
              skillCompetence: tempData.skillCompetence,
              attitudeCompetence: tempData.attitudeCompetence,
              targetParticipant: tempData.targetParticipant,
              purpose: tempData.purpose,
              capacity: tempData.capacity,
              learningMethod: tempData.learningMethod,
              certificateClarification: tempData.certificateClarification,
              activity: tempData.activity,
              layoutType: layoutType
            }

            this.activities = []
            for (let l = 0; l < tempData.coreClassActivity.length; l++) {
              this.activities.push({
                title: tempData.coreClassActivity[l].title,
                isIndependent: tempData.coreClassActivity[l].isIndependent,
                activityItems: []
              })
              for (let m = 0; m < tempData.coreClassActivity[l].coreClassActivityItem.length; m++) {
                this.activities[l].activityItems.push({
                  title: tempData.coreClassActivity[l].coreClassActivityItem[m].title ? tempData.coreClassActivity[l].coreClassActivityItem[m].title : null,
                  description: tempData.coreClassActivity[l].coreClassActivityItem[m].description ? tempData.coreClassActivity[l].coreClassActivityItem[m].description : null,
                  activityItemType: tempData.coreClassActivity[l].coreClassActivityItem[m].activityItemType ? tempData.coreClassActivity[l].coreClassActivityItem[m].activityItemType : null,
                  itemReferenceId: tempData.coreClassActivity[l].coreClassActivityItem[m].itemReferenceId ? tempData.coreClassActivity[l].coreClassActivityItem[m].itemReferenceId : null,
                  includeInCertificate: tempData.coreClassActivity[l].coreClassActivityItem[m].includeInCertificate
                    ? tempData.coreClassActivity[l].coreClassActivityItem[m].includeInCertificate
                    : false,
                  download_status: tempData.coreClassActivity[l].coreClassActivityItem[m].download_status ? tempData.coreClassActivity[l].coreClassActivityItem[m].download_status : false,
                  warned: tempData.coreClassActivity[l].coreClassActivityItem[m].warned ? tempData.coreClassActivity[l].coreClassActivityItem[m].warned : false
                })
              }
            }
          } else {
            showVueToast(resp.data.message, 'success', 3000)
          }
        })
    },
    forVModel(index, childIndex) {
      return this.conditions[index].or[childIndex][this.withCondition(index, childIndex)]
    },
    withCondition(index, childIndex) {
      return Object.keys(this.conditions[index].or[childIndex])[0]
    },
    isChecked(index, childIndex) {
      return this.conditions[index].or[childIndex][this.withCondition(index, childIndex)].withinTime !== undefined
    },
    toUserFriendly(program) {
      this.$store.dispatch('kelas/GET_LIST_PROGRAM').then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          if (program === response.data.data[i].code) {
            this.program = response.data.data[i]
          }
        }
      })
    },
    addNewActivity() {
      const item = {
        title: null,
        isIndependent: false,
        activityItems: [
          {
            title: null,
            description: null,
            activityItemType: null,
            itemReferenceId: null,
            includeInCertificate: false,
            download_status: false,
            warned: false
          }
        ]
      }
      this.activities.push(item)
    },
    changePosition(type, otherParams, index) {
      this.refresh = !this.refresh
      switch (type) {
        case 'activity':
          const activity = this.activities.splice(index, 1)[0]
          this.activities.splice(otherParams === 'up' ? index - 1 : index + 1, 0, activity)
          break
        case 'child':
          const childActivity = this.activities[index].activityItems.splice(otherParams.index, 1)[0]
          this.activities[index].activityItems.splice(otherParams.position === 'up' ? otherParams.index - 1 : otherParams.index + 1, 0, childActivity)
          break
      }
    },
    openDeleteModal(type, activityIndex, index) {
      this.visibleDeleteModal = true
      this.selectedIndexToBeDeleted = {
        child: index,
        parent: activityIndex
      }
      this.selectedTypeToBeDeleted = type === 'activity' ? 'activity' : 'sub-activity'
    },
    deleteInstructorActivityItem(activityIndex, activityItemIndex) {
      this.activities[activityIndex].activityItems[activityItemIndex].expert_id = null
    },
    closeDeleteModal() {
      this.visibleDeleteModal = false
      this.selectedIndexToBeDeleted = null
    },
    deleteActivityItem() {
      if (this.selectedIndexToBeDeleted && typeof this.selectedIndexToBeDeleted.child === 'undefined') {
        this.activities.splice(this.selectedIndexToBeDeleted.parent, 1)
      } else {
        this.activities[this.selectedIndexToBeDeleted.parent].activityItems.splice(this.selectedIndexToBeDeleted.child, 1)
      }
      this.closeDeleteModal()
    }
  }
}
</script>
<style lang="scss" scoped>
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }

  // Box.
  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #f7931e;
    border-radius: 4px;
    margin-right: 10px;
  }

  // Box hover
  &:hover + label:before {
    background: #f7931e;
    opacity: 0.4;
  }

  // Box checked
  &:checked + label:before {
    background: #f7931e;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.class-type {
  li {
    margin-bottom: 3px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.grid-layout {
  gap: 24px;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
}

.grid-layout2 {
  gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.ctnr {
  display: block;
  position: relative;
  cursor: pointer;
  height: 20px;
  --tw-text-opacity: 1;
  // color: rgba(156, 163, 175, var(--tw-text-opacity));
  color: #333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ctnr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #888888;
  border-radius: 5px;
}

.ctnr:hover input ~ .checkmark {
  background-color: rgb(243, 244, 246);
}

.ctnr input:checked ~ .checkmark {
  background-color: #f7931e;
  border: 1px solid #f7931e;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.ctnr input:checked ~ .checkmark:after {
  display: block;
}

.ctnr .checkmark:after {
  left: 5px;
  top: 1px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.date {
  &::v-deep .vdatetime-input {
    padding: 12px 44px 12px 12px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #d6d6d6;
    font-size: 16px;
    cursor: pointer;
    // pr-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg text-base
  }
}
</style>
