<template>
  <div class="p-6">
    <div class="font-bold text-3xl">Hello, {{ profile.companyName }}</div>
    <div class="mt-10">
      <div class="mb-2">
        <p class="font-semibold mb-4 flex gap-1 items-centerpy-1">
          <span class="-ml-1"><Label color="yellow" /></span>Profile
        </p>
      </div>
      <div class="bg-white p-6 flex w-11/12 rounded-lg text-base mb-6">
        <div class="font-semibold w-48">
          <p class="leading-10 text-sm">Company Name</p>
          <p class="leading-10 text-sm">PIC Name</p>
          <p class="leading-10 text-sm">Email</p>
          <p class="leading-10 text-sm">Brand Name</p>
        </div>
        <div class="font-normal w-64">
          <p class="leading-10 text-sm">{{ profile.companyName }}</p>
          <p class="leading-10 text-sm">{{ profile.picName }}</p>
          <p class="leading-10 text-sm">{{ profile.picEmail }}</p>
          <div class="h-10 leading-10">
            <p v-if="!edited.isEditInfo" class="leading-10 text-sm">{{ !profile.brandName ? '-' : profile.brandName }}</p>
            <TextField class="" v-model="profile.brandName" borderEnabled type="text" placeholder="Brand Name" v-else />
          </div>
        </div>
        <div class="font-semibold w-48 ml-10">
          <p class="leading-10 text-sm">Phone Number</p>
          <p class="leading-10 text-sm">Type</p>
          <p class="leading-10 text-sm">Registration Date</p>
          <button class="bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer mt-10" @click="editInfoAdmin(edited.isEditInfo)">
            {{ edited.isEditInfo ? 'Save' : 'Edit Info' }}
          </button>
        </div>
        <div class="font-normal w-64">
          <p class="leading-10 text-sm">{{ profile.phoneNumber }}</p>
          <p class="leading-10 text-sm capitalize">
            {{
              profile.roles.length == 0
                ? 'Empety Role'
                : profile.roles
                    .map((e) => e.toLowerCase())
                    .join(', ')
                    .replace(/_/g, ' ')
            }}
          </p>
          <p class="leading-10 text-sm">
            {{ moment(profile.createdDate).local().format('DD MMMM YYYY HH:mm') }}
          </p>
        </div>
      </div>
      <div class="mb-2">
        <p class="font-semibold mb-4 flex gap-1 items-centerpy-1">
          <span class="-ml-1"><Label color="yellow" /></span>Signer Info
        </p>
      </div>
      <div class="bg-white p-6 flex w-11/12 rounded-lg text-base mb-6">
        <div class="font-semibold w-48">
          <p class="leading-10 text-sm mt-1">Signer Name</p>
          <p class="leading-10 text-sm mt-3">Signer Title</p>
          <p class="leading-10 text-sm mt-3">Company Address</p>
        </div>
        <div class="font-normal w-64">
          <div v-if="!edited.isEditDetai" style="margin-top: 97px">
            <p class="leading-10 -mt-24">{{ !documentadmin.signerName ? '-' : documentadmin.signerName }}</p>
            <p class="leading-10 mt-3">{{ !documentadmin.signerTitle ? '-' : documentadmin.signerTitle }}</p>
            <p class="leading-10 mt-3">{{ !documentadmin.companyAddress ? '-' : documentadmin.companyAddress }}</p>
          </div>
          <div v-else style="margin-top: -35px">
            <TextField class="leading-10 mt-9" v-model="documentadmin.signerName" borderEnabled type="text" placeholder="Signer Name" />
            <TextField class="leading-10 mt-2" v-model="documentadmin.signerTitle" borderEnabled type="text" placeholder="Signer Title" />
            <TextField class="leading-10 mt-2" v-model="documentadmin.companyAddress" borderEnabled type="text" placeholder="Company Addres" />
          </div>
        </div>
        <div class="font-semibold w-48 ml-10">
          <p class="leading-10 text-sm mt-1">Company Logo</p>
          <button class="bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer mt-10" @click="editDetailAdmin(edited.isEditDetai)">
            {{ edited.isEditDetai ? 'Save' : 'Edit Detail' }}
          </button>
        </div>
        <div class="font-normal w-64">
          <input style="display: none" accept="image/jpg, image/png, image/webp" type="file" ref="photo" @change="onFileChange($event)" />
          <div class="border border-yellow-400 w-16 h-16 mt-1 rounded-lg -ml-6" v-on:click="edited.isEditDetai ? $refs.photo.click() : ''">
            <img v-if="documentadmin.companyLogoUrl !== ''" :src="documentadmin.companyLogoUrl" :class="`w-full h-full rounded-lg ${edited.isEditDetai ? 'cursor-pointer' : 'cursor-default'}`" />
            <p v-else :class="`text-center p-2 text-xs font-normal pt-4 ${edited.isEditDetai ? 'cursor-pointer' : 'cursor-default'}`">
              {{ !edited.isEditDetai ? 'No Image' : 'Change Photo' }}
            </p>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <p class="font-semibold mb-4 flex gap-1 items-center py-1">
          <span class="-ml-1"><Label color="yellow" /></span>Attachment
        </p>
      </div>
      <div class="bg-white w-11/12">
        <div class="profile__form-notes" style="margin-top: 20px; margin-left: 22px">
          <p>File Format: pdf,jpg/png</p>
          <span>!</span>
        </div>
        <div class="flex flex-wrap rounded-lg text-sm mb-11 p-6">
          <div class="w-full lg:w-1/2 -mt-6" v-for="(data, idx1) in formDataAttachment" :key="idx1">
            <div class="font-semibold w-full items-center leading-snug flex my-4" v-for="(doc, idx2) in data" :key="idx2">
              <p class="w-44">{{ doc.name }}</p>
              <p v-if="!formEdit && !doc.url" class="text-sm ml-2">-</p>
              <p :class="`py-2 px-2.5 font-bold text-sm text-yellow rounded-lg w-3/4  ${doc.photoName ? 'cursor-pointer' : ''}`" @click="toPreview(doc.url, doc.photoName)" v-if="!formEdit && doc.url">
                {{ doc.photoName ? doc.photoName : '-' }}
              </p>
              <input
                accept="image/jpeg, image/png, application/pdf, image/webp"
                type="file"
                :id="`inputFIle${idx1}${idx2}`"
                @change="onFileChangeAttachment($event, doc.key)"
                class="cursor-pointer text-yellow-400 text-sm hidden"
              />

              <button
                v-if="formEdit"
                :class="`${doc.choosed ? 'bg-green-400 text-white' : 'bg-yellow-secondary text-yellow'} py-2 px-4 font-bold text-sm rounded-lg cursor-pointer w-1/2 truncate ...`"
                :onclick="`document.getElementById('inputFIle${idx1}${idx2}').click()`"
              >
                {{ doc.url ? (doc.photoName ? doc.photoName : 'Choose File') : doc.photoName ? doc.photoName : 'Change Document' }}
              </button>
            </div>
            <div v-show="idx1 === 1">
              <button class="bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer mt-24 mb-8" @click="formEdit = true" v-if="!formEdit">Edit Documents</button>
              <button class="bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer mt-24 mb-8" @click="saveDocumentAttachment" v-else>Save</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mb-2">
        <label class="text-sm font-semibold">Activity</label>
      </div>
      <div class="bg-white py-9 px-220 rounded-lg w-11/12 text-base text-center">
        <img src="@/assets/amico.png" class="block mx-auto" />
        <p class="py-4 text-sm font-medium">Harap periksa dokumen yang telah kami kirimkan kepada Anda</p>
        <button class="bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer" @click="bukaDokument">Buka Dokumen</button>
      </div> -->
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { toModelingDataAttachmentForSave } from '@/utils/attachment'
import { mapActions } from 'vuex'
import axios from 'axios'
export default {
  data() {
    return {
      moment: moment,
      profile: {
        id: '',
        companyName: '',
        picName: '',
        phoneNumber: '',
        roles: [],
        createdDate: new Date(),
        brandName: ''
      },
      imageController: {
        changePhotoProfile: false
      },
      documentadmin: {
        companyAddress: '',
        companyLogoUrl: '',
        signerName: '',
        signerTitle: ''
      },
      apiImg: {
        uploadUrl: '',
        key: ''
      },
      fileName: '',
      typeImg: '',
      edited: {
        isEditDetai: false,
        isEditInfo: false
      },
      imageResponse: [],
      formDataAttachment: [
        [
          { name: '', url: '', photoName: '', key: '', choosed: false },
          { name: '', url: '', photoName: '', key: '', choosed: false },
          { name: '', url: '', photoName: '', key: '', choosed: false },
          { name: '', url: '', photoName: '', key: '', choosed: false }
        ],
        [
          { name: '', url: '', photoName: '', key: '', choosed: false },
          { name: '', url: '', photoName: '', key: '', choosed: false }
        ]
      ],
      formEdit: false,
      attachment: [],
      notDubbleClick: false,
      durationPopup: 2000,
      typeAttachment: '',
      documentName: ['KTP / SIM / Paspor / KITAS Direksi', 'Nomor Pokok Wajib Pajak (NPWP)', 'NIB/Izin Usaha', 'Domisili/PKKPR', 'Anggaran Dasar Direksi Terakhir', 'Other Document']
    }
  },
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label')
  },
  mounted() {
    this.getDataAdmin()
    this.getDataAttachment()
  },
  methods: {
    ...mapActions('client', [
      'SET_PHOTO',
      'SET_ADMIN_DATA_INFO',
      'SET_ADMIN_DATA_ATTACHMENT',
      'GET_ADMIN_DATA',
      'GET_ADMIN_DATA_INFO',
      'GET_URL_API_IMG',
      'GET_ATTACHMENT_DATA_INFO',
      'SET_ADMIN_DATA_BRAND_NAME'
    ]),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    bukaDokument() {
      this.$router.push('/document-client')
    },
    async editInfoAdmin(val) {
      if (!val) {
        this.edited.isEditInfo = true
        return
      }
      this.showLoading()
      const payload = {
        brandName: this.profile.brandName
      }
      try {
        const res = await this.SET_ADMIN_DATA_BRAND_NAME({
          payload
        })
        this.getDataAdmin()
      } catch (error) {}
      this.edited.isEditInfo = false
      this.hideLoading()
    },
    async editDetailAdmin(val) {
      if (!val) {
        this.edited.isEditDetai = true
        return
      }
      this.showLoading()
      const data = this.imageController.changePhotoProfile ? false : true
      if (!data) await this.upladImageControl(this.documentadmin.companyLogoUrl)
      const payload = {
        companyAddress: this.documentadmin.companyAddress,
        companyLogoUrl: data ? this.documentadmin.companyLogoUrl : `${this.apiImg.uploadUrl}/${this.apiImg.key}`,
        signerName: this.documentadmin.signerName,
        signerTitle: this.documentadmin.signerTitle,
        originalFileName: this.fileName
      }
      try {
        const res = await this.SET_ADMIN_DATA_INFO({
          payload
        })
        this.getDataAdmin()
      } catch (error) {}
      this.imageController.changePhotoProfile = false
      this.edited.isEditDetai = false
      this.hideLoading()
    },
    onFileChange(e) {
      this.imageController.changePhotoProfile = true
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      this.typeImg = file.type
      this.fileName = file.name
      let reader = new FileReader()
      reader.onload = (e) => {
        this.documentadmin.companyLogoUrl = e.target.result
      }
      reader.readAsDataURL(file)
    },
    async getDataAdmin() {
      this.showLoading()
      try {
        const data = await this.GET_ADMIN_DATA_INFO()
        if (Object.keys(data).length !== 0) {
          this.documentadmin = data
        }
        this.profile = await this.GET_ADMIN_DATA()
      } catch (err) {}
      this.hideLoading()
    },
    async upladImageControl(url) {
      let baseUrl
      switch (process.env.NODE_ENV) {
        case 'production':
          baseUrl = 'https://prod-api-v4.g2academy.co'
          break
        case 'staging':
          baseUrl = 'https://staging-api-v4.g2academy.co'
          break
        case 'beta':
          baseUrl = 'https://dev-api-v4.g2academy.co'
          break
      }
      const { data } = await axios({
        method: 'GET',
        url: `${baseUrl}/uploads?contentType=${this.typeImg}`
      })
      this.apiImg = data
      let binary = atob(url.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeImg })
      await fetch(this.apiImg.uploadUrl, {
        method: 'PUT',
        body: blobData
      })
    },
    createImageForAttachment(el, tp) {
      let reader = new FileReader()
      reader.onload = (e) => {
        this.attachment.forEach((element) => {
          if (element.registrationDocumentName === tp) {
            element.partnerDocumentUrl = e.target.result
            element.type = el.type
            element.originalFileName = el.name
          }
          this.typeAttachment = el.type
        })
        if (this.formDataAttachment[0].find((item) => item.key === tp)) {
          this.formDataAttachment[0].find((item) => item.key === tp).choosed = true
          this.formDataAttachment[0].find((item) => item.key === tp).photoName = el.name
        } else {
          this.formDataAttachment[1].find((item) => item.key === tp).choosed = true
          this.formDataAttachment[1].find((item) => item.key === tp).photoName = el.name
        }
        this.formDataAttachment.push(1)
        this.formDataAttachment.splice(this.formDataAttachment.length - 1)
      }
      reader.readAsDataURL(el)
    },
    onFileChangeAttachment(e, name) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImageForAttachment(files[0], name)
    },
    async saveDocumentAttachment() {
      if (this.buttonEdit) return
      let notValited = false
      this.formDataAttachment.forEach((el1) => {
        el1.forEach((el2) => {
          if (el2.photoName.includes('_')) {
            notValited = true
          }
        })
      })
      if (notValited) {
        if (this.notDubbleClick) return
        this.errorMessage('Nama file/dokumen tidak boleh mengandung garis bawah (_)')
        this.notDubbleClick = true
        setTimeout(() => {
          this.notDubbleClick = false
        }, this.durationPopup)
        return
      }
      if (this.typeAttachment === 'application/pdf' || this.typeAttachment === 'image/jpeg' || this.typeAttachment === 'image/png') {
        this.showLoading()
        const response = await toModelingDataAttachmentForSave(this.attachment)
        await this.SET_ADMIN_DATA_ATTACHMENT({
          documents: response
        })
        this.getDataAttachment()
        this.formEdit = false
        return
      }
      if (this.notDubbleClick) return
      this.errorMessage('Document salah format')
      this.notDubbleClick = true
      setTimeout(() => {
        this.notDubbleClick = false
      }, this.durationPopup)
    },
    async getDataAttachment() {
      this.showLoading()
      try {
        const data = await this.GET_ATTACHMENT_DATA_INFO()
        this.attachment = data
      } catch (err) {}
      for (let i = 0; i < this.attachment.length; i++) {
        if (i < 4) this.formDataAttachment[0][i] = { name: this.documentName[i], url: '', photoName: '', key: this.attachment[i].registrationDocumentName, choosed: false }
        this.formDataAttachment[1][0] = { name: this.documentName[4], url: '', photoName: '', key: this.attachment[4].registrationDocumentName, choosed: false }
        this.formDataAttachment[1][1] = { name: this.documentName[5], url: '', photoName: '', key: this.attachment[5].registrationDocumentName, choosed: false }
      }
      this.formDataAttachment.forEach((e) => {
        e.forEach((e2) => {
          const data = this.attachment.find((items) => items.registrationDocumentName === e2.key)
          if (data) {
            const name = data.partnerDocumentUrl.split('/')
            let response = ''
            if (
              name[name.length - 1].split('_')[0].includes('.jpg') ||
              name[name.length - 1].split('_')[0].includes('.jpeg') ||
              name[name.length - 1].split('_')[0].includes('.png') ||
              name[name.length - 1].split('_')[0].includes('.pdf')
            ) {
              response = name[name.length - 1].split('_')[0]
            }
            e2.url = data.partnerDocumentUrl
            e2.photoName = response
          }
        })
      })
      this.formDataAttachment.push(1)
      this.formDataAttachment.splice(this.formDataAttachment.length - 1)
      this.hideLoading()
    },
    toPreview(url, val) {
      if (!val) return
      window.open(url)
    },
    errorMessage(message) {
      this.$toasted.show(message, {
        position: 'top-center',
        type: 'error',
        duration: this.durationPopup,
        theme: 'bubble',
        singleton: true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
input[type='file']::file-selector-button {
  border: 2px solid #e7bb5c;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: orange;
  transition: 1s;
  margin-top: 5px;
  color: white;
}
.profile {
  text-align: left;
  &__form {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(18, 18, 18, 0.16);
    padding: 25px;
    &-notes {
      background-color: #fff;
      border: 1px dashed #f7931e;
      display: inline-block;
      padding: 10px;
      border-radius: 5px;
      position: relative;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-color: #f7931e;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        position: absolute;
        left: -10px;
        top: -10px;
      }
      p {
        margin: 0;
        font-size: 12px;
        color: #f7931e;
        line-height: 18px;
      }
    }
  }
}
</style>
