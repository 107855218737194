<template>
  <div class="">
    <h3>G2 Academy Talent Dashboard</h3>
    <div class="py-24">
      <img src="@/assets/images/amico.png" alt="" />
      <p>Access your dashboard to talent information system here</p>
      <div class="text-center">
        <div class="inline-block w-80 mt-6 mb-16">
          <Button buttonText="Open Talent Dashboard" size="full" @action="talent()" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  methods: {
    talent() {
      window.open('https://talent.g2academy.co/login')
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}
h3 {
  margin-top: 20px;
  font-weight: bold;
  font-size: 32px;
}
p {
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
</style>
