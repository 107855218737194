<template>
  <div class="content-body pb-8">
    <div class="space-y-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-4">
          <ArrowForward direction="left" @handleClick="changeRoute(breadcrumb[0])" class="cursor-pointer" />
          <h1 class="text-3xl font-bold">{{ pageName }}</h1>
        </div>
        <Button :buttonText="actionButtonText" :disabled="!leaveTypeDetails.name || hasEmptyValue" @action="saveData()" />
      </div>
      <div class="breadcrumb">
        <li class="inline-flex items-center text-sm" :key="'breadcrumbInLeaveTypeForm' + index" v-for="(route, index) in breadcrumb">
          <span :class="[isLastRoute(index) ? 'text-yellow cursor-pointer' : 'text-neutral']" @click="changeRoute(route, index)">
            {{ route.title }}
          </span>
          <Chevron color="#F7931E" height="14" class="mx-3" v-if="isLastRoute(index)" />
        </li>
      </div>
      <div class="shadow-small card pt-4 pb-8 bg-white rounded-lg px-7 grid grid-cols-2">
        <div class="space-y-4">
          <div class="border-b border-neutral-50 py-3 flex justify-between items-center">
            <p class="font-semibold flex gap-1 items-center">
              <span class=""><Label color="yellow" /></span>Leave Type
            </p>
          </div>
          <!-- LEAVE NAME -->
          <TextField label="Leave Name" type="text" borderEnabled placeholder="Enter leave type name" v-model="leaveTypeDetails.name" />
          <!-- LEAVE UNIT -->
          <Dropdown :options="leaveUnitOptions" label="Leave Unit" v-model="leaveTypeDetails.unit" :default="leaveTypeDetails.unit" placeholder="Select leave type unit" />
          <!-- LEAVE LIMIT -->
          <div class="space-y-2">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Duration Type</label>
            <p v-for="(duration, durationIdx) in durationTypeOptions" :key="'durationTypeOptions' + durationIdx">
              <input type="radio" :id="`durationType${durationIdx}`" name="radio-group-duration" :value="duration" v-model="leaveTypeDetails.durationType" />
              <label :for="`durationType${durationIdx}`">{{ duration }}</label>
            </p>
          </div>
          <div class="border border-neutral-400 rounded-lg p-5 ml-6 space-y-4" v-if="leaveTypeDetails.durationType === 'Limited'">
            <p class="font-semibold flex gap-1 items-center">Limited Leave</p>
            <!-- LEAVE CYCLE -->
            <Dropdown :options="limitedLeaveCylceOptions" label="Leave Cycle" v-model="leaveTypeDetails.limitedLeave.limitedLeaveCycle" :default="leaveTypeDetails.limitedLeave.limitedLeaveCycle" />
            <div class="space-y-2">
              <!-- START CYCLE FROM -->
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Start Cycle from</label>
              <div class="flex items-center gap-2" v-for="(cycleItem, cycleItemIdx) in startCycleOptions" :key="'startCycleOptions' + cycleItemIdx">
                <input type="radio" :id="`startCycleOptions${cycleItemIdx}`" name="radio-group-cycle" :value="cycleItem" v-model="leaveTypeDetails.limitedLeave.limitedstartFrom" />
                <label :for="`startCycleOptions${cycleItemIdx}`">{{ cycleItem.label }}</label>
                <div v-if="cycleItem.value === 'SPECIFIC'" class="flex items-center gap-2">
                  <Dropdown :options="months" v-model="selectedMonth" :disabled="leaveTypeDetails.limitedLeave.limitedstartFrom.value === 'JOIN_DATE'" :default="selectedMonth" maxHeight="190px" />
                  <Dropdown
                    :options="numberOfDaysInMonth"
                    :disabled="leaveTypeDetails.limitedLeave.limitedstartFrom.value === 'JOIN_DATE'"
                    v-model="selectedDate"
                    :default="selectedDate"
                    maxHeight="190px"
                  />
                </div>
              </div>
            </div>
            <!-- LEAVE DURATION -->
            <TextField
              label="Leave Balance in a Cycle (days)"
              type="number"
              borderEnabled
              placeholder="Enter leave duration"
              :error="errorInput['limitedBalanceCycle']"
              @input="handleInputNumberChange('limitedBalanceCycle', ...arguments)"
              v-model="leaveTypeDetails.limitedLeave.limitedBalanceCycle"
              isStayShow
            />
            <!-- LEAVE FREQUENCY -->
            <div class="space-y-2">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Leave Frequency</label>
              <p v-for="(frequency, frequencyIdx) in leaveFrequencyOptions" :key="'leaveFrequencyOptions' + frequencyIdx">
                <input type="radio" :id="`leaveFrequency${frequencyIdx}`" name="radio-group-frequency" :value="frequency" v-model="leaveTypeDetails.limitedLeave.limitedFrequence" />
                <label :for="`leaveFrequency${frequencyIdx}`">{{ frequency.label }}</label>
              </p>
            </div>
            <div class="bg-neutral-100 rounded-lg p-5 ml-6 flex gap-2" v-if="leaveTypeDetails.limitedLeave.limitedFrequence.value === 'GRADUALLY'">
              <div class="flex items-center gap-2 w-1/3">
                <TextField
                  type="number"
                  @input="handleInputNumberChange('limitedGraduallyScopeDays', ...arguments)"
                  :error="errorInput['limitedGraduallyScopeDays']"
                  isStayShow
                  borderEnabled
                  v-model="leaveTypeDetails.limitedLeave.limitedGraduallyScope.days"
                />
              </div>
              <div class="flex items-center">
                <p>(day) in a</p>
              </div>
              <div class="w-1/3">
                <Dropdown
                  :options="limitedGraduallyScopeInterval"
                  optionLabel="label"
                  v-model="leaveTypeDetails.limitedLeave.limitedGraduallyScope.interval"
                  :default="leaveTypeDetails.limitedLeave.limitedGraduallyScope.interval"
                />
              </div>
            </div>
          </div>
          <!-- LEAVE RULE -->
          <div class="border-b border-neutral-50 py-3 flex justify-between items-center">
            <p class="font-semibold flex gap-1 items-center">
              <span class=""><Label color="yellow" /></span>Leave Rule
            </p>
          </div>
          <!-- LEAVE SCOPE -->
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Leave Scope</label>
          <p v-for="(scope, scopeIdx) in leaveScopeOptions" :key="'leaveScopeOptions' + scopeIdx">
            <input type="radio" :id="`leaveScope${scopeIdx}`" name="radio-group-scope" :value="scope" v-model="leaveTypeDetails.leaveRules.leaveScope" />
            <label :for="`leaveScope${scopeIdx}`">{{ scope }}</label>
          </p>
          <div class="bg-neutral-100 rounded-lg p-5 ml-6 flex justify-between gap-2" v-if="leaveTypeDetails.leaveRules.leaveScope === 'Custom'">
            <div class="flex items-center gap-2 w-1/2">
              <Dropdown
                class="w-full"
                optionLabel="label"
                :options="leaveScopeCustoms"
                @change="handleLeaveScopeChange"
                v-model="leaveTypeDetails.leaveRules.customScope.attribute"
                :default="leaveTypeDetails.leaveRules.customScope.attribute"
              />
              <p>include</p>
            </div>
            <div class="w-1/2">
              <Dropdown :options="dynamicLeaveScopeOptions" optionLabel="label" v-model="leaveTypeDetails.leaveRules.customScope.value" :default="leaveTypeDetails.leaveRules.customScope.value" />
            </div>
          </div>
          <!-- GRANT LEAVES -->
          <div class="space-y-2 mt-1">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Grants leaves for</label>
            <div class="flex items-center gap-2" v-for="(grantItem, grantItemIdx) in grantLeaveOptions" :key="'grantLeaveOptions' + grantItemIdx">
              <input type="radio" :id="`grantLeaveOptions${grantItemIdx}`" name="radio-group-grant" :value="grantItem" v-model="leaveTypeDetails.leaveRules.grantLeaveFor" />
              <label :for="`grantLeaveOptions${grantItemIdx}`">{{ grantItem.label }}</label>
              <div v-if="grantItem.label !== 'All'" class="flex items-center gap-2">
                <TextField
                  type="number"
                  borderEnabled
                  v-model="leaveTypeDetails.leaveRules.grantLeaveException.joinedFrom"
                  :disabled="leaveTypeDetails.leaveRules.grantLeaveFor?.value === 'ALL'"
                  :error="errorInput['grantLeaveFor']"
                  isStayShow
                  @input="handleInputNumberChange('grantLeaveFor', ...arguments)"
                  class="w-20"
                />
                {{ leaveTypeDetails.unit.toLowerCase() }}
              </div>
            </div>
          </div>
          <div class="space-y-6">
            <div class="mb-2">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-2">Time limit to submit requests</label>
              <div class="flex items-center gap-2" v-for="(ruleTimeLimit, ruleTimeLimitIdx) in leaveRuleOptions" :key="'ruleTimeLimit' + ruleTimeLimitIdx">
                <div v-if="ruleTimeLimit === 'Limited'" class="flex items-center gap-2">
                  <input type="radio" :id="`ruleTimeLimit${ruleTimeLimitIdx}`" name="rule-time-limit" :value="ruleTimeLimit" v-model="leaveTypeDetails.leaveRules.ruleTimeLimit" />
                  <label :for="`ruleTimeLimit${ruleTimeLimitIdx}`" class=""></label>
                  <div class="flex items-center gap-2 -ml-1">
                    <TextField
                      :for="`ruleTimeLimit${ruleTimeLimitIdx}`"
                      type="number"
                      borderEnabled
                      :disabled="leaveTypeDetails.leaveRules.ruleTimeLimit === 'Unlimited'"
                      v-model="leaveTypeDetails.leaveRules.ruleTimeLimitDays"
                      :error="errorInput['ruleTimeLimit']"
                      isStayShow
                      @input="handleInputNumberChange('ruleTimeLimit', ...arguments)"
                      class="w-20 mt-5"
                    />
                    <p class="pt-4">(days) before leave starts</p>
                  </div>
                </div>
                <div v-else class="">
                  <input type="radio" :id="`ruleTimeLimit${ruleTimeLimitIdx}`" name="rule-time-limit" :value="ruleTimeLimit" v-model="leaveTypeDetails.leaveRules.ruleTimeLimit" />
                  <label :for="`ruleTimeLimit${ruleTimeLimitIdx}`">{{ ruleTimeLimit }}</label>
                </div>
              </div>
            </div>
            <div class="space-y-3">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block -mb-1">Minimum duration of a single leave</label>
              <div class="flex items-center gap-2" v-for="(ruleTimeMin, ruleTimeMinIdx) in leaveRuleOptions" :key="'ruleTimeMinDuration' + ruleTimeMinIdx">
                <input type="radio" :id="`ruleTimeMinDuration${ruleTimeMinIdx}`" name="rule-time-min-duration" :value="ruleTimeMin" v-model="leaveTypeDetails.leaveRules.ruleMinimumDuration" />
                <label :for="`ruleTimeMinDuration${ruleTimeMinIdx}`">{{ ruleTimeMin === 'Limited' ? 'Fixed' : ruleTimeMin }}</label>
                <div v-if="ruleTimeMin === 'Limited'" class="flex items-center gap-2">
                  <TextField
                    type="number"
                    borderEnabled
                    v-model="leaveTypeDetails.leaveRules.ruleMinimumDurationDays"
                    class="w-20"
                    :disabled="leaveTypeDetails.leaveRules.ruleMinimumDuration === 'Unlimited'"
                    :error="errorInput['ruleMinimumDurationDays']"
                    isStayShow
                    @input="handleInputNumberChange('ruleMinimumDurationDays', ...arguments)"
                  />
                  <p>days</p>
                </div>
              </div>
            </div>
            <div class="space-y-3">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block -mb-1">Maximum duration of a single leave</label>
              <div class="flex items-center gap-2" v-for="(ruleTimeMax, ruleTimeMaxIdx) in leaveRuleOptions" :key="'ruleTimeMaxDuration' + ruleTimeMaxIdx">
                <input type="radio" :id="`ruleTimeMaxDuration${ruleTimeMaxIdx}`" name="rule-time-max-duration" :value="ruleTimeMax" v-model="leaveTypeDetails.leaveRules.ruleMaximumDuration" />
                <label :for="`ruleTimeMaxDuration${ruleTimeMaxIdx}`">{{ ruleTimeMax === 'Limited' ? 'Fixed' : ruleTimeMax }}</label>
                <div v-if="ruleTimeMax === 'Limited'" class="flex items-center gap-2">
                  <TextField
                    type="number"
                    borderEnabled
                    v-model="leaveTypeDetails.leaveRules.ruleMaximumDurationDays"
                    class="w-20"
                    isStayShow
                    :disabled="leaveTypeDetails.leaveRules.ruleMaximumDuration === 'Unlimited'"
                    :error="errorInput['ruleMaximumDurationDays']"
                    @input="handleInputNumberChange('ruleMaximumDurationDays', ...arguments)"
                  />
                  <p>days</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast, upperCaseFirstWord } from '@/utils'
import dayjs from 'dayjs'
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward')
  },
  data() {
    return {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      selectedMonth: 'Jan',
      selectedDate: 1,
      currentDate: dayjs(),
      leaveTypeDetails: {
        name: '',
        unit: 'Days',
        durationType: 'Limited',
        customerId: 1,
        limitedLeave: {
          limitedLeaveCycle: 'Annually', //ANNUALLY,MONTHLY
          limitedstartFrom: {
            label: 'Employee join date',
            value: 'JOIN_DATE'
          },
          limitedSpecificDate: '2023-10-15',
          limitedBalanceCycle: 14,
          limitedFrequence: {
            label: 'Grants all leave at once',
            value: 'ALL'
          }, //ALL, GRADUALLY
          limitedGraduallyScope: {
            days: 1,
            interval: {
              label: 'Week',
              value: 'WEEKLY'
            } //WEEKLY, MONTHLY, YEARLY
          }
        },
        leaveRules: {
          leaveScope: 'All', // ALL,CUSTOM
          customScope: {
            attribute: 'Gender',
            value: 'Female'
          },
          grantLeaveFor: {
            label: 'All',
            value: 'ALL'
          },
          grantLeaveException: {
            // jika pilih grantLeaveFor :EXCEPTION
            joinedFrom: 90
          },
          ruleTimeLimit: 'Limited', //LIMITED,UNLIMITED
          ruleTimeLimitDays: 14,
          ruleMinimumDuration: 'Limited', //LIMITED,UNLIMITED
          ruleMinimumDurationDays: 1,
          ruleMaximumDuration: 'Limited', //LIMITED,UNLIMITED
          ruleMaximumDurationDays: 5
        }
      },
      leaveScopeSelected: 'All',
      durationTypeOptions: ['Unlimited', 'Limited'],
      leaveUnitOptions: ['Days', 'Hours'],
      leaveScopeOptions: ['All', 'Custom'],
      leaveScopeCustoms: [
        {
          label: 'Gender',
          value: 'GENDER'
        },
        {
          label: 'Status',
          value: 'MARITAL_STATUS'
        },
        {
          label: 'PTKP Status',
          value: 'PTKP'
        }
      ], // MARITAL_STATUS, PTKP 'Gender', 'Status', 'PTKP Status'
      leaveScopeCustomIndex: 0, // refer to gender options
      leaveScopeCustomOptions: [
        // Gender Options
        [
          {
            label: 'Male',
            value: 'MALE'
          },
          {
            label: 'Female',
            value: 'FEMALE'
          }
        ],
        // Marital Status Options
        [
          {
            label: 'Single',
            value: 'SINGLE'
          },
          {
            label: 'Menikah',
            value: 'MARRIED'
          },
          {
            label: 'Janda/Duda',
            value: 'WIDOW'
          }
        ],
        // PTKP Status Options (Fetching API)
        []
      ],
      grantLeaveOptions: [
        {
          label: 'All',
          value: 'ALL'
        },
        {
          label: 'Employees who have been with the organization for over',
          value: 'EXCEPTION'
        }
      ],
      limitedLeaveCylceOptions: ['Annually', 'Monthly'],
      startCycleOptions: [
        {
          label: 'Employee join date',
          value: 'JOIN_DATE'
        },
        {
          label: 'Specified date each year',
          value: 'SPECIFIC'
        }
      ],
      leaveFrequencyOptions: [
        {
          label: 'Grants all leave at once',
          value: 'ALL'
        },
        {
          label: 'Grants leave gradually',
          value: 'GRADUALLY'
        }
      ],
      limitedGraduallyScopeInterval: [
        {
          label: 'Week',
          value: 'WEEKLY'
        },
        {
          label: 'Month',
          value: 'MONTHLY'
        }
      ],
      leaveRuleOptions: ['Unlimited', 'Limited'],
      errorInput: {
        limitedBalanceCycle: '',
        limitedGraduallyScopeDays: '',
        ruleTimeLimit: '',
        ruleMinimumDurationDays: '',
        ruleMaximumDurationDays: ''
      },
      delayTime: 300,
      debounceTimer: null
    }
  },
  computed: {
    ...mapGetters('common', ['isUnauthorized']),
    year() {
      return Number(this.currentDate.format('YYYY'))
    },
    totalDayInMonth() {
      return dayjs(this.currentDate).daysInMonth()
    },
    numberOfDaysInMonth() {
      return [...Array(this.totalDayInMonth)].map((val, index) => index + 1)
    },
    dynamicLeaveScopeOptions() {
      return this.leaveScopeCustomOptions[this.leaveScopeCustomIndex]
    },
    customerId() {
      return localStorage.getItem('client')
    },
    leaveTypeId() {
      return this.$route.params.leaveTypeId
    },
    pageName() {
      return this.$route.meta.label
    },
    currentPath() {
      return this.$route.fullPath
    },
    breadcrumb() {
      return [
        {
          title: 'Leave Type',
          path: '/leave-type'
        },
        {
          title: this.pageName,
          path: this.currentPath
        }
      ]
    },
    actionButtonText() {
      return this.pageName === 'Add Leave' ? 'Save' : 'Update'
    },
    durationType() {
      return this.leaveTypeDetails.durationType
    },
    leaveTypeUnit() {
      return this.leaveTypeDetails.unit
    },
    hasEmptyValue() {
      return Object.values(this.errorInput).filter((val) => val).length > 0
    },
    leaveScope() {
      return this.leaveTypeDetails?.leaveRules?.leaveScope
    },
    ruleTimeLimit() {
      return this.leaveTypeDetails.leaveRules.ruleTimeLimit
    },
    ruleMinimumDuration() {
      return this.leaveTypeDetails.leaveRules.ruleMinimumDuration
    },
    ruleMaximumDuration() {
      return this.leaveTypeDetails.leaveRules.ruleMaximumDuration
    },
    limitedLeaveCycle() {
      return this.leaveTypeDetails.limitedLeave.limitedLeaveCycle
    }
  },
  watch: {
    limitedLeaveCycle(value) {
      if (value === 'Annually') {
        this.limitedGraduallyScopeInterval = [
          {
            label: 'Week',
            value: 'WEEKLY'
          },
          {
            label: 'Month',
            value: 'MONTHLY'
          }
        ]
        return
      }
      this.limitedGraduallyScopeInterval = [
        {
          label: 'Week',
          value: 'WEEKLY'
        }
      ]
    },
    leaveScope(value) {
      if (value === 'Custom' && this.leaveTypeDetails.leaveRules?.customScope?.attribute) {
        this.leaveTypeDetails.leaveRules.customScope.attribute = {
          label: 'Gender',
          value: 'GENDER'
        }
        this.leaveScopeCustomIndex = 0
        this.leaveTypeDetails.leaveRules.customScope.value = {
          label: 'Female',
          value: 'FEMALE'
        }
      }
    },
    durationType(value) {
      if (value === 'Unlimited') {
        this.leaveTypeDetails.limitedLeave.limitedBalanceCycle = 1
        this.errorInput['limitedBalanceCycle'] = ''
      }
    },
    selectedMonth(value) {
      // get month order
      const monthOrder = this.months.indexOf(value) + 1
      // set new month based on selected value
      this.currentDate = dayjs(`${this.year}-${monthOrder}-01`)
    },
    leaveTypeUnit(val) {
      if (val === 'Hours') {
        this.leaveTypeDetails.durationType = 'Unlimited'
        this.durationTypeOptions = ['Unlimited']
        this.leaveTypeDetails.leaveRules.leaveScope = 'All'
        this.leaveScopeOptions = ['All']
        this.leaveTypeDetails.leaveRules.grantLeaveFor = {
          label: 'All',
          value: 'ALL'
        }
        this.grantLeaveOptions = [
          {
            label: 'All',
            value: 'ALL'
          }
        ]
        this.leaveTypeDetails.leaveRules.ruleTimeLimit = 'Unlimited'
        this.leaveTypeDetails.leaveRules.ruleMinimumDuration = 'Unlimited'
        this.leaveTypeDetails.leaveRules.ruleMaximumDuration = 'Unlimited'
        this.leaveRuleOptions = ['Unlimited']
      } else {
        this.durationTypeOptions = ['Unlimited', 'Limited']
        this.leaveScopeOptions = ['All', 'Custom']
        this.leaveRuleOptions = ['Unlimited', 'Limited']
        this.grantLeaveOptions = [
          {
            label: 'All',
            value: 'ALL'
          },
          {
            label: 'Employees who have been with the organization for over',
            value: 'EXCEPTION'
          }
        ]
      }
    },
    ruleTimeLimit(value) {
      if (value === 'Unlimited') {
        this.leaveTypeDetails.leaveRules.ruleTimeLimitDays = 1
        this.errorInput['ruleTimeLimit'] = ''
      }
    },
    ruleMinimumDuration(value) {
      if (value === 'Unlimited') {
        this.leaveTypeDetails.leaveRules.ruleMinimumDurationDays = 1
        this.errorInput['ruleMinimumDurationDays'] = ''
      }
    },
    ruleMaximumDuration(value) {
      if (value === 'Unlimited') {
        this.leaveTypeDetails.leaveRules.ruleMaximumDurationDays = 1
        this.errorInput['ruleMaximumDurationDays'] = ''
      }
    }
  },
  created() {
    this.setupDate()
    this.getData()
  },
  methods: {
    ...mapActions('humanresource', ['GET_LEAVE_TYPE_DETAIL', 'UPDATE_LEAVE_TYPE', 'CREATE_NEW_LEAVE_TYPE']),
    ...mapActions('common', ['showLoading', 'hideLoading']),

    setupDate() {
      // setup from january
      this.currentDate = dayjs(`${this.year}-${1}-01`)
    },
    handleLeaveScopeChange(value) {
      let getIndex = this.leaveScopeCustoms.indexOf(value)
      this.leaveScopeCustomIndex = getIndex
      this.leaveTypeDetails.leaveRules.customScope.value = this.leaveScopeCustomOptions[getIndex][1]
    },
    handleInputNumberChange(objecItem, value) {
      const isNotValid = value.charAt(0) === '0' && value.length > 1
      if (!value || isNotValid) {
        this.errorInput[objecItem] = 'Input value is not valid'
      } else if (parseInt(value) < 1 || parseInt(value) === 0) {
        this.errorInput[objecItem] = 'Minimum input value is 1'
      } else {
        this.errorInput[objecItem] = ''
      }
    },
    isLastRoute(index) {
      return this.breadcrumb.length - 1 !== index
    },
    changeRoute(route) {
      if (route?.title === this.pageName) return
      this.$router.push(route.path)
    },
    getPtkpOptionsAysnc() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('employee/GET_LIST_MARITAL_STATUS')
          .then((response) => {
            if (response?.data?.code === 200) {
              resolve(
                response.data.data.map((item) => {
                  return {
                    value: item?.id,
                    label: `${item.marital_code}`
                  }
                })
              )
            } else {
              resolve([])
            }
          })
          .catch(() => {
            resolve([])
          })
      })
    },
    async getData() {
      // get PTKP options
      this.leaveScopeCustomOptions[2] = await this.getPtkpOptionsAysnc()
      if (this.leaveTypeId) {
        this.fetchingData()
      } else {
        this.leaveTypeDetails.customerId = this.customerId
      }
    },
    fetchingData() {
      this.showLoading()
      this.GET_LEAVE_TYPE_DETAIL({ leaveTypeId: this.leaveTypeId })
        .then((res) => {
          if (res.data.code === 200) {
            const response = { ...res?.data?.data }
            response.unit = upperCaseFirstWord(response.unit)
            response.durationType = upperCaseFirstWord(res?.data?.data.durationType)
            response.limitedLeave = this.mappingLimitedLeaveResponse(res?.data?.data.limitedLeave, res?.data?.data.durationType)
            response.leaveRules = this.mappingLeaveRulesResponse(res?.data?.data.leaveRules, this.leaveScopeCustomOptions[2])
            this.leaveTypeDetails = response
            this.hideLoading()
          } else {
            this.isUnauthorized ? null : showVueToast('Your request is failed!', 'error', 3000)
            this.hideLoading()
          }
        })
        .catch(() => {
          this.hideLoading()
        })
    },
    mappingLeaveRulesResponse(leaveRules, ptkpOptions) {
      const dummyData = {
        leaveScope: 'All', // ALL,CUSTOM
        customScope: {
          attribute: 'Gender',
          value: 'Female'
        },
        grantLeaveFor: {
          label: 'All',
          value: 'ALL'
        },
        grantLeaveException: {
          // jika pilih grantLeaveFor :EXCEPTION
          joinedFrom: 90
        },
        ruleTimeLimit: 'Limited', //LIMITED,UNLIMITED
        ruleTimeLimitDays: 14,
        ruleMinimumDuration: 'Limited', //LIMITED,UNLIMITED
        ruleMinimumDurationDays: 1,
        ruleMaximumDuration: 'Limited', //LIMITED,UNLIMITED
        ruleMaximumDurationDays: 5
      }
      const response = { ...leaveRules }
      let leaveScopeOptions = []
      if (response?.customScope?.attribute === 'GENDER') {
        leaveScopeOptions = this.leaveScopeCustomOptions[0]
      } else if (response?.customScope?.attribute === 'MARITAL_STATUS') {
        leaveScopeOptions = this.leaveScopeCustomOptions[1]
      } else {
        leaveScopeOptions = ptkpOptions
      }
      const customScopePayload = {
        attribute: this.leaveScopeCustoms.filter((item) => item.value === response?.customScope?.attribute)[0],
        value: leaveScopeOptions.filter((item) => item.value == response?.customScope?.value)[0]
      }
      const grantLeaveException = {
        joinedFrom: 1
      }
      if (response.grantLeaveFor === 'EXCEPTION') {
        grantLeaveException.joinedFrom = response?.grantLeaveException?.joinedFrom
      }
      const payload = {
        leaveScope: response?.leaveScope ? upperCaseFirstWord(response.leaveScope) : dummyData.leaveScope,
        customScope:
          response.leaveScope === 'ALL' || !response?.leaveScope
            ? {
                attribute: 'Gender',
                value: 'Female'
              }
            : customScopePayload,
        grantLeaveFor: response?.grantLeaveFor ? this.grantLeaveOptions.filter((item) => item.value === response.grantLeaveFor)[0] : dummyData.grantLeaveFor,
        grantLeaveException,
        ruleTimeLimit: response.ruleTimeLimit ? upperCaseFirstWord(response.ruleTimeLimit) : dummyData.ruleTimeLimit,
        ruleTimeLimitDays: response.ruleTimeLimitDays ? (response.ruleTimeLimit === 'UNLIMITED' ? 1 : response.ruleTimeLimitDays) : dummyData.ruleTimeLimitDays,
        ruleMinimumDuration: response.ruleMinimumDuration ? upperCaseFirstWord(response.ruleMinimumDuration) : dummyData.ruleMinimumDuration,
        ruleMinimumDurationDays: response.ruleMinimumDurationDays ? (response.ruleMinimumDuration === 'UNLIMITED' ? 1 : response.ruleMinimumDurationDays) : dummyData.ruleMinimumDurationDays,
        ruleMaximumDuration: response.ruleMaximumDuration ? upperCaseFirstWord(response.ruleMaximumDuration) : dummyData.ruleMaximumDuration,
        ruleMaximumDurationDays: response.ruleMaximumDurationDays ? (response.ruleMaximumDuration === 'UNLIMITED' ? 1 : response.ruleMaximumDurationDays) : dummyData.ruleMaximumDurationDays
      }
      return payload
    },
    mappingLimitedLeaveResponse(response, type) {
      const dummyData = {
        limitedLeaveCycle: 'Annually', //ANNUALLY,MONTHLY
        limitedstartFrom: {
          label: 'Employee join date',
          value: 'JOIN_DATE'
        },
        limitedSpecificDate: '2023-10-15',
        limitedBalanceCycle: 14,
        limitedFrequence: {
          label: 'Grants all leave at once',
          value: 'ALL'
        }, //ALL, GRADUALLY
        limitedGraduallyScope: {
          days: 1,
          interval: {
            label: 'Week',
            value: 'WEEKLY'
          } //WEEKLY, MONTHLY, YEARLY
        }
      }
      if (type === 'UNLIMITED') return dummyData
      const graduallyScope = {
        days: response?.limitedGraduallyScope?.days,
        interval: this.limitedGraduallyScopeInterval.filter((item) => item.value === response?.limitedGraduallyScope?.interval)[0]
      }
      const graduallyScopeDummy = {
        days: 1,
        interval: {
          label: 'Week',
          value: 'WEEKLY'
        }
      }
      // mapping date
      if (response.limitedstartFrom === 'SPECIFIC') {
        this.selectedDate = dayjs(response.limitedSpecificDate).format('D')
        this.selectedMonth = dayjs(response.limitedSpecificDate).format('MMM')
      }
      const payload = {
        limitedLeaveCycle: response.limitedLeaveCycle ? upperCaseFirstWord(response.limitedLeaveCycle) : dummyData.limitedLeaveCycle,
        limitedstartFrom: response.limitedLeaveCycle ? this.startCycleOptions.filter((item) => item.value === response.limitedstartFrom)[0] : dummyData.limitedstartFrom,
        limitedSpecificDate: null,
        limitedBalanceCycle: response.limitedBalanceCycle ? response.limitedBalanceCycle : dummyData.limitedBalanceCycle,
        limitedFrequence: response.limitedFrequence ? this.leaveFrequencyOptions.filter((item) => item.value === response.limitedFrequence)[0] : dummyData.limitedFrequence,
        limitedGraduallyScope: response.limitedFrequence === 'GRADUALLY' && response.limitedFrequence ? graduallyScope : graduallyScopeDummy
      }

      return payload
    },
    saveData() {
      const payload = { ...this.leaveTypeDetails }
      payload.unit = payload.unit.toUpperCase()
      payload.durationType = payload.durationType.toUpperCase()
      payload.limitedLeave = this.mappingLimitedLeaveForRequest(this.leaveTypeDetails)
      payload.leaveRules = this.mappingLeaveRulesForRequest(this.leaveTypeDetails.leaveRules)
      this.leaveTypeId ? this.updateLeaveType(payload) : this.createNewLeaveType(payload)
    },
    mappingLeaveRulesForRequest(leaveRules) {
      const payload = {
        leaveScope: leaveRules.leaveScope.toUpperCase(), // all
        customScope: {
          attribute: leaveRules?.customScope?.attribute?.value,
          value: Number.isInteger(leaveRules?.customScope?.value?.value) ? leaveRules?.customScope?.value?.value.toString() : leaveRules?.customScope?.value?.value?.toUpperCase()
        },
        grantLeaveFor: leaveRules.grantLeaveFor?.value, // all
        grantLeaveException: {
          joinedFrom: leaveRules?.grantLeaveException?.joinedFrom
        },
        ruleTimeLimit: leaveRules.ruleTimeLimit.toUpperCase(),
        ruleTimeLimitDays: leaveRules.ruleTimeLimit === 'Unlimited' ? 0 : leaveRules.ruleTimeLimitDays,
        ruleMinimumDuration: leaveRules.ruleMinimumDuration.toUpperCase(),
        ruleMinimumDurationDays: leaveRules.ruleMinimumDuration === 'Unlimited' ? 0 : leaveRules.ruleMinimumDurationDays,
        ruleMaximumDuration: leaveRules.ruleMaximumDuration.toUpperCase(),
        ruleMaximumDurationDays: leaveRules.ruleMaximumDuration === 'Unlimited' ? 0 : leaveRules.ruleMaximumDurationDays
      }
      if (payload.leaveScope === 'ALL') payload.customScope = null
      if (payload.grantLeaveFor === 'ALL') payload.grantLeaveException = null
      return payload
    },
    mappingLimitedLeaveForRequest(leaveTypeDetails) {
      if (leaveTypeDetails.durationType === 'Unlimited') return null
      const graduallyScope = {
        days: leaveTypeDetails?.limitedLeave?.limitedGraduallyScope?.days,
        interval: leaveTypeDetails?.limitedLeave?.limitedGraduallyScope?.interval?.value
      }
      const payload = {
        limitedLeaveCycle: leaveTypeDetails.limitedLeave.limitedLeaveCycle.toUpperCase(),
        limitedstartFrom: leaveTypeDetails.limitedLeave.limitedstartFrom.value,
        limitedSpecificDate: leaveTypeDetails.limitedLeave.limitedstartFrom.value === 'JOIN_DATE' ? null : this.constructTime(),
        limitedBalanceCycle: leaveTypeDetails.limitedLeave.limitedBalanceCycle,
        limitedFrequence: leaveTypeDetails.limitedLeave.limitedFrequence.value,
        limitedGraduallyScope: leaveTypeDetails.limitedLeave.limitedFrequence.value === 'ALL' ? null : graduallyScope
      }
      return payload
    },
    constructTime() {
      const date = this.selectedDate > 9 ? this.selectedDate : `0${this.selectedDate}`
      const monthOrder = this.months.indexOf(this.selectedMonth) + 1
      const month = monthOrder > 9 ? monthOrder : `0${monthOrder}`
      return `${this.year}-${month}-${date}`
    },
    createNewLeaveType(payload) {
      this.showLoading()
      this.CREATE_NEW_LEAVE_TYPE({ payload })
        .then((res) => {
          if (res?.data?.code) {
            showVueToast(`New leave type created!`, 'success', 2500)
            this.changeRoute(this.breadcrumb[0])
          } else {
            setTimeout(() => {
              showVueToast(`Failed to update this ${this.leaveTypeDetails.name}!`, 'error', 3000)
            }, 300)
          }
          this.hideLoading()
        })
        .catch(() => {
          this.isUnauthorized ? null : showVueToast('Your request is failed!', 'error', 3000)
          this.hideLoading()
        })
    },
    updateLeaveType(payload) {
      this.showLoading()
      this.UPDATE_LEAVE_TYPE({ leaveTypeId: this.leaveTypeId, payload })
        .then((res) => {
          if (res?.data?.code) {
            showVueToast(`Leave type updated!`, 'success', 2500)
            this.changeRoute(this.breadcrumb[0])
          } else {
            setTimeout(() => {
              showVueToast(`Failed to update this ${this.leaveTypeDetails.name}!`, 'error', 3000)
            }, 300)
          }
          this.hideLoading()
        })
        .catch(() => {
          this.isUnauthorized ? null : showVueToast('Your request is failed!', 'error', 3000)
          this.hideLoading()
        })
    }
  }
}
</script>

<style scoped>
.content-body {
  min-height: calc(83vh - 0px);
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #58595b;
}

[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #c2c2c2;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #f7931e;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #f7931e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>