var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isScheduleCodeUnavailable ? _c('div', [_c('h1', {
    staticClass: "font-bold text-2xl"
  }, [_vm._v("Schedule Code Unavailable")]), _c('h3', {
    staticClass: "py-3"
  }, [_vm._v("Schedule code of "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v(_vm._s(_vm.scheduleName))]), _vm._v(" is not available, please enter new schedule code to continue the process")]), _c('TextField', {
    staticClass: "mb-5",
    attrs: {
      "borderEnabled": "",
      "placeholder": "Enter Schedule Code"
    },
    model: {
      value: _vm.schedulePayload['scheduleCodePMO'],
      callback: function callback($$v) {
        _vm.$set(_vm.schedulePayload, 'scheduleCodePMO', $$v);
      },
      expression: "schedulePayload['scheduleCodePMO']"
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Continue",
      "size": "big"
    },
    on: {
      "action": _vm.updateLmsClass
    }
  })], 1) : _c('div', [_c('p', {
    staticClass: "text-2xl font-bold mb-6 text-left"
  }, [_vm._v("Import from CSV")]), _c('div', {
    staticClass: "grid grid-cols-2 gap-3 mb-4"
  }, [_c('Dropdown', {
    attrs: {
      "label": "Select Class",
      "options": _vm.list_kelas,
      "default": _vm.selectedCoreClass,
      "placeholder": "Select Class",
      "optionLabel": "nama",
      "maxHeight": "220px",
      "isLoadData": _vm.isFetchingCoreClass,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getCoreClassList
    },
    model: {
      value: _vm.selectedCoreClass,
      callback: function callback($$v) {
        _vm.selectedCoreClass = $$v;
      },
      expression: "selectedCoreClass"
    }
  }), _c('Dropdown', {
    attrs: {
      "label": "Select Schedule (Optional)",
      "options": _vm.lmsClassList,
      "default": _vm.selectedLmsClass,
      "placeholder": "Select Schedule",
      "optionLabel": "nama",
      "disabled": !_vm.selectedCoreClass,
      "maxHeight": "220px",
      "isLoadData": _vm.isFetchingLmsClass,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getLmsClassList
    },
    model: {
      value: _vm.selectedLmsClass,
      callback: function callback($$v) {
        _vm.selectedLmsClass = $$v;
      },
      expression: "selectedLmsClass"
    }
  }), _c('Dropdown', {
    attrs: {
      "options": _vm.digitalPlatforms,
      "label": "Digital Platforms",
      "placeholder": "Select Digital Platforms",
      "optionLabel": "name"
    },
    model: {
      value: _vm.selectedDp,
      callback: function callback($$v) {
        _vm.selectedDp = $$v;
      },
      expression: "selectedDp"
    }
  }), _c('Dropdown', {
    attrs: {
      "options": _vm.dummyOptions,
      "label": "For Dummy",
      "default": _vm.selectedDummy
    },
    model: {
      value: _vm.selectedDummy,
      callback: function callback($$v) {
        _vm.selectedDummy = $$v;
      },
      expression: "selectedDummy"
    }
  })], 1), _c('div', {
    staticClass: "modal-body-drag-area mb-2",
    on: {
      "dragover": _vm.dragover,
      "dragleave": _vm.dragleave,
      "drop": _vm.drop
    }
  }, [!_vm.isUploading ? _c('div', [_c('input', {
    ref: "file",
    attrs: {
      "type": "file",
      "name": "file-input",
      "id": "assetsFieldHandle",
      "accept": ".csv"
    },
    on: {
      "change": _vm.onChange
    }
  }), _c('div', {
    staticClass: "upload-icon"
  }, [_c('upload', {
    attrs: {
      "width": "486",
      "height": "48",
      "color": "#F7931E"
    }
  }), _c('div', {
    staticClass: "upload-icon-text"
  }, [_vm._v("Drag & Drop File")]), _c('div', [_vm._v("Or")])], 1), _c('label', {
    staticClass: "block cursor-pointer",
    attrs: {
      "for": "assetsFieldHandle"
    }
  }, [!_vm.isReadyToSend ? _c('span', [_vm._v("Select File")]) : _c('span', [_vm._v("Change File")])])]) : _vm._e(), _c('div', {
    staticClass: "text-left flex justify-center items-center w-full flex-col"
  }, [_vm.isUploading ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "bar relative mx-auto w-min"
  }, [_c('span', {
    staticClass: "font-medium text-sm text-yellow-primary transition-all inline-block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
  }, [_vm._v(_vm._s("".concat(_vm.progressReal, "%")))]), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "-1 -1 34 34"
    }
  }, [_c('circle', {
    staticClass: "progress-bar__background",
    attrs: {
      "cx": "16",
      "cy": "16",
      "r": "15.9155"
    }
  }), _c('circle', {
    staticClass: "progress-bar__progress js-progress-bar",
    style: {
      'stroke-dashoffset': _vm.progressPercentage
    },
    attrs: {
      "cx": "16",
      "cy": "16",
      "r": "15.9155"
    }
  })])]), _c('div', {
    staticClass: "flex justify-center flex-col items-start mt-4"
  }, [_vm.currentProgress === 100 ? _c('div', {
    staticClass: "mb-2 text-center"
  }, [_c('span', {
    staticClass: "text-green-600 text-sm"
  }, [_vm._v("Upload Voucher is Success!")])]) : _vm._e(), _c('div', {
    staticClass: "mb-1"
  }, [_c('span', {
    staticClass: "text-neutral-500 text-sm"
  }, [_vm._v("Total Data")]), _vm._v(": "), _c('strong', [_vm._v(_vm._s(_vm.totalData))])]), _c('div', {
    staticClass: "mb-1"
  }, [_c('span', {
    staticClass: "text-neutral-500 text-sm"
  }, [_vm._v("Total Saved Data")]), _vm._v(": "), _c('strong', [_vm._v(_vm._s(_vm.totalSavedData))])])])]) : _vm._e(), _vm.isReadyToSend ? _c('div', [_c('div', {
    staticClass: "text-sm text-neutral-400 flex gap-2 items-center justify-between bg-grey py-2 px-3 rounded relative"
  }, [_c('Attach', {
    attrs: {
      "color": '#ADADAD'
    }
  }), _c('span', {
    staticClass: "overflow-ellipsis w-80 whitespace-nowrap overflow-hidden"
  }, [_vm._v(_vm._s(_vm.seletedFile.name))]), _c('span', {
    staticClass: "text-sm text-neutral-400 inline-block w-24 text-right"
  }, [_vm._v(" (" + _vm._s(_vm.bytesToSize(_vm.seletedFile.size)) + ") ")])], 1)]) : _vm._e()])]), _vm._m(0), _vm.error ? _c('div', {
    staticClass: "text-system-error mt-2 text-sm"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e(), _c('div', {
    staticClass: "upload-submit"
  }, [_c('button', {
    class: {
      disabled: !_vm.isEligibleToUpload
    },
    attrs: {
      "disabled": !_vm.isEligibleToUpload || _vm.isUploading
    },
    on: {
      "click": _vm.submit
    }
  }, [!_vm.isUploading ? [_vm._v("Upload")] : _c('LoadingDot')], 2)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notes text-left"
  }, [_vm._v(" Download the template "), _c('a', {
    staticClass: "text-yellow-primary font-semibold",
    attrs: {
      "target": "_blank",
      "href": "https://docs.google.com/spreadsheets/d/1-3TihtkCEk9dXVdNeLd9QYJfCMOTDJ0oC3BqLNW6reU/edit?usp=sharing"
    }
  }, [_vm._v("here")]), _vm._v(". (The template must be in .csv format & please make sure it doesn't contain any special characters) ")]);
}]

export { render, staticRenderFns }