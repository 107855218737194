// import 'core-js/actual/promise'
// import 'core-js/actual/string'
// import 'core-js/actual/array'
import Vue from 'vue'
// import BootstrapVue from "bootstrap-vue";
import App from './App'
import router from './router'
import store from './store'
window.axios = require('axios')
window.queryString = require('query-string')

import VueProgressBar from 'vue-progressbar'
import VeeValidate from 'vee-validate'
import VuejsDialog from 'vuejs-dialog'

import { ClientTable } from 'vue-tables-2'
import VueGoodTablePlugin from 'vue-good-table'
import VueCurrencyFilter from 'vue-currency-filter'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import mapInit from 'highcharts/modules/map'
import More from 'highcharts/highcharts-more'
import solidgauge from 'highcharts/modules/solid-gauge'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import Datetime from 'vue-datetime'
import VueClipboard from 'vue-clipboard2'
import VueKonva from 'vue-konva'

import 'vue-datetime/dist/vue-datetime.css'

import 'vue-good-table/dist/vue-good-table.css'

import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import VueQuillEditor from 'vue-quill-editor'
import 'tailwindcss/tailwind.css'
import Toasted from 'vue-toasted'

Vue.config.devtools = true
Vue.use(VueKonva)
Vue.use(Toasted)
Vue.use(VeeValidate)
Vue.use(VueProgressBar, {
  color: '#34BFA3',
  failedColor: 'red',
  height: '2px'
})

Vue.use(VuejsDialog)
Vue.use(ClientTable)
Vue.use(VueGoodTablePlugin)
Vue.use(VueQuillEditor)
stockInit(Highcharts)
mapInit(Highcharts)
More(Highcharts)
solidgauge(Highcharts)

Vue.use(HighchartsVue)
// Vue.user(Highcharts)
Vue.use(VueCurrencyFilter, {
  symbol2: 'Rp.',
  symbol: '',
  thousandsSeparator: '.',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})
Vue.use(VueMoment, {
  moment
})
Vue.use(Datetime)
Vue.use(VueClipboard)

// Initialize Axios
if (process.env.NODE_ENV === 'production') {
  window.axios.defaults.baseURL = 'https://api3.g2academy.co/'
} else if (process.env.NODE_ENV === 'staging') {
  window.axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL_MAIN}/`
  // window.axios.defaults.baseURL = 'https://staging-api.g2academy.co/'
  // window.axios.defaults.baseURL = 'http://staging-api.g2academy.co/'
} else if (process.env.NODE_ENV === 'beta') {
  window.axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL_MAIN}/`
  // window.axios.defaults.baseURL = 'http://localhost:8383/'
  // window.axios.defaults.baseURL = 'https://beta-api.g2academy.co/'
  // window.axios.defaults.baseURL = 'http://beta-api.g2academy.co/'
  // window.axios.defaults.baseURL = 'https://api3.g2academy.co/'
} else if (process.env.NODE_ENV === 'dev') {
  window.axios.defaults.baseURL = 'http://localhost:8383/'
} else {
  window.axios.defaults.baseURL = 'http://localhost:8383/'
  // window.axios.defaults.baseURL = 'https://beta-api.g2academy.co/'
}

window.axios.defaults.headers.post['Content-Type'] = 'application/json'
window.axios.defaults.headers.common['X-G2-TimeZone-Offset'] = new Date().getTimezoneOffset()
if (store.getters['customer/customer'].token) {
  window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['customer/customer'].token
} else {
  window.axios.defaults.headers.common['Authorization'] = ''
}

// Axios Request interceptor
axios.interceptors.request.use(
  function (config) {
    router.app.$Progress.start()
    return config
  },
  function (error) {
    router.app.$Progress.fail()
    return Promise.reject(error)
  }
)

// Axios Response interceptor
axios.interceptors.response.use(
  function (resp) {
    router.app.$Progress.finish()
    return resp
  },
  function (error) {
    const token = store.getters['customer/customer'].token
    let isAlreadyExpired = false
    if (token) {
      const tokenExpiredAt = parseJwt(token).exp
      isAlreadyExpired = new Date().getTime() > new Date(tokenExpiredAt * 1000).getTime()
    }

    router.app.$Progress.fail()
    if (error.response.status === 403) {
      store.dispatch('common/set403Page', { value: true })
      store.dispatch('common/hideLoading')
    }

    if ((error.response.status === 401 && isAlreadyExpired) || (error.response.status === 403 && isAlreadyExpired)) {
      store.dispatch('common/setUnauthorized', { value: true })
      store.dispatch('common/hideLoading')
    }

    if (error.response.status === 502 || error.response.status === 503 || error.response.status === 0) {
      router.push('/pages/server-down')
    }

    return Promise.reject(error.response.data)
  }
)

const parseJwt = (token) => {
  if (!token) return null
  var base64Url = token?.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['customer/isAuthenticated']
  if (isLoggedIn) {
    const token = store.getters['customer/customer'].token
    const tokenExpiredAt = parseJwt(token).exp
    const isAlreadyExpired = new Date().getTime() > new Date(tokenExpiredAt * 1000).getTime()
    if (isAlreadyExpired) {
      store.dispatch('common/setUnauthorized', { value: true })
      store.dispatch('common/hideLoading')
    }
  }
  router.app.$Progress.start()
  next()
})

router.afterEach((to, from) => {
  router.app.$Progress.finish()
})

Vue.config.productionTip = false

// Vue.use(BootstrapVue)
//Vue.use(VueAxios, axios)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
