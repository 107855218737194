<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5625 10.25H2.1875C1.73995 10.25 1.31072 10.0722 0.994257 9.75574C0.67779 9.43927 0.5 9.01005 0.5 8.5625V2.1875C0.5 1.73995 0.67779 1.31072 0.994257 0.994257C1.31072 0.67779 1.73995 0.5 2.1875 0.5H8.5625C9.01005 0.5 9.43927 0.67779 9.75574 0.994257C10.0722 1.31072 10.25 1.73995 10.25 2.1875V8.5625C10.25 9.01005 10.0722 9.43927 9.75574 9.75574C9.43927 10.0722 9.01005 10.25 8.5625 10.25ZM19.8125 10.25H13.4375C12.9899 10.25 12.5607 10.0722 12.2443 9.75574C11.9278 9.43927 11.75 9.01005 11.75 8.5625V2.1875C11.75 1.73995 11.9278 1.31072 12.2443 0.994257C12.5607 0.67779 12.9899 0.5 13.4375 0.5H19.8125C20.2601 0.5 20.6893 0.67779 21.0057 0.994257C21.3222 1.31072 21.5 1.73995 21.5 2.1875V8.5625C21.5 9.01005 21.3222 9.43927 21.0057 9.75574C20.6893 10.0722 20.2601 10.25 19.8125 10.25ZM8.5625 21.5H2.1875C1.73995 21.5 1.31072 21.3222 0.994257 21.0057C0.67779 20.6893 0.5 20.2601 0.5 19.8125V13.4375C0.5 12.9899 0.67779 12.5607 0.994257 12.2443C1.31072 11.9278 1.73995 11.75 2.1875 11.75H8.5625C9.01005 11.75 9.43927 11.9278 9.75574 12.2443C10.0722 12.5607 10.25 12.9899 10.25 13.4375V19.8125C10.25 20.2601 10.0722 20.6893 9.75574 21.0057C9.43927 21.3222 9.01005 21.5 8.5625 21.5ZM19.8125 21.5H13.4375C12.9899 21.5 12.5607 21.3222 12.2443 21.0057C11.9278 20.6893 11.75 20.2601 11.75 19.8125V13.4375C11.75 12.9899 11.9278 12.5607 12.2443 12.2443C12.5607 11.9278 12.9899 11.75 13.4375 11.75H19.8125C20.2601 11.75 20.6893 11.9278 21.0057 12.2443C21.3222 12.5607 21.5 12.9899 21.5 13.4375V19.8125C21.5 20.2601 21.3222 20.6893 21.0057 21.0057C20.6893 21.3222 20.2601 21.5 19.8125 21.5Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#F7931E'
    },
    width: {
      type: String,
      default: '22'
    },
    height: {
      type: String,
      default: '22'
    }
  }
}
</script>
