<template>
  <div class="sidebar w-64 fixed left-0 h-full bg-neutral-500 z-0 text-white overflow-y-auto" :style="{ paddingTop: `${customer?.customerRoles?.length > 1 ? '76px' : '64px'}` }">
    <div v-for="(nav, index) in navItems" :key="'nav-' + index">
      <router-link v-if="!nav.children" :to="nav.url" class="flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow">
        <component :is="nav.icon" />
        <span class="font-medium text-base">{{ nav.name }}</span>
      </router-link>
      <template v-else>
        <input
          type="radio"
          :value="nav.name"
          name="radio"
          :id="'checkbox' + index"
          v-model="selected.item"
          class="hidden"
          @click="uncheck(nav)"
          :key="'input + index'"
          :checked="selected.active === nav.active"
        />
        <label :for="'checkbox' + index" class="cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow" :key="'label + index'">
          <div class="flex items-center gap-2">
            <component :is="nav.icon" />
            <span class="font-medium text-base">{{ nav.name }}</span>
          </div>
          <Chevron :direction="selected.item === nav.name ? 'up' : 'down'" width="12" color="white" />
        </label>
        <div class="bg-neutral" v-if="selected.item === nav.name && !nav.isHaveGrandChild" @click="refreshPage">
          <router-link :to="childNav.url" v-for="(childNav, idx) in nav.children" :key="'child-nav-' + idx" class="pl-10 flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow">
            <component :is="childNav.icon" />
            <span class="font-medium text-sm">{{ childNav.name }}</span>
            <span v-show="childNav.name === 'Document' && isNotoficaate"><img src="@/assets/images/activition.png" alt="Message" class="hover:bg-yellow w-4 h-4" /></span>
          </router-link>
        </div>
        <div class="bg-neutral" v-if="selected.item === nav.name && nav.isHaveGrandChild">
          <template>
            <div v-for="(grandChild, grandChildIdx) in nav.children" :key="'grandChild-' + grandChildIdx" class="pl-4">
              <input type="radio" :value="grandChild.name" :id="'grandChildIdx' + grandChildIdx" v-model="selected.child" class="hidden" @click="uncheck2(grandChild)" :key="'input + grandChildIdx'" />
              <label
                :for="'grandChildIdx' + grandChildIdx"
                class="cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow"
                :key="'label + grandChildIdx'"
              >
                <div class="flex items-center gap-2">
                  <component :is="grandChild.icon" />
                  <span class="font-medium text-base">{{ grandChild.name }}</span>
                </div>
                <Chevron :direction="selected.child === grandChild.name ? 'up' : 'down'" width="12" color="white" />
              </label>
              <div class="bg-neutral" v-if="selected.child === grandChild.name" @click="refreshPage">
                <router-link
                  :to="grandChildNav.url"
                  v-for="(grandChildNav, idx) in grandChild.children"
                  :key="'grand-child-nav-' + idx"
                  class="pl-10 flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-neutral-300"
                >
                  <component :is="grandChildNav.icon" />
                  <span class="font-medium text-sm">{{ grandChildNav.name }}</span>
                  <span v-show="grandChildNav.name === 'Document' && isNotoficaate"><img src="@/assets/images/activition.png" alt="Message" class="hover:bg-neutral-300 w-4 h-4" /></span>
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Chevron')
  },
  props: {
    navItems: Array
  },
  data() {
    return {
      refreshLoop: 0,
      navChildActive: '',
      selected: {
        item: null,
        child: null,
        grandChild: null
      },
      grandChildActivePath: []
    }
  },
  created() {
    let self = this
    this.navItems.forEach((item, index) => {
      if (item.children) {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].url === self.$route.path || self.$route.path.includes(item.children[i].url)) {
            this.selected.item = item.name
          }
        }
      }
      if (item.isHaveGrandChild) {
        item.children.forEach((child, childIndex) => {
          child.children.forEach((grandChild, grandChildIndex) => {
            if (grandChild.url === self.$route.path) {
              this.selected.item = item.name
              this.selected.child = child.name
            }
          })
        })
      }
    })
  },
  computed: {
    ...mapGetters('client', ['isNotifier']),
    ...mapGetters('customer', ['customer']),
    isNotoficaate() {
      return this.isNotifier
    }
  },
  methods: {
    subIsActive(input) {
      return this.$route.path.includes(input)
    },
    refreshPage() {
      this.$store.dispatch('common/setRefreshPage', { value: this.refreshLoop++ })
    },
    uncheck(selected) {
      if (selected === this.selected.item) {
        this.selected.item = false
      } else {
        this.selected.item = selected
      }
    },
    uncheck2(grandChild) {
      if (grandChild === this.selected.child) {
        this.selected.child = false
      } else {
        this.selected.child = grandChild
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.router-link-exact-active,
.active {
  background-color: #f7931e;
  box-shadow: inset 0px 10px 2px -10px #000, inset 0px -10px 2px -10px #000;
}
.sidebar {
  top: 0;
}
</style>
