<template>
  <div class="header flex justify-between fixed top-0 left-0 w-full z-20 items-center bg-neutral py-3 px-4 text-white">
    <div class="logo">
      <img src="~@/assets/images/logo-admin.svg" />
    </div>
    <div class="relative">
      <div class="flex items-center gap-5 pr-4">
        <a href="https://g2academy.atlassian.net/servicedesk/customer/portal/2" target="_blank"><HelpCircle /></a>
        <div class="text-white border-r border-white h-10 flex flex-col justify-center pr-6 relative cursor-pointer">
          <div class="menu-item">
            <div class="flex justify-center items-center cursor-pointer">
              <span class="inline-block relative">
                <span class="bg-system-error text-white absolute -right-1 -top-1 text-smallest w-4 h-4 flex justify-center items-center rounded-full">{{ notificationCount }}</span>
                <Notification color="white" />
              </span>
            </div>
            <div class="submenu absolute right-0 shadow-sm w-screen max-w-md z-10"><NotificationMenu @refetch="refetchNotification()" /></div>
          </div>
        </div>
        <div class="flex items-center gap-3">
          <div class="p-1 h-10 w-10 bg-yellow rounded-full border border-neutral-500 flex justify-center items-center">
            <p :class="`font-bold text-white ${customer.name && customer.name !== '' && forInitial(customer.name).length > 1 ? '' : 'text-lg'}`">
              {{ customer.name && customer.name !== '' ? forInitial(customer.name) : 'A' }}
            </p>
          </div>
          <div class="customer cursor-pointer text-left">
            <p class="font-bold text-base mb-0">{{ customer.name }}</p>
            <div class="text-xs flex items-center" v-if="customer && customer.customerRoles">
              <div>{{ isGetFromLocalStorage ? customerNameLocStorage : customerSelected.name }}</div>
              <div class="pl-2 pt-1" v-if="customerOptions.length >= 2">
                <Chevron direction="down" width="10" color="white" />
                <div class="dropdown invisible opacity-0 ease-linear transition-all duration-300 pt-2 left-24 top-10 transform absolute cursor-default z-10">
                  <div class="bg-neutral-500 text-left shadow-small py-4 px-3 rounded-lg">
                    <div v-for="(person, index) in customerOptions" :key="index" class="cursor-pointer" :class="index + 1 === customerOptions.length ? '' : 'mb-2'" @click="clickCustomer(person)">
                      {{ person.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="logout ml-3">
            <span class="cursor-pointer"><Chevron direction="down" width="12" color="white" /></span>
            <div class="dropdown invisible opacity-0 ease-linear transition-all duration-300 pt-6 right-0 top-full transform absolute cursor-default z-10">
              <div class="bg-neutral-500 shadow-small py-4 px-6 rounded-lg">
                <div class="mb-1">
                  <router-link to="/change-password" class="whitespace-nowrap inline-block text-sm font-semibold"> Change Password</router-link>
                </div>
                <div>
                  <span @click="logout" class="text-sm inline-block font-semibold cursor-pointer">Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    HelpCircle: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/HelpCircle'),
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Chevron'),
    Notification: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Notification'),
    NotificationMenu: () => import(/* webpackChunkName: "NotificationMenu" */ '@/components/Notification/NotificationMenu')
  },
  data() {
    return {
      customerSelected: '',
      isGetFromLocalStorage: true,
      customerDummy: [
        {
          id: 2,
          name: 'Accelerice',
          createdDate: '2021-10-08T06:10:11.157+0000'
        },
        {
          id: 3,
          name: 'G-trust Learning',
          createdDate: '2021-10-08T06:10:11.157+0000'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('customer', ['customer']),
    ...mapGetters('notification', ['notificationCount']),
    userId() {
      return this.customer?.id
    },
    customerNameLocStorage() {
      return localStorage.getItem('client-name')
    },
    customerNameTemporary() {
      return this.customerSelected.name
    },
    customerOptions() {
      return this.customer && this.customer.customerRoles.length !== 0
        ? this.customer.customerRoles
            .map((person) => {
              return {
                ...person.customer
              }
            })
            .reverse()
        : []
    }
  },
  mounted() {
    this.customerOptions
  },
  watch: {
    customerNameTemporary() {
      location.reload()
    }
  },
  methods: {
    ...mapActions('notification', ['getNotifications', 'getNotificationCount']),
    ...mapActions('instructur', ['SYNC_INSTRUCTOR_EC']),
    clickCustomer(person) {
      this.isGetFromLocalStorage = false
      localStorage.setItem('client-name', person.name)
      localStorage.setItem('client', person.id)
      let params = queryString.stringify({
        ...{
          customerId: person.id
        }
      })

      this.SYNC_INSTRUCTOR_EC({
        params
      })
      this.customerSelected = person
    },
    refetchNotification() {
      this.getNotifications({
        userId: this.userId,
        params: {
          page: 0,
          size: 500,
          platform: 'ADMIN_CENTER'
        }
      }).then(() => {
        this.refetchNotificationCount()
      })
    },
    refetchNotificationCount() {
      this.getNotificationCount({
        userId: this.userId,
        params: {
          platform: 'ADMIN_CENTER'
        }
      })
    },
    forInitial: function (name) {
      const arr = name.split(' ')
      let newArr = []
      newArr.push(arr[0].slice(0, 1).toUpperCase())
      if (arr.length > 1) newArr.push(arr[1].slice(0, 1).toUpperCase())
      return newArr.join('')
    },
    logout: function () {
      self = this
      this.$store.dispatch('customer/LOGOUT').then(function (resp) {
        self.$router.push('/pages/login')
      })
    },
    profile: function () {
      this.$router.push('/customer/' + this.$store.getters['customer/customer'].id)
    }
  }
}
</script>
<style lang="scss" scoped>
.z-1000 {
  z-index: 1000;
}
.logout,
.customer {
  &:hover .dropdown {
    visibility: visible;
    opacity: 1;
    padding-top: 15px;
  }
}
.submenu {
  visibility: hidden;
  opacity: 0;
  padding-top: 0;
  transition: all 300ms ease-out;
}
.menu-item:hover .submenu {
  visibility: visible;
  opacity: 1;
  padding-top: 8px;
}
</style>
