import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { isNotEmptyErrorForm, showVueToast } from '@/utils'
import moment from 'moment'
import { mapActions } from 'vuex'
import ImageUploadMixin from '@/mixins/image-upload'

const CONFIG = {
  name: 'Name',
  email: 'Email',
  firstJoinDate: 'First Joined',
  departmentId: 'Department'
}
export default {
  name: 'EmployeeForm',
  mixins: [ImageUploadMixin],

  mounted() {},
  data() {
    return {
      dropdownItems: {
        religions: [],
        ptkps: [],
        departments: [],
        grades: [],
        supervisors: [],
        levels: [
          { id: 1, name: 1 },
          { id: 2, name: 2 },
          { id: 3, name: 3 },
          { id: 4, name: 4 },
          { id: 5, name: 5 },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 },
          { id: 9, name: 9 },
          { id: 10, name: 10 },
          { id: 11, name: 11 },
          { id: 12, name: 12 }
        ],
        statuses: [],
        maritals: [
          { id: 'SINGLE', name: 'Tidak Menikah' },
          { id: 'MARRIED', name: 'Menikah' },
          { id: 'WIDOW', name: 'Duda/Janda' }
        ]
      },
      selectedItems: { religion: '', ptkp: '', department: '', grade: '', level: '', status: '', marital: '', spv: '' },
      genders: { isMale: false, isFemale: false, name: '', value: '' },
      selected: 'Employee Data',
      formErrors: [],
      options: {
        id: 0,
        photoImage: '',
        name: '',
        email: '',
        gender: null,
        firstJoinDate: '',
        phoneNumber: '',
        limit: 7,
        page: 0,
        startDate: '',
        departmentId: '',
        endDate: '',
        customerId: 1,
        lastContractDate: '',
        noKtp: '',
        placeBirth: '',
        dateBirth: '',
        address: '',
        religionId: null,
        statusMaritalId: null,
        child: null,
        otherEmail: '',
        lastEducation: '',
        instituteName: '',
        nik: '',
        role: '',
        statusId: null,
        gradeId: null,
        level: null,
        bpjsTkNo: '',
        bpjsKesehatanNo: '',
        npwpNo: '',
        npwpName: '',
        npwpAddress: '',
        npwpDomisili: '',
        contactName: null,
        contactRelation: '',
        contactPhone: null,
        contactAddress: '',
        g2employeeId: ''
      },
      isReset: false,
      departementList: [
        {
          id: 2,
          value: 'Alliance + B2B'
        },
        {
          id: 3,
          value: 'B2C'
        },
        {
          id: 4,
          value: 'CEO'
        },
        {
          id: 5,
          value: 'Edu & Curriculum'
        },
        {
          id: 6,
          value: 'Finance Accounting Tax'
        },
        {
          id: 7,
          value: 'G2Lab'
        },
        {
          id: 8,
          value: 'HRD'
        },
        {
          id: 9,
          value: 'Legal, IR & Compliance'
        },
        {
          id: 10,
          value: 'Marketing'
        },
        {
          id: 11,
          value: 'Platform and Data Engineer'
        },
        {
          id: 12,
          value: 'Prakerja'
        }
      ],

      //   selectedTab: 'Attendance',
      label: '',
      perPage: 5,
      showProgress: true,
      counterProgress: 0,
      idForm: 0,
      modeForm: null,
      columns: [
        { field: 'id', label: 'Id' },
        { field: 'name', label: 'Name' },
        { field: 'hp', label: 'Phone Number' },
        { field: 'email', label: 'Email' },
        { field: 'password', label: 'Password' }
      ],
      columns2: [
        { field: 'attendanceDate', label: 'Date' },
        { field: 'checkIn', label: 'Start' },
        { field: 'checkOut', label: 'Leave' }
      ],
      pieData: null,
      masters: {},
      customer: {},
      levelId: 0,
      locked: false,
      stillLoading: true,
      edited: {
        isEditDetai: false
      },
      messageErrorDepartment: '',
      messageErrorSupervisor: ''
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('employee', ['GET_EMPLOYEE_CHART']),
    syncAllDataRequired() {
      // const getData1 = Promise.resolve(this.attendanceData())
      const getData2 = Promise.resolve(this.getListReligion())
      const getData3 = Promise.resolve(this.getListMaritals())
      const getData4 = Promise.resolve(this.getListDepartment())
      const getData5 = Promise.resolve(this.getListGrade())
      const getData6 = Promise.resolve(this.getListStatus())
      const getData7 = Promise.resolve(this.getListSupervisor())

      Promise.all([getData2, getData3, getData4, getData5, getData6, getData7]).then(() => {
        this.initData()
      })
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    async initData() {
      // if (this.$route.meta.name == 'customer' && this.$route.meta.mode == 'Edit' && this.$route.meta.mode == 'Detail') {
      //   if (this.$route.params.id != this.$store.getters['customer/customer'].id) {
      //     this.locked = true
      //   }
      // }
      // if (this.locked) {
      //   return null
      // }
      // this.counterProgress = 0
      this.customer = this.$store.getters['customer/customer']
      if (this.modeForm == 'Edit' || this.modeForm == 'Detail') {
        const customerId = this.clientId
        this.$store
          .dispatch('employee/GET_EMPLOYEE', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            customerId: customerId
          })
          .then((resp) => {
            if (resp.data.code == 200) {
              let tempData = this.$store.getters['employee/employee']
              for (var key in tempData) {
                this.options[key] = tempData[key]
              }
              this.setGender(resp.data.data.gender)
              const religion = resp?.data?.data?.religion || null
              const department = resp?.data?.data?.department || null
              const statusMarital = resp?.data?.data?.statusMarital || null
              const grade = resp?.data?.data?.grade || null
              const spv = resp?.data?.data?.supervisor || null
              const status = resp?.data?.data?.status || null
              const level = resp?.data?.data?.level || null
              const marital = resp?.data?.data?.maritalStatus || null
              this.selectedItems.religion = this.dropdownItems.religions.filter((value) => value.id === religion?.id)[0]
              this.selectedItems.department = this.dropdownItems.departments.filter((value) => value.id === department?.id)[0]
              this.selectedItems.ptkp = this.dropdownItems.ptkps.filter((value) => value.id === statusMarital?.id)[0]
              this.selectedItems.grade = this.dropdownItems.grades.filter((value) => value.id === grade?.id)[0]
              this.selectedItems.spv = this.dropdownItems.supervisors.filter((value) => value.id === spv?.id)[0]
              this.selectedItems.status = this.dropdownItems.statuses.filter((value) => value.id === status?.id)[0]
              this.selectedItems.level = this.dropdownItems.levels.filter((value) => value.id === level)[0]
              this.selectedItems.marital = this.dropdownItems.maritals.filter((value) => value.id === marital)[0]
              this.$forceUpdate()
              this.counterProgress = 100
              this.showProgress = false
              this.stillLoading = false
              this.hideLoading()
            } else {
              // this.$swal(resp.data.message)
              this.counterProgress = 100
              this.showProgress = false
              this.stillLoading = false
              this.hideLoading()
            }
          })

        // try {
        //   const resp = await this.GET_EMPLOYEE_CHART({
        //     masterId: this.idForm,
        //     params: { customerId, startDate: this.options.startDate, endDate: this.options.endDate }
        //   })
        //   this.resetChart()
        //   this.label = resp.attendance_date_name
        //   this.pieData[0].count = resp.total_completed
        //   this.pieData[1].count = resp.total_start
        //   this.pieData[2].count = resp.total_leave
        //   this.pieData[3].count = resp.total_alpha
        // } catch {
        //   // showVueToast('There is a problem retrieving data', 'error', 2000)
        // }
      } else {
        this.counterProgress = 100
        this.showProgress = false
        this.stillLoading = false
        this.hideLoading()
      }
    },
    submitData() {
      if (!this.allValidation()) return
      this.showLoading()
      if (this.modeForm == 'Edit') {
        const payload = {
          photoImage: this.options.photoImage,
          name: this.options?.name,
          gender: this.genders.value,
          phoneNumber: this.options.phoneNumber,
          noKtp: this.options.noKtp,
          placeBirth: this.options.placeBirth,
          dateBirth: this.options.dateBirth,
          address: this.options.address,
          religionId: this.selectedItems.religion?.id ? this.selectedItems.religion?.id : null,
          statusPTKPId: this.selectedItems.ptkp?.id ? this.selectedItems.ptkp?.id : null,
          maritalStatus: this.selectedItems.marital?.id ? this.selectedItems.marital?.id : null,
          child: parseInt(this.options.child),
          email: this.options.email,
          otherEmail: this.options.otherEmail,
          lastEducation: this.options.lastEducation,
          instituteName: this.options.instituteName,
          departmentId: this.selectedItems.department?.id ? this.selectedItems.department?.id : null,
          role: this.options.role,
          gradeId: this.selectedItems.grade?.id ? this.selectedItems.grade?.id : null,
          spvId: this.selectedItems.spv?.id ? this.selectedItems.spv?.id : null,
          level: this.selectedItems.level?.id ? this.selectedItems.level?.id : null,
          statusId: this.selectedItems.status?.id ? this.selectedItems.status?.id : null,
          firstJoinDate: this.options.firstJoinDate,
          lastContractDate: this.options.lastContractDate,
          bpjsTkNo: this.options.bpjsTkNo,
          bpjsKesehatanNo: this.options.bpjsKesehatanNo,
          npwpNo: this.options.npwpNo,
          npwpName: this.options.npwpName,
          npwpAddress: this.options.npwpAddress,
          npwpDomisili: this.options.npwpDomisili,
          contactName: this.options.contactName,
          contactRelation: this.options.contactRelation,
          contactPhone: this.options.contactPhone,
          contactAddress: this.options.contactAddress,
          nik: '',
          customerId: localStorage.getItem('client')
        }

        this.$store
          .dispatch('employee/UPDATE_EMPLOYEE', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            payload: payload
          })
          .then((resp) => {
            this.hideLoading()
            showVueToast('The process is successful!', 'success', 2000)
            if (resp.data.status == '200 OK') {
              this.$router.push('/employee')
            }
          })
          .catch((resp) => {
            this.hideLoading()
            if (resp.code === 400) {
              if (resp?.message) {
                showVueToast(resp?.message, 'error', 3000)
              } else {
                showVueToast('Your request is failed!', 'error', 3000)
              }
            } else {
              showVueToast('Your request is failed!', 'error', 3000)
            }
          })
      } else {
        const customerId = this.clientId
        const payload = {
          photoImage: this.options.photoImage,
          name: this.options?.name,
          gender: this.genders.value,
          phoneNumber: this.options.phoneNumber,
          noKtp: this.options.noKtp,
          placeBirth: this.options.placeBirth,
          dateBirth: this.options.dateBirth,
          address: this.options.address,
          religionId: this.selectedItems.religion?.id ? this.selectedItems.religion?.id : null,
          statusPTKPId: this.selectedItems.ptkp?.id ? this.selectedItems.ptkp?.id : null,
          maritalStatus: this.selectedItems.marital?.id ? this.selectedItems.marital?.id : null,
          child: parseInt(this.options.child),
          email: this.options.email,
          otherEmail: this.options.otherEmail,
          lastEducation: this.options.lastEducation,
          instituteName: this.options.instituteName,
          departmentId: this.selectedItems.department?.id ? this.selectedItems.department?.id : null,
          role: this.options.role,
          gradeId: this.selectedItems.grade?.id ? this.selectedItems.grade?.id : null,
          spvId: this.selectedItems.spv?.id ? this.selectedItems.spv?.id : null,
          level: this.selectedItems.level?.id ? this.selectedItems.level?.id : null,
          statusId: this.selectedItems.status?.id ? this.selectedItems.status?.id : null,
          firstJoinDate: this.options.firstJoinDate,
          lastContractDate: this.options.lastContractDate,
          bpjsTkNo: this.options.bpjsTkNo,
          bpjsKesehatanNo: this.options.bpjsKesehatanNo,
          npwpNo: this.options.npwpNo,
          npwpName: this.options.npwpName,
          npwpAddress: this.options.npwpAddress,
          npwpDomisili: this.options.npwpDomisili,
          contactName: this.options.contactName,
          contactRelation: this.options.contactRelation,
          contactPhone: this.options.contactPhone,
          contactAddress: this.options.contactAddress,
          nik: this.options.nik,
          customerId: localStorage.getItem('client')
        }

        this.$store
        .dispatch('employee/CREATE_EMPLOYEE', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            customerId: customerId,
            payload: payload
          })
          .then((resp) => {
            this.hideLoading()
            showVueToast('The process is successful!', 'success', 2000)
            if (resp.data.status == '200 OK') {
              if (this.customer.role_id > 2) {
                this.initData()
              } else {
                this.$router.push('/employee')
              }
            }
          })
          .catch((resp) => {
            this.hideLoading()
            if (resp.status == '400 BAD_REQUEST') {
              showVueToast(resp.message, 'error', 3000)
            }
          })
      }
    },
    handleFileSelect(evt, item, field) {
      this.uploadImage(evt, item, field)
    },
    changeImage(item) {
      this.$refs[item].click()
    },
    allValidation() {
      let pass = true
      const error = isNotEmptyErrorForm(this.options)
      this.formErrors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (this.options.email === '') {
        this.formErrors['email'] = "Email can't be empty!"
        pass = false
      } else if (!emailPattern.test(this.options.email)) {
        this.formErrors['email'] = 'Incorrect email format!'
        pass = false
      } else if (this.options.firstJoinDate === '') {
        this.formErrors['firstJoinDate'] = "First Joined can't be empty!"
        pass = false
      }
      if (this.selectedItems.department === null) {
        this.messageErrorDepartment = "Department can't be empty!"
        pass = false
      }
      if (this.selectedItems.spv === null) {
        this.messageErrorSupervisor = "Direct supervisor can't be empty!"
        pass = false
      }
      if (!this.options.phoneNumber) {
        this.formErrors['phoneNumber'] = "Phone number can't be empty!"
        pass = false
      }

      if (this.selectedItems.status === null) {
        this.formErrors['statusId'] = "Status can't be empty!"
        pass = false
      }
      if (!this.options.noKtp) {
        this.formErrors['idNumber'] = "ID Number can't be empty!"
        pass = false
      }
      return pass
    },
    dropzoneRemovedSuccess: function (file, response) {
      this.profileLogo = ''
    },
    rowStyleClassFn(row) {
      let result = 'default'

      return result
    },
    attendanceData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let date = new Date()
      let startDate = date.setDate(date.getDate() - 7)
      let endDate = new Date()
      this.options.startDate = moment(startDate).local().format('YYYY-MM-DD')
      this.options.endDate = moment(endDate).local().format('YYYY-MM-DD')
      let paramsTemp = queryString.stringify({
        ...{
          customerId: this.clientId,
          employeeId: this.idForm,
          startDate: moment(startDate).local().format('YYYY-MM-DD'),
          endDate: moment(endDate).local().format('YYYY-MM-DD')
        }
      })
      this.$store
        .dispatch('attendance/GET_LIST_ATTENDANCE', {
          masterType: this.$route.meta.name,
          params: paramsTemp
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
          this.hideLoading()
        })
    },
    async onSearch() {
      this.options.page = 0
      let paramsTemp = queryString.stringify({
        ...{
          customerId: this.clientId,
          employeeId: this.idForm
        },
        ...this.options
      })
      this.$store.dispatch('attendance/GET_LIST_ATTENDANCE', {
        masterType: this.$route.meta.name,
        params: paramsTemp
      })
      try {
        const resp = await this.GET_EMPLOYEE_CHART({
          masterId: this.idForm,
          params: { customerId: this.clientId, startDate: this.options.startDate, endDate: this.options.endDate }
        })
        this.resetChart()
        this.label = resp.attendance_date_name
        this.pieData[0].count = resp.total_completed
        this.pieData[1].count = resp.total_start
        this.pieData[2].count = resp.total_leave
        this.pieData[3].count = resp.total_alpha
      } catch {
        // showVueToast('There is a problem retrieving data', 'error', 2000)
      }
    },
    resetFilter() {
      ;(this.options.startDate = ''), (this.options.endDate = ''), this.showLoading()
      this.attendanceData()
    },
    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage - 1
      let paramsTemp = queryString.stringify({
        ...{
          limit: params.currentPerPage,
          page: params.currentPage,
          size: params.currentPerPage,
          customerId: this.clientId,
          employeeId: this.idForm
        },
        ...this.options
      })
      this.$store.dispatch('attendance/GET_LIST_ATTENDANCE', {
        masterType: this.$route.meta.name,
        params: paramsTemp
      })
    },
    resetChart() {
      this.pieData = [
        {
          title: 'Total Start & Leave',
          color: '#F7931E',
          count: 0
        },
        {
          title: 'Total Start',
          color: '#00B3BE',
          count: 0
        },
        {
          title: 'Total Leave',
          color: '#A967AA',
          count: 0
        },
        {
          title: 'Total Alpha',
          color: '#EB5757',
          count: 0
        }
      ]
    },
    changeGender() {
      if (this.genders.isMale) {
        this.genders.isFemale = false
        this.genders.name = 'Male'
        this.genders.value = 'MALE'
      }
      if (this.genders.isFemale) {
        this.genders.isMale = false
        this.genders.name = 'Female'
        this.genders.value = 'FEMALE'
      }
    },
    changeMale() {
      this.genders.isFemale = false
      this.genders.name = 'Male'
      this.genders.value = 'MALE'
    },
    changeFemale() {
      this.genders.isMale = false
      this.genders.name = 'Female'
      this.genders.value = 'FEMALE'
    },
    getListReligion() {
      this.$store.dispatch('employee/GET_LIST_RELIGION').then((response) => {
        this.dropdownItems.religions = response.data?.data.map((item) => {
          return {
            id: item?.id,
            name: item?.name[0] + item?.name.slice(1).toLowerCase()
          }
        })
      })
    },
    getListMaritals() {
      this.$store.dispatch('employee/GET_LIST_MARITAL_STATUS').then((response) => {
        this.dropdownItems.ptkps = response.data.data.map((item) => {
          return {
            id: item?.id,
            name: `${item.marital_code} (${item?.name.replace('UNTUK KARYAWAN YANG ', '')[0] + item?.name.toLowerCase().replace('untuk karyawan yang ', '').slice(1)}`
          }
        })
      })
    },
    getListDepartment() {
      this.$store.dispatch('employee/GET_LIST_DEPARTMENT').then((response) => {
        this.dropdownItems.departments = response.data.data
      })
    },
    getListGrade() {
      this.$store.dispatch('employee/GET_LIST_GRADE').then((response) => {
        this.dropdownItems.grades = response.data.data
      })
    },
    getListStatus() {
      this.$store.dispatch('employee/GET_LIST_EMPLOYEE_STATUS').then((response) => {
        this.dropdownItems.statuses = response.data.data
      })
    },
    getListSupervisor(name) {
      const params = queryString.stringify({
        name,
      })
      this.$store.dispatch('employee/GET_LIST_SUPERVISOR', {
        params: params
      }).then((response) => {
        this.dropdownItems.supervisors = response.data.data
      })
    },
    selectReligion(value) {
      this.selectedItems.religion = value
    },
    selectMarital(value) {
      this.selectedItems.ptkp = value
    },
    selectDepartment(value) {
      this.selectedItems.department = value
    },
    selectGrade(value) {
      this.selectedItems.grade = value
    },
    selectSupervisor(value) {
      this.selectedItems.spv = value
    },
    selectLevel(value) {
      this.selectedItems.level = value
    },
    selectStatus(value) {
      this.selectedItems.status = value
      this.calculateEndContractDate()
    },
    setGender(value) {
      this.genders.isMale = value === 'MALE' ? true : false
      this.genders.isFemale = value === 'FEMALE' ? true : false
      this.genders.name = value === 'MALE' ? 'Male' : 'Female'
      this.genders.value = value === 'MALE' ? 'MALE' : 'FEMALE'
    },
    setDropdownValue(value) {
      this.selectedItems.grade = this.dropdownItems.grades.find((item) => item.id === value.grade.id)
      this.selectedItems.level = this.dropdownItems.levels.find((item) => item.id === value.level)
      this.selectedItems.status = this.dropdownItems.statuses.find((item) => item.id === value.status.id)
    },
    setSelected(tab) {
      this.selected = tab
      if (tab === 'Attendance') {
        this.onSearch()
      }
    },
    toEditEmployee(id) {
      this.$router.push('/employee/edit/' + id)
    },
    calculateEndContractDate() {
      //if status and joindate fill then calculate end contract date
      if (this.selectedItems.status && this.options.firstJoinDate) {
        if (this.selectedItems.status?.durationInDay > 0) {
          let joinDate = this.options.firstJoinDate
          let days = this.selectedItems.status?.durationInDay
          this.options.lastContractDate = this.addDays(joinDate, days)
        } else {
          this.options.lastContractDate = ''
        }
      }
    },
    addDays(tanggal, days) {
      let tgl = new Date(tanggal)
      tgl.setDate(tgl.getDate() + days)

      let tahun = tgl.getFullYear()
      let bulan = (tgl.getMonth() + 1).toString().padStart(2, '0')
      let hari = tgl.getDate().toString().padStart(2, '0')

      let tanggalBaru = tahun + '-' + bulan + '-' + hari
      return tanggalBaru
    }
  },

  created() {
    this.modeForm = this.$route.meta.mode
    this.idForm = this.$route.params.id || null
    this.syncAllDataRequired()
  },
  computed: {
    clientId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['attendance/list_attendance'] ? this.$store.getters['attendance/list_attendance'] : []
    },
    isEditMode() {
      return this.modeForm === 'Edit'
    },
    totalRecords: function () {
      return this.$store.getters['attendance/paginate'].total ? this.$store.getters['attendance/paginate'].total : 0
    },
    filters: function () {
      return [
        { field: 'startDate', label: 'Start Date' },
        { field: 'endDate', label: 'End Date' }
      ]
    },
    selectedItemStatus() {
      return this.selectedItems.status
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    PieChart: () => import('./PieChart.js'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Trash: () => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    TabNav: () => import(/* webpackChunkName: "TabNav" */ '@/components/Tabs/TabNav'),
    Tab: () => import(/* webpackChunkName: "Tab" */ '@/components/Tabs/Tab'),
    Accordion: () => import(/* webpackChunkName: "Accordion" */ '@/components/Accordion/Accordion'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward')
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
    },
    selectedItemStatus(val) {
      if (val) {
        this.formErrors['statusId'] = ''
        this.options.statusId = val.id
      }
    }
  }
}
