var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("G2Academy Curation Dashboard")]), _c('img', {
    attrs: {
      "src": require("@/assets/amico.png"),
      "alt": ""
    }
  }), _c('p', [_vm._v("Access your dashboard to curation system here")]), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "inline-block w-80 mt-6 mb-16"
  }, [_c('button', {
    staticClass: "button disabled"
  }, [_vm._v("Coming Soon")])])])]);
}]

export { render, staticRenderFns }