<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1.99997H13C13.5304 1.99997 14.0391 2.21068 14.4142 2.58576C14.7893 2.96083 15 3.46954 15 3.99997V4.37497C15 4.40812 14.9868 4.43992 14.9634 4.46336C14.9399 4.4868 14.9082 4.49997 14.875 4.49997H1.125C1.09185 4.49997 1.06005 4.4868 1.03661 4.46336C1.01317 4.43992 1 4.40812 1 4.37497V3.99997C1 3.46954 1.21071 2.96083 1.58579 2.58576C1.96086 2.21068 2.46957 1.99997 3 1.99997H3.5V1.49997C3.50001 1.43229 3.51375 1.36531 3.54041 1.3031C3.56707 1.24088 3.60608 1.18473 3.65508 1.13804C3.70407 1.09135 3.76204 1.05509 3.82547 1.03147C3.8889 1.00784 3.95646 0.997337 4.02406 1.00059C4.29312 1.01403 4.5 1.24497 4.5 1.51403V1.99997H11.5V1.49997C11.5 1.43229 11.5138 1.36531 11.5404 1.3031C11.5671 1.24088 11.6061 1.18473 11.6551 1.13804C11.7041 1.09135 11.762 1.05509 11.8255 1.03147C11.8889 1.00784 11.9565 0.997337 12.0241 1.00059C12.2931 1.01403 12.5 1.24497 12.5 1.51403V1.99997Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.125 5.49997H14.875C14.9082 5.49997 14.9399 5.51314 14.9634 5.53658C14.9868 5.56002 15 5.59182 15 5.62497V13C15 13.5304 14.7893 14.0391 14.4142 14.4142C14.0391 14.7893 13.5304 15 13 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13V5.62497C1 5.59182 1.01317 5.56002 1.03661 5.53658C1.06005 5.51314 1.09185 5.49997 1.125 5.49997ZM11.4874 8.12156C11.7386 7.8524 11.724 7.43054 11.4549 7.17931C11.1857 6.92809 10.7639 6.94264 10.5126 7.21181L7.23519 10.7234L6.12263 9.68033C5.85402 9.42851 5.43213 9.44212 5.18031 9.71072C4.92849 9.97933 4.9421 10.4012 5.21071 10.653L6.81071 12.153C6.93989 12.2741 7.11193 12.3389 7.2889 12.333C7.46587 12.3271 7.63322 12.251 7.75404 12.1216L11.4874 8.12156Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
