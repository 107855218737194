var render = function () {
  var _vm$leaveRequestDetai, _vm$leaveRequestDetai2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-body pb-8"
  }, [_c('div', {
    staticClass: "space-y-4"
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', {
    staticClass: "flex items-center gap-4"
  }, [_c('ArrowForward', {
    staticClass: "cursor-pointer",
    attrs: {
      "direction": "left"
    },
    on: {
      "handleClick": function handleClick($event) {
        return _vm.goBack();
      }
    }
  }), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.pageName))])], 1)]), _c('div', {
    staticClass: "shadow-small card pt-4 pb-4 bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "space-y-4 px-6 w-1/2"
  }, [_c('div', {
    staticClass: "border-b border-neutral-50 py-3 flex justify-between items-center"
  }, [_c('p', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_c('span', {}, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Leave Details ")])]), _c('TextField', {
    attrs: {
      "label": "Employee Name",
      "type": "text",
      "borderEnabled": "",
      "disabledWhite": ""
    },
    model: {
      value: _vm.leaveRequestDetails.employeeName,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveRequestDetails, "employeeName", $$v);
      },
      expression: "leaveRequestDetails.employeeName"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Request No",
      "type": "text",
      "borderEnabled": "",
      "disabledWhite": ""
    },
    model: {
      value: _vm.leaveRequestDetails.leaveNo,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveRequestDetails, "leaveNo", $$v);
      },
      expression: "leaveRequestDetails.leaveNo"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Department",
      "type": "text",
      "borderEnabled": "",
      "disabledWhite": ""
    },
    model: {
      value: _vm.leaveRequestDetails.department,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveRequestDetails, "department", $$v);
      },
      expression: "leaveRequestDetails.department"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Leave Type",
      "type": "text",
      "borderEnabled": "",
      "disabledWhite": ""
    },
    model: {
      value: _vm.leaveRequestDetails.leaveType,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveRequestDetails, "leaveType", $$v);
      },
      expression: "leaveRequestDetails.leaveType"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Start Time",
      "type": "".concat(_vm.leaveRequestDetails.leaveTypeUnit === 'DAYS' ? 'date' : 'datetime-local'),
      "borderEnabled": "",
      "disabledWhite": ""
    },
    model: {
      value: _vm.leaveRequestDetails.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveRequestDetails, "startDate", $$v);
      },
      expression: "leaveRequestDetails.startDate"
    }
  }), _c('TextField', {
    attrs: {
      "label": "End Time",
      "type": "".concat(_vm.leaveRequestDetails.leaveTypeUnit === 'DAYS' ? 'date' : 'datetime-local'),
      "borderEnabled": "",
      "disabledWhite": ""
    },
    model: {
      value: _vm.leaveRequestDetails.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveRequestDetails, "endDate", $$v);
      },
      expression: "leaveRequestDetails.endDate"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Leave Duration (".concat(_vm.leaveRequestDetails.leaveTypeUnit.toLowerCase(), ")"),
      "type": "text",
      "borderEnabled": "",
      "disabledWhite": "",
      "notes": "Automatic calculation of duration based on schedules"
    },
    model: {
      value: _vm.leaveRequestDetails.leaveDuration,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveRequestDetails, "leaveDuration", $$v);
      },
      expression: "leaveRequestDetails.leaveDuration"
    }
  }), _c('TextArea', {
    attrs: {
      "label": "Reason for leave",
      "borderEnabled": "",
      "disabledWhite": ""
    },
    model: {
      value: _vm.leaveRequestDetails.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveRequestDetails, "reason", $$v);
      },
      expression: "leaveRequestDetails.reason"
    }
  }), _vm._v(" "), (_vm$leaveRequestDetai = _vm.leaveRequestDetails) !== null && _vm$leaveRequestDetai !== void 0 && _vm$leaveRequestDetai.approvalUrl ? _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-2"
  }, [_vm._v("Approval Document")]), _c('div', {
    staticClass: "py-10 border-dashed border-2 border border-neutral-500 rounded-lg relative"
  }, [_c('div', {
    staticClass: "text-sm text-yellow absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  }, [_c('a', {
    staticClass: "flex items-center gap-2 cursor-pointer",
    attrs: {
      "href": ((_vm$leaveRequestDetai2 = _vm.leaveRequestDetails) === null || _vm$leaveRequestDetai2 === void 0 ? void 0 : _vm$leaveRequestDetai2.approvalUrl) + "?token=".concat(_vm.accessToken),
      "target": "_blank"
    }
  }, [_c('Attach', {
    attrs: {
      "color": "#F7931E"
    }
  }), _c('p', [_vm._v("View File")])], 1)])])]) : _vm._e()], 1), _c('div', {
    staticClass: "flex items-center gap-2 border-t border-neutral-50 pt-4 mt-5"
  }, [_c('Button', {
    attrs: {
      "disabled": this.leaveRequestDetails.status !== 'On Review',
      "type": "greenTertiary",
      "size": "icon",
      "forIcon": {
        color: this.leaveRequestDetails.status === 'On Review' ? 'green' : '',
        width: '14',
        height: '14'
      },
      "icon": function icon() {
        return import(
        /* webpackChunkName: 'icon' */
        '@/components/Icons/CheckmarkCircle');
      },
      "buttonText": "Approve",
      "additionalClass": "ml-6 py-2 px-3"
    },
    on: {
      "action": function action($event) {
        return _vm.confirmAction('APPROVED');
      }
    }
  }), _c('Button', {
    attrs: {
      "disabled": this.leaveRequestDetails.status !== 'On Review' && this.leaveRequestDetails.status !== 'Approved',
      "type": "deleteTertiary",
      "size": "icon",
      "forIcon": {
        color: this.leaveRequestDetails.status === 'On Review' || this.leaveRequestDetails.status === 'Approved' ? 'red' : '',
        width: '14',
        height: '14'
      },
      "icon": function icon() {
        return import(
        /* webpackChunkName: 'icon' */
        '@/components/Icons/CrossCircle');
      },
      "buttonText": "Reject",
      "additionalClass": "py-2 px-3"
    },
    on: {
      "action": function action($event) {
        return _vm.confirmAction('REJECTED');
      }
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }