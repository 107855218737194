var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-body pb-8"
  }, [_c('div', {
    staticClass: "space-y-4"
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', {
    staticClass: "flex items-center gap-4"
  }, [_c('ArrowForward', {
    staticClass: "cursor-pointer",
    attrs: {
      "direction": "left"
    },
    on: {
      "handleClick": function handleClick($event) {
        return _vm.changeRoute(_vm.breadcrumb[0]);
      }
    }
  }), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.pageName))])], 1), _c('Button', {
    attrs: {
      "buttonText": _vm.actionButtonText,
      "disabled": !_vm.leaveTypeDetails.name || _vm.hasEmptyValue
    },
    on: {
      "action": function action($event) {
        return _vm.saveData();
      }
    }
  })], 1), _c('div', {
    staticClass: "breadcrumb"
  }, _vm._l(_vm.breadcrumb, function (route, index) {
    return _c('li', {
      key: 'breadcrumbInLeaveTypeForm' + index,
      staticClass: "inline-flex items-center text-sm"
    }, [_c('span', {
      class: [_vm.isLastRoute(index) ? 'text-yellow cursor-pointer' : 'text-neutral'],
      on: {
        "click": function click($event) {
          return _vm.changeRoute(route, index);
        }
      }
    }, [_vm._v(" " + _vm._s(route.title) + " ")]), _vm.isLastRoute(index) ? _c('Chevron', {
      staticClass: "mx-3",
      attrs: {
        "color": "#F7931E",
        "height": "14"
      }
    }) : _vm._e()], 1);
  }), 0), _c('div', {
    staticClass: "shadow-small card pt-4 pb-8 bg-white rounded-lg px-7 grid grid-cols-2"
  }, [_c('div', {
    staticClass: "space-y-4"
  }, [_c('div', {
    staticClass: "border-b border-neutral-50 py-3 flex justify-between items-center"
  }, [_c('p', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_c('span', {}, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Leave Type ")])]), _c('TextField', {
    attrs: {
      "label": "Leave Name",
      "type": "text",
      "borderEnabled": "",
      "placeholder": "Enter leave type name"
    },
    model: {
      value: _vm.leaveTypeDetails.name,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveTypeDetails, "name", $$v);
      },
      expression: "leaveTypeDetails.name"
    }
  }), _c('Dropdown', {
    attrs: {
      "options": _vm.leaveUnitOptions,
      "label": "Leave Unit",
      "default": _vm.leaveTypeDetails.unit,
      "placeholder": "Select leave type unit"
    },
    model: {
      value: _vm.leaveTypeDetails.unit,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveTypeDetails, "unit", $$v);
      },
      expression: "leaveTypeDetails.unit"
    }
  }), _c('div', {
    staticClass: "space-y-2"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Duration Type")]), _vm._l(_vm.durationTypeOptions, function (duration, durationIdx) {
    return _c('p', {
      key: 'durationTypeOptions' + durationIdx
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.durationType,
        expression: "leaveTypeDetails.durationType"
      }],
      attrs: {
        "type": "radio",
        "id": "durationType".concat(durationIdx),
        "name": "radio-group-duration"
      },
      domProps: {
        "value": duration,
        "checked": _vm._q(_vm.leaveTypeDetails.durationType, duration)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails, "durationType", duration);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "durationType".concat(durationIdx)
      }
    }, [_vm._v(_vm._s(duration))])]);
  })], 2), _vm.leaveTypeDetails.durationType === 'Limited' ? _c('div', {
    staticClass: "border border-neutral-400 rounded-lg p-5 ml-6 space-y-4"
  }, [_c('p', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_vm._v("Limited Leave")]), _c('Dropdown', {
    attrs: {
      "options": _vm.limitedLeaveCylceOptions,
      "label": "Leave Cycle",
      "default": _vm.leaveTypeDetails.limitedLeave.limitedLeaveCycle
    },
    model: {
      value: _vm.leaveTypeDetails.limitedLeave.limitedLeaveCycle,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveTypeDetails.limitedLeave, "limitedLeaveCycle", $$v);
      },
      expression: "leaveTypeDetails.limitedLeave.limitedLeaveCycle"
    }
  }), _c('div', {
    staticClass: "space-y-2"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Start Cycle from")]), _vm._l(_vm.startCycleOptions, function (cycleItem, cycleItemIdx) {
    return _c('div', {
      key: 'startCycleOptions' + cycleItemIdx,
      staticClass: "flex items-center gap-2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.limitedLeave.limitedstartFrom,
        expression: "leaveTypeDetails.limitedLeave.limitedstartFrom"
      }],
      attrs: {
        "type": "radio",
        "id": "startCycleOptions".concat(cycleItemIdx),
        "name": "radio-group-cycle"
      },
      domProps: {
        "value": cycleItem,
        "checked": _vm._q(_vm.leaveTypeDetails.limitedLeave.limitedstartFrom, cycleItem)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails.limitedLeave, "limitedstartFrom", cycleItem);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "startCycleOptions".concat(cycleItemIdx)
      }
    }, [_vm._v(_vm._s(cycleItem.label))]), cycleItem.value === 'SPECIFIC' ? _c('div', {
      staticClass: "flex items-center gap-2"
    }, [_c('Dropdown', {
      attrs: {
        "options": _vm.months,
        "disabled": _vm.leaveTypeDetails.limitedLeave.limitedstartFrom.value === 'JOIN_DATE',
        "default": _vm.selectedMonth,
        "maxHeight": "190px"
      },
      model: {
        value: _vm.selectedMonth,
        callback: function callback($$v) {
          _vm.selectedMonth = $$v;
        },
        expression: "selectedMonth"
      }
    }), _c('Dropdown', {
      attrs: {
        "options": _vm.numberOfDaysInMonth,
        "disabled": _vm.leaveTypeDetails.limitedLeave.limitedstartFrom.value === 'JOIN_DATE',
        "default": _vm.selectedDate,
        "maxHeight": "190px"
      },
      model: {
        value: _vm.selectedDate,
        callback: function callback($$v) {
          _vm.selectedDate = $$v;
        },
        expression: "selectedDate"
      }
    })], 1) : _vm._e()]);
  })], 2), _c('TextField', {
    attrs: {
      "label": "Leave Balance in a Cycle (days)",
      "type": "number",
      "borderEnabled": "",
      "placeholder": "Enter leave duration",
      "error": _vm.errorInput['limitedBalanceCycle'],
      "isStayShow": ""
    },
    on: {
      "input": function input($event) {
        var _vm2;

        return (_vm2 = _vm).handleInputNumberChange.apply(_vm2, ['limitedBalanceCycle'].concat(Array.prototype.slice.call(arguments)));
      }
    },
    model: {
      value: _vm.leaveTypeDetails.limitedLeave.limitedBalanceCycle,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveTypeDetails.limitedLeave, "limitedBalanceCycle", $$v);
      },
      expression: "leaveTypeDetails.limitedLeave.limitedBalanceCycle"
    }
  }), _c('div', {
    staticClass: "space-y-2"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Leave Frequency")]), _vm._l(_vm.leaveFrequencyOptions, function (frequency, frequencyIdx) {
    return _c('p', {
      key: 'leaveFrequencyOptions' + frequencyIdx
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.limitedLeave.limitedFrequence,
        expression: "leaveTypeDetails.limitedLeave.limitedFrequence"
      }],
      attrs: {
        "type": "radio",
        "id": "leaveFrequency".concat(frequencyIdx),
        "name": "radio-group-frequency"
      },
      domProps: {
        "value": frequency,
        "checked": _vm._q(_vm.leaveTypeDetails.limitedLeave.limitedFrequence, frequency)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails.limitedLeave, "limitedFrequence", frequency);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "leaveFrequency".concat(frequencyIdx)
      }
    }, [_vm._v(_vm._s(frequency.label))])]);
  })], 2), _vm.leaveTypeDetails.limitedLeave.limitedFrequence.value === 'GRADUALLY' ? _c('div', {
    staticClass: "bg-neutral-100 rounded-lg p-5 ml-6 flex gap-2"
  }, [_c('div', {
    staticClass: "flex items-center gap-2 w-1/3"
  }, [_c('TextField', {
    attrs: {
      "type": "number",
      "error": _vm.errorInput['limitedGraduallyScopeDays'],
      "isStayShow": "",
      "borderEnabled": ""
    },
    on: {
      "input": function input($event) {
        var _vm3;

        return (_vm3 = _vm).handleInputNumberChange.apply(_vm3, ['limitedGraduallyScopeDays'].concat(Array.prototype.slice.call(arguments)));
      }
    },
    model: {
      value: _vm.leaveTypeDetails.limitedLeave.limitedGraduallyScope.days,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveTypeDetails.limitedLeave.limitedGraduallyScope, "days", $$v);
      },
      expression: "leaveTypeDetails.limitedLeave.limitedGraduallyScope.days"
    }
  })], 1), _vm._m(0), _c('div', {
    staticClass: "w-1/3"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.limitedGraduallyScopeInterval,
      "optionLabel": "label",
      "default": _vm.leaveTypeDetails.limitedLeave.limitedGraduallyScope.interval
    },
    model: {
      value: _vm.leaveTypeDetails.limitedLeave.limitedGraduallyScope.interval,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveTypeDetails.limitedLeave.limitedGraduallyScope, "interval", $$v);
      },
      expression: "leaveTypeDetails.limitedLeave.limitedGraduallyScope.interval"
    }
  })], 1)]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "border-b border-neutral-50 py-3 flex justify-between items-center"
  }, [_c('p', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_c('span', {}, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Leave Rule ")])]), _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Leave Scope")]), _vm._l(_vm.leaveScopeOptions, function (scope, scopeIdx) {
    return _c('p', {
      key: 'leaveScopeOptions' + scopeIdx
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.leaveRules.leaveScope,
        expression: "leaveTypeDetails.leaveRules.leaveScope"
      }],
      attrs: {
        "type": "radio",
        "id": "leaveScope".concat(scopeIdx),
        "name": "radio-group-scope"
      },
      domProps: {
        "value": scope,
        "checked": _vm._q(_vm.leaveTypeDetails.leaveRules.leaveScope, scope)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails.leaveRules, "leaveScope", scope);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "leaveScope".concat(scopeIdx)
      }
    }, [_vm._v(_vm._s(scope))])]);
  }), _vm.leaveTypeDetails.leaveRules.leaveScope === 'Custom' ? _c('div', {
    staticClass: "bg-neutral-100 rounded-lg p-5 ml-6 flex justify-between gap-2"
  }, [_c('div', {
    staticClass: "flex items-center gap-2 w-1/2"
  }, [_c('Dropdown', {
    staticClass: "w-full",
    attrs: {
      "optionLabel": "label",
      "options": _vm.leaveScopeCustoms,
      "default": _vm.leaveTypeDetails.leaveRules.customScope.attribute
    },
    on: {
      "change": _vm.handleLeaveScopeChange
    },
    model: {
      value: _vm.leaveTypeDetails.leaveRules.customScope.attribute,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveTypeDetails.leaveRules.customScope, "attribute", $$v);
      },
      expression: "leaveTypeDetails.leaveRules.customScope.attribute"
    }
  }), _c('p', [_vm._v("include")])], 1), _c('div', {
    staticClass: "w-1/2"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.dynamicLeaveScopeOptions,
      "optionLabel": "label",
      "default": _vm.leaveTypeDetails.leaveRules.customScope.value
    },
    model: {
      value: _vm.leaveTypeDetails.leaveRules.customScope.value,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveTypeDetails.leaveRules.customScope, "value", $$v);
      },
      expression: "leaveTypeDetails.leaveRules.customScope.value"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "space-y-2 mt-1"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Grants leaves for")]), _vm._l(_vm.grantLeaveOptions, function (grantItem, grantItemIdx) {
    var _vm$leaveTypeDetails$;

    return _c('div', {
      key: 'grantLeaveOptions' + grantItemIdx,
      staticClass: "flex items-center gap-2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.leaveRules.grantLeaveFor,
        expression: "leaveTypeDetails.leaveRules.grantLeaveFor"
      }],
      attrs: {
        "type": "radio",
        "id": "grantLeaveOptions".concat(grantItemIdx),
        "name": "radio-group-grant"
      },
      domProps: {
        "value": grantItem,
        "checked": _vm._q(_vm.leaveTypeDetails.leaveRules.grantLeaveFor, grantItem)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails.leaveRules, "grantLeaveFor", grantItem);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "grantLeaveOptions".concat(grantItemIdx)
      }
    }, [_vm._v(_vm._s(grantItem.label))]), grantItem.label !== 'All' ? _c('div', {
      staticClass: "flex items-center gap-2"
    }, [_c('TextField', {
      staticClass: "w-20",
      attrs: {
        "type": "number",
        "borderEnabled": "",
        "disabled": ((_vm$leaveTypeDetails$ = _vm.leaveTypeDetails.leaveRules.grantLeaveFor) === null || _vm$leaveTypeDetails$ === void 0 ? void 0 : _vm$leaveTypeDetails$.value) === 'ALL',
        "error": _vm.errorInput['grantLeaveFor'],
        "isStayShow": ""
      },
      on: {
        "input": function input($event) {
          var _vm4;

          return (_vm4 = _vm).handleInputNumberChange.apply(_vm4, ['grantLeaveFor'].concat(Array.prototype.slice.call(arguments)));
        }
      },
      model: {
        value: _vm.leaveTypeDetails.leaveRules.grantLeaveException.joinedFrom,
        callback: function callback($$v) {
          _vm.$set(_vm.leaveTypeDetails.leaveRules.grantLeaveException, "joinedFrom", $$v);
        },
        expression: "leaveTypeDetails.leaveRules.grantLeaveException.joinedFrom"
      }
    }), _vm._v(" " + _vm._s(_vm.leaveTypeDetails.unit.toLowerCase()) + " ")], 1) : _vm._e()]);
  })], 2), _c('div', {
    staticClass: "space-y-6"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-2"
  }, [_vm._v("Time limit to submit requests")]), _vm._l(_vm.leaveRuleOptions, function (ruleTimeLimit, ruleTimeLimitIdx) {
    return _c('div', {
      key: 'ruleTimeLimit' + ruleTimeLimitIdx,
      staticClass: "flex items-center gap-2"
    }, [ruleTimeLimit === 'Limited' ? _c('div', {
      staticClass: "flex items-center gap-2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.leaveRules.ruleTimeLimit,
        expression: "leaveTypeDetails.leaveRules.ruleTimeLimit"
      }],
      attrs: {
        "type": "radio",
        "id": "ruleTimeLimit".concat(ruleTimeLimitIdx),
        "name": "rule-time-limit"
      },
      domProps: {
        "value": ruleTimeLimit,
        "checked": _vm._q(_vm.leaveTypeDetails.leaveRules.ruleTimeLimit, ruleTimeLimit)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails.leaveRules, "ruleTimeLimit", ruleTimeLimit);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "ruleTimeLimit".concat(ruleTimeLimitIdx)
      }
    }), _c('div', {
      staticClass: "flex items-center gap-2 -ml-1"
    }, [_c('TextField', {
      staticClass: "w-20 mt-5",
      attrs: {
        "for": "ruleTimeLimit".concat(ruleTimeLimitIdx),
        "type": "number",
        "borderEnabled": "",
        "disabled": _vm.leaveTypeDetails.leaveRules.ruleTimeLimit === 'Unlimited',
        "error": _vm.errorInput['ruleTimeLimit'],
        "isStayShow": ""
      },
      on: {
        "input": function input($event) {
          var _vm5;

          return (_vm5 = _vm).handleInputNumberChange.apply(_vm5, ['ruleTimeLimit'].concat(Array.prototype.slice.call(arguments)));
        }
      },
      model: {
        value: _vm.leaveTypeDetails.leaveRules.ruleTimeLimitDays,
        callback: function callback($$v) {
          _vm.$set(_vm.leaveTypeDetails.leaveRules, "ruleTimeLimitDays", $$v);
        },
        expression: "leaveTypeDetails.leaveRules.ruleTimeLimitDays"
      }
    }), _c('p', {
      staticClass: "pt-4"
    }, [_vm._v("(days) before leave starts")])], 1)]) : _c('div', {}, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.leaveRules.ruleTimeLimit,
        expression: "leaveTypeDetails.leaveRules.ruleTimeLimit"
      }],
      attrs: {
        "type": "radio",
        "id": "ruleTimeLimit".concat(ruleTimeLimitIdx),
        "name": "rule-time-limit"
      },
      domProps: {
        "value": ruleTimeLimit,
        "checked": _vm._q(_vm.leaveTypeDetails.leaveRules.ruleTimeLimit, ruleTimeLimit)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails.leaveRules, "ruleTimeLimit", ruleTimeLimit);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "ruleTimeLimit".concat(ruleTimeLimitIdx)
      }
    }, [_vm._v(_vm._s(ruleTimeLimit))])])]);
  })], 2), _c('div', {
    staticClass: "space-y-3"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block -mb-1"
  }, [_vm._v("Minimum duration of a single leave")]), _vm._l(_vm.leaveRuleOptions, function (ruleTimeMin, ruleTimeMinIdx) {
    return _c('div', {
      key: 'ruleTimeMinDuration' + ruleTimeMinIdx,
      staticClass: "flex items-center gap-2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.leaveRules.ruleMinimumDuration,
        expression: "leaveTypeDetails.leaveRules.ruleMinimumDuration"
      }],
      attrs: {
        "type": "radio",
        "id": "ruleTimeMinDuration".concat(ruleTimeMinIdx),
        "name": "rule-time-min-duration"
      },
      domProps: {
        "value": ruleTimeMin,
        "checked": _vm._q(_vm.leaveTypeDetails.leaveRules.ruleMinimumDuration, ruleTimeMin)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails.leaveRules, "ruleMinimumDuration", ruleTimeMin);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "ruleTimeMinDuration".concat(ruleTimeMinIdx)
      }
    }, [_vm._v(_vm._s(ruleTimeMin === 'Limited' ? 'Fixed' : ruleTimeMin))]), ruleTimeMin === 'Limited' ? _c('div', {
      staticClass: "flex items-center gap-2"
    }, [_c('TextField', {
      staticClass: "w-20",
      attrs: {
        "type": "number",
        "borderEnabled": "",
        "disabled": _vm.leaveTypeDetails.leaveRules.ruleMinimumDuration === 'Unlimited',
        "error": _vm.errorInput['ruleMinimumDurationDays'],
        "isStayShow": ""
      },
      on: {
        "input": function input($event) {
          var _vm6;

          return (_vm6 = _vm).handleInputNumberChange.apply(_vm6, ['ruleMinimumDurationDays'].concat(Array.prototype.slice.call(arguments)));
        }
      },
      model: {
        value: _vm.leaveTypeDetails.leaveRules.ruleMinimumDurationDays,
        callback: function callback($$v) {
          _vm.$set(_vm.leaveTypeDetails.leaveRules, "ruleMinimumDurationDays", $$v);
        },
        expression: "leaveTypeDetails.leaveRules.ruleMinimumDurationDays"
      }
    }), _c('p', [_vm._v("days")])], 1) : _vm._e()]);
  })], 2), _c('div', {
    staticClass: "space-y-3"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block -mb-1"
  }, [_vm._v("Maximum duration of a single leave")]), _vm._l(_vm.leaveRuleOptions, function (ruleTimeMax, ruleTimeMaxIdx) {
    return _c('div', {
      key: 'ruleTimeMaxDuration' + ruleTimeMaxIdx,
      staticClass: "flex items-center gap-2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.leaveTypeDetails.leaveRules.ruleMaximumDuration,
        expression: "leaveTypeDetails.leaveRules.ruleMaximumDuration"
      }],
      attrs: {
        "type": "radio",
        "id": "ruleTimeMaxDuration".concat(ruleTimeMaxIdx),
        "name": "rule-time-max-duration"
      },
      domProps: {
        "value": ruleTimeMax,
        "checked": _vm._q(_vm.leaveTypeDetails.leaveRules.ruleMaximumDuration, ruleTimeMax)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.leaveTypeDetails.leaveRules, "ruleMaximumDuration", ruleTimeMax);
        }
      }
    }), _c('label', {
      attrs: {
        "for": "ruleTimeMaxDuration".concat(ruleTimeMaxIdx)
      }
    }, [_vm._v(_vm._s(ruleTimeMax === 'Limited' ? 'Fixed' : ruleTimeMax))]), ruleTimeMax === 'Limited' ? _c('div', {
      staticClass: "flex items-center gap-2"
    }, [_c('TextField', {
      staticClass: "w-20",
      attrs: {
        "type": "number",
        "borderEnabled": "",
        "isStayShow": "",
        "disabled": _vm.leaveTypeDetails.leaveRules.ruleMaximumDuration === 'Unlimited',
        "error": _vm.errorInput['ruleMaximumDurationDays']
      },
      on: {
        "input": function input($event) {
          var _vm7;

          return (_vm7 = _vm).handleInputNumberChange.apply(_vm7, ['ruleMaximumDurationDays'].concat(Array.prototype.slice.call(arguments)));
        }
      },
      model: {
        value: _vm.leaveTypeDetails.leaveRules.ruleMaximumDurationDays,
        callback: function callback($$v) {
          _vm.$set(_vm.leaveTypeDetails.leaveRules, "ruleMaximumDurationDays", $$v);
        },
        expression: "leaveTypeDetails.leaveRules.ruleMaximumDurationDays"
      }
    }), _c('p', [_vm._v("days")])], 1) : _vm._e()]);
  })], 2)])], 2)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center"
  }, [_c('p', [_vm._v("(day) in a")])]);
}]

export { render, staticRenderFns }