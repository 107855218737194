<template>
  <div class="jadwal-kelas-selection h-screen">
    <p class="font-bold text-3xl">Create New Class Schedule</p>
    <div class="my-32">
      <div class="flex justify-center gap-8 items-center mb-8">
        <router-link to="/jadwalkelas/add" class="selection border-yellow-primary p-8 bg-white rounded-2xl">
          <div class="flex h-32 w-32 justify-center items-center">
            <img src="~@/assets/images/webinar.png" />
          </div>
          <p class="mt-4 text-center font-semibold text-lg text-yellow-primary">
            Webinar
          </p>
        </router-link>
        <router-link to="/jadwalkelas/new-schema" class="selection border-yellow-primary p-8 text-center bg-white rounded-2xl">
          <div class="flex h-32 w-32 justify-center items-center">
            <img src="~@/assets/images/new-schema.png" />
          </div>
          <p class="mt-4 text-center font-semibold text-lg text-yellow-primary">
            New Schema
          </p>
        </router-link>
      </div>
      <div class="text-center">
        <Button buttonText="Back" type="primary" @action="goToListPage()" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  methods: {
    goToListPage() {
      this.$router.push('/jadwalkelas')
    }
  }
}
</script>
<style lang="scss" scoped>
.selection {
  border-width: 1px;
  transition: all 150ms ease-in;
  &:hover {
    background-color: #f7931e !important;
    text-decoration: none;
    p {
      color: #fff !important;
    }
  }
}
.justify-center {
  justify-content: center !important;
}
</style>
