<template>
  <div>
    <div v-if="isScheduleCodeUnavailable">
      <h1 class="font-bold text-2xl">Schedule Code Unavailable</h1>
      <h3 class="py-3">Schedule code of <span class="font-semibold">{{ scheduleName }}</span> is not
        available, please enter new schedule code to continue the process</h3>
      <TextField class="mb-5" borderEnabled v-model="schedulePayload['scheduleCodePMO']"
        placeholder="Enter Schedule Code" />
      <Button buttonText="Continue" size="big" @action="updateLmsClass" />
    </div>
    <div v-else>
      <p class="text-2xl font-bold mb-6 text-left">Import from CSV</p>
      <div class="grid grid-cols-2 gap-3 mb-4">
        <Dropdown label="Select Class" :options="list_kelas" v-model="selectedCoreClass" :default="selectedCoreClass"
          placeholder="Select Class" optionLabel="nama" @search="getCoreClassList" maxHeight="220px"
          :isLoadData="isFetchingCoreClass" enableSearch />
        <Dropdown label="Select Schedule (Optional)" :options="lmsClassList" v-model="selectedLmsClass"
          :default="selectedLmsClass" placeholder="Select Schedule" optionLabel="nama" :disabled="!selectedCoreClass"
          @search="getLmsClassList" maxHeight="220px" :isLoadData="isFetchingLmsClass" enableSearch />
        <Dropdown :options="digitalPlatforms" label="Digital Platforms" v-model="selectedDp"
          placeholder="Select Digital Platforms" optionLabel="name" />
        <Dropdown :options="dummyOptions" label="For Dummy" v-model="selectedDummy" :default="selectedDummy" />
      </div>

      <div class="modal-body-drag-area mb-2" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <div v-if="!isUploading">
          <input type="file" name="file-input" id="assetsFieldHandle" @change="onChange" ref="file" accept=".csv" />
          <div class="upload-icon">
            <upload width="486" height="48" color="#F7931E" />
            <div class="upload-icon-text">Drag & Drop File</div>
            <div>Or</div>
          </div>
          <label for="assetsFieldHandle" class="block cursor-pointer">
            <span v-if="!isReadyToSend">Select File</span>
            <span v-else>Change File</span>
          </label>
        </div>
        <div class="text-left flex justify-center items-center w-full flex-col">
          <div class="mb-4" v-if="isUploading">
            <div class="bar relative mx-auto w-min">
              <span
                class="font-medium text-sm text-yellow-primary transition-all inline-block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">{{
                  `${progressReal}%` }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

                <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress js-progress-bar" :style="{
                  'stroke-dashoffset': progressPercentage
                }" />
              </svg>
            </div>
            <div class="flex justify-center flex-col items-start mt-4">
              <div class="mb-2 text-center" v-if="currentProgress === 100">
                <span class="text-green-600 text-sm">Upload Voucher is Success!</span>
              </div>
              <div class="mb-1">
                <span class="text-neutral-500 text-sm">Total Data</span>:
                <strong>{{ totalData }}</strong>
              </div>
              <div class="mb-1">
                <span class="text-neutral-500 text-sm">Total Saved Data</span>:
                <strong>{{ totalSavedData }}</strong>
              </div>
            </div>
          </div>
          <div v-if="isReadyToSend">
            <div
              class="text-sm text-neutral-400 flex gap-2 items-center justify-between bg-grey py-2 px-3 rounded relative">
              <Attach :color="'#ADADAD'" />
              <span class="overflow-ellipsis w-80 whitespace-nowrap overflow-hidden">{{ seletedFile.name }}</span>
              <span class="text-sm text-neutral-400 inline-block w-24 text-right"> ({{ bytesToSize(seletedFile.size) }})
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="notes text-left">
        Download the template
        <a class="text-yellow-primary font-semibold" target="_blank"
          href="https://docs.google.com/spreadsheets/d/1-3TihtkCEk9dXVdNeLd9QYJfCMOTDJ0oC3BqLNW6reU/edit?usp=sharing">here</a>.
        (The template
        must be in .csv format & please make sure it doesn't contain any special characters)
      </div>
      <div class="text-system-error mt-2 text-sm" v-if="error">{{ error }}</div>

      <div class="upload-submit">
        <button :disabled="!isEligibleToUpload || isUploading" :class="{ disabled: !isEligibleToUpload }" @click="submit">
          <template v-if="!isUploading">Upload</template>
          <LoadingDot v-else />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Upload from '@/components/Icons/Upload'
import LoadingDot from '@/components/Illustration/LoadingDot'
import { showVueToast } from '@/utils'
export default {
  components: {
    Upload,
    LoadingDot,
    Attach: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Attach'),
    Close: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Close'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search')
  },
  props: {
    isScheduleCodeUnavailable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedCoreClass: null,
    selectedLmsClass: null,
    isFetchingCoreClass: false,
    isFetchingLmsClass: false,
    lmsClassList: [],
    seletedFile: {},
    error: null,
    isReadyToSend: false,
    isUploading: false,
    progressPercentage: 0,
    progressReal: 0,
    maxFiles: 5,
    maxFileSize: 100 * 1024 * 1024,
    selectedClass: null,
    selectedDp: null,
    dummyOptions: ['Yes', 'No'],
    selectedDummy: 'No',
    voucherList: [],
    totalSavedData: null,
    totalData: null,
    currentProgress: null,
    isFetching: false,
    isSearching: false,
    classKeyword: '',
    debounce: null,
    classList: [],
    schedulePayload: { scheduleCodePMO: '' }
  }),
  watch: {
    seletedFile(val) {
      if (val && val.length < 1) {
        this.isReadyToSend = false
      }
    },
    selectedCoreClass() {
      this.selectedLmsClass = null
    }
  },
  computed: {
    ...mapGetters('kelas', ['list_kelas']),
    ...mapGetters('common', ['digitalPlatforms']),
    customerId() {
      return localStorage.getItem('client')
    },
    isEligibleToUpload() {
      return this.selectedCoreClass && this.selectedDp && this.selectedDummy && this.isReadyToSend
    },
    scheduleName() {
      return this.schedulePayload?.name || ''
    },
    isScheduleCode(){
      return this.isScheduleCodeUnavailable
    }
  },
  created() {
    this.getDP()
  },
  methods: {
    ...mapActions('gallery', ['UPLOAD_FILE']),
    ...mapActions('kelas', ['GET_LIST_KELAS']),
    ...mapActions('voucher', ['CREATE_VOUCHER', 'GET_VOUCHER_PROGRESS']),
    ...mapActions('common', ['DIGITAL_PLATFORMS', 'showLoading', 'hideLoading']),
    ...mapActions('jadwalkelas', ['GET_JADWALKELAS_NEW', 'UPDATE_JADWALKELAS_NEW']),
    getDP() {
      this.DIGITAL_PLATFORMS()
    },
    updateLmsClass() {
      this.showLoading()
      this.UPDATE_JADWALKELAS_NEW({
        customerId: this.customerId,
        lmsClassId: this.selectedLmsClass.id,
        payload: this.schedulePayload
      }).then(async (res) => {
        if (res.code === 422) {
          showVueToast('Failed to update schedule code!', 'error', 3000)
          this.hideLoading()
          return
        }
        if (res.status === 200) {
          this.submit(true)
        } else {
          showVueToast(`${res?.errors?.error ? res?.errors?.error.charAt(0).toUpperCase() + res?.errors?.error.slice(1) : 'System error!'}!`, 'error', 3000)
          setTimeout(() => {
            this.hideLoading()
            this.temporaryDisabled = false
          }, 3500)
        }
      })
    },
    getClassDetails() {
      return new Promise((resolve, reject) => {
        this.GET_JADWALKELAS_NEW({
          lmsClassId: this.selectedLmsClass.id,
          customerId: this.customerId
        })
          .then((res) => {
            const result = JSON.parse(JSON.stringify(res?.data?.data))
            resolve(result)
          })
          .catch(() => {
            reject({ scheduleCodePMO: '' })
            showVueToast('LMS class schedule is not found!', 'error', 2000)
          })
      })
    },
    getCoreClassList(name = '') {
      this.isFetchingCoreClass = true
      const paramsTemp = queryString.stringify({
        ...{
          name,
          limit: 200
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          params: paramsTemp,
          customerId: this.customerId
        })
        .then(() => {
          this.isFetchingCoreClass = false
        })
    },
    getLmsClassList(name = '') {
      this.isFetchingLmsClass = true
      const paramsTemp = queryString.stringify({
        ...{
          name,
          page: 0,
          limit: 200,
          core_class_id: this.selectedCoreClass?.id || '',
          is_schedule_available: true
        }
      })
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          params: paramsTemp,
          customerId: this.customerId
        })
        .then((res) => {
          this.lmsClassList = res.data.data.map((lms) => {
            const nama = `${lms.nama} ( ${moment(lms.start_date).format('DD MMMM YYYY')} - ${moment(lms.end_date).format('DD MMMM YYYY')} )`
            return {
              ...lms,
              nama
            }
          })
          this.isFetchingLmsClass = false
        })
    },
    searchClass() {
      if (this.classKeyword !== '') {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(() => {
          if (this.classKeyword !== '') self.getClassList(self.classKeyword)
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    selectClass(item) {
      this.selectedClass = item
      this.classKeyword = item.nama
      this.isSearching = false
    },
    getClassList(searchTerm = '') {
      this.isFetching = true
      this.classList = []
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          sort: 'name',
          name: searchTerm
        }
      })
      this.GET_LIST_KELAS({
        params: paramsTemp,
        customerId: this.customerId
      }).then(() => {
        this.classList = this.list_kelas
        this.isSearching = true
        this.isFetching = false
        // this.hideLoading()
      })
    },
    close(event) {
      this.$emit('close')
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
    },
    onChange() {
      if (this.$refs.file.files.length > 0) {
        this.seletedFile = this.$refs.file.files[0]
        this.processFile()
        this.isReadyToSend = true
      }
    },
    closeAndRefetch() {
      this.$emit('closeAndRefetch')
    },
    submit(isDirectToMainPage) {
      this.isUploading = true
      const payloads = {
        ecommerce: this.selectedDp.code,
        dummy: this.selectedDummy === 'Yes',
        core_class_id: this.selectedCoreClass.id,
        codes: this.voucherList
      }
      if (this.selectedLmsClass) {
        payloads.lms_class_id = this.selectedLmsClass.id
      } else {
        delete payloads.lms_class_id
      }

      this.CREATE_VOUCHER({
        customerId: this.customerId,
        payload: payloads
      }).then((res) => {
        if (isDirectToMainPage) return this.$emit('close')
        const progressId = res.data.data
        const limit = progressId.indexOf(" ")
        const trimString = progressId.substring(0, limit)
        this.getProgress(trimString)
      }).catch(async (err) => {
        this.isUploading = true
        if (err.errors.error === "creating voucher failed. schedule_code_pmo can't empty/null") {
          this.schedulePayload = await this.getClassDetails()
          setTimeout(() => {
            this.$emit('handleCodeUnavailable')
          }, 2000)
          return
        }
        showVueToast('Failed to create voucher!', 'error', 2000)

      })
    },
    getProgress(id) {
      let self = this
      this.GET_VOUCHER_PROGRESS({
        progressId: id
      }).then((res) => {
        this.currentProgress = res.data.data.progress
        this.totalSavedData = res.data.data.saved_data
        this.totalData = res.data.data.total_data
        this.uploadProgress(this.currentProgress)
        let t = null
        if (this.currentProgress < 100) {
          setTimeout(() => {
            this.getProgress(id)
          }, 1000)
        } else {
          this.isReadyToSend = false
          setTimeout(() => {
            self.$emit('close')
          }, 2000)
        }
      })
    },
    processFile() {
      const input = this.seletedFile
      const reader = new FileReader()
      let self = this
      reader.onload = function (e) {
        const text = e.target.result
        const result = text.split('\n')
        self.voucherList = result.filter((item) => item !== '')
      }
      reader.readAsText(input)
    },
    afterUpload(counter, fileCount) {
      if (counter === fileCount) {
        this.isUploading = false
        this.closeAndRefetch()
      }
    },
    uploadProgress(value) {
      this.progressPercentage = 100 - value
      this.progressReal = value
      this.$forceUpdate()
    },
    remove(i) {
      this.seletedFile.splice(i, 1)
      this.error = null
      this.checkError()
    },
    checkError() {
      let isError = false
      for (let i = 0; i < this.seletedFile.length; i++) {
        if (this.seletedFile[i].size > this.maxFileSize) {
          isError = true
          this.error = `There is file(s) that exceeded the maximum size of 100MB. Please re-select the files.`
        }
      }
      if (isError) this.isReadyToSend = false
      else this.isReadyToSend = true
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('uploading')) {
        event.currentTarget.classList.add('uploading')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('uploading')
    },
    drop(event) {
      this.error = null
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()
      event.currentTarget.classList.remove('uploading')
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-body {
  position: relative;

  &-drag {
    &-area {
      border: 2px dashed #ccc;
      border-radius: 6px;
      padding: 20px;
      display: flex;
      min-height: 200px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      transition: all 200ms linear;

      input {
        position: absolute;
        opacity: 0;
        overflow: hidden;
        height: 1px;
        width: 1px;
      }

      label {
        cursor: pointer;
        margin-top: 15px;

        span {
          background-color: #f7931e;
          border: none;
          outline: none;
          color: #fff;
          border-radius: 3px;
          padding: 8px 16px;
          font-size: 12px;
          transition: all 100ms linear;
          display: inline-block;
          margin-bottom: 5px;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .upload-icon {
        &-text {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.uploading {
  background-color: #eee;
}

.upload-submit {
  text-align: center;
  margin-top: 30px;

  button {
    background-color: #f7931e;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 3px;
    padding: 8px 40px;
    margin: 0;
    transition: all 100ms linear;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      background-color: #ccc;
      cursor: not-allowed;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.error {
  color: red;
  margin: 10px 0px;
  min-height: 20px;
}

.upload-files {
  &-name {
    display: block;
    color: #999;
  }

  &-size {
    font-size: 12px;
    color: #999;
    display: inline-block;
    margin-left: 5px;
  }
}

.notes {
  color: #999;
  font-size: 12px;
}

.progress {
  background-color: #f7931e;
}

.rounded-lg {
  border-radius: 8px !important;
}

$progress-bar-stroke-width: 1.8;
$progress-bar-size: 120px;

.bar svg {
  height: $progress-bar-size;
  transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progress-bar__background {
  fill: none;
  stroke: #eee;
  stroke-width: $progress-bar-stroke-width;
}

.progress-bar__progress {
  fill: none;
  stroke: #eee;
  stroke: #f7931e;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 100ms ease-in-out;
}

.border-system-error {
  border-width: 1px !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.gap-0 {
  gap: 0px !important;
}
</style>
