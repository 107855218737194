var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "".concat(_vm.width, "px"),
      "height": "".concat(_vm.height, "px"),
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8 1.5C4.41031 1.5 1.5 4.41031 1.5 8C1.5 11.5897 4.41031 14.5 8 14.5C11.5897 14.5 14.5 11.5897 14.5 8C14.5 4.41031 11.5897 1.5 8 1.5Z",
      "stroke": _vm.color,
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.99945 1.5C6.18477 1.5 4.47852 4.41031 4.47852 8C4.47852 11.5897 6.18477 14.5 7.99945 14.5C9.81414 14.5 11.5204 11.5897 11.5204 8C11.5204 4.41031 9.81414 1.5 7.99945 1.5Z",
      "stroke": _vm.color,
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M3.66602 3.6665C4.86102 4.51494 6.36539 5.02088 7.99945 5.02088C9.63352 5.02088 11.1379 4.51494 12.3329 3.6665M12.3329 12.3334C11.1379 11.4849 9.63352 10.979 7.99945 10.979C6.36539 10.979 4.86102 11.4849 3.66602 12.3334",
      "stroke": _vm.color,
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.5 8H1.5M8 1.5V14.5V1.5Z",
      "stroke": _vm.color,
      "stroke-miterlimit": "10"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }