var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail pb-6 w-full min-h-contain"
  }, [_c('p', {
    staticClass: "text-3xl font-bold mb-4"
  }, [_vm._v("Zoom")]), _c('div', {
    staticClass: "breadcrumb flex items-center gap-2 mb-4"
  }, [_c('router-link', {
    staticClass: "text-sm text-yellow font-medium",
    attrs: {
      "to": "/integrations"
    }
  }, [_vm._v("Integrations")]), _c('Chevron', {
    attrs: {
      "direction": "right",
      "width": "10",
      "height": "10",
      "color": "#F7931E"
    }
  }), _c('p', {
    staticClass: "text-sm font-medium text-neutral-500 ellipsis"
  }, [_vm._v("Zoom")])], 1), _c('div', {
    staticClass: "bg-white p-6 rounded-md shadow-soft"
  }, [_c('div', {
    staticClass: "border-b border-neutral-50 mb-4 pb-2 flex justify-between items-center"
  }, [_c('div', {
    staticClass: "font-semibold flex gap-1 items-start"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  }), _vm._m(0)], 1), _c('Button', {
    attrs: {
      "buttonText": "Connect New Account",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.getAuthZoomURL();
      }
    }
  })], 1), _c('TableComponent', {
    attrs: {
      "columns": _vm.columns,
      "list": _vm.zoomAccountList
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.field === 'action' ? _c('div', {
          staticClass: "text-right"
        }, [_c('Button', {
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toggleDeleteConfirmation(entry.id);
            }
          }
        })], 1) : column.field === 'createdDate' ? _c('div', [_vm._v(" " + _vm._s(_vm.formatDate(entry.createdDate)) + " ")]) : _c('div', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }])
  }), _vm.totalRecords > 0 ? _c('div', {
    staticClass: "mt-6 flex justify-center items-center"
  }, [_c('Pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalRecords,
      "pageSize": _vm.pageSize
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleDeleteConfirmation,
      "width": "640px",
      "id": "delete"
    },
    on: {
      "close": function close($event) {
        return _vm.toggleDeleteConfirmation();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "my-4"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-4"
  }, [_vm._v("Are you sure to delete this account?")]), _c('p', {
    staticClass: "text-sm text-neutral-500"
  }, [_vm._v("This action can not be undone!")]), _c('div', {
    staticClass: "flex items-center justify-center mt-8 gap-8 px-20"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "secondary",
      "size": "full"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteAccount();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary",
      "size": "full"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleDeleteConfirmation();
      }
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('p', {
    staticClass: "font-bold"
  }, [_vm._v("Connected Accounts")]), _c('p', {
    staticClass: "text-smallest text-neutral-500 leading-3"
  }, [_vm._v("Connected accounts can be used for auto-generate Webinar link on Class Schedule")])]);
}]

export { render, staticRenderFns }