var render = function () {
  var _vm$customer, _vm$customer$customer;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar w-64 fixed left-0 h-full bg-neutral-500 z-0 text-white overflow-y-auto",
    style: {
      paddingTop: "".concat(((_vm$customer = _vm.customer) === null || _vm$customer === void 0 ? void 0 : (_vm$customer$customer = _vm$customer.customerRoles) === null || _vm$customer$customer === void 0 ? void 0 : _vm$customer$customer.length) > 1 ? '76px' : '64px')
    }
  }, _vm._l(_vm.navItems, function (nav, index) {
    var _domProps;

    return _c('div', {
      key: 'nav-' + index
    }, [!nav.children ? _c('router-link', {
      staticClass: "flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow",
      attrs: {
        "to": nav.url
      }
    }, [_c(nav.icon, {
      tag: "component"
    }), _c('span', {
      staticClass: "font-medium text-base"
    }, [_vm._v(_vm._s(nav.name))])], 1) : [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selected.item,
        expression: "selected.item"
      }],
      key: 'input + index',
      staticClass: "hidden",
      attrs: {
        "type": "radio",
        "name": "radio",
        "id": 'checkbox' + index
      },
      domProps: (_domProps = {
        "value": nav.name,
        "checked": _vm.selected.active === nav.active
      }, _domProps["checked"] = _vm._q(_vm.selected.item, nav.name), _domProps),
      on: {
        "click": function click($event) {
          return _vm.uncheck(nav);
        },
        "change": function change($event) {
          return _vm.$set(_vm.selected, "item", nav.name);
        }
      }
    }), _c('label', {
      key: 'label + index',
      staticClass: "cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow",
      attrs: {
        "for": 'checkbox' + index
      }
    }, [_c('div', {
      staticClass: "flex items-center gap-2"
    }, [_c(nav.icon, {
      tag: "component"
    }), _c('span', {
      staticClass: "font-medium text-base"
    }, [_vm._v(_vm._s(nav.name))])], 1), _c('Chevron', {
      attrs: {
        "direction": _vm.selected.item === nav.name ? 'up' : 'down',
        "width": "12",
        "color": "white"
      }
    })], 1), _vm.selected.item === nav.name && !nav.isHaveGrandChild ? _c('div', {
      staticClass: "bg-neutral",
      on: {
        "click": _vm.refreshPage
      }
    }, _vm._l(nav.children, function (childNav, idx) {
      return _c('router-link', {
        key: 'child-nav-' + idx,
        staticClass: "pl-10 flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow",
        attrs: {
          "to": childNav.url
        }
      }, [_c(childNav.icon, {
        tag: "component"
      }), _c('span', {
        staticClass: "font-medium text-sm"
      }, [_vm._v(_vm._s(childNav.name))]), _c('span', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: childNav.name === 'Document' && _vm.isNotoficaate,
          expression: "childNav.name === 'Document' && isNotoficaate"
        }]
      }, [_c('img', {
        staticClass: "hover:bg-yellow w-4 h-4",
        attrs: {
          "src": require("@/assets/images/activition.png"),
          "alt": "Message"
        }
      })])], 1);
    }), 1) : _vm._e(), _vm.selected.item === nav.name && nav.isHaveGrandChild ? _c('div', {
      staticClass: "bg-neutral"
    }, [_vm._l(nav.children, function (grandChild, grandChildIdx) {
      return _c('div', {
        key: 'grandChild-' + grandChildIdx,
        staticClass: "pl-4"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selected.child,
          expression: "selected.child"
        }],
        key: 'input + grandChildIdx',
        staticClass: "hidden",
        attrs: {
          "type": "radio",
          "id": 'grandChildIdx' + grandChildIdx
        },
        domProps: {
          "value": grandChild.name,
          "checked": _vm._q(_vm.selected.child, grandChild.name)
        },
        on: {
          "click": function click($event) {
            return _vm.uncheck2(grandChild);
          },
          "change": function change($event) {
            return _vm.$set(_vm.selected, "child", grandChild.name);
          }
        }
      }), _c('label', {
        key: 'label + grandChildIdx',
        staticClass: "cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow",
        attrs: {
          "for": 'grandChildIdx' + grandChildIdx
        }
      }, [_c('div', {
        staticClass: "flex items-center gap-2"
      }, [_c(grandChild.icon, {
        tag: "component"
      }), _c('span', {
        staticClass: "font-medium text-base"
      }, [_vm._v(_vm._s(grandChild.name))])], 1), _c('Chevron', {
        attrs: {
          "direction": _vm.selected.child === grandChild.name ? 'up' : 'down',
          "width": "12",
          "color": "white"
        }
      })], 1), _vm.selected.child === grandChild.name ? _c('div', {
        staticClass: "bg-neutral",
        on: {
          "click": _vm.refreshPage
        }
      }, _vm._l(grandChild.children, function (grandChildNav, idx) {
        return _c('router-link', {
          key: 'grand-child-nav-' + idx,
          staticClass: "pl-10 flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-neutral-300",
          attrs: {
            "to": grandChildNav.url
          }
        }, [_c(grandChildNav.icon, {
          tag: "component"
        }), _c('span', {
          staticClass: "font-medium text-sm"
        }, [_vm._v(_vm._s(grandChildNav.name))]), _c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: grandChildNav.name === 'Document' && _vm.isNotoficaate,
            expression: "grandChildNav.name === 'Document' && isNotoficaate"
          }]
        }, [_c('img', {
          staticClass: "hover:bg-neutral-300 w-4 h-4",
          attrs: {
            "src": require("@/assets/images/activition.png"),
            "alt": "Message"
          }
        })])], 1);
      }), 1) : _vm._e()]);
    })], 2) : _vm._e()]], 2);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }